import React from 'react';

import Tooltip from './../../Tooltip/Tooltip';

const StateIcon = (props) => {
	let content = null;
	let viewBox = '0 0 70 72';
	const disabled = props.disabled;
	switch (props.state) {
		case 'active':
			content = (
				<g>
					<defs>
						<style>{'.prefix__c{fill:none;stroke:#45413c;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.25px}'}</style>
					</defs>
					<title />
					<rect height={45.58} rx={8.747} ry={8.747} width={45.58} x={2.21} y={2.21} fill="#fff" />
					<path
						d="M30.282 43.466H17.008c-6.619 0-11.119-1.847-14.8-6.77v2.347a8.747 8.747 0 008.747 8.747h28.088a8.747 8.747 0 008.747-8.747V36.7c-3.678 4.919-10.89 6.766-17.508 6.766z"
						fill="#f0f0f0"
					/>
					<rect className="prefix__c" height={45.58} rx={8.747} ry={8.747} width={45.58} x={2.21} y={2.21} />
					<path
						d="M35.773 8.324C29.3 14.875 22.957 21.7 19.541 30.185q-3.128-2.622-6.385-5.095c-3.838-2.925-7.578 3.585-3.785 6.476 3.255 2.48 6.364 5.115 9.391 7.868 1.977 1.8 5.625 1.236 6.268-1.655 2.215-9.96 9.094-17.116 16.047-24.151 3.396-3.436-1.904-8.743-5.304-5.304z"
						fill="#009900"
					/>
					<path
						d="M13.777 29.089q2.724 2.076 5.36 4.243a.993.993 0 001.545-.376c3.515-7.924 9.554-14.4 15.713-20.631a3.559 3.559 0 015.413.3c1.694-3.348-2.952-7.422-6.035-4.3-6.473 6.55-12.816 13.375-16.232 21.86q-3.128-2.622-6.385-5.1C9.591 22.372 6.109 27.8 8.675 30.9c.736-2.064 2.903-3.487 5.102-1.811z"
						fill="#9ceb60"
					/>
					<path
						className="prefix__c"
						d="M35.773 8.324C29.3 14.875 22.957 21.7 19.541 30.185q-3.128-2.622-6.385-5.095c-3.838-2.925-7.578 3.585-3.785 6.476 3.255 2.48 6.364 5.115 9.391 7.868 1.977 1.8 5.625 1.236 6.268-1.655 2.215-9.96 9.094-17.116 16.047-24.151 3.396-3.436-1.904-8.743-5.304-5.304z"
					/>
				</g>
			);
			viewBox = '0 0 50 50';
			break;
		case 'protected':
			content = (
				<g opacity={disabled ? 0.5 : 1}>
					<path
						fill={disabled ? "rgb(168, 162, 162)" : "#009900"}
						d="M40.2 10.2l-.3-1-1.1-.1c-7.2-.6-16.2-6.7-16.3-6.7l-.8-.6-.7.5c-.1.1-9.1 6.2-16.3 6.7l-1 .1-.3 1c-.1.3-5.8 24.9 17.7 37.8l.7.4.7-.4C46 35 40.3 10.4 40.2 10.2z"
					/>
					<path
						fill="#231f20"
						d="M21.8 49c-.1 0-.2 0-.4-.1l-.7-.4C-3.3 35.4 2.6 10.2 2.7 10l.2-1c.1-.3.4-.6.7-.6l1.1-.1c7-.5 15.8-6.6 15.9-6.6l.8-.6c.3-.1.6-.1.8 0l.8.6c.2.1 9 6.1 16 6.6l1 .1c.3 0 .6.3.7.6l.3 1c.1.3 5.9 25.4-18.1 38.5l-.7.4c-.1.1-.3.1-.4.1zM4.3 9.9l-.1.5c-.1.2-5.6 24.3 17.4 36.9l.3.2.3-.2c22.9-12.5 17.4-36.6 17.4-36.9l-.1-.5H39c-7.6-.7-16.7-6.9-16.8-7l-.4-.3-.4.3c-.4.3-9.2 6.3-16.6 6.9l-.5.1z"
					/>
					<path fill={disabled ? "rgb(116, 116, 116)" : "#fecf12"} d="M21.8 42.5C7.3 33.6 7.3 19.2 7.9 13.6c5.5-1.2 11-4.2 13.9-5.9 2.9 1.8 8.4 4.8 13.9 5.9.7 5.6.6 20-13.9 28.9z" />
					<path
						fill="#231f20"
						d="M21.8 43.2c-.1 0-.3 0-.4-.1C6.6 34 6.5 19.4 7.2 13.6c0-.3.3-.6.6-.7 5.4-1.1 10.9-4.1 13.7-5.8.2-.1.5-.1.8 0 2.8 1.7 8.2 4.7 13.7 5.8.3.1.6.3.6.7.5 5.8.4 20.4-14.4 29.5-.1.1-.2.1-.4.1zM8.6 14.3c-.5 5.8 0 18.9 13.2 27.3C35.1 33.2 35.6 20.1 35 14.3c-5.2-1.2-10.4-3.9-13.2-5.7-2.8 1.7-8 4.5-13.2 5.7z"
					/>
					<path
						fill="#fff"
						d="M5.5 18.6c-.4 0-.7-.3-.7-.7-.1-2.7.1-4.9.4-6.3 0-.3.3-.6.6-.6.9-.1 1.8-.3 2.7-.6.4-.1.8.1.9.5.1.4-.1.8-.5.9-.9.2-1.7.4-2.4.5-.2 1.3-.4 3.3-.3 5.5 0 .4-.3.8-.7.8zM5.7 21.4c-.4 0-.7-.3-.7-.6 0-.2 0-.4-.1-.6 0-.4.3-.8.7-.8.4 0 .8.3.8.7 0 .2 0 .4.1.6 0 .4-.2.8-.6.8-.1-.1-.2-.1-.2-.1z"
					/>
					<path
						fill={disabled ? "rgb(168, 162, 162)" : "#009900"}
						d="M46.7 8s-.1-.1-.3-.1c-.2-.1-.4-.1-.7-.2-.3-.1-.7-.1-1.1-.1h-1.5c-1.2.1-2.5.5-3.9 1-1.6.7-3.4 1.6-5.1 2.9-2.1 1.5-4.2 3.3-6.4 5.6-2.3 2.4-5.2 6.4-7.6 9.6l-5.2-4.1c-.4-.2-.9-.1-1.2.2-.3.3-.4.8-.1 1.2l5.7 9.9c.2.3.5.5.9.5s.7-.2.9-.5c.4-.7 1.2-2.1 2.4-4 1.8-2.9 3.7-5.5 5.7-7.9 2.7-3.3 5.4-6 8-7.9 3.2-2.4 6.2-3.7 9.1-4h.1c.5 0 .8-.4.9-.8s-.2-1.1-.6-1.3z"
					/>
					<path
						fill="#231f20"
						d="M20.1 35.1c-.6 0-1.2-.3-1.5-.9l-5.7-9.9c-.4-.7-.3-1.5.2-2.1.5-.6 1.4-.7 2.1-.4l.1.1 4.6 3.6.6-.8c2.2-2.9 4.6-6.2 6.6-8.3 2.2-2.3 4.4-4.2 6.5-5.7 1.8-1.3 3.6-2.3 5.3-3 1.4-.6 2.8-.9 4.1-1 .5-.1 1.1-.1 1.6 0 .4 0 .8.1 1.2.1.4.1.6.2.8.2.3.3.4.3.4.3l-.3.7.3-.7c.7.4 1.1 1.1.9 1.9-.2.8-.8 1.3-1.6 1.4h-.1c-2.7.3-5.6 1.6-8.7 3.9-2.6 1.9-5.2 4.6-7.9 7.8-2 2.4-3.9 5-5.7 7.9-1.2 1.9-2 3.3-2.4 4-.1.6-.7.9-1.4.9zm-5.7-11.8c-.1 0-.1 0-.2.1s-.1.2 0 .3l5.7 9.9c.1.2.4.2.4 0 .4-.7 1.2-2.2 2.4-4.1 1.8-2.9 3.8-5.6 5.8-8 2.7-3.3 5.5-6 8.2-8 3.3-2.5 6.4-3.9 9.4-4.2h.1c.1 0 .2-.1.3-.2 0-.1 0-.2-.1-.3 0 0-.1 0-.2-.1-.1 0-.3-.1-.6-.2s-.6-.1-1-.1h-1.4c-1.1.1-2.4.4-3.7.9-1.6.6-3.3 1.6-5 2.8-2 1.4-4.2 3.3-6.3 5.5-1.9 2-4.4 5.2-6.5 8.1l-1.5 2.1-5.8-4.5c.1 0 .1 0 0 0z"
					/>
				</g>
			);
			viewBox = '0 0 50 50';
			break;
		case 'unprotected':
			content = (
				<g opacity={disabled ? 0.5 : 1}>
					<path
						fill="#666"
						d="M43.562 9.98l-.3-1-1.1-.1c-7.2-.6-16.2-6.7-16.3-6.7l-.8-.6-.7.5c-.1.1-9.1 6.2-16.3 6.7l-1 .1-.3 1c-.1.3-5.8 24.9 17.7 37.8l.7.4.7-.4c23.5-12.9 17.8-37.5 17.7-37.7z"
					/>
					<path
						fill="#231f20"
						d="M25.162 48.78c-.1 0-.2 0-.4-.1l-.7-.4c-24-13.1-18.1-38.3-18-38.5l.2-1c.1-.3.4-.6.7-.6l1.1-.1c7-.5 15.8-6.6 15.9-6.6l.8-.6c.3-.1.6-.1.8 0l.8.6c.2.1 9 6.1 16 6.6l1 .1c.3 0 .6.3.7.6l.3 1c.1.3 5.9 25.4-18.1 38.5l-.7.4c-.1.1-.3.1-.4.1zm-17.5-39.1l-.1.5c-.1.2-5.6 24.3 17.4 36.9l.3.2.3-.2c22.9-12.5 17.4-36.6 17.4-36.9l-.1-.5h-.5c-7.6-.7-16.7-6.9-16.8-7l-.4-.3-.4.3c-.4.3-9.2 6.3-16.6 6.9l-.5.1z"
					/>
					<path
						fill="#b2b2b2"
						d="M25.162 42.28c-14.5-8.9-14.5-23.3-13.9-28.9 5.5-1.2 11-4.2 13.9-5.9 2.9 1.8 8.4 4.8 13.9 5.9.7 5.6.6 20-13.9 28.9z"
					/>
					<path
						fill="#999"
						d="M25.162 42.98c-.1 0-.3 0-.4-.1-14.8-9.1-14.9-23.7-14.2-29.5 0-.3.3-.6.6-.7 5.4-1.1 10.9-4.1 13.7-5.8.2-.1.5-.1.8 0 2.8 1.7 8.2 4.7 13.7 5.8.3.1.6.3.6.7.5 5.8.4 20.4-14.4 29.5-.1.1-.2.1-.4.1zm-13.2-28.9c-.5 5.8 0 18.9 13.2 27.3 13.3-8.4 13.8-21.5 13.2-27.3-5.2-1.2-10.4-3.9-13.2-5.7-2.8 1.7-8 4.5-13.2 5.7z"
					/>
					<path
						fill="#fff"
						d="M8.862 18.38c-.4 0-.7-.3-.7-.7-.1-2.7.1-4.9.4-6.3 0-.3.3-.6.6-.6.9-.1 1.8-.3 2.7-.6.4-.1.8.1.9.5.1.4-.1.8-.5.9-.9.2-1.7.4-2.4.5-.2 1.3-.4 3.3-.3 5.5 0 .4-.3.8-.7.8zM9.062 21.18c-.4 0-.7-.3-.7-.6 0-.2 0-.4-.1-.6 0-.4.3-.8.7-.8.4 0 .8.3.8.7 0 .2 0 .4.1.6 0 .4-.2.8-.6.8-.1-.1-.2-.1-.2-.1z"
					/>
					<path opacity={disabled ? 0.35 : 1} strokeOpacity="null" strokeWidth="null" stroke="null" fill="#bf0000" d="M19.928 15.893l12.783 12.783-2.143 2.144-12.783-12.783z" />
					<path opacity={disabled ? 0.35 : 1} strokeOpacity="null" strokeWidth="null" stroke="null" fill="#bf0000" d="M17.785 28.676l12.783-12.783 2.143 2.144L19.928 30.82z" />
				</g>
			);
			viewBox = '0 0 50 50';
			break;
		case 'locked':
			content = (
				<g>
					<path
						fill="#999"
						d="M43.562 9.98l-.3-1-1.1-.1c-7.2-.6-16.2-6.7-16.3-6.7l-.8-.6-.7.5c-.1.1-9.1 6.2-16.3 6.7l-1 .1-.3 1c-.1.3-5.8 24.9 17.7 37.8l.7.4.7-.4c23.5-12.9 17.8-37.5 17.7-37.7z"
					/>
					<path
						fill="#231f20"
						d="M25.162 48.78c-.1 0-.2 0-.4-.1l-.7-.4c-24-13.1-18.1-38.3-18-38.5l.2-1c.1-.3.4-.6.7-.6l1.1-.1c7-.5 15.8-6.6 15.9-6.6l.8-.6c.3-.1.6-.1.8 0l.8.6c.2.1 9 6.1 16 6.6l1 .1c.3 0 .6.3.7.6l.3 1c.1.3 5.9 25.4-18.1 38.5l-.7.4c-.1.1-.3.1-.4.1zm-17.5-39.1l-.1.5c-.1.2-5.6 24.3 17.4 36.9l.3.2.3-.2c22.9-12.5 17.4-36.6 17.4-36.9l-.1-.5h-.5c-7.6-.7-16.7-6.9-16.8-7l-.4-.3-.4.3c-.4.3-9.2 6.3-16.6 6.9l-.5.1z"
					/>
					<path
						fill="#62584e"
						d="M25.162 42.28c-14.5-8.9-14.5-23.3-13.9-28.9 5.5-1.2 11-4.2 13.9-5.9 2.9 1.8 8.4 4.8 13.9 5.9.7 5.6.6 20-13.9 28.9z"
					/>
					<path
						fill="#999"
						d="M25.162 42.98c-.1 0-.3 0-.4-.1-14.8-9.1-14.9-23.7-14.2-29.5 0-.3.3-.6.6-.7 5.4-1.1 10.9-4.1 13.7-5.8.2-.1.5-.1.8 0 2.8 1.7 8.2 4.7 13.7 5.8.3.1.6.3.6.7.5 5.8.4 20.4-14.4 29.5-.1.1-.2.1-.4.1zm-13.2-28.9c-.5 5.8 0 18.9 13.2 27.3 13.3-8.4 13.8-21.5 13.2-27.3-5.2-1.2-10.4-3.9-13.2-5.7-2.8 1.7-8 4.5-13.2 5.7z"
					/>
					<path
						fill="#fff"
						d="M8.862 18.38c-.4 0-.7-.3-.7-.7-.1-2.7.1-4.9.4-6.3 0-.3.3-.6.6-.6.9-.1 1.8-.3 2.7-.6.4-.1.8.1.9.5.1.4-.1.8-.5.9-.9.2-1.7.4-2.4.5-.2 1.3-.4 3.3-.3 5.5 0 .4-.3.8-.7.8zM9.062 21.18c-.4 0-.7-.3-.7-.6 0-.2 0-.4-.1-.6 0-.4.3-.8.7-.8.4 0 .8.3.8.7 0 .2 0 .4.1.6 0 .4-.2.8-.6.8-.1-.1-.2-.1-.2-.1z"
					/>
					<svg width="100%" viewBox="0 0 128 128">
						<g>
							<path d="M54.808 56.383v-8.596a9.193 9.193 0 1118.387 0v8.596h5.594v-8.596a14.787 14.787 0 10-29.574 0v8.596z" fill="#dbdbdb" />
							<rect height={28.076} rx={2.812} ry={2.812} width={37.5} x={45.25} y={54.924} fill="#edcb48" />
							<path
								d="M68.745 66.203a4.744 4.744 0 10-7.322 3.943 1.492 1.492 0 01.694 1.254V75.3a1.124 1.124 0 001.13 1.119h1.506a1.124 1.124 0 001.13-1.119V71.4a1.462 1.462 0 01.67-1.239 4.682 4.682 0 002.192-3.958z"
								fill="#4f5144"
							/>
							{/* <path d="M118.188 22.588A215.873 215.873 0 0164 0v128c63.22-42.262 54.188-105.412 54.188-105.412z" opacity={0.05} /> */}
						</g>
					</svg>
				</g>
			);
			viewBox = '0 0 50 50';
			break;
		default:
			content = (
				<g>
					{/*<defs>
						<radialGradient cx="50%" cy="50%" fx="50%" fy="50%" id="prefix__b" r="50%">
							<stop offset="0%" stopColor="#ff1919" />
							<stop offset="72.158%" stopColor="#ff1919" />
							<stop offset="100%" stopColor="#860000" />
						</radialGradient>
						<linearGradient id="prefix__a" x1="48.97%" x2="64.516%" y1="93.866%" y2="32.567%">
							<stop offset="0%" stopColor="#ff1919" />
							<stop offset="43.624%" stopColor="#ff1919" />
							<stop offset="100%" stopColor="#ff1919" />
						</linearGradient>
					</defs>*/}
					<path d="M85.32 22.94h22.42v143.31H85.32z" fill="red" strokeWidth="5" stroke="darkred" transform="rotate(-771.3 96.53 94.59)" />
					<path
						d="M96 9.2c47.94 0 86.8 38.86 86.8 86.8s-38.86 86.8-86.8 86.8S9.2 143.94 9.2 96 48.06 9.2 96 9.2zm-.11 22.3c-35.63.06-64.45 28.99-64.39 64.61.06 35.63 28.99 64.45 64.61 64.39 35.63-.06 64.45-28.99 64.39-64.61-.06-35.63-28.99-64.45-64.61-64.39z"
						fill="red"
						stroke="darkred"
						strokeWidth="5"
						transform="rotate(-359.9 96 96)"
					/>
					<path
						d="M94.66 29.48c35.29 0 63.89 30.34 63.89 67.76S129.95 165 94.66 165c-35.28 0-63.88-30.34-63.88-67.76s28.6-67.76 63.88-67.76z"
						fill="none"
					/>
				</g>
			);
			viewBox = '0 0 192 192';
			break;
	}
	return (
		<div {...props}>
			<Tooltip tooltip={props.tooltip}>
				<svg width="100%" viewBox={viewBox}>
					{content}
				</svg>
			</Tooltip>
		</div>
	);
};

export default StateIcon;
