import React from 'react';

const invalidField = props => {
	return (
		<div {...props}>
			<svg width="100%" viewBox="0 0 342 332">
				<g>
					<path
						fill="#5EAD46"
						d="M28.028 148.487c-34.842 40.744-32.203 95.509 4.655 81.153 21.7-8.452 49.81-65.873 56.113-97.316 6.304-31.443-25.928-24.578-60.768 16.163zM315.432 148.487c34.842 40.744 32.203 95.509-4.655 81.153-21.701-8.452-49.811-65.873-56.113-97.316-6.305-31.443 25.926-24.578 60.768 16.163z"
					/>
					<path
						fill="#51943B"
						d="M292.868 214.192c-17.05-21.386-33.555-58.68-38.204-81.867-4.147-20.688 8.386-24.788 27.368-13.573-2.198 13.9-6.545 44.569-4.108 56.02 2.136 10.03 9.976 28.359 14.944 39.42z"
					/>
					<path
						opacity={0.32}
						fill="#3E3E3E"
						d="M315.01 291.922c0-13.746-65.611-24.887-146.547-24.887-80.935 0-146.547 11.142-146.547 24.887 0 4.503 7.079 8.723 19.397 12.366-3.976 3.022 62.812 15.529 145.215 12.325 82.403-3.204 128.483-11.985 128.483-24.691z"
					/>
					<path
						fill="#5EAD46"
						d="M240.192 290.347c-38.251-9.272-93.693-9.851-133.452 2.037-15.122 5.187-64.93 16.06-59.89-30.444 14.686-76.156 23.64-148.643 19.243-212.583 0-15.12 12.369-27.488 27.489-27.488L247.52 4.459c15.119 0 27.49 12.369 27.49 27.489-5.298 67.483 6.28 147.009 21.99 229.993 6.874 44.44-39.585 32.9-56.81 28.406z"
					/>
					<path
						fill="#51943B"
						d="M78.139 40.042c10.404-11.758 61.117-17.251 71.108-21.424 1.653-.691 3.437-1.981 5.26-3.64L93.583 21.87c-15.12 0-27.49 12.369-27.49 27.488.952 13.82 1.276 28.042 1.056 42.613 2.227-22.23 5.765-46.024 10.99-51.93z"
					/>
					<ellipse
						transform="rotate(-67.728 206.449 163.175)"
						ry={8.47}
						rx={11.144}
						opacity={0.15}
						fill="#0D6C3F"
						cy={38.403}
						cx={273.29}
					/>
					<ellipse
						transform="rotate(-67.736 245.653 173.97)"
						ry={6.31}
						rx={8.304}
						opacity={0.15}
						fill="#0D6C3F"
						cy={49.224}
						cx={312.519}
					/>
					<ellipse
						transform="rotate(-65.821 294.258 153.81)"
						ry={9.301}
						rx={10.842}
						opacity={0.15}
						fill="#0D6C3F"
						cy={26.89}
						cx={356.931}
					/>
					<circle r={9.361} opacity={0.15} fill="#0D6C3F" cy={44.667} cx={237.779} />
					<ellipse ry={61.153} rx={61.16} fill="#FFF" cy={104.689} cx={224.358} />
					<ellipse ry={61.153} rx={61.16} fill="#FFF" cy={104.689} cx={120.056} />
					<ellipse ry={14.343} rx={14.337} fill="#3F2945" cy={105.115} cx={228.628} />
					<ellipse ry={14.343} rx={14.337} fill="#3F2945" cy={105.115} cx={116.303} />
					<path
						fill="#EEC75E"
						d="M208.739 160.433l17.116 17.116a12.057 12.057 0 010 17.116l-69.413 69.412a12.055 12.055 0 01-17.116 0l-17.03-17.116a12.052 12.052 0 010-17.116l69.413-69.412c4.668-4.667 12.362-4.667 17.03 0z"
					/>
					<path
						fill="#F2D27E"
						d="M180.472 188.096l17.72 17.72c2.42 2.419 2.42 6.397 0 8.819l-21.696 21.694c-2.421 2.422-6.396 2.422-8.817 0l-17.72-17.721c-2.42-2.42-2.42-6.396 0-8.817l21.698-21.696c2.417-2.421 6.395-2.421 8.815 0z"
					/>
					<path
						fill="#CAA747"
						d="M172.088 199.074c-.866.863-.866 2.247 0 3.024.862.865 2.246.865 3.023 0 .867-.865.867-2.246 0-3.024-.777-.866-2.161-.866-3.023 0zM166.036 205.124c-.865.865-.865 2.246 0 3.026.865.865 2.247.865 3.026 0 .865-.865.865-2.25 0-3.026-.78-.866-2.162-.866-3.026 0zM159.985 211.174c-.866.865-.866 2.249 0 3.027.863.865 2.247.865 3.024 0 .866-.865.866-2.25 0-3.027-.776-.863-2.16-.863-3.024 0zM178.137 205.124c-.865.865-.865 2.246 0 3.026.865.865 2.249.865 3.026 0 .865-.865.865-2.25 0-3.026-.777-.866-2.162-.866-3.026 0zM172.088 211.174c-.866.865-.866 2.249 0 3.027.862.865 2.246.865 3.023 0 .867-.865.867-2.25 0-3.027-.777-.863-2.161-.863-3.023 0zM166.036 217.225c-.865.865-.865 2.247 0 3.026.865.865 2.247.865 3.026 0 .865-.865.865-2.247 0-3.026-.78-.865-2.162-.865-3.026 0zM184.188 211.174c-.865.865-.865 2.249 0 3.027.865.865 2.247.865 3.026 0 .866-.865.866-2.25 0-3.027-.778-.863-2.161-.863-3.026 0zM178.137 217.225c-.865.865-.865 2.247 0 3.026.865.865 2.249.865 3.026 0 .865-.865.865-2.247 0-3.026-.777-.865-2.162-.865-3.026 0zM172.088 223.278c-.866.863-.866 2.247 0 3.024.862.865 2.246.865 3.023 0 .867-.865.867-2.247 0-3.024-.777-.866-2.161-.866-3.023 0z"
					/>
					<path
						fill="#EEC75E"
						d="M162.715 158.672l69.412 69.412a12.055 12.055 0 010 17.116l-17.03 17.116a12.05 12.05 0 01-17.115 0l-69.413-69.412a12.055 12.055 0 010-17.116l17.115-17.116c4.667-4.667 12.362-4.667 17.03 0z"
					/>
					<path
						fill="#F2D27E"
						d="M182.768 186.334l21.696 21.696c2.42 2.42 2.42 6.397 0 8.817l-17.72 17.72c-2.42 2.423-6.395 2.423-8.816 0l-21.697-21.693c-2.42-2.422-2.42-6.4 0-8.818l17.72-17.721c2.42-2.422 6.397-2.422 8.817-.001z"
					/>
					<path
						fill="#CAA747"
						d="M179.31 197.314c-.865.777-.865 2.159 0 3.023.778.865 2.162.865 3.025 0 .866-.777.866-2.161 0-3.023-.863-.867-2.247-.867-3.024 0zM185.362 203.362c-.865.777-.865 2.16 0 3.026.777.865 2.16.865 3.026 0 .865-.78.865-2.161 0-3.026-.864-.865-2.25-.865-3.026 0zM191.412 209.414c-.866.777-.866 2.16 0 3.025.778.864 2.16.864 3.025 0 .866-.777.866-2.163 0-3.025-.863-.865-2.246-.865-3.025 0zM173.26 203.362c-.864.777-.864 2.16 0 3.026.778.865 2.16.865 3.026 0 .865-.78.865-2.161 0-3.026-.865-.865-2.248-.865-3.026 0zM179.31 209.414c-.865.777-.865 2.16 0 3.025.778.864 2.162.864 3.025 0 .866-.777.866-2.163 0-3.025-.863-.865-2.247-.865-3.024 0zM185.362 215.465c-.865.777-.865 2.16 0 3.024.777.865 2.16.865 3.026 0 .865-.778.865-2.161 0-3.024-.864-.867-2.25-.867-3.026 0zM167.209 209.414c-.866.777-.866 2.16 0 3.025.777.864 2.16.864 3.026 0 .865-.777.865-2.163 0-3.025-.864-.865-2.248-.865-3.026 0zM173.26 215.465c-.864.777-.864 2.16 0 3.024.778.865 2.16.865 3.026 0 .865-.778.865-2.161 0-3.024-.865-.867-2.248-.867-3.026 0zM179.31 221.517c-.865.778-.865 2.16 0 3.024.778.865 2.162.865 3.025 0 .866-.778.866-2.161 0-3.024-.863-.866-2.247-.866-3.024 0z"
					/>
				</g>
			</svg>
		</div>
	);
};

export default invalidField;
