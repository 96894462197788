import * as appActions from './../actions/appActionsTypes';

const initalState = {
	serviceWorkerInitialized: false,
	serviceWorkerUpdated: false,
	serviceWorkerRegistration: null,
	updateRefused: false
};

const initApp = (state, action) => {
	return {
		...state,
		serviceWorkerInitialized: true
	};
};

const updateApp = (state, action) => {
	return {
		...state,
		serviceWorkerUpdated: true,
		serviceWorkerRegistration: action.payload
	};
};

const refuseUpdate = (state, action) => {
	return {
		...state,
		updateRefused: true
	};
};

const appReducer = (state = initalState, action) => {
	switch (action.type) {
		case appActions.SW_INIT:
			return initApp(state, action);
		case appActions.SW_UPDATE:
			return updateApp(state, action);
		case appActions.REFUSE_UPDATE:
			return refuseUpdate(state, action);
		default:
			return state;
	}
};

export default appReducer;
