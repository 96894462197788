import React from 'react';
import { connect } from 'react-redux';

import { formateDatetime } from "./../../../../../utils/dateFunction";


import Time from "./../../../../icons/Time/Time";

import NotAvailable from "./../NotAvailable/NotAvailable.jsx";


import classes from "./Time.module.scss";


const time = (props) => {

    let content = null;
    if (!props.hidden) {
        let icon = <NotAvailable />;

        if (props.time) {
            icon = (
                <React.Fragment>
                    <Time
                        className={classes.iconState}
                        tooltip={props.tooltip}
                    />
                    {props.time ? <p className={classes.label}>{formateDatetime(props.time, props.lang)}</p> : null}
                </React.Fragment>
            );
        }

        content = (
            <div className={props.className}>
                {/*<i className={stateClasses.join(' ')} data-tip={props.tooltip} data-for="descriptorState" />*/}
                <span className={classes.container}>
                    {icon}
                </span>
            </div>
        );
    }
    return content;

};

const mapStateToProps = state => {
    return {
        lang: state.locale.lang,
    };
};

export default connect(mapStateToProps, null)(time);