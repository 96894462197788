import React from "react";

import classes from "./Type2.module.scss";

const type2 = (props) => {
  let type = "checkbox";
  let btnStyle = null;
  let toggleClass = [classes.toggle];
  const containerClasses = [classes["page__toggle"]];

  if (props.type) {
    type = props.type;
  }
  if (props.toggleClass) toggleClass.push(props.toggleClass);
  if (props.readOnly) btnStyle = { cursor: "not-allowed" };
  if (props.formStyling) {
    containerClasses.push("formItem");
    toggleClass.push("UI-input");
  }

  return (
    <div className={containerClasses.join(" ")}>
      {props.formStyling && <label className="inputTitle" />}
      <label className={toggleClass.join(" ")}>
        <input
          className={
            props.className
              ? [classes["toggle__input"], props.className].join(" ")
              : classes["toggle__input"]
          }
          id={props.id}
          type={type}
          name={props.groupName}
          checked={props.checked}
          onChange={props.inputChange}
          disabled={props.readOnly}
          dummy-data={props["dummy-data"]}
        />
        <span className={[classes["toggle__label"]].join(" ")} style={btnStyle}>
          <span className={classes["toggle__text"]}>{props.text}</span>
        </span>
      </label>
    </div>
  );
};

export default type2;
