// @flow
import {
	CLEAR_SENSORS,
	FETCH_SENSORS,
	FILTER_SENSORS,
	FETCH_SENSOR_BY_ID,
	POST_SENSOR,
	EDIT_SENSOR,
	DELETE_SENSOR,
	ACTIVATE_SENSOR,
	CHANGE_LOADING_STATUS,
	CHANGE_SUCCESS_STATUS,
	HANDLE_REMOTE_ERROR,
	CLEAR_REMOTE_ERROR,
	FETCH_SENSOR_MEASUREMENTS,
	CLEAR_MEASUREMENTS,
	FETCH_SENSOR_MULTIPLE_DATA,
	CHANGE_GRAPH_LOADING_STATUS,
	CHANGE_CHUNK,
	SET_CHUNK_SIZE,
	PROTECT_DEVICE,
} from './sensorActionTypes';

import { sensorAdded, sensorDeleted, sensorUpdated, sensorDecoded, sensorEncoded, sensorLocked } from '../../utils/toasts';

import { setCoins } from './coinsActions';

import axios from './../../axios/axios.js';
import { unmarshall } from './../../utils/awsMarshaller';

const fetchAllData = (method, url, params, customData, dataFieldName, lastEvalKey = null, completeFetch = true) => {
	let data = [];
	let config = {
		method: method,
	};
	if (data) {
		config.data = customData;
	}
	if (params) {
		config.params = params;
	}
	if (method === 'get' && lastEvalKey) {
		config.params = { ...params, lastEvaluatedKey: encodeURIComponent(JSON.stringify(lastEvalKey)) };
	} else {
		config.data = { ...data, lastEvaluatedKey: lastEvalKey };
	}

	return axios(url, config).then((resp) => {
		let data = resp.data[dataFieldName];

		if (resp.data.hasOwnProperty('LastEvaluatedKey') && completeFetch) {
			return fetchAllData(method, url, params, customData, dataFieldName, resp.data.LastEvaluatedKey, true).then((nextData) => data.concat(nextData));
		} else {
			return resp.data[dataFieldName];
		}
	});
};

export const fetchSensorsDirect = (showSpinner = false) => {
	return (dispatch) => {
		if (showSpinner) dispatch(changeLoadingStatus(true));

		fetchAllData('get', '/direct/sensors/', null, null, 'Items')
			.then((result) => {
				const sensors = {
					data: {
						siglink_devices: result.map((item) => unmarshall(item)),
					},
				};
				dispatch({ type: FETCH_SENSORS, payload: { sensors } });
			})
			.catch((err) => {
				dispatch(handleRemoteError(err, 'fetchSensorsDirect'));
			})
			.finally(() => {
				if (showSpinner) dispatch(changeLoadingStatus(false));
			});
	};
};
export const fetchSensors = (showSpinner = false, scanAll = false) => {
	return (dispatch) => {
		if (showSpinner) dispatch(changeLoadingStatus(true));


		fetchAllData("get", '/sensors/', { scanAll }, null, "siglink_devices")
			.then((siglink_devices) => {
				const sensors = {
					data: {
						siglink_devices
					}
				};
				dispatch({ type: FETCH_SENSORS, payload: { sensors } });
			})
			.catch((err) => {
				dispatch(handleRemoteError(err, 'fetchSensors'));
			})
			.finally(() => {
				if (showSpinner) dispatch(changeLoadingStatus(false));
			});
	};
};

export const fetchSensorByIdDirect = (id, type) => {
	return (dispatch) => {
		dispatch(changeLoadingStatus(true));
		axios
			.get(`/direct/sensors/${id}/`, { params: { type: type } })
			// .put(`/direct/sensors/${id}/`, [], { params: { type: type, protection: 'ON' } })
			.then((result) => {
				// console.log(result);
				const sensors = result.data.Items;
				if (sensors.length !== 1) {
					const err = {
						response: {
							status: 401,
							data: {
								error_message: 'Unauthorized',
							},
						},
					};
					dispatch(handleRemoteError(err, 'fetchSensorByIdDirect'));
				} else {
					const sensor = unmarshall(sensors[0]);
					dispatch({ type: FETCH_SENSOR_BY_ID, payload: { sensor } });
				}
			})
			.catch((err) => {
				dispatch(handleRemoteError(err, 'fetchSensorByIdDirect'));
			})
			.finally(() => {
				dispatch(changeLoadingStatus(false));
			});
	};
};

export const fetchSensorById = (id, type) => {
	return (dispatch) => {
		dispatch(changeLoadingStatus(true));
		axios
			.get(`/sensors/${id}`, { params: { type: type } })
			.then((sen) => {
				const sensor = { id, ...sen.data };
				dispatch({ type: FETCH_SENSOR_BY_ID, payload: { sensor } });
			})
			.catch((err) => {
				dispatch(handleRemoteError(err, 'fetchSensorById'));
			})
			.finally(() => {
				dispatch(changeLoadingStatus(false));
			});
	};
};

/*
export const fetchSensorGlobal = (id, from, to, update="all") => {
	return dispatch => {
		dispatch(changeLoadingStatus(true));
		const requestParams = {
			params:{
				from: from,
				to: to
			},
			headers:{
				"X-iot-gsol-secret": id
			}
		};
		axiosGlobal
			.get(`https://backend.iotpool.io/show`, requestParams)
			.then(globalSensor => {
				const sensor = { id, ...sen.data };
				dispatch({ type: FETCH_SENSOR_BY_ID, payload: { sensor } });
			})
			.catch(err => {
				dispatch(handleRemoteError(err, 'fetchSensorById'));
			})
			.finally(() => {
				dispatch(changeLoadingStatus(false));
			});
	};
};
*/

export const fetchSensorMeasurements = (id, type, timestampFrom, timestampTo, numOfRecs = null, condtions = {}) => {
	const params = {
		from: timestampFrom,
		to: timestampTo,
		type: type,
		numOfRecs: numOfRecs,
		recConditions: condtions,
	};

	return (dispatch) => {
		dispatch(changeGraphLoadingStatus(true));

		fetchAllData('get', `/sensors/${id}/measurements`, params, null, 'data', null)
			.then((measurements) => {
				dispatch({
					type: FETCH_SENSOR_MEASUREMENTS,
					payload: { id, measurements },
				});
			})
			.catch((err) => {
				dispatch(handleRemoteError(err, 'fetchSensorMeasurements'));
			})
			.finally(() => {
				dispatch(changeGraphLoadingStatus(false));
			});
	};
};

export const fetchMultipleData = (config, from, to) => {
	return (dispatch) => {
		dispatch(changeGraphLoadingStatus(true));

		const promises = [];

		config.forEach((sensorInfo) => {
			let params = {
				from: from,
				to: to,
				type: sensorInfo.type,
				series: sensorInfo.series.join('%'),
			};
			promises.push(fetchAllData('get', `/sensors/${sensorInfo.id}/measurements`, params, null, 'data', null));
		});

		Promise.all(promises)
			.then((result) => {
				const output = {};
				let i = 0;

				config.forEach((sensorInfo) => {
					sensorInfo.series.forEach((seriename) => {
						const data = [];
						for (let j = 0; j < result[i].length; j++) {
							const item = result[i][j];
							if (item.hasOwnProperty(seriename)) {
								data.push({
									id: item.id,
									timestamp: item.timestamp,
									[seriename]: item[seriename],
								});
							}
						}

						output[`${sensorInfo.id}_${seriename}`] = {
							id: sensorInfo.id,
							setname: seriename,
							data: data,
						};
					});
					i++;
				});

				dispatch({
					type: FETCH_SENSOR_MULTIPLE_DATA,
					payload: output,
				});
			})
			.catch((err) => {
				dispatch(handleRemoteError(err, 'fetchMultipleData'));
			})
			.finally(() => {
				dispatch(changeGraphLoadingStatus(false));
			});
	};
};

export const postSensor = (sensor, showLoader, renderOnSuccess = '/', history = null) => {
	return (dispatch) => {
		dispatch(changeLoadingStatus(showLoader));
		axios
			.post('/sensors/', sensor)
			.then((res) => {
				sensor.status = 'loading';
				if (res.data.credits) {
					dispatch(setCoins(res.data.credits));
				}
				dispatch({ type: POST_SENSOR, payload: { sensor } });
				if (history) history.push(renderOnSuccess);
				sensorAdded();
			})
			.catch((err) => {
				let eventId = 'https.unknown';
				if (err && err.response.status === 409) {
					switch (err.response.data.error_message) {
						case 'Insufficient ammount of credits':
							eventId = 'https.addSensor.insufficientAmmount';
							break;
						case 'The conditional request failed':
							eventId = 'https.addSensor.alreadyAdded';
							break;
						default:
							break;
					}
				} else if (err && err.response.status === 400) {
					eventId = 'https.invalidField';
					if (err && err.response && err.response.data && err.response.data.error_message) {
						if (err.response.data.error_message.startsWith('Sigfox communication error - during event: REGISTER')) {
							eventId = 'https.addSensor.sensorTypeMissmatch';
						}
					}
				}
				dispatch(handleRemoteError(err, 'postSensor', eventId));
			})
			.finally(() => {
				dispatch(changeLoadingStatus(false));
			});
	};
};

export const protectSensor = (id, type, name, protection) => {
	return (dispatch) => {
		dispatch(protectDeviceState(id, type, { status: 'loading' }));
		axios
			.put(`/sensors/${id}`, { id, type, name, protected: protection })
			.then((result) => {
				const sensor = result.data;
				sensor.status = null;
				//dispatch(protectDeviceState(id, type, { status: null, protected: protection }));
				dispatch({ type: EDIT_SENSOR, payload: { sensor } });
				if (sensor.force_locked) {
					sensorLocked(sensor.protected);
				} else {
					sensor.protected ? sensorEncoded() : sensorDecoded();
				}
			})
			.catch((err) => {
				if (err && err.response && err.response.status === 400) {
					dispatch(handleRemoteError(err, 'protectSensor', 'https.invalidField'));
				} else {
					dispatch(handleRemoteError(err, 'protectSensor'));
				}
				dispatch(protectDeviceState(id, type, { status: null }));
			});
	};
};

export const editSensor = (sensor, showLoader = true, renderOnSuccess = '/', history = null) => {
	return (dispatch) => {
		dispatch(changeLoadingStatus(showLoader));
		axios
			.put(`/sensors/${sensor.id}`, sensor)
			.then(() => {
				sensor.status = 'loading';
				dispatch({ type: EDIT_SENSOR, payload: { sensor } });
				if (history) history.push(renderOnSuccess);
				sensorUpdated();
			})
			.catch((err) => {
				if (err && err.response && err.response.status === 400) {
					dispatch(handleRemoteError(err, 'editSensor', 'https.invalidField'));
				} else {
					dispatch(handleRemoteError(err, 'editSensor'));
				}
			})
			.finally(() => {
				dispatch(changeLoadingStatus(false));
			});
	};
};

export const deleteSensor = (id, type, deleteData = false, showSpinner = false) => {
	return (dispatch) => {
		if (showSpinner) dispatch(changeLoadingStatus(true));

		axios
			.delete(`/sensors/${id}`, { params: { type: type, delData: deleteData } })
			.then(() => {
				sensorDeleted();
				dispatch({ type: DELETE_SENSOR, payload: { id } });
			})
			.catch((err) => {
				dispatch(handleRemoteError(err, 'deleteSensor'));
			})
			.finally(() => {
				if (showSpinner) dispatch(changeLoadingStatus(false));
			});
	};
};

export const activateSensor = (sensor_id) => {
	return {
		type: ACTIVATE_SENSOR,
		id: sensor_id,
	};
};

export const changeLoadingStatus = (isLoading) => ({
	type: CHANGE_LOADING_STATUS,
	payload: { isLoading },
});

export const changeGraphLoadingStatus = (isGraphLoading) => ({
	type: CHANGE_GRAPH_LOADING_STATUS,
	payload: { isGraphLoading },
});

export const changeSuccessStatus = (isSuccess) => ({
	type: CHANGE_SUCCESS_STATUS,
	payload: { isSuccess },
});

export const handleRemoteError = (error, eventName, eventId = 'https.unknown') => {
	return {
		type: HANDLE_REMOTE_ERROR,
		payload: { error, eventName, eventId },
	};
};

export const clearRemoteError = () => {
	return {
		type: CLEAR_REMOTE_ERROR,
	};
};

export const clearSensors = () => {
	return {
		type: CLEAR_SENSORS,
	};
};

export const setChunkSize = (size) => {
	return {
		type: SET_CHUNK_SIZE,
		chunkSize: size,
	};
};

export const changeChunk = (chunkNumber) => {
	return {
		type: CHANGE_CHUNK,
		chunkNumber: chunkNumber,
	};
};

export const filterSensors = (filter) => {
	return {
		type: FILTER_SENSORS,
		filter: filter,
	};
};

export const clearMeasurements = () => {
	return {
		type: CLEAR_MEASUREMENTS,
	};
};

export const protectDeviceState = (id, type, sensorProps) => {
	return {
		type: PROTECT_DEVICE,
		payload: { id, type, sensorProps },
	};
};
