import React from "react";
import GeneralModal from "./../General/General.jsx";
import { FormattedMessage } from "react-intl";

import InsertToken from "./InsertToken/InsertToken.jsx";
import ActivationResult from "./ActivationResult/ActivationResult.jsx";

const actiovationMessages = {
  progress: <FormattedMessage id="modal.topup.verifying" defaultMessage="Veryfying..." />,
  success: <FormattedMessage id="modal.topup.success" defaultMessage="Success"/>,
  failed: <FormattedMessage id="modal.topup.failed" defaultMessage="Failure" />
};

const modalActivate = props => {
  let content = null;
  let closeOnEsc = true;
  let closeOnOverlayClick = true;

  switch (props.state) {
    case "IN_PROGRESS":
    case "SUCCESS":
    case "FAILED":
        closeOnEsc = false;
        closeOnOverlayClick = false;
      content = (
        <ActivationResult continue={props.continue} result={props.state} messages={actiovationMessages}/>
      );
      break;
    default:
      content = (
        <InsertToken
          close={props.close}
          continue={props.activate}
          value={props.value}
          isDisabled={props.isDisabled}
          onValueChanged={props.onValueChanged}
        />
      );
      break;
  }

  return (
    <GeneralModal isOpen={props.isOpen} close={props.close} closeOnEsc={closeOnEsc} closeOnOverlayClick={closeOnOverlayClick}>
      {content}
    </GeneralModal>
  );
};

export default modalActivate;
