import React from 'react';
import { getValue } from './utils';

export function ste2Def(sensor, intl) {
	return sensor.registered_sensors.map((s) => {
		switch (s.type) {
			case 'binary_switch':
				let binValue = getValue(sensor, `custom:[bin]:${s.id}`);
				if (binValue || binValue === 0) binValue = intl.formatMessage({ id: `tooltip.binInput.${s.subType}.${binValue}` });
				let subType = intl.formatMessage({
					id: `tooltip.binInput.${s.subType}`,
					defaultMessage: 'general',
				});
				return {
					dataset: `custom:[bin]:${s.id}`,
					value: binValue,
					unit: '',
					icon: 'binary',
					tooltip: (
						<span>
							{intl.formatMessage({
								id: 'sensor.binaryInput',
								defaultMessage: 'unknown',
							})}
							{':' + s.id + ' '}
							{'('}
							{subType}
							{')'}
						</span>
					),
					legend:
						intl.formatMessage({
							id: 'sensor.binaryInput',
							defaultMessage: 'unknown',
						}) +
						' ' +
						s.id +
						'(' +
						intl.formatMessage({
							id: `tooltip.binInput.${s.subType}`,
							defaultMessage: 'general',
						}) +
						')',
					subType: s.subType,
					chartType: 'step',
				};
			default:
				let defaultLegend = s.id;
				let label = (
					<React.Fragment>
						{' ('}
						{intl.formatMessage({ id: 'sensor.sensor' })}
						{':' + s.id + ')'}
					</React.Fragment>
				);
				if (s.name) {
					label = ` (${s.name})`;
					defaultLegend = label;
				}
				return {
					dataset: `custom:[${s.units}]:${s.id}`,
					value: getValue(sensor, `custom:[${s.units}]:${s.id}`),
					unit: s.units,
					icon: s.type,
					tooltip: (
						<span>
							{intl.formatMessage({
								id: `tooltip.${s.type}`,
								defaultMessage: 'unknown',
							})}
							{label}
						</span>
					),
					legend:
						intl.formatMessage({
							id: `tooltip.${s.type}`,
							defaultMessage: 'unknown',
						}) + ` (${defaultLegend})`,
				};
		}
	});
}
