import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import * as am4core from '@amcharts/amcharts4/core';

import * as chartBuilder from './../../../utils/chartBuilder.js';
import * as chartExports from './../../../utils/chartExports.js';
import * as chartInteractions from './../../../utils/chartInteractions.js';

import Spinner from './../../../components/Spinner/Spinner.jsx';
import Label from './../../../components/charts/Label/Label';
import ChartToolbar from './../../../components/charts/Toolbar/Toolbar';
import Exporter from './../../../components/charts/Export/Exporter';
import { Button } from '@progress/kendo-react-buttons';

import aggregations from './../../../analytics/aggregations/aggregations.js';
import timeAggregations, { roundStart, roundEnd } from './../../../analytics/timeAggregations/timeAggregations.js';

//import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4themes_kelly from '@amcharts/amcharts4/themes/kelly';

import { clearMeasurements } from './../../../store/actions/sensorActions';

import classes from './Line.module.scss';

import interp from './../../../analytics/interpolation/interpolation';

//am4core.useTheme(am4themes_animated);
am4core.useTheme(am4themes_kelly);

am4core.options.minPolylineStep = 0;
//am4core.options.queue = true;
//am4core.options.onlyShowOnViewport = true;

const createEmptyDataSet = () => {
	const data = [];
	/*
	for (let i = 7; i >= 0; i--) {
		const date = new Date();
		data.push({
			timestamp: date.setDate(date.getDate() - i)
		});
	}
	*/

	return data;
};

const definedPeriods = ['year', 'month', 'week', 'day', 'hour', 'minute'];
const definedAggregations = [aggregations.sum, aggregations.average, aggregations.min, aggregations.max];

const labels = {
	chartTypes: ['chart.menu.lineType.line', 'chart.menu.lineType.bar'],
	periodTypes: [
		'chart.menu.periodType.year',
		'chart.menu.periodType.month',
		'chart.menu.periodType.week',
		'chart.menu.periodType.day',
		'chart.menu.periodType.hour',
		'chart.menu.periodType.minute',
	],
	aggregationTypes: ['chart.menu.aggrType.sum', 'chart.menu.aggrType.average', 'chart.menu.aggrType.min', 'chart.menu.aggrType.max'],
};

const regimes = chartBuilder.regimes;

class Line extends Component {
	constructor(props) {
		super(props);
		let alarmDef = {};
		this.seriesCount = props.mapping.length;
		this.isOnlyStepChart = false;
		this.showRedAlarmLabel = false;
		if (this.seriesCount === 1) {
			if (props.sensor && props.sensor.alarms && props.sensor.alarms.length > 0) {
				alarmDef = props.sensor.alarms.find((s) => s.dataset === props.mapping[0].data);
				if (!alarmDef) alarmDef = {};
				else if (alarmDef.alarm_raised) this.showRedAlarmLabel = true;
			}
			if (props.mapping[0].mode === 'step') {
				this.isOnlyStepChart = true;
				alarmDef = {}; //no alarms for stepLines only
			}
		}
		this.chart = null;
		this.activeOnTap = this.props.activeOnTap ? true : false;
		this.emptyData = false;
		this.bulletsVisible = false;
		this.bulletsInitialized = true;
		this.bulletsStarted = false;
		this.zoomToAlarm = null;
		this.initAlarmLimitsOn = false;
		this.actZoom = [];
		this.limitsVisible = false;
		this.showLimitAfterDataValidation = false;
		this.borders = { min: null, max: null };
		this.scroll = { top: 0, left: 0, behavior: 'smooth' };
		this.alarm = alarmDef;
		this.mobileRegime = false;
		this.multichartExportInit = false;
		this.exportName = this.props.exportName;
		this.legendsPosition = 'in';
		this.valueLabelsEnabled = true;
		this.multiDataSet = false;
		this.actRegime = regimes.LineSeries;
		this.mainXAxis = null;
		this.mainYAxis = null;
		this.chartRegimes = {
			LineSeries: { series: [], emptySet: { timestamp: new Date() } },
			ColumnSeries: {
				aggregation: aggregations.average,
				period: 'hour',
				series: [],
				emptySet: { start: new Date(), end: new Date() },
			},
		};
		if (!this.exportName && this.props.sensor) this.exportName = this.props.sensor.name;
		else this.exportName = 'Export';
		this.aggrs = {
			lastAggr: null,
			deleted: false,
		};
		this.proccessedData = [];
		this.multiAxisOn = false;

		const toolbarCofig = chartBuilder.loadConfig(this.actRegime);
		if (this.props.sensor && !this.props.sensor.notifications_enabled) toolbarCofig.limits = true;

		this.state = {
			zoomed: false,
			leftMargin: 0,
			chartReady: false,
			exporting: false,
			exportFailed: false,
			exportModalOpen: {
				xlsx: false,
				csv: false,
			},
			export: {
				defaultName: this.exportName,
				name: this.exportName,
				delimiter: ';',
				decimalSeparator: '.',
				decimalPlaces: '',
			},
			toolbar: {
				bullets: false,
				limits: false,
				average: false,
				min: false,
				max: false,
				multiAxes: false,
			},
			toolbarDisabling: { ...toolbarCofig },
			chartSelection: 0,
			aggregationSelection: 1,
			periodSelection: 4,
		};
	}

	componentDidUpdate(prevProps) {
		if (this.chart && prevProps.lang !== this.props.lang) {
			chartInteractions.changeLocale(this.chart, this.chartRegimes, this.props.mapping, this.props.lang);
		}
		if (prevProps.data !== this.props.data) {
			this.fillChart(true);
		}
		if (!this.chart && document.getElementById(this.props.id)) {
			this.initiateChart();
		}
	}
	componentDidMount() {
		if (document.getElementById(this.props.id)) {
			this.initiateChart();
		}
	}

	initiateChart = () => {
		const actRegime = this.actRegime;

		this.chart = chartBuilder.createXYChart(this.props.id, this.activeOnTap);
		this.chart.events.on('sizechanged', this.resizeHandler);
		this.chart.events.on('doublehit', this.fullZoomOut);
		this.chart.events.on('ready', this.chartReadyHandler);
		this.chart.events.on('beforedatavalidated', () => {
			if (this.state.chartReady) {
				this.chart.series.each((serie) => {
					serie.show();
				});
				if (this.limitsVisible) {
					this.displayLimits(false);
					this.showLimitAfterDataValidation = true;
				}
			}
		});
		this.chart.events.on('datavalidated', () => {
			if (this.state.chartReady) {
				if (this.mainXAxis) chartInteractions.showWeekends(this.mainXAxis);
			}
		});
		//this.chart.responsive.enabled = true;

		chartInteractions.setChartLocale(this.chart, this.props.lang);
		chartBuilder.makePreloaderTransparent(this.chart);
		chartBuilder.setCursorMode(this.chart, 'x');
		chartBuilder.addScrollbarX(this.chart);

		let dataName = '';

		let dateAxis = null;
		dateAxis = chartBuilder.addDateAxis(this.chart, 'second', false, 1000);
		dateAxis.events.on('selectionextremeschanged', this.dateRangeHandler);
		//dateAxis.events.on('datavalidated', () => console.log('Validated'));
		dateAxis.events.on('ready', this.dateAxisReadyHandler);

		let valAxis = null;
		const mainUnits = this.seriesCount > 1 ? '' : this.props.mapping[0].units;
		valAxis = chartBuilder.addValueAxis(this.chart, this.actRegime, false, true, mainUnits, false, null, false);
		valAxis.events.on('datarangechanged', this.rangeHandler);
		dateAxis.events.on('selectionextremeschanged', () => {
			if (this.showLimitAfterDataValidation) {
				this.displayLimits(true);
				this.showLimitAfterDataValidation = false;
			}
		});
		//valAxis.events.on('datavalidated', () => console.log('Validated'));

		this.mainXAxis = dateAxis;
		this.mainYAxis = valAxis;

		const allSeries = [];
		let syncAxis = null;
		let doSync = false;

		this.props.mapping.forEach((dataMapping) => {
			dataName += dataMapping.data + '_';

			const privateValAxis = chartBuilder.addValueAxis(
				this.chart,
				this.actRegime,
				this.seriesCount > 1,
				false,
				dataMapping.units,
				doSync,
				syncAxis,
				true
			);
			privateValAxis.events.on('datarangechanged', this.rangeHandler);
			if (!doSync) {
				syncAxis = privateValAxis;
				doSync = true;
			}

			//if (!this.props.commonTimeAxis) dateAxis = chartBuilder.addDateAxis(this.chart);

			const yAxis = this.multiAxisOn ? privateValAxis : valAxis;

			const series = chartBuilder.addSeries(
				this.chart,
				dateAxis,
				yAxis,
				dataMapping.mode,
				dataMapping.id,
				dataMapping.time,
				dataMapping.data,
				dataMapping.pretty_name,
				dataMapping.color,
				this.multiAxisOn,
				dataMapping.legend
			);

			series.events.on('hidden', this.toggleAxes);
			series.events.on('shown', this.toggleAxes);
			series.events.on('dataitemsvalidated', this.handleSeriesVisibility);

			this.chartRegimes[actRegime].series.push({
				x: dateAxis,
				y: privateValAxis,
				serie: series,
				config: dataMapping,
			});

			allSeries.push(series);

			chartBuilder.addBullets(series);
			//chartBuilder.addBulletAllarm(series);
			chartBuilder.setValueTooltip(series, dataMapping.units, dataMapping.color, dataMapping.customTooltipEvaluator);

			if (this.seriesCount === 1) {
				if (this.alarm) {
					if (this.alarm.hasOwnProperty('low') && this.alarm.low !== 'N/A') {
						chartBuilder.showLimit(valAxis, series, this.alarm.low, -10000, 'blue', `< ${this.alarm.low}`, 'top');
					}
					if (this.alarm.high && this.alarm.high !== 'N/A') {
						chartBuilder.showLimit(valAxis, series, this.alarm.high, 10000, 'red', `> ${this.alarm.high}`, 'bottom');
					}
				}
			}
		});

		this.chart.series.setAll(allSeries);

		//this.chart.data = createEmptyDataSet();
		this.fillChart();
		this.chart.exporting.filePrefix = `${this.exportName}_${dataName}`;
		chartInteractions.hideAllLimits(this.chart);
		//chartExports.initMultiSeriesExport(this.chart);

		if (this.seriesCount > 3) {
			chartInteractions.moveLegends(this.chart, 'out');
			this.legendsPosition = 'out';
		}
	};

	handleSeriesVisibility = (ev) => {
		if (this.multiAxisOn && this.actRegime !== regimes.ColumnSeries) {
			const series = ev.target;

			if (series.data.length > 0) {
				if (!series.isHiding && !series.isHidden) series.yAxis.disabled = false;
			} else {
				series.yAxis.disabled = true;
			}
		}
	};

	dateAxisReadyHandler = (ev) => {
		if (ev) {
			const axis = ev.target;
			chartInteractions.showWeekends(axis);
		}
	};

	fullZoomOut = () => {
		//console.log(new Date(this.mainXAxis.min), new Date(this.mainXAxis.max));
		//if(this.mainXAxis.zoomFactor > 1)
		if (this.mainXAxis.min && this.mainXAxis.max) this.mainXAxis.zoomToDates(this.mainXAxis.min, this.mainXAxis.max, false, false);
	};

	chartReadyHandler = () => {
		if (this.alarm.alarm_raised) {
			// This code turn on init alarm init when the data are ready when the page is loaded - QuickGrap case - data are externally inserted into params and the is graph page fully loaded
			this.displayLimits(true);
		}

		let updatedState = {
			...this.state,
			chartReady: true,
		};

		this.setState(updatedState);
	};

	resizeHandler = () => {
		if (this.ranges) {
			this.mainYAxis.zoomToValues(this.ranges[0], this.ranges[1], false, true);
		}
		if (this.chart.contentWidth < 535) {
			if (this.legendsPosition === 'in') {
				chartInteractions.moveLegends(this.chart, 'out');
				this.legendsPosition = 'out';
			}
			if (this.valueLabelsEnabled && this.multiAxisOn) {
				chartInteractions.yMultiAxesPrettierOn(this.chartRegimes[this.actRegime]);
				this.valueLabelsEnabled = false;
			}

			//* Have to be at the end or the axis labels are not positioned well.
			chartInteractions.turnOnMobileRegime(this.chart);
			this.mobileRegime = true;
		} else {
			if (this.seriesCount < 4 && this.legendsPosition === 'out') {
				chartInteractions.moveLegends(this.chart, 'in');
				this.legendsPosition = 'in';
			}
			if (!this.valueLabelsEnabled && this.multiAxisOn) {
				chartInteractions.yMultiAxesPrettierOff(this.chartRegimes[this.actRegime]);
				this.valueLabelsEnabled = true;
			}

			//* Have to be at the end or the axis labels are not positioned well.
			chartInteractions.turnOffMobileRegime(this.chart, this.mainYAxis);
			this.mobileRegime = false;
		}
	};

	rangeHandler = (ev) => {
		const max = this.mainYAxis.max;
		const min = this.mainYAxis.min;
		if (this.borders.min !== min || this.borders.max !== max) {
			this.borders.min = min;
			this.borders.max = max;
		}

		let marginConstant = 0;
		if (this.seriesCount === 1) marginConstant = 0;

		const leftMargin = chartInteractions.getXAxesLabelWidth(this.chart, marginConstant);

		if (leftMargin !== this.state.leftMargin) {
			this.setState({ ...this.state, leftMargin: leftMargin });
		}
	};

	dateRangeHandler = (ev) => {
		if (!this.aggrs.deleted) {
			this.deleteAggrsLines();
			this.setState({
				...this.state,
				toolbar: {
					...this.state.toolbar,
					average: false,
					min: false,
					max: false,
				},
			});
		}
		let axis = ev.target;
		chartInteractions.showWeekends(axis);
	};

	toggleAxes = (ev) => {
		var axis = ev.target.yAxis;
		if (axis === this.mainYAxis) return;

		var disabled = true;
		axis.series.each(function (series) {
			if (!series.isHiding && !series.isHidden) {
				disabled = false;
			}
		});
		axis.disabled = disabled;

		if (this.multiAxisOn && !disabled) {
			chartInteractions.turnOnMultipleAxes(this.chart, this.chartRegimes[this.actRegime], this.mainYAxis);
		}

		if (!this.multiAxisOn && this.seriesCount > 1 && !disabled) {
			if (!this.aggrs.deleted) {
				this.computeAggr(this.aggrs.lastAggr, this.state.toolbar[this.aggrs.lastAggr]);
			}
		}
	};

	componentWillUnmount() {
		if (this.chart) {
			this.chart.data = null;
			this.chart.dispose();
		}
		if (this.state.zoomed) {
			//when chart was zoomed and user leave page directly, then the whole
			// app can not be scrolled. Beuase of the hmtl body is set body.overflow = 'hidden';
			chartInteractions.disableZoomedStyle(this.chart);
		}
		this.props.clearMeasurements();
	}

	fillChart = (invalidate = false) => {
		if (this.chart) {
			let data = this.props.data;

			let containsMoreDatasets = this.props.containsMoreDatasets;
			const dataForInterp = [];

			if (containsMoreDatasets && this.props.mapping.length === Object.keys(this.props.data).length) {
				for (let i = 0; i < this.props.mapping.length; i++) {
					const actMap = this.props.mapping[i];

					dataForInterp.push(this.props.data[actMap.id + '_' + actMap.data]);
				}
				data = interp(dataForInterp);
			}

			if (!containsMoreDatasets) {
				data = {};
				const recomputedData = this.props.data.map((item) => {
					return {
						...item,
						timestamp: new Date(item.timestamp * 1000),
					};
				});
				this.props.mapping.forEach((actMap) => {
					const id = `${actMap.id}_${actMap.data}`;
					data[id] = recomputedData.map((item) => {
						return {
							timestamp: item.timestamp,
							//[actMap.data]: item[actMap.data]
							[id]: item[actMap.data],
						};
					});
				});
			}

			let dataLength = 0;
			for (let datasetName in data) {
				dataLength += data[datasetName].length;
			}

			if (this.alarm.alarm_raised && dataLength > 0) {
				/*
				data[data.length - 1].bullet =
					'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CgogPHRpdGxlLz4KIDxnPgogIDx0aXRsZT5iYWNrZ3JvdW5kPC90aXRsZT4KICA8cmVjdCBmaWxsPSJub25lIiBpZD0iY2FudmFzX2JhY2tncm91bmQiIGhlaWdodD0iNDAyIiB3aWR0aD0iNTgyIiB5PSItMSIgeD0iLTEiLz4KIDwvZz4KIDxnPgogIDx0aXRsZT5MYXllciAxPC90aXRsZT4KICA8cGF0aCBmaWxsPSIjY2YxZjI1IiBpZD0ic3ZnXzEiIGQ9Im0yMy42MjAwMDEsMTkuMDQ5OTk5bC05LjMzMDAwMSwtMTYuNDM5OTk5YTIuNTYsMi41NiAwIDAgMCAtNC40NSwwbC05LjM0LDE2LjQzOTk5OWEyLjU2LDIuNTYgMCAwIDAgMi4yMywzLjgyMDAwMmwxOC42NTk5OTksMGEyLjU2LDIuNTYgMCAwIDAgMi4yMzAwMDEsLTMuODIwMDAyeiIgY2xhc3M9ImNscy0xIi8+CiAgPHBhdGggZmlsbD0iI2ZmZmZmZiIgaWQ9InN2Z18yIiBkPSJtMTMuMjIsMTkuMTZhMS40NSwxLjQ1IDAgMCAxIC0xLDAuMzgwMDAxYTEuNDksMS40OSAwIDAgMSAtMSwtMC4zNzAwMDFhMS4zMywxLjMzIDAgMCAxIC0wLjQ0MDAwMSwtMS4wNDk5OTlhMS40MSwxLjQxIDAgMCAxIDEuNDMsLTEuNDJhMS40MSwxLjQxIDAgMCAxIDEsMC40MWExLjM2LDEuMzYgMCAwIDEgMC40MiwxYTEuMzQsMS4zNCAwIDAgMSAtMC40MSwxLjA0OTk5OXoiIGNsYXNzPSJjbHMtMiIvPgogIDxwYXRoIGZpbGw9IiNmZmZmZmYiIGlkPSJzdmdfMyIgZD0ibTEzLjYsOS42NGwtMC4zNzAwMDEsNC4xOWEzLjM3LDMuMzcgMCAwIDEgLTAuMjMsMS4xN2EwLjgyLDAuODIgMCAwIDEgLTAuNzgsMC40OGEwLjgsMC44IDAgMCAxIC0wLjc3LC0wLjQ2OTk5OWE0LDQgMCAwIDEgLTAuMjcsLTEuMjNsLTAuMjcsLTQuMDhjLTAuMDUsLTAuODA5OTk5IC0wLjA4LC0xLjM3IC0wLjA4LC0xLjcyYTEuNywxLjcgMCAwIDEgMC40MSwtMS4yYTEuNDIsMS40MiAwIDAgMSAxLjA4LC0wLjQ0YTEuMTIsMS4xMiAwIDAgMSAxLjA4LDAuNjZhMy41MywzLjUzIDAgMCAxIDAuMjYsMS41MmExMS4zLDExLjMgMCAwIDEgLTAuMDU5OTk5LDEuMTJ6IiBjbGFzcz0iY2xzLTIiLz4KICA8cmVjdCBzdHJva2Utd2lkdGg9IjAiIHg9IjAuMDY4NTE2IiB5PSItMC4wMjI4MzkiIGZpbGw9Im5vbmUiIGlkPSJzdmdfNCIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0IiBjbGFzcz0iY2xzLTMiLz4KIDwvZz4KPC9zdmc+';
				const alarmDate = data[datasetName][data.length - 1].timestamp;
				const days = 1000 * 60 * 60 * 10;
				const from = new Date(Math.floor(alarmDate.getTime() / days) * days);
				const to = new Date(Math.ceil(alarmDate.getTime() / days) * days);
				this.zoomToAlarm = { from: from, to: to };
				*/
			}

			if (dataLength === 0) {
				data = createEmptyDataSet();
				this.emptyData = true;
			} else if (dataLength > 1000) {
				chartInteractions.simplifyAnimations(this.chart);
				am4core.options.minPolylineStep = 5;
			} else if (dataLength > 4000) {
				chartInteractions.turnOffAnimations(this.chart);
				am4core.options.minPolylineStep = 15;
			} else {
				am4core.options.minPolylineStep = 0;
			}

			if (dataLength === 1) {
				this.addBullets();
			}

			this.multiDataSet = containsMoreDatasets;
			this.proccessedData = data;
			let dataToPlot = [];
			let periodForRound = 'minute';

			switch (this.actRegime) {
				case 'ColumnSeries':
					dataToPlot = this.computeBarData();
					periodForRound = this.chartRegimes[regimes.ColumnSeries].period;
					break;
				default:
					dataToPlot = this.proccessedData;
					break;
			}
			if (this.props.lockXAxis) {
				chartInteractions.lockAxis(this.mainXAxis, roundStart(this.props.from, periodForRound), roundEnd(this.props.to, periodForRound));
			}

			///this.onChartTypeChangehandler({target:{index: this.state.chartSelection}});

			this.injectData(dataToPlot, invalidate);
			this.fullZoomOut();
			if (this.state.toolbar.bullets !== this.bulletsVisible) {
				this.setState({
					...this.state,
					toolbar: {
						...this.state.toolbar,
						bullets: this.bulletsVisible,
					},
				});
			}
		}
	};

	injectData = (data, invalidate = false) => {
		chartInteractions.injectDataToIndividualSeries(this.chart, this.chartRegimes[this.actRegime], data, true);
		//if (invalidate) this.fullZoomOut();
	};

	computeBarData = () => {
		const aggregation = this.chartRegimes['ColumnSeries'].aggregation;
		const period = this.chartRegimes['ColumnSeries'].period;

		let data = {};
		for (let setname in this.proccessedData) {
			//const info = setname.split('_');
			//data[setname] = timeAggregations(this.proccessedData[setname], info[1], period, aggregation, 3);
			data[setname] = timeAggregations(this.proccessedData[setname], setname, period, aggregation, 3);
		}

		return data;
	};

	onChangeAggregationHandler = (e) => {
		const regime = this.actRegime;
		if (regime === 'ColumnSeries') {
			this.chartRegimes[regime].aggregation = definedAggregations[e.target.index];
			const dataToDisplay = this.computeBarData();
			this.injectData(dataToDisplay);
		}
		this.setState({
			...this.state,
			aggregationSelection: e.target.index,
		});
	};

	onChangePeriodHandler = (e) => {
		const regime = this.actRegime;

		//const lastDur = this.chart.scrollbarX.animationDuration;
		//this.chart.scrollbarX.animationDuration = 0;
		const prevIndex = this.state.periodSelection;
		let zoomToMin = this.mainXAxis.minZoomed;
		let zoomToMax = this.mainXAxis.maxZoomed;

		if (regime === 'ColumnSeries') {
			const period = definedPeriods[e.target.index];
			this.chartRegimes[regime].period = period;
			const dataToDisplay = this.computeBarData();

			const props = {
				baseInterval: {
					timeUnit: period,
					count: 1,
				},
			};

			this.injectData(dataToDisplay);
			chartInteractions.changeAxisProps(this.mainXAxis, props);

			let start = new Date(this.mainXAxis.minZoomed);
			let end = new Date(this.mainXAxis.maxZoomed);

			if (e.target.index < prevIndex) {
				zoomToMin = roundStart(start, period).getTime();
				zoomToMax = roundEnd(end, period).getTime();
			}

			if (this.props.lockXAxis) {
				chartInteractions.lockAxis(this.mainXAxis, roundStart(this.props.from, period), roundEnd(this.props.to, period));
			}

			//************************************************************************************ */
			//** This is not good solution I call 10x zoom to dates to force the zoom on my values */
			this.mainXAxis.zoomToDates(zoomToMin, zoomToMax, true, false, true);

			let timerRef = setInterval(() => {
				this.mainXAxis.zoomToDates(zoomToMin, zoomToMax, true, false, true);
			}, 10);
			setTimeout(() => {
				clearInterval(timerRef);
			}, 1000);

			//***************************************************************************** */
			this.setState({
				...this.state,
				periodSelection: e.target.index,
			});
		}

		//this.chart.scrollbarX.animationDuration = lastDur;
	};

	onChartTypeChangehandler = (e) => {
		let regime = this.actRegime;
		let dataToDisplay = null;
		let props = null;
		let enableAxes = true;
		let periodForRound = 'day';
		const newSeries = [];

		if (this.bulletsVisible) chartInteractions.hideBullets(this.chartRegimes[regime]);

		switch (e.target.index) {
			case 1:
				regime = 'ColumnSeries';
				periodForRound = this.chartRegimes[regime].period;
				props = {
					groupData: true,
					groupCount: this.mobileRegime ? 400 : 800,
					baseInterval: {
						timeUnit: this.chartRegimes[regime].period,
						count: 1,
					},
				};

				dataToDisplay = this.computeBarData();
				this.chartRegimes[regime].series = [];

				this.props.mapping.forEach((actMap) => {
					const barSerie = chartBuilder.addCategorySeriesBAR(
						this.chart,
						this.mainXAxis,
						this.mainYAxis,
						actMap.id,
						'start',
						actMap.data,
						actMap.pretty_name,
						actMap.legend,
						actMap.color,
						this.props.lang
					);

					barSerie.events.on('dataitemsvalidated', this.handleSeriesVisibility);

					this.chartRegimes[regime].series.push({
						x: this.mainXAxis,
						y: this.mainYAxis,
						serie: barSerie,
						config: actMap,
						ready: false,
					});

					newSeries.push(barSerie);
				});

				if (this.mobileRegime) chartInteractions.turnOnMobileRegime(this.chart);
				// Timeout because at the end is used setStat and that final "setState" rewrites changes in function "displayLimits"
				// So the function in setTimeout is processed by another Thread and set states correctly.
				if (this.limitsVisible) this.displayLimits(false, false);
				break;
			default:
				regime = 'LineSeries';

				const privateYAxes = this.chartRegimes[regime].series.map((serie) => serie.y);
				this.chartRegimes[regime].series = [];

				let i = 0;
				this.props.mapping.forEach((actMap) => {
					const privateValAxis = privateYAxes[i];

					const yAxis = this.multiAxisOn ? privateValAxis : this.mainYAxis;

					const series = chartBuilder.addSeries(
						this.chart,
						this.mainXAxis,
						yAxis,
						actMap.mode,
						actMap.id,
						actMap.time,
						actMap.data,
						actMap.pretty_name,
						actMap.color,
						this.multiAxisOn,
						actMap.legend
					);

					series.events.on('hidden', this.toggleAxes);
					series.events.on('shown', this.toggleAxes);
					series.events.on('dataitemsvalidated', this.handleSeriesVisibility);

					this.chartRegimes[regime].series.push({
						x: this.mainXAxis,
						y: privateValAxis,
						serie: series,
						config: actMap,
					});

					chartBuilder.addBullets(series);
					chartBuilder.setValueTooltip(series, actMap.units, actMap.color, actMap.customTooltipEvaluator);

					newSeries.push(series);
					i++;
				});

				dataToDisplay = this.proccessedData;

				periodForRound = 'minute';
				props = {
					groupData: false,
					groupCount: 1000,
					baseInterval: {
						timeUnit: 'second',
						count: 1,
					},
				};
				if (this.multiAxisOn) {
					chartInteractions.turnOnMultipleAxes(this.chart, this.chartRegimes[this.actRegime], this.mainYAxis);
					enableAxes = false;
				}
				break;
		}
		this.actRegime = regime;
		this.chart.series.setAll(newSeries);

		if (this.props.lockXAxis) {
			chartInteractions.lockAxis(this.mainXAxis, roundStart(this.props.from, periodForRound), roundEnd(this.props.to, periodForRound));
		}

		if (this.bulletsVisible) this.addBullets();
		chartInteractions.changeAxisProps(this.mainXAxis, props);
		chartInteractions.disableAllRegimesExceptOne(this.chartRegimes, this.actRegime, this.mainXAxis, this.mainYAxis);

		if (dataToDisplay) this.injectData(dataToDisplay);

		if (enableAxes) {
			this.mainYAxis.disabled = false;
			this.mainXAxis.disabled = false;
		}

		this.setState({
			...this.state,
			toolbarDisabling: chartBuilder.loadConfig(this.actRegime),
			chartSelection: e.target.index,
			toolbar: {
				...this.state.toolbar,
				limits: this.limitsVisible,
			},
		});

		/*
		this.chartRegimes[this.actRegime].series.forEach(item => {
			console.log(item.serie.name, item.serie.disabled);
		});

		setTimeout(() => {
			this.chartRegimes[this.actRegime].series.forEach(item => {
				console.log(item.serie.name, item.serie.disabled);
			});
		},1500);

		*/
	};

	addBullets = (add = true) => {
		if (add) {
			this.bulletsVisible = true;
			chartInteractions.showBullets(this.chartRegimes[this.actRegime]);
			let start = this.mainXAxis.minZoomed;
			let end = this.mainXAxis.maxZoomed;
			this.mainXAxis.zoomToDates(start + 1, end + 1, true, false);
		} else {
			this.bulletsVisible = false;
			chartInteractions.hideBullets(this.chartRegimes[this.actRegime]);
		}
	};

	onAddBullets = () => {
		if (this.bulletsVisible) {
			this.addBullets(false);
		} else {
			this.addBullets();
		}

		this.setState({
			...this.state,
			toolbar: {
				...this.state.toolbar,
				bullets: this.bulletsVisible,
			},
		});
	};

	onZoom = () => {
		//this.ranges = chartInteractions.getYAxesRanges(this.chart);
		this.ranges = [this.mainYAxis.minZoomed, this.mainYAxis.maxZoomed];
		const value = !this.state.zoomed;
		this.setState({ zoomed: value });
		if (value) {
			const doc = document.documentElement;
			this.scroll.left = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0);
			this.scroll.top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

			chartInteractions.setZoomedStyle(this.chart);
			this.chart.tapToActivate = false;

			if (this.mobileRegime) {
				this.chart.legend.disabled = true; //! Need to be disabled because of description bellow - functional from 4.9.20
			}
		} else {
			chartInteractions.disableZoomedStyle(this.chart);
			this.chart.tapToActivate = this.activeOnTap;

			setTimeout(() => {
				window.scrollTo(this.scroll);
			}, 200);
			this.chart.legend.disabled = false; //! From chart vesrion 4.9.7 -this causes error !!!
		}
	};

	onShowLimits = () => {
		if (this.props.sensor && this.props.sensor.notifications_enabled) {
			if (!this.limitsVisible) {
				if (this.borders && Object.keys(this.alarm).length > 0) {
					this.displayLimits(true);
				}
			} else {
				this.displayLimits(false);
			}
		}
	};

	displayLimits = (value, setState = true) => {
		if (value) {
			const alarm_high = +this.alarm.high;
			const alarm_low = +this.alarm.low;

			const valForOffset = isNaN(alarm_high) ? alarm_low : alarm_high;

			//console.log(alarm_low, alarm_high);

			const hight_offset = Math.abs(valForOffset) * 0.1 + 5;
			const low_offset = Math.abs(valForOffset) * 0.1 + 5;

			let max = this.borders.max;
			let min = this.borders.min;

			const highAlarmDefined = !isNaN(alarm_high) && typeof alarm_high === 'number';
			const lowAlarmDefined = !isNaN(alarm_low) && typeof alarm_low === 'number';

			//console.log(highAlarmDefined, lowAlarmDefined, min, max);

			if (highAlarmDefined) {
				if (this.borders.min >= alarm_high && !lowAlarmDefined) {
					min = alarm_high - hight_offset;
				} else if (alarm_high + hight_offset >= this.mainYAxis.maxZoomed) {
					max = alarm_high + hight_offset;
				} else {
					max = this.mainYAxis.maxZoomed;
				}
			}

			//console.log(min, max);

			if (lowAlarmDefined) {
				if (this.borders.max <= alarm_low && !highAlarmDefined) {
					max = alarm_low + low_offset;
					//console.log(alarm_low, low_offset);
				} else if (alarm_low - low_offset <= this.mainYAxis.minZoomed) {
					min = alarm_low - low_offset;
				} else {
					min = this.mainYAxis.minZoomed;
				}
			}
			//console.log(min, max);

			if (!this.emptyData) {
				if (this.chart.yAxes.values[0].minZoomed < min) {
					min = this.mainYAxis.minZoomed;
				}
				if (this.chart.yAxes.values[0].maxZoomed > max) {
					max = this.mainYAxis.maxZoomed;
				}
			}

			if (min || max) {
				//console.log(min, max, this.borders, this.mainYAxis.minZoomed, this.mainYAxis.maxZoomed);
				this.mainYAxis.strictMinMax = true;
				this.mainYAxis.min = min;
				this.mainYAxis.max = max;

				chartInteractions.zoomToRanges(this.chart, [[this.mainYAxis.min, this.mainYAxis.max]]);
			}
			this.limitsVisible = true;
			chartInteractions.showAllLimits(this.chart);
		} else {
			chartInteractions.hideAllLimits(this.chart);
			this.limitsVisible = false;

			this.mainYAxis.max = undefined;
			this.mainYAxis.min = undefined;
			this.mainYAxis.strictMinMax = false;

			this.mainYAxis.extraMax = 0;
			this.mainYAxis.extraMin = 0;
		}
		if (setState) {
			this.setState({
				...this.state,
				toolbar: {
					...this.state.toolbar,
					limits: this.limitsVisible,
				},
			});
		}
	};

	savePDF = () => {
		this.setState({
			...this.state,
			exporting: true,
			exportFailed: false,
			exportModalOpen: this.closeAllExportModals(),
		});
		chartExports
			.savePDF(this.chart, this.exportName, this.state.zoomed)
			.then(() => this.setState({ ...this.state, exporting: false }))
			.catch((err) => {
				this.setState({
					...this.state,
					exportFailed: true,
					exporting: false,
				});
			});
	};
	saveCSV = () => {
		this.setState({
			...this.state,
			exporting: true,
			exportFailed: false,
			exportModalOpen: this.closeAllExportModals(),
		});

		const config = {
			...this.state.export,
			mergeData: true,
		};

		if (!config.name) config.name = config.defaultName;
		if (!config.delimiter) config.delimiter = ';';
		if (!config.decimalSeparator) config.decimalSeparator = '.';
		if (config.decimalPlaces && isNaN(+config.decimalPlaces)) config.decimalPlaces = undefined;

		setTimeout(() => {
			try {
				chartExports.saveCsv(this.chart, config);
				this.setState({ ...this.state, exporting: false });
			} catch (err) {
				this.setState({
					...this.state,
					exportFailed: true,
					exporting: false,
				});
			}
		}, 100);
	};
	saveXLSX = () => {
		this.setState({
			...this.state,
			exporting: true,
			exportFailed: false,
			exportModalOpen: this.closeAllExportModals(),
		});

		const config = {
			name: this.state.export.name,
			decimalPlaces: this.state.export.decimalPlaces,
			mergeData: true,
		};

		if (!config.name) config.name = config.defaultName;
		if (config.decimalPlaces && isNaN(+config.decimalPlaces)) config.decimalPlaces = undefined;

		setTimeout(() => {
			try {
				chartExports.saveXlsx(this.chart, config);
				this.setState({ ...this.state, exporting: false });
			} catch (err) {
				this.setState({
					...this.state,
					exportFailed: true,
					exporting: false,
				});
			}
		}, 100);
	};
	exportFailedHandler = () => {
		this.setState({
			...this.state,
			exportFailed: false,
		});
	};

	chnageAggregationHandler = (aggr) => {
		const value = !this.state.toolbar[aggr];
		this.computeAggr(aggr, value);
	};

	computeAggr = (aggr, aggrValue) => {
		const updatedToolbar = {
			...this.state.toolbar,
			average: false,
			min: false,
			max: false,
		};

		if (!aggrValue) {
			this.deleteAggrsLines();
			this.setState({
				...this.state,
				toolbar: updatedToolbar,
			});

			return;
		}

		const to = this.chart.xAxes.values[0].maxZoomed;
		const from = this.chart.xAxes.values[0].minZoomed;

		this.deleteAggrsLines();

		let filteredData = [];
		let aggrResult = null;

		this.chartRegimes[this.actRegime].series.forEach((item) => {
			filteredData = this.proccessedData[item.serie.name].filter((record) => record.timestamp >= from && record.timestamp <= to);

			const dataset = item.config.id + '_' + item.config.data;

			switch (aggr) {
				case 'average':
					aggrResult = aggregations.average(filteredData, dataset);
					updatedToolbar.average = true;
					break;
				case 'min':
					aggrResult = aggregations.min(filteredData, dataset);
					updatedToolbar.min = true;
					break;
				case 'max':
					aggrResult = aggregations.max(filteredData, dataset);
					updatedToolbar.max = true;
					break;
				default:
					break;
			}

			chartBuilder.initLine(item, aggrResult, this.mobileRegime);

			this.aggrs.lastAggr = aggr;
			this.aggrs.deleted = false;
			this.setState({
				...this.state,
				toolbar: updatedToolbar,
			});
		});
	};

	zoomToAlarmHandler = () => {
		if (this.zoomToAlarm) {
			chartInteractions.zoomToDates(this.chart, this.zoomToAlarm.from, this.zoomToAlarm.to);
		}
	};

	swicthMultiAxishandler = () => {
		let value = !this.multiAxisOn;

		if (value) {
			chartInteractions.turnOnMultipleAxes(this.chart, this.chartRegimes[this.actRegime], this.mainYAxis);
			if (this.mobileRegime && this.valueLabelsEnabled) {
				chartInteractions.yMultiAxesPrettierOn(this.chartRegimes[this.actRegime]);
				this.valueLabelsEnabled = false;
			}
		} else {
			chartInteractions.turnOffMultipleAxes(this.chartRegimes[this.actRegime], this.mainXAxis, this.mainYAxis);
			if (!this.valueLabelsEnabled) {
				chartInteractions.yMultiAxesPrettierOff(this.chartRegimes[this.actRegime]);
				this.valueLabelsEnabled = true;
			}
		}

		this.multiAxisOn = value;

		this.deleteAggrsLines();

		if (this.aggrs.lastAggr) {
			this.computeAggr(this.aggrs.lastAggr, this.state.toolbar[this.aggrs.lastAggr]);
		}

		this.setState({
			...this.state,
			toolbar: {
				...this.state.toolbar,
				multiAxes: value,
			},
		});
	};

	deleteAggrsLines = () => {
		chartInteractions.deleteAggrLines(this.chart);
		this.aggrs.deleted = true;
	};

	onExportModalCloseHandler = () => {
		this.setState({
			...this.state,
			exportModalOpen: this.closeAllExportModals(),
		});
	};

	closeAllExportModals = () => {
		const exportModals = { ...this.state.exportModalOpen };
		for (let modalName in exportModals) exportModals[modalName] = false;

		return exportModals;
	};

	onExportSettingHandler = (e) => {
		const name = e.target.name;
		let value = e.target.value;

		this.setState({
			...this.state,
			export: {
				...this.state.export,
				[name]: value,
			},
		});
	};

	openExportModal = (type) => {
		this.setState({
			...this.state,
			exportModalOpen: {
				...this.closeAllExportModals(),
				[type]: true,
			},
			export: {
				...this.state.export,
				name: this.exportName,
				delimiter: ';',
				decimalSeparator: '.',
				decimalPlaces: '',
			},
		});
	};

	onExportClikHandler = () => {
		let type = null;
		for (const modalType in this.state.exportModalOpen) {
			if (this.state.exportModalOpen[modalType]) {
				type = modalType;
				break;
			}
		}
		switch (type) {
			case 'xlsx':
				this.saveXLSX();
				break;
			case 'csv':
				this.saveCSV();
				break;
			default:
				break;
		}
	};

	render() {
		let alarmRaised = false;
		let h1Style = null;
		if (this.showRedAlarmLabel) {
			alarmRaised = true;
			h1Style = { color: 'red' };
		}
		const resizeIcon = this.state.zoomed ? 'minimize.svg' : 'maximize.svg';
		const resizeTextId = this.state.zoomed ? 'chart.menu.zoomout' : 'chart.menu.zoomin';
		const pointsTextId = this.bulletsVisible ? 'chart.menu.bullets.off' : 'chart.menu.bullets.on';
		let visibility = 'visible';
		let spinner = null;
		let exportModal = null;
		let chartC = [classes.chart, classes.loaded].join(' ');

		const chartClass = this.state.zoomed ? classes.zoomedChart : classes.chartsContainer;

		if (!this.state.chartReady || this.props.loading /*||this.state.leftMargin < 1*/) {
			visibility = 'hidden';
			chartC = classes.chart;
			spinner = (
				<div className={classes.loaderContainer} style={this.props.loaderStyle}>
					<p>
						<FormattedMessage id="chart.loading" defaultMessage="Loading..." />
					</p>
					<Spinner type="circle" />
				</div>
			);
		} else if (this.state.exporting) {
			chartC = classes.chart;
			spinner = (
				<div className={classes.loaderContainer} style={this.props.loaderStyle}>
					<p>
						<FormattedMessage id="chart.exporting" defaultMessage="Exporting..." />
					</p>
					<Spinner type="circle" />
				</div>
			);
		} else if (this.state.exportFailed) {
			chartC = classes.chart;
			spinner = (
				<div className={classes.exportFailed}>
					<div style={{ color: 'red' }}>
						<FormattedMessage id="chart.export.failed" defaultMessage="Export failed" />
						{/*<p>{this.state.exportFailedMsg}</p>*/}
						<div className={classes.exportFailedButton}>
							<button className="btn btn-danger" onClick={this.exportFailedHandler}>
								OK
							</button>
						</div>
					</div>
				</div>
			);
		}

		if (this.state.exportModalOpen.xlsx) {
			chartC = classes.chart;
			exportModal = (
				<Exporter
					type="xlsx"
					containerClassName={classes.exportContainer}
					nameValue={this.state.export.name}
					decimalPlacesValue={this.state.export.decimalPlaces}
					onChange={this.onExportSettingHandler}
					onClose={this.onExportModalCloseHandler}
					onExportClick={this.onExportClikHandler}
				/>
			);
		} else if (this.state.exportModalOpen.csv) {
			chartC = classes.chart;
			exportModal = (
				<Exporter
					type="csv"
					containerClassName={classes.exportContainer}
					nameValue={this.state.export.name}
					delimiterValue={this.state.export.delimiter}
					decimalSeparatorValue={this.state.export.decimalSeparator}
					decimalPlacesValue={this.state.export.decimalPlaces}
					onChange={this.onExportSettingHandler}
					onClose={this.onExportModalCloseHandler}
					onExportClick={this.onExportClikHandler}
				/>
			);
		}

		const toolbarConfig = [
			{
				id: this.props.id,
				type: 'bullets',
				tip: this.props.intl.formatMessage({ id: pointsTextId }),
				toggable: true,
				selected: this.state.toolbar.bullets,
				imageUrl: process.env.PUBLIC_URL + '/assets/img/bullets.svg',
				handler: this.onAddBullets,
				disabled: this.state.toolbarDisabling.bullets,
				hidden: false,
			},
			{
				id: this.props.id,
				type: 'exportPdf',
				tip: this.props.intl.formatMessage({ id: 'chart.menu.pdf' }),
				toggable: false,
				icon: 'pdf',
				handler: this.savePDF,
				disabled: this.state.toolbarDisabling.pdf,
				hidden: false,
			},
			{
				id: this.props.id,
				type: 'exportCsv',
				tip: this.props.intl.formatMessage({ id: 'chart.menu.csv' }),
				toggable: false,
				icon: 'csv',
				handler: () => this.openExportModal('csv'),
				disabled: this.state.toolbarDisabling.csv,
				hidden: false,
			},
			{
				id: this.props.id,
				type: 'exportXlsx',
				tip: this.props.intl.formatMessage({ id: 'chart.menu.xlsx' }),
				toggable: false,
				icon: 'xls',
				handler: () => this.openExportModal('xlsx'),
				disabled: this.state.toolbarDisabling.xlsx,
				hidden: false,
			},
			{
				id: this.props.id,
				type: 'limits',
				tip: this.props.intl.formatMessage({ id: 'chart.menu.limits' }),
				toggable: true,
				selected: this.state.toolbar.limits,
				imageUrl: process.env.PUBLIC_URL + '/assets/img/limits.svg',
				handler: this.onShowLimits,
				disabled: this.state.toolbarDisabling.limits || Object.keys(this.alarm).length === 0,
				hidden: this.seriesCount > 1 || this.isOnlyStepChart,
			},
			{
				id: this.props.id,
				type: 'multiAxes',
				tip: this.props.intl.formatMessage({ id: 'chart.menu.multiAxis' }),
				toggable: true,
				selected: this.state.toolbar.multiAxes,
				imageUrl: process.env.PUBLIC_URL + '/assets/img/multi_axis.svg',
				handler: this.swicthMultiAxishandler,
				disabled: this.state.toolbarDisabling.multiAxes,
				hidden: this.seriesCount < 2,
			},
			{
				id: this.props.id,
				type: 'average',
				tip: this.props.intl.formatMessage({ id: 'chart.menu.average' }),
				toggable: true,
				selected: this.state.toolbar.average,
				imageUrl: process.env.PUBLIC_URL + '/assets/img/average.svg',
				handler: () => this.chnageAggregationHandler('average'),
				disabled: this.state.toolbarDisabling.avg,
				hidden: this.isOnlyStepChart,
			},
			{
				id: this.props.id,
				type: 'min',
				tip: this.props.intl.formatMessage({ id: 'chart.menu.min' }),
				toggable: true,
				selected: this.state.toolbar.min,
				imageUrl: process.env.PUBLIC_URL + '/assets/img/min.svg',
				handler: () => this.chnageAggregationHandler('min'),
				disabled: this.state.toolbarDisabling.min,
				hidden: this.isOnlyStepChart,
			},
			{
				id: this.props.id,
				type: 'max',
				tip: this.props.intl.formatMessage({ id: 'chart.menu.max' }),
				toggable: true,
				selected: this.state.toolbar.max,
				imageUrl: process.env.PUBLIC_URL + '/assets/img/max.svg',
				handler: () => this.chnageAggregationHandler('max'),
				disabled: this.state.toolbarDisabling.max,
				hidden: this.isOnlyStepChart,
			},
			{
				id: this.props.id,
				type: 'zoom',
				tip: this.props.intl.formatMessage({ id: 'chart.menu.zoomAlarm' }),
				toggable: false,
				iconClass: 'fas fa-exclamation-triangle',
				handler: this.zoomToAlarmHandler,
				disabled: false,
				hidden: true, //!this.alarm.alarm_raised
			},
		];

		const dropDownsConfig = [
			{
				id: this.props.id + 'chartTypeSelection',
				tip: 'Chart type',
				data: labels.chartTypes.map((label) => this.props.intl.formatMessage({ id: label })),
				default: this.props.intl.formatMessage({ id: labels.chartTypes[this.state.chartSelection] }),
				onChange: this.onChartTypeChangehandler,
				disabled: this.state.toolbarDisabling.type,
				value: this.props.intl.formatMessage({ id: labels.chartTypes[this.state.chartSelection] }),
				hidden: this.isOnlyStepChart,
			},
			{
				id: this.props.id + 'chartAggrSelection',
				tip: 'Aggregation',
				data: labels.aggregationTypes.map((label) => this.props.intl.formatMessage({ id: label })),
				default: this.props.intl.formatMessage({
					id: labels.aggregationTypes[this.state.aggregationSelection],
				}),
				onChange: this.onChangeAggregationHandler,
				disabled: this.state.toolbarDisabling.aggregation,
				value: this.props.intl.formatMessage({ id: labels.aggregationTypes[this.state.aggregationSelection] }),
				hidden: this.isOnlyStepChart,
			},
			{
				id: this.props.id + 'chartPeriodSelection',
				tip: 'Period',
				data: labels.periodTypes.map((label) => this.props.intl.formatMessage({ id: label })),
				default: this.props.intl.formatMessage({ id: labels.periodTypes[this.state.periodSelection] }),
				onChange: this.onChangePeriodHandler,
				disabled: this.state.toolbarDisabling.period,
				value: this.props.intl.formatMessage({ id: labels.periodTypes[this.state.periodSelection] }),
				hidden: this.isOnlyStepChart,
			},
		];

		return (
			<div className={chartClass} style={this.props.containerStyle}>
				{this.props.labelDisabled || this.state.zoomed ? null : (
					<Label
						showAlarmIcon
						alarmRaised={alarmRaised}
						style={h1Style}
						text={this.props.label}
						//alarmClick={this.zoomToAlarmHandler}
					/>
				)}
				<div className={classes.panelContainer} style={{ visibility: visibility }}>
					{this.props.leftPanelDisabled ? null : (
						<div className={classes.panelMenu} style={{ marginLeft: `${this.state.leftMargin}px` }}>
							<ChartToolbar buttons={toolbarConfig} dropDowns={dropDownsConfig} />
						</div>
					)}
					{this.props.rightPanelDisabled ? null : (
						<div className={classes.panelZoom}>
							<ChartToolbar
								buttons={[
									{
										id: this.props.id,
										type: 'resize',
										tip: this.props.intl.formatMessage({ id: resizeTextId }),
										toggable: false,
										imageUrl: process.env.PUBLIC_URL + `/assets/img/${resizeIcon}`,
										handler: this.onZoom,
										disabled: false,
										hidden: false,
									},
								]}
							/>
						</div>
					)}
				</div>
				<div className={classes.chartLoaderContainer}>
					<div className={chartC} style={this.props.style} id={this.props.id} />
					{this.state.exportModalOpen.csv || this.state.exportModalOpen.xlsx ? null : (
						<Button
							togglable={false}
							disabled={false}
							className={classes.resizeIcon}
							imageUrl={process.env.PUBLIC_URL + `/assets/img/${resizeIcon}`}
							onClick={this.onZoom}
						/>
					)}
					{spinner}
					{exportModal}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	lang: state.locale.lang,
});

export default connect(mapStateToProps, { clearMeasurements })(injectIntl(Line));
