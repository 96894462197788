import domtoimage from 'dom-to-image-more';
import { chain } from 'lodash';
import XLSX from 'xlsx';
//import { saveAs } from 'file-saver';
import { saveAs } from '@progress/kendo-file-saver';
//import { drawDOM, exportPDF, exportImage } from '@progress/kendo-drawing';

export const initMultiSeriesExport = chart => {
	chart.exporting.adapter.add('formatDataFields', (data, target) => {
		data.dataFields = {};
		//data.dataFields.name = 'name';
		chart.series.each(function (series) {
			const condition = !series.disabled && !series.isHiding && !series.isHidden && series.visible;
			if (condition) {
				data.dataFields[series.dataFields.dateX] = series.dataFields.dateX;
				if (series.className === 'ColumnSeries') {
					data.dataFields[series.dataFields.dateXEnd] = series.dataFields.dateXEnd;
				}
				//data.dataFields[series.dataFields.valueY] = series.dataFields.valueY;
				data.dataFields[series.dataFields.valueY] = series.name;
			}
		});
		return data;
	});

	chart.exporting.adapter.add('data', (data, target) => {
		// Assemble data from series

		//console.log(data, target);
		data = chart.series.values[0].data;

		for (let j = 1; j < chart.series.length; j++) {
			data = chain(data.concat(chart.series.values[j].data))
				.groupBy(chart.series.values[0].dataFields.dateX)
				// `key` is group's name , `value` is the array of objects
				.map((value, key) => {
					let item = {};
					value.forEach(val => {
						if (!val.hasOwnProperty('disabled') || !val.disabled) {
							item = { ...item, ...val };
						}
					});
					return item;
				})
				.value();
		}

		return { data: data };

		/* //OLD EXPORT - ALL SERIES ARE IN THE ROWS
				data = [];
				
				chart.series.each(function (series) {
					for (var i = 0; i < series.data.length; i++) {
						const condition = !series.disabled && !series.isHiding && !series.isHidden && series.visible;
						if (condition) {
							if (!series.data[i].disabled) {
								//series.data[i].name = series.name;
								data.push(series.data[i]);
							}
						}
					}
				});
				console.log("data", data);
				return { data: data };
			*/
	});
};

export const saveXlsx = (chart, config) => {

	let data = chart.data;

	if (config.mergeData) {
		data = prepareDataForExport(chart);
	}

	let ws = XLSX.utils.json_to_sheet(data, { cellDates: true });
	if (config.decimalPlaces) {
		formatteNumericData(ws, config.decimalPlaces);
	}

	/* add to workbook */
	let wb = XLSX.utils.book_new();
	XLSX.utils.book_append_sheet(wb, ws, 'Graph_data');

	if (window.cordova) {
		let xlsDataStr = XLSX.write(wb, { type: 'base64' });
		saveFile(`${config.name}.xlsx`, new Blob([s2ab(atob(xlsDataStr))], { type: "" }));
	} else {
		XLSX.writeFile(wb, `${config.name}.xlsx`);
	}

};

export const saveCsv = (chart, config) => {
	let data = chart.data;

	if (config.mergeData) {
		data = prepareDataForExport(chart);
	}


	let ws = XLSX.utils.json_to_sheet(data, { cellDates: true, dateNF: 'dd"."mm"."yy HH:MM:SS' });
	if (config.decimalPlaces || (config.decimalSeparator && config.decimalSeparator !== ".")) {
		formatteNumericData(ws, config.decimalPlaces, config.decimalSeparator);
	}

	let csv = XLSX.utils.sheet_to_csv(ws, { FS: config.delimiter });


	if (window.cordova) {
		saveFile(`${config.name}.csv`, new Blob([s2ab(csv)], { type: "application/octet-stream" }));
	} else {
		saveAs(new Blob([s2ab(csv)], { type: "application/octet-stream" }), `${config.name}.csv`);
	}

};

const getSensorOverviewPic = () => {
	const neco = document.getElementById('sensorOverview');
	if (!neco) return null;

	let style = {
		top: 0,
		left: 0,
		margin: 0,
		width: 550,
		height: 100
	};

	return domtoimage
		.toPng(neco, {
			style: style
		})
		.then(dataUrl => {
			let img = {
				alignment: 'center',
				image: dataUrl,
				width: 550
			};

			return img;
		});
};

/*
const getComponentTest = () => {
	const gridElement = document.getElementById('sensorOverview');
	if (!gridElement) return null;

	drawDOM(gridElement).then((group) => {
		return exportImage(group);
	}).then((dataUri) => {
		console.log(dataUri);
		let img = {
			alignment: 'center',
			image: dataUri
		};
		saveAs(dataUri, "scene.png");

		return img;
	});
};
*/

export const savePDF = (chart, name, zoomed) => {

	return Promise.all([getSensorOverviewPic(), chart.exporting.pdfmake, chart.exporting.getImage('png')]).then(res => {
		// pdfmake and chart snapshots are ready
		// res[0] contains pdfmake instance
		// res[1] contains shapshot of chart 1
		// etc.

		let sensorOverview = res[0];
		let pdfMake = res[1];
		let doc = {
			pageSize: 'A4',
			pageOrientation: 'portrait',
			pageMargins: [30, 30, 30, 30],
			content: []
		};
		doc.content.push({
			text: `${name}`,
			fontSize: 30,
			bold: true,
			margin: [0, 20, 0, 20],
			alignment: 'center'
		});

		doc.content.push(sensorOverview);

		let img = {
			alignment: 'center',
			margin: [0, 100, 0, 0],
			image: res[2],
			width: 550
		};

		if (zoomed) img.height = 350;

		doc.content.push(img);

		return new Promise((resolve, reject) => {
			try {
				if (window.cordova) {
					const pdfDocGenerator = pdfMake.createPdf(doc);
					pdfDocGenerator.getBlob((blob) => {
						saveFile(`${name}.pdf`, blob);
						resolve();
					});
				} else {
					const pdfDocGenerator = pdfMake.createPdf(doc);
					pdfDocGenerator.getBlob((blob) => {
						saveAs(blob, `${name}.pdf`);
						resolve();
					});
					/*
					pdfMake.createPdf(doc).download(`${name}.pdf`, () => {
						resolve();
					});
					*/
				}
			} catch (error) {
				//console.log("err", error);
				reject(error);
			}
		});
	});
};

export const prepareDataForExport = (chart) => {

	let data = [];

	for (let j = 0; j < chart.series.length; j++) {
		const series = chart.series.values[j];

		const condition = series.disabled || series.isHiding || series.isHidden || !series.visible;
		if (condition) continue;

		let seriesData = series.data;
		if (series.isEmpty) {
			seriesData = {
				[series.dataFields.dateX]: null,
				[series.pretty_name]: null
			};
		}

		data = chain(data.concat(seriesData))
			.groupBy(series.dataFields.dateX)
			.map((value, key) => {
				let item = {};
				value.forEach(val => {
					if (!val.hasOwnProperty('disabled') || !val.disabled) {
						item = { ...item, ...val };
					}
				});
				return item;
			})
			.orderBy(series.dataFields.dateX)
			.map(value => {
				let item = {...value};
				if(series.pretty_name){
					item[series.pretty_name] = item[series.dataFields.valueY];
					delete item[series.dataFields.valueY];
				}
				return item;
			})
			.value();
	}

	return data;
};

export const formatteNumericData = (ws, decimalPlaces = null, decimalSeparator = null) => {

	//import('xlsx').then(XLSX => {
	if (decimalPlaces || decimalSeparator) {
		let range = XLSX.utils.decode_range(ws['!ref']);
		for (let R = range.s.r; R <= range.e.r; ++R) {
			for (let C = range.s.c; C <= range.e.c; ++C) {
				let cell = ws[XLSX.utils.encode_cell({ r: R, c: C })];
				if (!cell || cell.t !== 'n') continue;
				/* cell.w is the formatted text */
				const DP = +decimalPlaces;
				if (decimalSeparator && !isNaN(DP)) {
					cell.w = cell.v.toFixed(DP).replace(/\./, decimalSeparator);
				} else if (!isNaN(DP)) {
					cell.w = cell.v.toFixed(DP);
					cell.z = `0.${"0".repeat(DP)}`;
				} else {
					cell.w = cell.v.toString(10).replace(/\./, decimalSeparator);
				}

			}
		}
	}
	//});


};



function s2ab(s) {
	let buf = new ArrayBuffer(s.length);
	let view = new Uint8Array(buf);
	for (let i = 0; i !== s.length; ++i) {
		view[i] = s.charCodeAt(i) & 0xFF;
	}
	return buf;
}

//! ******************************************************************************
//! *********** FUNCTIONS FOR CORDOVA - DONT DELETE ********************************
//! ******************************************************************************

/*
 Following functions are here for cordova (mobile app) only. This files ensure
 corect data export and corrrect save in mobile environment. The logic of file
 saving in mobile is differnet than in browser !!! So the following functions are
 key function for right mobile data export
*/


function writeFile(fileEntry, dataObj) {
	// Create a FileWriter object for our FileEntry (log.txt).
	fileEntry.createWriter(function (fileWriter) {
		fileWriter.onwriteend = function () {
			alert("Export saved to: " + fileEntry.toURL());
		};

		fileWriter.onerror = function (e) {
			alert("Export was not successfull. Error: ", e);
		};

		fileWriter.write(dataObj);
	}, (error) => {
		alert("Error: Could not create file writer, " + error.code);
	});
}


function createFile(dirEntry, fileName, data) {
	dirEntry.getFile(fileName, { create: true, exclusive: false }, function (fileEntry) {
		writeFile(fileEntry, data);
	}, (error) => {
		alert("Error: Could not create file, " + error.code);
	});

}

function createDirectory(rootDirEntry, filename, data) {
	rootDirEntry.getDirectory('IoTpool', { create: true }, function (dirEntry) {
		dirEntry.getDirectory('exports', { create: true }, function (subDirEntry) {
			createFile(subDirEntry, filename, data);
		}, (errExp) => {
			alert("Error: Could not create dir exports - ", errExp.code);
		});
	}, (errPool) => {
		alert("Error: Could not create dir IoTpool - ", errPool.code);
	});
}

function saveFile(filename, fileData) {
	window.requestFileSystem(window.PERSISTENT, 0, function (fs) {
		createDirectory(fs.root, filename, fileData);
	}, (err) => {
		alert("Error: Could not access file system, " + err.target.error.code);
	});
}

//! ******************************************************************************


