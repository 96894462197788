export const temperature = 'temperature';
export const temperature_inner = 'temperature_inner';
export const temperature_ext = 'temperature_ext';
export const humidity = 'humidity';
export const iaq = 'iaq';
export const pressure = 'pressure';
export const pir = 'pir';
export const door = 'door';
export const smoke = 'smoke';
export const battery = 'battery';
export const signal = 'signal';
export const battery_idle = 'battery_idle';
export const battery_idle_lvl = 'battery_idle_lvl';
export const battery_lvl = 'battery_lvl';
export const signal_lvl = 'signal_lvl';
export const last_msg_time = 'time';
export const flood = 'leakage';
export const position = 'position';
export const id = 'sensor_id';
export const state = 'state';
export const expiration = 'expiration';
export const device_power = 'arming';
export const charging = 'charging';
