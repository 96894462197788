const aggregate = (data, setname, period, aggregation, round = 2) => {
	const result = [];
	let subResult = [];

	if (data.length === 0) return [];

	let refValue = getValue(data[0].timestamp, period);
	let value = null;
	let neededExtraResultSave = true;

	for (let i = 0; i < data.length; i++) {
		const record = data[i];

		value = getValue(record.timestamp, period);

		if (value === refValue) {
			subResult.push(record);
			if (i < data.length - 1) continue;
			else neededExtraResultSave = false; // last item at all
		}

		let aggrResult = aggregation(subResult, setname);
		if (aggrResult !== null) {
			aggrResult = Math.round(aggregation(subResult, setname) * Math.pow(10, round)) / Math.pow(10, round);
		}
		result.push({
			start: roundStart(subResult[0].timestamp, period),
			end: roundEnd(subResult[subResult.length - 1].timestamp, period),
			[setname]: aggrResult
		});

		refValue = value;
		subResult = [record];

		if (i === data.length - 1 && neededExtraResultSave) {
			// if this is the last item- then we have to save the last item too because there is no following cycle
			result.push({
				start: roundStart(record.timestamp, period),
				end: roundEnd(record.timestamp, period),
				[setname]: record[setname]
			});
		}
	}

	return result;
};

export default aggregate;

export const getValue = (timestamp, period) => {
	let date = new Date(timestamp.getTime());

	switch (period) {
		case 'year':
			date.setMonth(0);
			date.setDate(0);
			date.setMinutes(0);
			date.setHours(0);
			date.setSeconds(0);
			date.setMilliseconds(0);
			return Math.round(date.getTime());
		case 'month':
			date.setDate(0);
			date.setMinutes(0);
			date.setHours(0);
			date.setSeconds(0);
			date.setMilliseconds(0);
			return Math.round(date.getTime());
		case 'week':
			const yearId = timestamp.getFullYear() * 100;
			return yearId + getWeek(timestamp);
		case 'day':
			date.setMinutes(0);
			date.setHours(0);
			date.setSeconds(0);
			date.setMilliseconds(0);
			return Math.round(date.getTime());
		case 'hour':
			date.setMinutes(0);
			date.setSeconds(0);
			date.setMilliseconds(0);
			return Math.round(date.getTime());
		case 'minute':
			date.setSeconds(0);
			date.setMilliseconds(0);
			return Math.round(date.getTime());
		case 'second':
			date.setMilliseconds(0);
			return Math.round(date.getTime());
		default:
			return null;
	}
};

export const roundStart = (timestamp, period) => {
	switch (period) {
		case 'year':
			return new Date(timestamp.getFullYear(), 0, 1, 0, 0, 0);
		case 'month':
			return new Date(timestamp.getFullYear(), timestamp.getMonth(), 1, 0, 0, 0);
		case 'week':
			return getDateOfISOWeek(getWeek(timestamp), timestamp.getFullYear());
		case 'day':
			return new Date(timestamp.getFullYear(), timestamp.getMonth(), timestamp.getDate(), 0, 0, 0);
		case 'hour':
			return new Date(
				timestamp.getFullYear(),
				timestamp.getMonth(),
				timestamp.getDate(),
				timestamp.getHours(),
				0,
				0
			);
		case 'minute':
			return new Date(
				timestamp.getFullYear(),
				timestamp.getMonth(),
				timestamp.getDate(),
				timestamp.getHours(),
				timestamp.getMinutes(),
				0
			);
		case 'second':
			return new Date(
				timestamp.getFullYear(),
				timestamp.getMonth(),
				timestamp.getDate(),
				timestamp.getHours(),
				timestamp.getMinutes(),
				timestamp.getSeconds()
			);
		default:
			return null;
	}
};

export const roundEnd = (timestamp, period) => {
	switch (period) {
		case 'year':
			return new Date(timestamp.getFullYear() + 1, 0, 0, 23, 59, 59);
		case 'month':
			return new Date(timestamp.getFullYear(), timestamp.getMonth() + 1, 0, 23, 59, 59);
		case 'week':
			const d = getDateOfISOWeek(getWeek(timestamp), timestamp.getFullYear());

			d.setDate(d.getDate() + 6);
			d.setHours(23);
			d.setMinutes(59);
			d.setSeconds(59);
			return d;
		case 'day':
			return new Date(timestamp.getFullYear(), timestamp.getMonth(), timestamp.getDate(), 23, 59, 59);
		case 'hour':
			return new Date(
				timestamp.getFullYear(),
				timestamp.getMonth(),
				timestamp.getDate(),
				timestamp.getHours(),
				59,
				59
			);
		case 'minute':
			return new Date(
				timestamp.getFullYear(),
				timestamp.getMonth(),
				timestamp.getDate(),
				timestamp.getHours(),
				timestamp.getMinutes(),
				59
			);
		case 'second':
			return new Date(
				timestamp.getFullYear(),
				timestamp.getMonth(),
				timestamp.getDate(),
				timestamp.getHours(),
				timestamp.getMinutes(),
				timestamp.getSeconds()
			);
		default:
			return null;
	}
};

/**
 * Returns the week number for this date.  dowOffset is the day of week the week
 * "starts" on for your locale - it can be from 0 to 6. If dowOffset is 1 (Monday),
 * the week returned is the ISO 8601 week number.
 * @param int dowOffset
 * @return int
 */

const getWeek = (date, dowOffset = 1) => {
	/*getWeek() was developed by Nick Baicoianu at MeanFreePath: http://www.meanfreepath.com */
	const newYear = new Date(date.getFullYear(), 0, 1);
	let day = newYear.getDay() - dowOffset; //the day of week the year begins on
	day = day >= 0 ? day : day + 7;
	const daynum =
		Math.floor(
			(date.getTime() - newYear.getTime() - (date.getTimezoneOffset() - newYear.getTimezoneOffset()) * 60000) /
				86400000
		) + 1;
	//if the year starts before the middle of a week
	if (day < 4) {
		const weeknum = Math.floor((daynum + day - 1) / 7) + 1;
		if (weeknum > 52) {
			const nYear = new Date(date.getFullYear() + 1, 0, 1);
			let nday = nYear.getDay() - dowOffset;
			nday = nday >= 0 ? nday : nday + 7;
			/*if the next year starts before the middle of
        the week, it is week #1 of that year*/
			return nday < 4 ? 1 : 53;
		}
		return weeknum;
	} else {
		return Math.floor((daynum + day - 1) / 7);
	}
};

export const getDateOfISOWeek = (w, y) => {
	var simple = new Date(y, 0, 1 + (w - 1) * 7);
	var dow = simple.getDay();
	var ISOweekStart = simple;
	if (dow <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
	else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
	return ISOweekStart;
};
