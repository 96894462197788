import React from 'react';

import Tooltip from "./../../Tooltip/Tooltip";

const CoinIcon = props => {
    return (
        <div {...props}>
            <Tooltip tooltip={props.tooltip}>
                <svg width="100%" viewBox="0 0 13.388 9.366">
                    <g transform="matrix(.26458 0 0 .26458 -42.35 63.082)">
                        <path fill="none" d="M-1-1h582v402H-1z" />
                    </g>
                    <g
                        clipPath="url(#prefix__clipPath92)"
                        transform="matrix(.26458 0 0 .26458 -42.35 63.082)"
                    >
                        <g transform="translate(135.36 -270.72)">
                            <path d="M50 57.6C35.8 57.6 24.7 52 24.7 45S35.8 32.3 50 32.3c14.2 0 25.3 5.6 25.3 12.7S64.2 57.6 50 57.6zm0-23.3c-12.6 0-23.3 4.9-23.3 10.6 0 5.8 10.7 10.6 23.3 10.6 12.6 0 23.3-4.9 23.3-10.6 0-5.7-10.7-10.6-23.3-10.6z" />
                            <path d="M50 67.7c-14.2 0-25.3-5.5-25.3-12.6V45h2v10.1c0 5.8 10.7 10.6 23.3 10.6 12.6 0 23.3-4.9 23.3-10.6V45h2v10.1c0 7-11.1 12.6-25.3 12.6z" />
                            <path d="M29.8 52.3h2v10.1h-2zM35.2 54.3h2v10.1h-2zM41.5 63.8c-.6 0-1-.4-1-1v-6.7c0-.6.4-1 1-1s1 .4 1 1v6.7c0 .5-.4 1-1 1zM46.9 61.1c-.6 0-1-.4-1-1v-3.4c0-.6.4-1 1-1s1 .4 1 1v3.4c0 .6-.4 1-1 1zM56.4 45.1c-.2 0-.5-.1-.7-.3-.4-.4-.4-1-.1-1.4l7.6-8.2c.4-.4 1-.4 1.4-.1.4.3.4 1 .1 1.4l-7.6 8.2c-.2.3-.4.4-.7.4zM61.4 47.6c-.2 0-.5-.1-.7-.3-.4-.4-.4-1-.1-1.4l4.5-4.9c.4-.4 1-.4 1.4-.1.4.4.4 1 .1 1.4l-4.5 4.9c-.2.3-.4.4-.7.4zM67.1 49.3c-.2 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l2-2.1c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-2 2.1c-.2.2-.4.3-.7.3z" />
                        </g>
                    </g>
                </svg>
            </Tooltip>
        </div>
    );
};

export default CoinIcon;
