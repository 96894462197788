import React from 'react';

import Tooltip from "./../../../Tooltip/Tooltip";

const flood = props => {
    return (
        <div {...props}>
            <Tooltip tooltip={props.tooltip}>
                <svg width="100%" viewBox="0 0 40 40">
                    <path
                        d="M5.353 16.16c2.035 1.042 4.07 2.888 6.092 2.89 2.82.003 5.595-3.575 8.427-3.575s5.722 3.578 8.564 3.575c2.04-.002 4.066-1.846 6.093-2.889a1.61 1.61 0 011.614.058c.504.308.79.815.792 1.407v.3a1.63 1.63 0 01-1.208 1.592c-2.557.711-4.52 3.416-7.29 3.417-2.843.003-5.733-3.575-8.565-3.575s-5.606 3.578-8.427 3.575c-2.43-.001-4.878-2.665-7.32-3.402a1.628 1.628 0 01-1.174-1.579v-.327c0-.59.285-1.1.79-1.407a1.61 1.61 0 011.612-.06zm0 8.004a1.608 1.608 0 00-1.613.06 1.61 1.61 0 00-.789 1.409v.326c0 .74.466 1.366 1.174 1.58 2.442.735 4.89 3.4 7.32 3.402 2.82.002 5.595-3.576 8.427-3.576s5.722 3.578 8.564 3.576c2.772-.001 4.734-2.707 7.291-3.419a1.63 1.63 0 001.208-1.59v-.301a1.613 1.613 0 00-.792-1.407 1.61 1.61 0 00-1.614-.058c-2.027 1.044-4.053 2.888-6.093 2.889-2.842.003-5.732-3.576-8.564-3.576s-5.606 3.579-8.427 3.576c-2.022-.001-4.057-1.847-6.092-2.89zm0-15.978a1.61 1.61 0 00-1.613.06 1.61 1.61 0 00-.789 1.407v.328c0 .739.466 1.366 1.174 1.579 2.442.736 4.89 3.4 7.32 3.402 2.82.003 5.595-3.575 8.427-3.575s5.722 3.578 8.564 3.575c2.771-.002 4.734-2.706 7.291-3.418a1.631 1.631 0 001.208-1.592v-.3a1.61 1.61 0 00-.792-1.406 1.608 1.608 0 00-1.614-.059c-2.027 1.044-4.053 2.888-6.093 2.89-2.842.001-5.732-3.576-8.564-3.576s-5.606 3.577-8.427 3.575c-2.022 0-4.057-1.847-6.092-2.89z"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        fill="#26a9e0"
                    />
                </svg>
            </Tooltip>
        </div>
    );
};

export default flood;
