import React from "react";
import { injectIntl } from "react-intl";

import Tooltip from "./../../../../Tooltip/Tooltip";

import classes from "./SimpleInput.module.scss";

const simpleInput = props => {
  let error = null;
  let borderClass = classes.validBorder;
  let highLight = props.touched;


  if (props.highLight === "Off") {
    highLight = false;
  }

  if (props.errMsg && !props.isValid) {
    error = props.errMsg;
  }
  let isValid = (
    <span>
      <i className={["fas", "fa-check-circle", classes.valid].join(" ")} />
    </span>
  );

  if (!props.isValid) {
    isValid = (
      <Tooltip tooltip={props.tooltip} containerClass={classes.tooltip} placement="right">
        <span className={classes.tip}>
          <i className={["fas", "fa-times-circle", classes.invalid].join(" ")}
            onClick={props.onTipClicked} />
        </span>
      </Tooltip>
    );
    borderClass = classes.invalidBorder;
  }

  return (
    <div className={classes.content}>
      <input
        className={highLight ? borderClass : classes.normalBorder}
        type={props.type}
        placeholder={props.emptyText}
        required=""
        name={props.name}
        onChange={props.onInputChange}
        maxLength="70"
      />
      {highLight ? isValid : null}
      <div>{highLight ? error : null}</div>
    </div>
  );
};

export default injectIntl(simpleInput);
