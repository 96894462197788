import React, { forwardRef } from 'react';
import styles from './ChartsPanel.module.scss';
import { injectIntl } from 'react-intl';
import SelectPeriod from './SelectPeriod/SelectPeriod.jsx';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './ChartsPanel.scss';
//import "react-datepicker/dist/react-datepicker-cssmodules.css";
import cs from 'date-fns/locale/cs';
import en from 'date-fns/locale/en-GB';

registerLocale('cs', cs);
registerLocale('en-GB', en);

const ChartsPanel = ({
	lang,
	intl,
	onSelect,
	dateFrom,
	dateTo,
	selectedPeriod,
	onDateFromChange,
	onDateToChange,
	onFilterButtonClick,
	filterButtonDisabled,
	style,
	closeOnSelect
}) => {
	const isWeekday = date => {
		const day = date.getDay();
		const dayClasses = [];

		if (date > new Date()) {
			return styles.futureDate;
		}

		if (day === 6 || day === 0) {
			//dayClasses.push(styles.weekday);
		}

		if (date > dateFrom && date < dateTo && dateFrom && dateTo) {
			if (
				Math.ceil(date.getTime() / (24 * 60 * 60 * 1000)) !==
				Math.floor(dateTo.getTime() / (24 * 60 * 60 * 1000))
			) {
				dayClasses.push(styles.selectedDay);
			}
		}

		if (dayClasses.length > 0) {
			return dayClasses.join(' ');
		}

		return undefined;
	};



	const ref = React.createRef()
	const CustomDateInput = forwardRef(({ onClick, value }, ref) => (
		<input className={styles.picker} onClick={onClick} value={value} onChange={onClick} ref={ref} readOnly/>
	));
	return (
		<div className={styles.chartsPanel} style={style}>
			<div className={styles.selectDiv}>
				<p className={styles.datePickerText}>{intl.formatMessage({ id: 'chart.period' })}</p>
				<SelectPeriod onSelect={onSelect} selectedPeriod={selectedPeriod} />
			</div>
			<div className={styles.datePickerDiv}>
				<p className={styles.datePickerText}>{intl.formatMessage({ id: 'chart.from' })}</p>

				<DatePicker
					className={styles.picker}
					onChange={onDateFromChange}
					selected={dateFrom}
					showTimeInput={false}
					dateFormat="MMMM d, yyyy"
					isClearable={false}
					placeholderText="--   /   --   /  --     --:--"
					timeInputLabel="Time:"
					showMonthDropdown
					showYearDropdown
					dropdownMode="select"
					minDate={new Date(2015, 1, 1)}
					maxDate={new Date()}
					//highlightDates={[dateTo]}
					dayClassName={isWeekday}
					shouldCloseOnSelect={closeOnSelect}
					locale={lang === 'en' ? 'en-GB' : 'cs'}
					customInput={<CustomDateInput ref={ref} />}
				/>

				<p className={styles.datePickerText}>{intl.formatMessage({ id: 'chart.to' })}</p>
				<span className={styles.datePicker}>
					<DatePicker
						className={styles.picker}
						onChange={onDateToChange}
						selected={dateTo}
						showTimeInput={false}
						dateFormat="MMMM d, yyyy"
						isClearable={false}
						placeholderText="--   /   --   /  --     --:--"
						timeInputLabel="Time:"
						showMonthDropdown
						showYearDropdown
						dropdownMode="select"
						minDate={dateFrom}
						maxDate={new Date()}
						//highlightDates={[dateFrom]}
						dayClassName={isWeekday}
						shouldCloseOnSelect={closeOnSelect}
						locale={lang === 'en' ? 'en-GB' : 'cs'}
						customInput={<CustomDateInput ref={ref} />}
					/>
				</span>

				<button
					className="btn btn-md btn-primary ml-3"
					style={{ height: '38px', border: 'none' }}
					onClick={onFilterButtonClick}
					disabled={filterButtonDisabled}
				>
					{intl.formatMessage({ id: 'button.filter' })}
				</button>
			</div>
		</div>
	);
};

export default injectIntl(ChartsPanel);
