import React from "react";
import { FormattedMessage } from "react-intl";

import Coin from "./Coin/Coin.jsx";

import classes from "./CoinPanel.module.scss";

const coinPanel = props => {
  let btn = null;
  if (props.btnEnabled) {
    btn = (
      <button
        onClick={props.donateTokens}
        className={["btn btn-primary", classes.buttonContainer].join(" ")}
      >
        <FormattedMessage id="button.topup" defaultMessage="Top Up" />
      </button>
    );
  }

  return (
    <div className={classes.container}>
      {btn}
      <Coin
        label={props.coinLabel}
        value={props.coinValue}
        className={classes.coinPanel}
      />
    </div>
  );
};

export default coinPanel;
