import React from 'react';
import GeneralModal from './../General/General.jsx';

import { FormattedMessage } from 'react-intl';
import InitModal from './InitModal/InitModal.jsx';
import ConfirmModal from './ConfirmModal/ConfirmModal.jsx';
import ActivationResult from './../modalTopUp/ActivationResult/ActivationResult.jsx';

const actiovationMessages = {
	progress: <FormattedMessage id="sensor.activation" defaultMessage="Activation..." />,
	success: <FormattedMessage id="modal.activation.success" defaultMessage="Activation was successful" />,
	failed: <FormattedMessage id="modal.activation.failed" defaultMessage="Activation was not successful" />
};

const modalActivate = props => {
	let content = null;
	let continueBtn = null;
	let cancelBtn = null;
	let closeOnEsc = true;
	let closeOnOverlayClick = true;

	switch (props.state) {
		case 'IN_PROGRESS':
		case 'SUCCESS':
		case 'FAILED':
			closeOnEsc = false;
			closeOnOverlayClick = false;
			content = <ActivationResult continue={props.close} result={props.state} messages={actiovationMessages} />;
			break;
		case 'CONFIRMATION':
			content = <ConfirmModal selection={props.selection} />;
			continueBtn = {
				style: { marginRight: '15px' },
				className: 'Btn_range',
				action: props.continue,
				text: <FormattedMessage id="button.continue" defaultMessage="Continue" />
			};
			cancelBtn = {
				className: 'btn btn-md btn-secondary',
				action: props.close,
				isDisabled: false,
				text: <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
			};
			break;
		default:
			content = (
				<InitModal
					isOpen={props.isOpen}
					close={props.close}
					sensorId={props.sensorId}
					sensorName={props.sensorName}
					selectionClick={props.selectionClick}
					prices={props.prices}
				/>
			);
			break;
	}

	return (
		<GeneralModal
			isOpen={props.isOpen}
			close={props.close}
			actionBtn={continueBtn}
			cancelBtn={cancelBtn}
			closeOnEsc={closeOnEsc}
			closeOnOverlayClick={closeOnOverlayClick}
		>
			{content}
		</GeneralModal>
	);
};

export default modalActivate;
