import React from 'react';

import Tooltip from './../../Tooltip/Tooltip';
import { Button } from '@progress/kendo-react-buttons';
import { DropDownList } from '@progress/kendo-react-dropdowns';

import classes from './Toolbar.module.scss';

const toolbar = props => {
	const selectedButtons = [];
	const selectedDropDowns = [];



	if (props.buttons) {
		for (let i = 0; i < props.buttons.length; i++) {
			const buttonDef = props.buttons[i];

			if (buttonDef.hidden) continue;

			selectedButtons.push(
				<Tooltip tooltip={buttonDef.tip} key={buttonDef.id + buttonDef.type}>
					<Button
						togglable={buttonDef.toggable}
						disabled={buttonDef.disabled}
						className={classes.icon}
						imageUrl={buttonDef.imageUrl}
						icon={buttonDef.icon}
						iconClass={buttonDef.iconClass}
						onClick={buttonDef.handler}
						selected={buttonDef.selected}
					/>
				</Tooltip>
			);
		}
	}

	if (props.dropDowns) {
		for (let i = 0; i < props.dropDowns.length; i++) {
			const dropDownDef = props.dropDowns[i];

			if (dropDownDef.hidden) continue;

			selectedDropDowns.push(
				<Tooltip tooltip={dropDownDef.tip} key={dropDownDef.id} placement="right">
					<DropDownList
						data={dropDownDef.data}
						defaultValue={dropDownDef.default}
						className={classes.chartTypeDropDown}
						onChange={dropDownDef.onChange}
						disabled={dropDownDef.disabled}
						value={dropDownDef.value}
					/>
				</Tooltip>
			);
		}
	}

	return (
		<React.Fragment>
			<div className={classes.container}>
				<div className={classes.pokus}>
					<div>{selectedButtons}</div>
					<div className={classes.dropDownsContainer}>{selectedDropDowns}</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default toolbar;
