import React from "react";

import classes from "./SpinnerWithResult.module.scss";

const spinnerWithResult = props => {
  let content = null;
  let circleClasses = [classes["circle-loader"]];
  let checkMarkClasses = [classes["checkmark"]];
  if (!props.loading) {
    if (props.resultSuccess) {
      circleClasses.push(classes["load-complete"]);
      checkMarkClasses.push(classes["draw"]);
    } else {
      circleClasses.push(classes["load-error"]);
      checkMarkClasses.push(classes["error"]);
    }
  }

  if (props.loading || props.resultSuccess !== null) {
    content = (
      <div className={circleClasses.join(" ")}>
        <div className={checkMarkClasses.join(" ")}></div>
      </div>
    );
  }

  return content;
};

export default spinnerWithResult;
