// @flow
import React, { Component } from 'react';

import { ToastContainer } from 'react-toastify';
import actions from './../store/actions/actions';

import '@progress/kendo-theme-default/dist/all.css';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import './../styles/App.scss';
import './../styles/uiInputs.scss';

import Header from './../containers/layout/Header/Header.jsx';
import Footer from './../components/layout/Footer/Footer.jsx';
import Menu from './../containers/pages/Menu/Menu';

import SensorsListPage from './pages/SensorsList/SensorsList.jsx';
import SensorAddPage from './pages/SensorAdd/SensorAdd.jsx';
import SensorEditPage from './pages/SensorEdit/SensorEdit.jsx';
import SensorDetailPage from './pages/SensorDetail/SensorDetail.jsx';
import Notifications from './pages/Menu/Notifications/Notifications.jsx';
import User from './pages/Menu/Users/Users.jsx';
//import Devices from "./pages/Menu/Devices/Devices";
import AlarmModal from './../components/modals/AlarmModal/AlarmModal';

import { Route, Switch, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';

//import IdleTimer from 'react-idle-timer';
import axios from './../axios/axios.js';

//import awsmobile from "../aws-exports";
import { withAuthenticator } from 'aws-amplify-react';
import authWatchDog, { refreshCurrentSession, logOut, stopWatching } from './../utils/authWatchDog.js';


import AuthStyles from './Auth/Auth.js';
import GlobalNetworkErrorModal from './../containers/Errors/NetworkError/NetworkError.jsx';

import Amplify from '@aws-amplify/core';
import { initialize } from './../firebase';

Amplify.configure({
	Auth: {
		//identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab', //REQUIRED - Amazon Cognito Identity Pool ID
		region: 'eu-central-1',
		userPoolId: 'eu-central-1_A6Z8wOtCd',
		userPoolWebClientId: '738p4167mip927pulojkf5m2lr',
	},
	Analytics: {
		disabled: true,
	},
});
const firebase = initialize();


class App extends Component {
	constructor(props) {
		super(props);
		this.idleTimer = null;
		const expTime = props.authData.signInUserSession.idToken.payload.exp * 1000;
		this.state = {
			width: window.innerWidth,
			sessionExpired: expTime + 15000 < new Date().getTime(), // + 15 sec
			raisedAlarms: [],
		};
		const token = props.authData.signInUserSession.idToken.jwtToken;
		if (!token) {
			// this should not happen
			throw Error('Unknow id token, when user is logged in');
		}
		axios.defaults.headers.common['Iot-Id-Token'] = token;
	}
	componentDidMount() {
		this.props.store.dispatch(actions.refreshToken("IOT_GSOL"));
		if (firebase) {
			firebase.messaging().onMessage((payload) => {
				if (!('Notification' in window)) {
					console.log('This browser does not support system notifications');
				}
				// Let's check whether notification permissions have already been granted
				else if (Notification.permission === 'granted' && "serviceWorker" in navigator) {
					//console.log("onMesage", payload);
					const notificationTitle = payload.notification.title;
					const notificationOptions = {
						body: payload.notification.body,
						icon: payload.notification.icon,
						data: {
							FCM_MSG: {
								data: payload.data
							}
						}
					};
					// ! COMMENTED PART NOT WORKED ON MOBILE BROWSER - WORKED JUST ON DESKTOP -> WE HAVE TO DISPLAY NOTIF VIA SERVICEWORKER
					// var notification = new Notification(notificationTitle, notificationOptions);
					// notification.onclick = function (event) {
					// 	event.preventDefault(); // prevent the browser from focusing the Notification's tab
					// 	window.open(payload.data.url, '_blank');
					// 	notification.close();

					navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope').then(registration => {
						//console.log("registration", registration, registration.active);
						registration.showNotification(notificationTitle, notificationOptions);
					});
				} else {
					setTimeout(() => {
						this.props.store.dispatch(actions.fetchSensorsDirect(false));
						// some delay - to be sure that all states are actual in database.
					}, 300);

					if (payload.data.active === 'true') {
						const newAlarmArr = this.state.raisedAlarms.slice();
						newAlarmArr.push(payload.data);

						this.setState({
							...this.state,
							raisedAlarms: newAlarmArr,
						});
					} else {
						this.removeActiveAlarm(payload.data);
					}
				}
			});
			firebase.messaging().onTokenRefresh(() => {
				firebase
					.messaging()
					.getToken()
					.then((token) => {
						//save new token for this device into the DB
						axios.post('/users/notifications/push/device', {}, { params: { entity: 'IOT_GSOL', id: token } });
					});
			});
		}

		//* appActions are used in containers-layout-Header
		window.addEventListener('resize', this.handleResize);

		this.props.store.dispatch(actions.fetchCoins());

		if (this.state.sessionExpired) {
			// If session expired (checked in constructor) ther renew the ssesion and load all sensors
			refreshCurrentSession()
				.then((actSession) => {
					axios.defaults.headers.common['Iot-Id-Token'] = actSession.idToken.jwtToken;
					this.setState({ ...this.state, sessionExpired: false });
				})
				.catch(() => logOut());
		} else {
			authWatchDog();
			document.getElementById('root-loader').classList.add('root_loader_hide');
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.sessionExpired && !this.state.sessionExpired) {
			authWatchDog();
			document.getElementById('root-loader').classList.add('root_loader_hide');
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
		stopWatching();
		this.props.history.push('/');
		//this.props.store.dispatch(actions.clearSensors());
	}

	handleResize = (e) => {
		this.setState({ width: e.currentTarget.innerWidth });
	};

	onCloseSimpleAlarmHandler = (e) => {
		this.removeActiveAlarm(e);
	};

	onCloseAllAlarmsHandler = () => {
		this.setState({
			raisedAlarms: [],
		});
	};

	onShowAlarmHandler = (e) => {
		if (e.url_detail) {
			this.removeActiveAlarm(e);
			this.props.history.push(e.url_detail.replace('https://app.iotpool.io', ''));
		}
	};

	removeActiveAlarm = (alarm) => {
		if (alarm.url && this.state.raisedAlarms.length > 0) {
			const reducedRaisedAlarms = this.state.raisedAlarms.slice(1);

			if (reducedRaisedAlarms.length < this.state.raisedAlarms.length) {
				this.setState({
					raisedAlarms: reducedRaisedAlarms,
				});
			}
		}
	};

	render() {
		if (this.state.sessionExpired) return null;

		const { width } = this.state;
		let alarmModal = null;
		if (this.state.raisedAlarms.length > 0) {
			alarmModal = (
				<AlarmModal
					isOpen
					alarm={this.state.raisedAlarms[0]}
					totalAlarms={this.state.raisedAlarms.length}
					close={this.onCloseSimpleAlarmHandler}
					closeAll={this.onCloseAllAlarmsHandler}
					onShow={this.onShowAlarmHandler}
				/>
			);
		}

		return (
			<div className="App" id="outer-container2">
				{/*<IdleTimer
					timeout={59 * 60 * 1000}
					ref={ref => {
						this.idleTimer = ref;
					}}
					onIdle={() => {
						this.props.history.push('/');
						logOut();
					}}
				/>*/}
				<Header width={width} />
				<Menu />
				<div className="mainContentContainer">
					<Switch>
						<Route exact path="/" render={({ history }) => <SensorsListPage width={width} history={history} />} />
						<Route exact path="/charts" render={({ history }) => <SensorsListPage width={width} history={history} chartModeOn />} />
						<Route path="/add" render={({ history }) => <SensorAddPage history={history} />} />
						<Route path="/edit/:id" render={({ match, history }) => <SensorEditPage id={match.params.id} history={history} />} />
						<Route path="/display/:id" render={({ match, history }) => <SensorDetailPage id={match.params.id} history={history} />} />
						<Route path="/notifications" render={() => <Notifications />} />
						<Route path="/user" render={() => <User />} />
						{/* <Route path="/devices" render={() => <Devices />} /> */}
						<Route render={() => <Redirect to="/" />} />
					</Switch>
				</div>
				<Footer />
				<ToastContainer />
				<GlobalNetworkErrorModal />
				{alarmModal}
			</div>
		);
	}
}

/*
const mapStateToProps = state => {
	return {
		sessionExpired: state.auth.sessionExpired
	};
};


const mapDispatchToProps = dispatch => {
	return {
		clearSensors: () => dispatch(clearSensors())
	};
};
*/

export default withAuthenticator(withRouter(App), false, AuthStyles);
