
import { getValue } from "./utils";
import * as fields from "../../sensorDescriptors/Info/infos"

export function siotechGpsDef(sensor, intl) {
    return [
        {
            dataset: "temperature",
            value: getValue(sensor, fields.temperature),
            unit: "°C",
            icon: "temperature",
            tooltip: intl.formatMessage({ id: "tooltip.temperature" }),
            legend:
                intl.formatMessage({ id: "tooltip.temperature" }) +
                `(${sensor.origName})`,
        },
        {
            dataset: "gps",
            unit: "",
            icon: "gps",
            tooltip: intl.formatMessage({ id: "tooltip.gpsTracking" }),
            legend:
                intl.formatMessage({ id: "tooltip.gpsTracking" }) +
                `(${sensor.origName})`,
            disabled: true,
        },
    ]
}