import React from 'react';

import { injectIntl } from 'react-intl';

import Unit from "./../../../../../icons/Unit/Unit";
import Battery from "./../../battery/Battery";
import Signal from "./../../signal/Signal";
import Id from "./../../../../../icons/Id/Id";
import Time from "./../../Time/Time";
import NotAvailable from "./../../NotAvailable/NotAvailable";

import TextIcon from "./../../TextIcon/TextIcon";

import * as infos from "./../infos";

const wicub2 = (props) => {

    let icon = null;
    let iconClass = null;
    let tooltip = null;
    let value = props.value;
    let style = null;

    switch (props.type) {
        case infos.temperature:
            icon = <Unit type="temperature" tooltip={props.intl.formatMessage({ id: 'tooltip.info.lastTemperature' })}/>;
            break;
        case infos.humidity:
            icon = <Unit type="humidity" tooltip={props.intl.formatMessage({ id: 'tooltip.info.lastHumidity' })}/>;
            break;
        case infos.pressure:
            icon = <Unit type="pressure" tooltip={props.intl.formatMessage({ id: 'tooltip.info.lastPressure' })}/>;
            break;
        case infos.iaq:
            icon = <Unit type="iaq" tooltip={props.intl.formatMessage({ id: 'tooltip.info.lastIaq' })}/>;
            break;
        case infos.battery_lvl:
            return (
                <Battery
                    value={props.value}
                    tooltip={props.intl.formatMessage({ id: 'tooltip.battery' }) + ` [1-5]  :  ${props.value}`}
                />
            );
        case infos.signal_lvl:
            return (
                <Signal
                    value={props.value}
                    tooltip={props.intl.formatMessage({ id: 'tooltip.signal' }) + ` [1-5]  :  ${props.value}`}
                />
            );
        case infos.id:
            icon = <Id tooltip={props.intl.formatMessage({ id: 'tooltip.info.id' })}/>;
            break;
        case infos.last_msg_time:
            return (
                <Time
                    lang={props.lang}
                    time={props.value}
                    tooltip={props.intl.formatMessage({ id: 'tooltip.info.lastMsgTime' })}
                />
            );
        case infos.charging:
            iconClass = "fas fa-bolt";
            const chState = props.value ? "tooltip.info.charging" : "tooltip.info.notCharging";
            style = props.value ? { color: "orange" } : null;
            tooltip = props.intl.formatMessage({ id: chState });
            break;
        default:
            return <NotAvailable />;
    }

    return (
        <TextIcon
            iconComponent={icon}
            iconClass={iconClass}
            text={value}
            tooltip={tooltip}
            style={style}
        />);
};


export default injectIntl(wicub2);