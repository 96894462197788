import React from "react";
import { injectIntl } from "react-intl";

import classes from "./SubmitInput.module.scss";

const submitInput = props => {
  return (
    <div className={classes.content}>
      <input
        disabled={!props.isEnabled}
        type={"submit"}
        value={props.value}
        required=""
        name={props.name}
        onClick={props.onSubmit}
      />
    </div>
  );
};

export default injectIntl(submitInput);
