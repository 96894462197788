import React from 'react';

import Tooltip from './../../../Tooltip/Tooltip';

const motion = (props) => {
	const disabledColor = 'lightgray';
	let motionState = (
		<div {...props}>
			<Tooltip tooltip={props.tooltip}>
				<svg width="100%" viewBox="0 0 64 64">
					<path
						fill={props.disabled ? disabledColor : '#236570'}
						d="M6.053 55.362a1.9 1.9 0 00.997 2.487c.247.104.503.151.75.151.74 0 1.433-.425 1.746-1.144l3.996-9.284-3.16-2.27-4.329 10.06zM18.64 31.575l-.542 4.67 3.132 2.922c.361.34.826.51 1.291.51.513 0 1.016-.208 1.395-.605a1.88 1.88 0 00-.094-2.666l-5.183-4.831z"
					/>
					<path
						fill={props.disabled ? disabledColor : '#32717f'}
						d="M21.05 47.212a3.712 3.712 0 00-1.822-2.552l-.086-.057-3.635-2.61 1.262-10.882c0-.936-.503-1.834-1.357-2.354-.845-.52-1.86-.577-2.724-.151l-5.752 2.742a3.772 3.772 0 00-1.974 2.458l-.93 4.935a1.906 1.906 0 001.518 2.212 1.918 1.918 0 002.221-1.512l.883-4.718 4.12-1.967-1.291 10.996s5.856 4.236 5.865 4.264l1.89 8.5c.217.992 1.252 1.664 2.258 1.437a1.88 1.88 0 001.196-.823c.266-.435.36-.936.247-1.427l-1.889-8.49zM17.168 27.736c.047 0 .085.01.123.01 2.098 0 3.797-1.693 3.797-3.782s-1.699-3.782-3.797-3.782a3.788 3.788 0 00-3.796 3.782c0 .964.37 1.834.968 2.505a3.818 3.818 0 002.705 1.267zM47.98 11.678h10.754l.317-1.896a.947.947 0 00-.949-.946H48.61a.947.947 0 00-.949.946l.532 3.177-.212-1.281zM48.295 13.569l1.214 7.257c.176.181.726.297.996.297H56.2c.277 0 .827-.116 1-.3l-.05.3c0 .53-.418.945-.95.945h-5.695a.95.95 0 01-.668-.272c.172.17.41.277.672.277h5.695c.524 0 .949-.424.949-.946l1.55-9.267-.291 1.709H48.295z"
					/>
					<path
						fill={props.disabled ? disabledColor : '#236570'}
						d="M56.2 22.068c.532 0 .95-.416.95-.945l.05-.3c-.173.184-.723.3-1 .3h-5.695c-.27 0-.82-.116-.996-.297-.003-.002.047.297.047.297 0 .265.108.502.281.673a.95.95 0 00.668.272H56.2z"
					/>
					<path fill={props.disabled ? disabledColor : '#d0eae8'} d="M48.193 12.96l.1.609h10.119l.291-1.709.032-.182H47.98z" />
					<path
						fill={props.disabled ? disabledColor : '#f05f51'}
						d="M59.829 38.17a.952.952 0 00-1.322-.234c-8.453 5.885-19.902 4.052-26.067-4.17-6.164-8.223-4.676-19.68 3.387-26.08a.944.944 0 00.151-1.33.951.951 0 00-1.334-.15c-8.87 7.043-10.506 19.645-3.724 28.69 4.105 5.477 10.34 8.377 16.685 8.377 4.137 0 8.32-1.233 11.99-3.787.43-.3.534-.889.234-1.317z"
					/>
					<path
						fill={props.disabled ? disabledColor : '#f05f51'}
						d="M35.483 31.502a15.145 15.145 0 0012.15 6.077c2.946 0 5.924-.856 8.545-2.632a.943.943 0 00.25-1.313.951.951 0 00-1.318-.25c-5.89 3.993-13.845 2.67-18.107-3.013a13.164 13.164 0 012.242-18.149.942.942 0 00.134-1.33.95.95 0 00-1.336-.133 15.044 15.044 0 00-2.56 20.743z"
					/>
					<path
						fill={props.disabled ? disabledColor : '#f05f51'}
						d="M40.046 28.108c1.845 2.462 4.713 3.782 7.622 3.782 1.749 0 3.514-.478 5.079-1.473a.943.943 0 00.29-1.305.95.95 0 00-1.311-.288c-3.334 2.119-7.794 1.306-10.16-1.847-2.364-3.154-1.878-7.646 1.107-10.227a.943.943 0 00.095-1.334.951.951 0 00-1.339-.094c-3.732 3.226-4.34 8.843-1.383 12.786z"
					/>
				</svg>
			</Tooltip>
		</div>
	);

	if (props.closed) {
		motionState = (
			<div {...props}>
				<Tooltip tooltip={props.tooltip}>
					<svg width="100%" viewBox="0 0 64 64">
						<g>
							<path
								fill={props.disabled ? disabledColor : '#32717f'}
								d="M47.98 11.678h10.754l.317-1.896a.947.947 0 00-.949-.946H48.61a.947.947 0 00-.949.946l.532 3.177-.212-1.281zM48.295 13.569l1.214 7.257c.176.181.726.297.996.297H56.2c.277 0 .827-.116 1-.3l-.05.3c0 .53-.418.945-.95.945h-5.695a.95.95 0 01-.668-.272c.172.17.41.277.672.277h5.695c.524 0 .949-.424.949-.946l1.55-9.267-.291 1.709H48.295z"
							/>
							<path
								fill={props.disabled ? disabledColor : '#236570'}
								d="M56.2 22.068c.532 0 .95-.416.95-.945l.05-.3c-.173.184-.723.3-1 .3h-5.695c-.27 0-.82-.116-.996-.297-.003-.002.047.297.047.297 0 .265.108.502.281.673a.95.95 0 00.668.272H56.2z"
							/>
							<path fill={props.disabled ? disabledColor : '#d0eae8'} d="M48.193 12.96l.1.609h10.119l.291-1.709.032-.182H47.98z" />
							<path
								fill={props.disabled ? disabledColor : '#007f3f'}
								d="M60.11 38.17a.952.952 0 00-1.32-.234c-8.453 5.885-19.903 4.052-26.068-4.17-6.164-8.223-4.676-19.68 3.387-26.08a.944.944 0 00.151-1.33.951.951 0 00-1.333-.15c-8.87 7.043-10.507 19.645-3.725 28.69 4.106 5.477 10.34 8.377 16.686 8.377 4.136 0 8.32-1.233 11.99-3.787.428-.3.533-.889.233-1.317z"
							/>
							<path
								fill={props.disabled ? disabledColor : '#007f00'}
								d="M35.483 31.502a15.145 15.145 0 0012.15 6.077c2.946 0 5.924-.856 8.545-2.632a.943.943 0 00.25-1.313.951.951 0 00-1.318-.25c-5.89 3.993-13.845 2.67-18.107-3.013a13.164 13.164 0 012.242-18.149.942.942 0 00.134-1.33.95.95 0 00-1.336-.133 15.044 15.044 0 00-2.56 20.743z"
							/>
							<path
								fill={props.disabled ? disabledColor : '#007f00'}
								d="M40.046 28.108c1.845 2.462 4.713 3.782 7.622 3.782 1.749 0 3.514-.478 5.079-1.473a.943.943 0 00.29-1.305.95.95 0 00-1.311-.288c-3.334 2.119-7.794 1.306-10.16-1.847-2.364-3.154-1.878-7.646 1.107-10.227a.943.943 0 00.095-1.334.951.951 0 00-1.339-.094c-3.732 3.226-4.34 8.843-1.383 12.786z"
							/>
							{/* <path strokeOpacity="null" strokeWidth="null" stroke="null" fill={props.disabled ? disabledColor :"#7f7f7f" d="M4.522 56.669h54.391v1.693H4.522z" /> */}
						</g>
					</svg>
				</Tooltip>
			</div>
		);
	}

	return motionState;
};

export default motion;
