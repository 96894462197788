import React from 'react';

const LowCredits = props => {
	return (
		<div {...props}>
			<svg width="100%" viewBox="0 0 342 432">
				<g>
					<title>{'Layer 1'}</title>
					<path
						fill="#5EAD46"
						d="M311.333 236.496c-24.07-47.899-75.581-66.678-76.62-27.136-.614 23.278 41.438 71.433 67.986 89.423 26.549 17.992 32.7-14.38 8.634-62.287z"
					/>
					<path
						fill="#51943B"
						d="M276.283 209.355c-9.768.38-18.814 3.583-22.274 12.382-6.015 15.302 12.922 50.88 12.937 50.908-15.746-16.18-28.21-33.506-28.981-45.38-1.542-23.779 17.074-28.143 38.318-17.91z"
					/>
					<path
						opacity={0.32}
						fill="#e5e5e5"
						d="M322.9 383.837c0-13.746-65.612-24.887-146.548-24.887-80.935 0-146.547 11.142-146.547 24.887 0 4.503 7.079 8.723 19.397 12.367-3.976 3.022 62.812 15.53 145.215 12.325 82.403-3.206 128.483-11.986 128.483-24.692z"
					/>
					<path
						fill="#5EAD46"
						d="M14.918 256.058c-21.23 49.228-2.51 100.761 28.453 76.145 18.23-14.492 28.096-77.66 24.815-109.56-3.278-31.902-32.036-15.81-53.268 33.415z"
					/>
					<path
						fill="#51943B"
						d="M55.908 312.147c9.96-25.472 14.698-65.98 12.279-89.504-2.157-20.988-15.344-21.197-30.16-4.87 6.213 12.63 19.437 40.638 20.496 52.3.928 10.211-1.141 30.04-2.615 42.074z"
					/>
					<path
						fill="#5EAD46"
						d="M239.268 379.195c-38.251-9.272-93.693-9.85-133.452 2.037-15.122 5.187-64.93 16.06-59.89-30.442 14.686-76.155 23.64-148.644 19.243-212.584 0-15.119 12.369-27.489 27.489-27.489l153.939-17.41c15.119 0 27.49 12.37 27.49 27.488-5.298 67.485 6.28 147.01 21.99 229.994 6.875 44.44-39.584 32.901-56.81 28.406z"
					/>
					<path
						fill="#51943B"
						d="M77.216 128.89c10.404-11.758 61.117-17.25 71.108-21.424 1.653-.689 3.437-1.98 5.26-3.638l-60.925 6.89c-15.12 0-27.49 12.37-27.49 27.49.952 13.82 1.276 28.041 1.056 42.612 2.227-22.23 5.765-46.025 10.99-51.93z"
					/>
					<circle r={60.654} fill="#FFF" cy={202.977} cx={219.898} />
					<circle r={60.654} fill="#FFF" cy={202.977} cx={107.612} />
					<path d="M229.518 86.382c0-4.679 4.987-8.469 11.145-8.469 6.157 0 11.145 3.79 11.145 8.47 0 4.678-4.988 8.468-11.145 8.468-6.158 0-11.145-3.79-11.145-8.469z" />
					<ellipse
						transform="rotate(-67.728 212.212 193.64)"
						ry={8.469}
						rx={11.145}
						opacity={0.15}
						fill="#0D6C3F"
						cy={86.382}
						cx={240.663}
					/>
					<path d="M271.587 97.186c0-3.486 3.716-6.31 8.303-6.31 4.587 0 8.303 2.824 8.303 6.31 0 3.486-3.716 6.31-8.303 6.31-4.587 0-8.303-2.824-8.303-6.31z" />
					<ellipse
						transform="rotate(-67.734 251.442 204.453)"
						ry={6.31}
						rx={8.303}
						opacity={0.15}
						fill="#0D6C3F"
						cy={97.186}
						cx={279.89}
					/>
					<path d="M314.067 76.137c0-5.139 4.852-9.301 10.843-9.301 5.99 0 10.843 4.162 10.843 9.301s-4.853 9.301-10.843 9.301-10.843-4.162-10.843-9.301z" />
					<ellipse
						transform="rotate(-65.821 300.047 184.274)"
						ry={9.301}
						rx={10.843}
						opacity={0.15}
						fill="#0D6C3F"
						cy={76.137}
						cx={324.91}
					/>
					<circle r={9.361} opacity={0.15} fill="#0D6C3F" cy={117.303} cx={245.205} />
					<ellipse ry={8.398} rx={8.394} fill="#3F2945" cy={203.952} cx={225.443} />
					<ellipse ry={8.398} rx={8.394} fill="#3F2945" cy={203.952} cx={101.083} />
					<path
						fill="#3F2945"
						d="M243.088 140.517a5.203 5.203 0 01-4.185-1.068c-.632-.512-15.047-11.77-32.19-1.943-2.467 1.423-5.61.56-7.026-1.904a5.141 5.141 0 011.899-7.028c23.413-13.432 43.006 2.202 43.827 2.873 2.202 1.818 2.524 5.064.727 7.261a5.176 5.176 0 01-3.052 1.81zM138.042 140.517a5.203 5.203 0 01-4.185-1.068c-.632-.512-15.048-11.77-32.19-1.943-2.467 1.423-5.61.56-7.026-1.904a5.141 5.141 0 011.899-7.028c23.413-13.432 43.006 2.202 43.827 2.873 2.202 1.818 2.524 5.064.727 7.261a5.179 5.179 0 01-3.052 1.81z"
					/>
					<circle r={21.396} fill="#51943B" cy={302.358} cx={162.266} />
					<path
						fill="#e5e5e5"
						d="M179.44 40.378a5.594 5.594 0 11-11.188 0V6.81a5.596 5.596 0 015.595-5.594 5.597 5.597 0 015.594 5.594v33.568zM106.115 61.342a5.601 5.601 0 010 7.92 5.591 5.591 0 01-7.911-.009L74.47 45.52a5.591 5.591 0 010-7.91 5.582 5.582 0 017.903 0l23.74 23.732zM139.069 48.354c.96 2.936-.627 6.106-3.57 7.075-2.928.961-6.088-.65-7.065-3.586l-10.46-31.884a5.598 5.598 0 013.56-7.067 5.611 5.611 0 017.075 3.57l10.46 31.892zM241.579 61.342a5.603 5.603 0 000 7.92 5.593 5.593 0 007.912-.009l23.725-23.733a5.58 5.58 0 000-7.91 5.582 5.582 0 00-7.903 0l-23.734 23.732zM208.622 48.354a5.608 5.608 0 003.57 7.075c2.928.961 6.09-.65 7.066-3.586L229.71 19.96a5.592 5.592 0 00-3.562-7.067 5.605 5.605 0 00-7.066 3.57l-10.461 31.892z"
					/>
				</g>
			</svg>
		</div>
	);
};

export default LowCredits;
