import React from "react";
import { FormattedMessage } from "react-intl";
import Spinner from "./../../../Spinner/SpinnerWithResult/SpinnerWithResult.jsx";

const activationResult = props => {
  const isLoading = props.result === "IN_PROGRESS";
  const resultSuccess = props.result === "SUCCESS";

  let message = props.messages.progress;

  if (!isLoading) {
    if (resultSuccess) {
      message = props.messages.success;
    } else {
      message = props.messages.failed;
    }
  }

  let button = (
    <button
      className="Btn_range"
      onClick={props.continue}
      disabled={isLoading}
      style={{ marginRight: "10px" }}
    >
      <FormattedMessage id="button.OK" defaultMessage="OK" />
    </button>
  );

  return (
    <div>
      <div>
        <h6 className="modalMessage">{message}</h6>
      </div>
      <div style={{ alignItems: "center" }}>
        <Spinner loading={isLoading} resultSuccess={resultSuccess} />
      </div>
      {button}
    </div>
  );
};

export default activationResult;
