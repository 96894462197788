import React, { Component } from 'react';
import styles from './HeaderDesktop.module.scss';
import { Link } from 'react-router-dom';
import Auth from '@aws-amplify/auth';
import LangPanel from '../../../common/LangPanel/LangPanel.jsx';
import { FormattedMessage, injectIntl } from 'react-intl';
import CoinPanel from './../../../common/CoinPanel/CoinPanel.jsx';
//import classes from "./HeaderDesktop.module.scss";

class HeaderDesktop extends Component {
	constructor(props) {
		super(props);

		this._isMounted = false;
	}
	componentDidMount() {
		this._isMounted = true;
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	handleSignOut = () => {
		if (this._isMounted) Auth.signOut();
	};

	render() {
		const { lang } = this.props;
		return (
			<div className={styles.header}>
				<div className={styles.headerPanel}>
					<Link to="/">
						<img
							className={`img-fluid ${styles.iotIcon}`}
							src={process.env.PUBLIC_URL + '/assets/img/IoTpool_logo_inv.png'}
							alt="iot-logo"
						/>
					</Link>
					{/*<h1 className={styles.title}>IoT Solution</h1>*/}
					<span className={styles.rightPanel}>
						<CoinPanel
							btnEnabled={this.props.btnEnabled}
							donateTokens={this.props.donateTokens}
							coinValue={this.props.coinValue}
						/>
						<div className={styles.signoutDiv}>
							{/*<Link to="/">*/}
								<button className="Button__button___1FrBC" onClick={this.handleSignOut}>
									<FormattedMessage id="button.signout" defaultMessage="Sign Out" />
								</button>
							{/*</Link>*/}
							<LangPanel lang={lang} style={{ color: 'white', backgroundColor: 'transparent' }} />
						</div>
					</span>
				</div>
			</div>
		);
	}
}

export default injectIntl(HeaderDesktop);
