import React from 'react';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import classes from './FormNavigation.module.scss';

const formNavigation = props => {
	let confirmButtonClasses = ['btn btn-md btn-primary mr-2'];
	let backButtonClasses = ['btn btn-md btn-secondary mr-2'];
	let homeButtonClasses = ['btn btn-md btn-secondary mr-2'];

	if (props.animation) confirmButtonClasses.push(classes.button);
	if (props.confirmBtnClass) confirmButtonClasses.push(props.confirmBtnClass);

	if (props.backBtnClass) backButtonClasses.push(props.backBtnClass);

	if (props.homeBtnClass) homeButtonClasses.push(props.homeBtnClass);

	const backHome = e => {
		e.preventDefault();
		if (props.back) {
			props.back(e);
		} else {
			props.history.goBack();
		}
	};

	let confirmButton = (
		<button
			onClick={props.confirm}
			className={confirmButtonClasses.join(' ')}
			type="submit"
			disabled={props.confirmBtnDisabled}
		>
			{props.confirmText}
		</button>
	);
	let backButton = (
		<button className={backButtonClasses.join(' ')} disabled={props.backBtnDisabled} onClick={backHome}>
			<FormattedMessage id="button.back" defaultMessage="Back" />
		</button>
	);
	let homeButton = (
		<Link to="/">
			<button className={homeButtonClasses.join(' ')} disabled={props.homeBtnDisabled}>
				<FormattedMessage id="button.home" defaultMessage="Home" />
			</button>
		</Link>
	);

	if (props.confirmButtonHidded) confirmButton = null;
	if (props.backButtonHidden) backButton = null;
	if (props.homeButtonHidden) homeButton = null;

	return (
		<div className={classes.buttonWrapper} style={props.wraperStyle}>
			{confirmButton}
			{backButton}
			{homeButton}
		</div>
	);
};

export default withRouter(formNavigation);
