import React from "react"
import {useSelector, useDispatch} from 'react-redux'
import {changeAdminRegime} from "../../../store/actions/userActions"
import ToggleButton from "../../UI/ToggleButtons/Type1/Type1"

export function AdminMenu(){
    const {logged_user, adminRegime} = useSelector(state => state.users);
    const dispatch = useDispatch()

    const loadAllSensors = (e) =>{
        dispatch(changeAdminRegime(e.value))
    }

    return logged_user.permissions && logged_user.permissions.permissions.role === 'GLOBAL_ADMIN' ? 
    (
        <ToggleButton
        key="Amin-regime"
        descriptionText="Admin regime"
        click={loadAllSensors}
        value={adminRegime}
        />
    ):null
}