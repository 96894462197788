


const googleAnalytics = (props) => {
    props.pageview(window.location.pathname + window.location.search);

    return null;
};


export default googleAnalytics;