import React from 'react';

import Tooltip from "./../../../Tooltip/Tooltip";

const iaq = props => {
    return (
        <div {...props}>
            <Tooltip tooltip={props.tooltip}>
                <svg width="100%" viewBox="0 0 256 256">
                    <path
                        d="M152.799 201.767c-37.136 0-54.443-27.512-71.176-54.118-11.152-17.727-22.394-33.912-37.964-44.457-17.475-11.834-37.248-9.634-37.248-9.634S40.73 72.125 80.33 69.416c29.13-1.994 46.848 16.542 56.142 29.442-1.012 1.906-17.197 12.93-13.539 36.733 3.806 24.779 20.133 41.491 49.903 26.085-48.454 12.279-53.818-36.7-32.321-57.021 7.73-8.003 20.367-15.339 40.718-15.339 10.87.012 43.685.048 54.109 37.545 5.494 19.764-6.161 54.161-49.273 68.745-12.255 4.146-23.136 6.161-33.27 6.161z"
                        fill="#7EAEC6"
                    />
                    <linearGradient
                        gradientUnits="userSpaceOnUse"
                        id="iaq_prefix__a"
                        x1={14.05}
                        x2={243.931}
                        y1={66.228}
                        y2={184.401}
                    >
                        <stop offset={0} stopColor="#cbdfe8" />
                        <stop offset={0.239} stopColor="#c7dde6" />
                        <stop offset={0.462} stopColor="#bcd5e1" />
                        <stop offset={0.679} stopColor="#a9c9d9" />
                        <stop offset={0.89} stopColor="#8fb9cd" />
                        <stop offset={1} stopColor="#7eaec6" />
                    </linearGradient>
                    <path
                        d="M152.799 197.113c-34.567 0-50.436-25.23-67.236-51.942-10.658-16.945-22.473-34.439-39.294-45.833-7.836-5.306-16.049-8.012-23.051-9.358 13.357-6.248 34.597-14.358 57.43-15.921a58.728 58.728 0 013.982-.136c17.597 0 32.973 8.264 45.752 24.576-5.048 5.206-15.176 17.445-12.049 37.8 3.458 22.518 16.003 35.964 33.558 35.964 7.049 0 14.815-2.17 23.085-6.452 0 0 27.048-14.136 14.83-39.155 6.788 29.479-22.439 33.164-31.291 32.315h-.003c-14.612 0-20.761-7.742-23.345-14.239-4.718-11.855-1.042-27.63 8.546-36.694l.151-.148c8.921-9.236 21.494-13.918 37.37-13.918 10.976.012 40.145.045 49.624 34.136 5.003 18.003-6.048 49.479-46.282 63.091-11.765 3.981-22.159 5.914-31.777 5.914z"
                        fill="url(#iaq_prefix__a)"
                    />
                    <linearGradient
                        gradientUnits="userSpaceOnUse"
                        id="iaq_prefix__b"
                        x1={63.724}
                        x2={174.341}
                        y1={88.61}
                        y2={170.404}
                    >
                        <stop offset={0} stopColor="#c1d8e3" />
                        <stop offset={0.648} stopColor="#92bbcf" />
                        <stop offset={1} stopColor="#7eaec6" />
                    </linearGradient>
                    <path
                        d="M66.717 85.098c35.7 12.433 36.07 63.839 57.355 83.488 10.703 9.882 27.333 15.864 54.291-1.397-27.345 11.442-39.212 7.797-47.57.539-32.555-28.273-6.733-69.194-6.733-69.194s-14.352-19.848-57.343-13.436z"
                        fill="url(#iaq_prefix__b)"
                    />
                    <linearGradient
                        gradientUnits="userSpaceOnUse"
                        id="iaq_prefix__c"
                        x1={39.465}
                        x2={204.575}
                        y1={98.315}
                        y2={154.646}
                    >
                        <stop offset={0} stopColor="#f2f7f9" />
                        <stop offset={0.336} stopColor="#eef4f7" />
                        <stop offset={0.699} stopColor="#e1ebf1" />
                        <stop offset={1} stopColor="#d0e0e9" />
                    </linearGradient>
                    <path
                        d="M175.827 109.104c17.664-3.006 27.227 11.227 27.748 25.436.67 18.27-9.824 38.033-44.527 43.597 41.733-9.897 56.145-57.418 16.779-69.033z"
                        fill="url(#iaq_prefix__c)"
                    />
                    <path
                        d="M214.057 108.595c22.661 19.533 21.785 60.276-32.685 79.67 37.479-21.352 48.154-47.719 32.685-79.67z"
                        fill="#7EAEC6"
                    />
                    <linearGradient
                        gradientUnits="userSpaceOnUse"
                        id="iaq_prefix__d"
                        x1={44.861}
                        x2={150.752}
                        y1={85.184}
                        y2={189.911}
                    >
                        <stop offset={0} stopColor="#dde8ef" />
                        <stop offset={0.283} stopColor="#d9e5ed" />
                        <stop offset={0.589} stopColor="#ccdee8" />
                        <stop offset={0.904} stopColor="#b6d1de" />
                        <stop offset={1} stopColor="#aeccdb" />
                    </linearGradient>
                    <path
                        d="M40.73 90.064C79.13 99.1 94.297 185.9 144.633 191.709c-43.652-20.255-42.785-70.082-61.63-91.197C72.214 88.428 56.217 84.258 40.73 90.064z"
                        fill="url(#iaq_prefix__d)"
                    />
                    <linearGradient
                        gradientUnits="userSpaceOnUse"
                        id="iaq_prefix__e"
                        x1={40.73}
                        x2={143.454}
                        y1={133.818}
                        y2={133.818}
                    >
                        <stop offset={0} stopColor="#f2f7f9" />
                        <stop offset={0.336} stopColor="#eef4f7" />
                        <stop offset={0.699} stopColor="#e1ebf1" />
                        <stop offset={1} stopColor="#d0e0e9" />
                    </linearGradient>
                    <path
                        d="M40.73 90.064c31.158-4.049 42.157 14.124 51.955 33.951 7.094 14.358 18.009 57.17 50.77 56.361-31.16-5-37.233-41.827-47.451-60.064C83.905 98.728 71.199 79.949 40.73 90.064z"
                        fill="url(#iaq_prefix__e)"
                    />
                    <linearGradient
                        gradientUnits="userSpaceOnUse"
                        id="iaq_prefix__f"
                        x1={89.789}
                        x2={177.406}
                        y1={92.344}
                        y2={142.372}
                    >
                        <stop offset={0} stopColor="#f2f7f9" />
                        <stop offset={0.336} stopColor="#eef4f7" />
                        <stop offset={0.699} stopColor="#e1ebf1" />
                        <stop offset={1} stopColor="#d0e0e9" />
                    </linearGradient>
                    <path
                        d="M156.257 101.958c-19.394 21.982-20.067 45.006 2.791 54.564-37.897-3.685-19.858-45.512-2.791-54.564z"
                        fill="url(#iaq_prefix__f)"
                    />
                    <linearGradient
                        gradientUnits="userSpaceOnUse"
                        id="iaq_prefix__g"
                        x1={137.988}
                        x2={188.801}
                        y1={126.336}
                        y2={143.403}
                    >
                        <stop offset={0} stopColor="#c1d8e3" />
                        <stop offset={0.648} stopColor="#92bbcf" />
                        <stop offset={1} stopColor="#7eaec6" />
                    </linearGradient>
                    <path
                        d="M163.236 122.777c13.576 2.133 25 17.609 9.309 31.612 27.345-11.443 7.951-39.758-9.309-31.612z"
                        fill="url(#iaq_prefix__g)"
                    />
                    <linearGradient
                        gradientUnits="userSpaceOnUse"
                        id="iaq_prefix__h"
                        x1={-88.235}
                        x2={229.431}
                        y1={111.124}
                        y2={217.821}
                    >
                        <stop offset={0} stopColor="#c1d8e3" />
                        <stop offset={0.648} stopColor="#92bbcf" />
                        <stop offset={1} stopColor="#7eaec6" />
                    </linearGradient>
                    <path
                        d="M56.569 127.683c14.352 24.185 19.364 66.694 55.879 82.688 24.16 10.582 66.691 8.212 103.927-22.106-40.342 23.036-71.676 24.736-92.494 17.521-37.427-12.973-47.691-62.097-67.312-78.103z"
                        fill="url(#iaq_prefix__h)"
                    />
                    <linearGradient
                        gradientUnits="userSpaceOnUse"
                        id="iaq_prefix__i"
                        x1={67.124}
                        x2={266.028}
                        y1={50.178}
                        y2={116.986}
                    >
                        <stop offset={0} stopColor="#c1d8e3" />
                        <stop offset={0.648} stopColor="#92bbcf" />
                        <stop offset={1} stopColor="#7eaec6" />
                    </linearGradient>
                    <path
                        d="M148.748 87.476c67.757-26.373 98.088 15.082 96.203 48.115 20.169-41.136-27.928-88.451-96.203-48.115z"
                        fill="url(#iaq_prefix__i)"
                    />
                    <linearGradient
                        gradientTransform="scale(1 -1) rotate(82.473 2201.234 1.319)"
                        gradientUnits="userSpaceOnUse"
                        id="iaq_prefix__j"
                        x1={1746.415}
                        x2={1773.398}
                        y1={1926.789}
                        y2={1926.789}
                    >
                        <stop offset={0} stopColor="#8cc63e" />
                        <stop offset={0.209} stopColor="#88c53f" />
                        <stop offset={0.435} stopColor="#7bc241" />
                        <stop offset={0.669} stopColor="#65be44" />
                        <stop offset={0.907} stopColor="#47b848" />
                        <stop offset={1} stopColor="#39b54a" />
                    </linearGradient>
                    <path
                        d="M228.36 196.216c-16.258-7.233 17.306-24.379 17.306-24.379s-1.046 31.615-17.306 24.379z"
                        fill="url(#iaq_prefix__j)"
                    />
                    <linearGradient
                        gradientTransform="scale(1 -1) rotate(82.473 2201.234 1.319)"
                        gradientUnits="userSpaceOnUse"
                        id="iaq_prefix__k"
                        x1={1737.871}
                        x2={1770.36}
                        y1={1929.853}
                        y2={1929.853}
                    >
                        <stop offset={0} stopColor="#8cc63e" />
                        <stop offset={0.006} stopColor="#8bc63e" />
                        <stop offset={0.452} stopColor="#5fbd45" />
                        <stop offset={0.798} stopColor="#43b749" />
                        <stop offset={1} stopColor="#39b54a" />
                    </linearGradient>
                    <path
                        d="M243.551 174.622c-7.309 16.776-23.476 29.664-23.476 29.664s-2.603-.124-1.791-2.261c.806-2.13 14.712-11.709 25.267-27.403z"
                        fill="url(#iaq_prefix__k)"
                    />
                    <g>
                        <linearGradient
                            gradientTransform="scale(1 -1) rotate(-4.87 -14175.235 302.792)"
                            gradientUnits="userSpaceOnUse"
                            id="iaq_prefix__l"
                            x1={63.455}
                            x2={90.44}
                            y1={1002.779}
                            y2={1002.779}
                        >
                            <stop offset={0} stopColor="#8cc63e" />
                            <stop offset={0.209} stopColor="#88c53f" />
                            <stop offset={0.435} stopColor="#7bc241" />
                            <stop offset={0.669} stopColor="#65be44" />
                            <stop offset={0.907} stopColor="#47b848" />
                            <stop offset={1} stopColor="#39b54a" />
                        </linearGradient>
                        <path
                            d="M72.405 204.137c6.473-16.579 25.158 16.155 25.158 16.155s-31.63.421-25.158-16.155z"
                            fill="url(#iaq_prefix__l)"
                        />
                        <linearGradient
                            gradientTransform="scale(1 -1) rotate(-4.87 -14175.235 302.792)"
                            gradientUnits="userSpaceOnUse"
                            id="iaq_prefix__m"
                            x1={54.913}
                            x2={87.4}
                            y1={1005.843}
                            y2={1005.843}
                        >
                            <stop offset={0} stopColor="#8cc63e" />
                            <stop offset={0.006} stopColor="#8bc63e" />
                            <stop offset={0.452} stopColor="#5fbd45" />
                            <stop offset={0.798} stopColor="#43b749" />
                            <stop offset={1} stopColor="#39b54a" />
                        </linearGradient>
                        <path
                            d="M94.681 218.31c-17.097-6.524-30.718-22.076-30.718-22.076s.006-2.606 2.173-1.897c2.166.709 12.381 14.158 28.545 23.973z"
                            fill="url(#iaq_prefix__m)"
                        />
                    </g>
                    <g>
                        <linearGradient
                            gradientTransform="scale(-1 1) rotate(69.231 166.327 -1465.274)"
                            gradientUnits="userSpaceOnUse"
                            id="iaq_prefix__n"
                            x1={1469.688}
                            x2={1496.676}
                            y1={-608.155}
                            y2={-608.155}
                        >
                            <stop offset={0} stopColor="#8cc63e" />
                            <stop offset={0.209} stopColor="#88c53f" />
                            <stop offset={0.435} stopColor="#7bc241" />
                            <stop offset={0.669} stopColor="#65be44" />
                            <stop offset={0.907} stopColor="#47b848" />
                            <stop offset={1} stopColor="#39b54a" />
                        </linearGradient>
                        <path
                            d="M176.954 59.446c14.167 10.767-22.433 19.77-22.433 19.77s8.263-30.536 22.433-19.77z"
                            fill="url(#iaq_prefix__n)"
                        />
                        <linearGradient
                            gradientTransform="scale(-1 1) rotate(69.231 166.327 -1465.274)"
                            gradientUnits="userSpaceOnUse"
                            id="iaq_prefix__o"
                            x1={1461.148}
                            x2={1493.637}
                            y1={-605.091}
                            y2={-605.091}
                        >
                            <stop offset={0} stopColor="#8cc63e" />
                            <stop offset={0.006} stopColor="#8bc63e" />
                            <stop offset={0.452} stopColor="#5fbd45" />
                            <stop offset={0.798} stopColor="#43b749" />
                            <stop offset={1} stopColor="#39b54a" />
                        </linearGradient>
                        <path
                            d="M157.217 76.989c10.958-14.658 29.646-23.497 29.646-23.497s2.509.718 1.23 2.609c-1.276 1.891-17.006 8.03-30.876 20.888z"
                            fill="url(#iaq_prefix__o)"
                        />
                    </g>
                </svg>
            </Tooltip>
        </div>
    );
};

export default iaq;
