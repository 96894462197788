import { siglinkDef } from "./siglink";
import { simpleLeakDef } from "./simpleLeak";
import { siotechGpsDef } from "./siotechGps";
import { siotechUAlarmDef } from "./siotechUAlarm";
import { siotechUSecurityDef } from "./siotechUSecurity";
import { ste2Def } from "./ste2";
import { wicubDef } from "./wicub";

import sensors from "./../../../../../utils/sensorTypes";

export function getDefinition(type, sensor, intl) {
    switch (type) {
        case sensors.siglink.iot_label:
            return siglinkDef(sensor, intl);
        case sensors.simpleLeak.iot_label:
            return simpleLeakDef(sensor, intl);
        case sensors.siotech_gps.iot_label:
            return siotechGpsDef(sensor, intl);
        case sensors.siotech_gps_industrial.iot_label:
            return siotechGpsDef(sensor, intl);
        case sensors.siotech_unior_alarm.iot_label:
            return siotechUAlarmDef(sensor, intl);
        case sensors.siotech_unior_security.iot_label:
            return siotechUSecurityDef(sensor, intl);
        case sensors.ste2.iot_label:
            return ste2Def(sensor, intl);
        case sensors.wicub.iot_label:
            return wicubDef(sensor, intl);
        default:
            throw new Error("unknown sensor type");
    }
}
