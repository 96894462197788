import React from 'react';

import Tooltip from "./../../../Tooltip/Tooltip";

const Binary = props => {
    let switchState = (
        <svg width="100%" viewBox="0 0 65 65">
            <path d="M62.917 37.044H52.815c-.671-2.627-3.05-4.578-5.883-4.578a6.086 6.086 0 00-6.079 6.079 6.085 6.085 0 006.079 6.077c2.833 0 5.213-1.951 5.884-4.578h10.101a1.5 1.5 0 000-3zm-15.984 4.578a3.082 3.082 0 01-3.079-3.077 3.082 3.082 0 013.079-3.079 3.082 3.082 0 013.078 3.079 3.082 3.082 0 01-3.078 3.077zM47.63 23.206a1.5 1.5 0 00-1.396-2.655l-24.709 13a6.031 6.031 0 00-3.456-1.085c-2.833 0-5.213 1.951-5.884 4.578H2.083a1.5 1.5 0 100 3h10.101c.67 2.627 3.051 4.578 5.884 4.578a6.085 6.085 0 006.079-6.077c0-.954-.227-1.854-.621-2.658L47.63 23.206zM18.068 41.622a3.082 3.082 0 01-3.079-3.077c0-1.698 1.381-3.079 3.079-3.079s3.079 1.381 3.079 3.079a3.082 3.082 0 01-3.079 3.077z" />
        </svg>
    );

    if (props.closed) {
        switchState = (
            <svg width="100%" viewBox="0 0 512 512">
                <defs>
                    <style>
                        {
                            ".closed_switch_prefix__cls-1{fill:none;stroke:#231f20;stroke-miterlimit:10;stroke-width:25px}"
                        }
                    </style>
                </defs>
                <title />
                <path
                    className="closed_switch_prefix__cls-1"
                    d="M111.38 255.54L0 255.56M512 255.91l-111.42.02M136.1 227c17.18-2 33.15 14.49 31.78 31.78-1.06 13.32-12.29 25.62-26.44 26.44-16.41.94-30.4-13.94-30.06-30.06.29-13.9 11.1-26.64 24.72-28.16zM368.8 227c17.18-2 33.15 14.49 31.78 31.78-1.06 13.32-12.29 25.62-26.44 26.44-16.41.94-30.4-13.94-30.06-30.06.29-13.9 11.1-26.64 24.72-28.16z"
                />
                <path
                    strokeLinecap="round"
                    fill="none"
                    stroke="#231f20"
                    strokeMiterlimit={10}
                    strokeWidth={25}
                    d="M343.93 256.17l-175.86-.34"
                />
            </svg>
        );
    }
    return (
        <div {...props}>
            <Tooltip tooltip={props.tooltip}>
                {switchState}
            </Tooltip>
        </div>
    );
};

export default Binary;
