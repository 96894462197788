import React from 'react';

const invalidPassword = props => {
    return (
        <div {...props}>
            <svg width="100%" viewBox="0 0 382 322">
                <g>
                    <title>{'Layer 1'}</title>
                    <path
                        fill="#5EAD46"
                        d="M24.2 148.425c-34.841 40.744-32.202 95.51 4.656 81.153 21.7-8.452 49.81-65.873 56.113-97.316 6.304-31.443-25.928-24.578-60.768 16.163zM311.605 148.425c34.842 40.744 32.203 95.51-4.655 81.153-21.701-8.452-49.811-65.873-56.113-97.316-6.305-31.443 25.926-24.578 60.768 16.163z"
                    />
                    <path
                        fill="#51943B"
                        d="M289.04 214.13c-17.048-21.386-33.554-58.68-38.203-81.867-4.147-20.688 8.386-24.788 27.368-13.573-2.198 13.901-6.545 44.57-4.108 56.02 2.136 10.03 9.976 28.36 14.944 39.42z"
                    />
                    <path
                        opacity={0.32}
                        fill="#3E3E3E"
                        d="M311.184 291.86c0-13.746-65.612-24.887-146.548-24.887-80.935 0-146.547 11.142-146.547 24.887 0 4.503 7.079 8.723 19.397 12.366-3.976 3.022 62.812 15.53 145.215 12.325 82.403-3.204 128.483-11.985 128.483-24.69z"
                    />
                    <path
                        fill="#5EAD46"
                        d="M236.365 290.285c-38.251-9.272-93.693-9.85-133.452 2.037-15.122 5.187-64.93 16.06-59.89-30.444 14.686-76.156 23.64-148.643 19.243-212.583 0-15.119 12.369-27.488 27.489-27.488l153.939-17.41c15.119 0 27.49 12.37 27.49 27.49-5.298 67.482 6.28 147.008 21.99 229.992 6.874 44.44-39.585 32.901-56.81 28.406z"
                    />
                    <path
                        fill="#51943B"
                        d="M74.312 39.98c10.404-11.758 61.117-17.25 71.108-21.424 1.653-.69 3.437-1.98 5.26-3.639l-60.925 6.891c-15.12 0-27.49 12.37-27.49 27.488.952 13.821 1.276 28.042 1.056 42.613 2.227-22.229 5.765-46.024 10.99-51.929z"
                    />
                    <path
                        fill="#ffaa56"
                        d="M260.752 34.839c0-4.68 4.987-8.47 11.144-8.47s11.144 3.79 11.144 8.47c0 4.68-4.987 8.47-11.144 8.47s-11.144-3.79-11.144-8.47z"
                    />
                    <ellipse
                        transform="rotate(-67.728 206.449 163.175)"
                        ry={8.47}
                        rx={11.144}
                        opacity={0.15}
                        fill="#0D6C3F"
                        cy={34.839}
                        cx={271.896}
                    />
                    <path
                        fill="#ffaa56"
                        d="M302.822 45.66c0-3.487 3.716-6.31 8.304-6.31s8.304 2.823 8.304 6.31c0 3.485-3.716 6.31-8.304 6.31s-8.304-2.825-8.304-6.31z"
                    />
                    <ellipse
                        transform="rotate(-67.736 245.653 173.97)"
                        ry={6.31}
                        rx={8.304}
                        opacity={0.15}
                        fill="#0D6C3F"
                        cy={45.659}
                        cx={311.126}
                    />
                    <path
                        fill="#ffaa56"
                        d="M344.578 23.374c0-5.139 4.852-9.3 10.842-9.3s10.842 4.161 10.842 9.3c0 5.139-4.852 9.301-10.842 9.301s-10.842-4.162-10.842-9.3z"
                    />
                    <ellipse
                        transform="rotate(-65.821 294.258 153.81)"
                        ry={9.301}
                        rx={10.842}
                        opacity={0.15}
                        fill="#0D6C3F"
                        cy={23.374}
                        cx={355.42}
                    />
                    <circle
                        r={9.361}
                        opacity={0.15}
                        fill="#0D6C3F"
                        cy={44.605}
                        cx={233.952}
                    />
                    <ellipse ry={61.153} rx={61.16} fill="#FFF" cy={104.627} cx={220.531} />
                    <ellipse ry={61.153} rx={61.16} fill="#FFF" cy={104.627} cx={116.229} />
                    <ellipse
                        ry={14.343}
                        rx={14.337}
                        fill="#3F2945"
                        cy={105.053}
                        cx={224.801}
                    />
                    <ellipse
                        ry={14.343}
                        rx={14.337}
                        fill="#3F2945"
                        cy={105.053}
                        cx={112.476}
                    />
                    <path
                        fill="#EEC75E"
                        d="M204.912 160.371l17.116 17.116a12.057 12.057 0 010 17.116l-69.413 69.412a12.055 12.055 0 01-17.116 0l-17.03-17.116a12.052 12.052 0 010-17.116l69.413-69.412c4.668-4.667 12.362-4.667 17.03 0z"
                    />
                    <path
                        fill="#F2D27E"
                        d="M176.645 188.034l17.72 17.721c2.42 2.418 2.42 6.396 0 8.818l-21.696 21.694c-2.421 2.422-6.396 2.422-8.817 0l-17.72-17.72c-2.42-2.42-2.42-6.397 0-8.818l21.698-21.696c2.417-2.42 6.395-2.42 8.815.001z"
                    />
                    <path
                        fill="#CAA747"
                        d="M168.26 199.012c-.865.863-.865 2.247 0 3.024.863.865 2.247.865 3.024 0 .867-.865.867-2.246 0-3.024-.777-.866-2.161-.866-3.023 0zM162.209 205.062c-.865.865-.865 2.246 0 3.026.865.865 2.247.865 3.026 0 .865-.865.865-2.249 0-3.026-.78-.866-2.162-.866-3.026 0zM156.158 211.112c-.866.865-.866 2.25 0 3.027.863.865 2.247.865 3.024 0 .866-.865.866-2.249 0-3.027-.776-.863-2.16-.863-3.024 0zM174.31 205.062c-.865.865-.865 2.246 0 3.026.865.865 2.249.865 3.026 0 .865-.865.865-2.249 0-3.026-.777-.866-2.162-.866-3.026 0zM168.26 211.112c-.865.865-.865 2.25 0 3.027.863.865 2.247.865 3.024 0 .867-.865.867-2.249 0-3.027-.777-.863-2.161-.863-3.023 0zM162.209 217.163c-.865.865-.865 2.247 0 3.026.865.865 2.247.865 3.026 0 .865-.865.865-2.247 0-3.026-.78-.865-2.162-.865-3.026 0zM180.36 211.112c-.864.865-.864 2.25 0 3.027.866.865 2.248.865 3.027 0 .866-.865.866-2.249 0-3.027-.778-.863-2.161-.863-3.026 0zM174.31 217.163c-.865.865-.865 2.247 0 3.026.865.865 2.249.865 3.026 0 .865-.865.865-2.247 0-3.026-.777-.865-2.162-.865-3.026 0zM168.26 223.216c-.865.863-.865 2.247 0 3.024.863.865 2.247.865 3.024 0 .867-.865.867-2.247 0-3.024-.777-.866-2.161-.866-3.023 0z"
                    />
                    <path
                        fill="#EEC75E"
                        d="M158.888 158.61l69.412 69.412a12.055 12.055 0 010 17.116l-17.03 17.116a12.05 12.05 0 01-17.115 0l-69.413-69.412a12.055 12.055 0 010-17.116l17.115-17.116c4.667-4.667 12.362-4.667 17.03 0z"
                    />
                    <path
                        fill="#F2D27E"
                        d="M178.94 186.272l21.697 21.696c2.42 2.421 2.42 6.397 0 8.817l-17.72 17.721c-2.42 2.422-6.395 2.422-8.816 0l-21.697-21.694c-2.42-2.422-2.42-6.4 0-8.818l17.72-17.72c2.42-2.423 6.397-2.423 8.817-.002z"
                    />
                    <path
                        fill="#CAA747"
                        d="M175.484 197.252c-.866.777-.866 2.16 0 3.023.777.865 2.16.865 3.024 0 .866-.777.866-2.16 0-3.023-.863-.867-2.247-.867-3.024 0zM181.535 203.3c-.865.777-.865 2.161 0 3.026.777.865 2.16.865 3.026 0 .865-.78.865-2.16 0-3.026-.864-.865-2.25-.865-3.026 0zM187.585 209.352c-.866.777-.866 2.16 0 3.025.778.864 2.16.864 3.025 0 .866-.777.866-2.163 0-3.025-.863-.865-2.246-.865-3.025 0zM169.433 203.3c-.864.777-.864 2.161 0 3.026.778.865 2.16.865 3.026 0 .865-.78.865-2.16 0-3.026-.865-.865-2.248-.865-3.026 0zM175.484 209.352c-.866.777-.866 2.16 0 3.025.777.864 2.16.864 3.024 0 .866-.777.866-2.163 0-3.025-.863-.865-2.247-.865-3.024 0zM181.535 215.403c-.865.777-.865 2.16 0 3.024.777.865 2.16.865 3.026 0 .865-.778.865-2.16 0-3.024-.864-.867-2.25-.867-3.026 0zM163.382 209.352c-.866.777-.866 2.16 0 3.025.777.864 2.16.864 3.026 0 .865-.777.865-2.163 0-3.025-.864-.865-2.248-.865-3.026 0zM169.433 215.403c-.864.777-.864 2.16 0 3.024.778.865 2.16.865 3.026 0 .865-.778.865-2.16 0-3.024-.865-.867-2.248-.867-3.026 0zM175.484 221.455c-.866.778-.866 2.16 0 3.024.777.865 2.16.865 3.024 0 .866-.778.866-2.16 0-3.024-.863-.866-2.247-.866-3.024 0z"
                    />
                </g>
            </svg>
        </div>
    );
};

export default invalidPassword;
