import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import Coin from './../../../common/CoinPanel/Coin/Coin.jsx';

import classes from './InitModal.module.scss';

const initModal = props => {
	if (!props.prices) {
		return null;
	}

	let content = (
		<div className={classes.sensorInfoContainer}>
			<h6 className="modalMessage">
				<FormattedMessage
					id="modal.topup.period"
					defaultMessage="Select next activation period you want to apply on the sensor"
				/>
			</h6>
			<div className="modalSensorInfoDiv">
				<p className="modalSensorInfo">
					<FormattedMessage id="sensor.name" defaultMessage="Name" />: {props.sensorName}
				</p>
				<p className="modalSensorInfo">
					<FormattedMessage id="sensor.id" defaultMessage="ID" />: {props.sensorId}
				</p>
			</div>
		</div>
	);

	const periodPrices = props.prices.periods.map(period => {
		let id = `000${props.prices.periods.indexOf(period) + 1}`;
		const periodNum = Object.keys(period)[0];
		const periodPrice = period[periodNum];

		return (
			<button
				key={id}
				className={['Btn_range'].join(' ')}
				onClick={e =>
					props.selectionClick(e, { id: id, price: periodPrice, period: `select.period.${periodNum}` })}
			>
				<Coin
					label={props.intl.formatMessage({ id: `select.period.${periodNum}` }) + ': '}
					value={`${periodPrice}`}
					coinClassName={classes.coin}
					coinTextClassName={classes.coinText}
				/>
			</button>
		);
	});

	const cancel = (
		<button className="btn btn-dark" onClick={props.close}>
			<FormattedMessage id="button.cancel" defaultMessage="Cancel" />
		</button>
	);

	const menu = (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			<div className={classes.coinSelectMenu}>{periodPrices}</div>
			<div>{cancel}</div>
		</div>
	);
	return (
		<div>
			{content}
			{menu}
		</div>
	);
};

export default injectIntl(initModal);
