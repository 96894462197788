// @flow
import React from "react";

import {
    FETCH_SENSORS,
    FILTER_SENSORS,
    FETCH_SENSOR_BY_ID,
    POST_SENSOR,
    EDIT_SENSOR,
    DELETE_SENSOR,
    ACTIVATE_SENSOR,
    CHANGE_LOADING_STATUS,
    CHANGE_SUCCESS_STATUS,
    HANDLE_REMOTE_ERROR,
    FETCH_SENSOR_MEASUREMENTS,
    CLEAR_MEASUREMENTS,
    FETCH_SENSOR_MULTIPLE_DATA,
    CHANGE_GRAPH_LOADING_STATUS,
    CLEAR_REMOTE_ERROR,
    CLEAR_SENSORS,
    CHANGE_CHUNK,
    SET_CHUNK_SIZE,
    PROTECT_DEVICE
} from "../actions/sensorActionTypes";

/*eslint no-extend-native: ["error", { "exceptions": ["Array"] }]*/
Object.defineProperty(Array.prototype, "toChunks", {
    value: function (chunkSize) {
        let array = this;
        return [].concat.apply(
            [],
            array.map(function (elem, i) {
                return i % chunkSize ? [] : [array.slice(i, i + chunkSize)];
            })
        );
    },
});

const applySensorFilter = (sensors, filter) => {
    const sen = sensors
        .filter((sensor) => sensor.name.toLowerCase().includes(filter))
        .map((sensor) => {
            const indexFrom = sensor.name.toLowerCase().indexOf(filter);
            const indexTo = indexFrom + filter.length;

            const part0 = sensor.name.substring(0, indexFrom);
            let part1 = sensor.name.substring(indexFrom, indexTo);
            const part2 = sensor.name.substring(indexTo, sensor.name.length);

            part1 = part1.length > 0 ? <mark>{part1}</mark> : part1;

            return {
                ...sensor,
                origName: sensor.name,
                name: (
                    <span>
                        {part0}
                        {part1}
                        {part2}
                    </span>
                ),
            };
        });

    return sen;
};

const initialState = {
    sensors: [],
    chunkSize: 25,
    selectedChunk: 1,
    filter: "",
    filteredSensors: [],
    lastPostedSensor: { id: "", time: 0 },
    lastDeletedSensor: { id: "", time: 0 },
    sensorMeasurements: {},
    isLoading: false,
    isGraphLoading: false,
    isFetchSuccess: false,
    network_error: "",
};

const fetchSensors = (state, action) => {
    const sensors = action.payload.sensors.data.siglink_devices;
    const filteredSensors = applySensorFilter(sensors, state.filter);

    return {
        ...state,
        filteredSensors: filteredSensors.toChunks(state.chunkSize),
        sensors: sensors,
    };
};

const filterSensors = (state, action) => {
    const sensors = applySensorFilter(state.sensors, action.filter);

    return {
        ...state,
        filter: action.filter,
        filteredSensors: sensors.toChunks(state.chunkSize),
        selectedChunk: 1,
    };
};

const fetchSensorById = (state, action) => {
    const sensor = action.payload.sensor;

    let updatedSensors = state.sensors.slice();
    let sensorToUpdate = updatedSensors.find((s) => s.id === sensor.id);

    if (sensorToUpdate) {
        updatedSensors = state.sensors.map((s) => {
            if (s.id === sensorToUpdate.id) {
                return sensor;
            } else {
                return s;
            }
        });
    } else {
        updatedSensors.push(sensor);
    }

    return {
        ...state,
        filteredSensors: updatedSensors.toChunks(state.chunkSize),
        sensors: updatedSensors,
    };
};

const fetchSensorMeasurements = (state, action) => {
    return {
        ...state,
        sensorMeasurements: { [action.payload.id]: action.payload.measurements },
    };
};

const fetchSensorMultipleData = (state, action) => {
    return {
        ...state,
        sensorMeasurements: action.payload,
    };
};

const clearMeasurements = (state, action) => {
    return {
        ...state,
        sensorMeasurements: {},
    };
};

const postSensor = (state, action) => {
    const updatedSensors = state.sensors.concat([action.payload.sensor]);
    return {
        ...state,
        sensors: updatedSensors,
        filteredSensors: updatedSensors.toChunks(state.chunkSize),
        lastPostedSensor: {
            id: action.payload.sensor.id,
            time: new Date().getTime(),
        },
        isFetchSuccess: true,
    };
};

const editSensor = (state, action) => {
    const sensors = state.sensors.map((sensor) => {
        if (sensor.id === action.payload.sensor.id) {
            return { ...sensor, ...action.payload.sensor };
        } else {
            return sensor;
        }
    });

    return {
        ...state,
        isFetchSuccess: true,
        sensors: sensors,
        filteredSensors: sensors.toChunks(state.chunkSize),
    };
};

const deleteSensor = (state, action) => {
    const sensors = state.sensors.filter((sensor) => sensor.id !== action.payload.id);
    return {
        ...state,
        isFetchSuccess: true,
        lastDeletedSensor: {
            id: action.payload.id,
            time: new Date().getTime(),
        },
        sensors: sensors,
        filteredSensors: sensors.toChunks(state.chunkSize),
    };
};

const activateSensor = (state, action) => {
    const id = action.id;
    const sensors = state.sensors;

    let index = null;
    for (let i = 0; i < sensors.length; i++) {
        if (sensors[i].id === id) {
            index = i;
            break;
        }
    }

    sensors[index] = {
        ...sensors[index],
        state: "ACTIVE",
    };

    return {
        ...state,
        sensors: sensors,
        filteredSensors: sensors.toChunks(state.chunkSize),
    };
};


const changeLoadinStatus = (state, action) => {
    return {
        ...state,
        isLoading: action.payload.isLoading,
    };
};

const changeGraphLoadingStat = (state, action) => {
    return {
        ...state,
        isGraphLoading: action.payload.isGraphLoading,
    };
};


const changeSuccessStat = (state, action) => {
    return {
        ...state,
        isFetchSuccess: action.payload.isSuccess,
    };
};

const handleRemoteError = (state, action) => {
    let statusCode = null;
    let errMessage = "";

    const error = action.payload.error;
    let eventId = action.payload.eventId;
    const eventName = action.payload.eventName;
    let errorDescription = null;

    if (error.response) {
        statusCode = error.response.status;
        if (error.response.data.error_message) {
            errMessage = error.response.data.error_message;
        } else {
            errMessage = error.response.data.message;
        }
        errorDescription = "Received response from server. Different from 2xx";
    } else if (error.request) {
        // The request was made but no response was received
        errMessage = error.request;
        errorDescription = "The request was made but no response was received";
    } else {
        //Something happened in setting up the request that triggered an Error
        errMessage = error.message;
        errorDescription = "Something happened in setting up the request that triggered an Error";
    }

    if (typeof errMessage !== "string") {
        errMessage = JSON.stringify(errMessage);
    }

    if (statusCode === 401 && errMessage === "The incoming token has expired") {
        //* if The id token has expired and was not successfully updated by watchdog, then restart might help
        //* but if not, then we have to ensure the app will not reload inifinitely.
        //* after reload - the user will be logged out if id token refresh fails - handled by watchdog.
        window.location.reload();
        return { ...state }; //No state change
    } else if (statusCode === 401) {
        eventId = "https.lowPermissions";
    }

    return {
        ...state,
        network_error: {
            status: statusCode,
            message: errMessage,
            eventId: eventId,
            eventName: eventName,
            description: errorDescription,
        },
    };
};

const clearRemoteError = (state, action) => {
    return {
        ...state,
        network_error: "",
    };
};

const clearSensors = (state, action) => {
    console.log("clear sensors");
    return {
        ...state,
        sensors: [],
        filteredSensors: [],
    };
};

const setChunkSize = (state, action) => {
    let filteredSensors = applySensorFilter(state.sensors, state.filter);
    filteredSensors = filteredSensors.toChunks(action.chunkSize);
    return {
        ...state,
        filteredSensors: filteredSensors,
        chunkSize: action.chunkSize,
        selectedChunk: 1,
    };
};

const changeChunk = (state, action) => {
    return {
        ...state,
        selectedChunk: action.chunkNumber,
    };
};

const protectDeviceState = (state, action) => {
    const { id, type, sensorProps } = action.payload;
    let sensorIndex = state.sensors.findIndex(sensor => sensor.id === id && sensor.sensor_type === type);
    if (!sensorIndex) {
        return state;
    };
    const sensors = state.sensors.map((sensor, i) => {
        if (i === sensorIndex) {
            return { ...sensor, ...sensorProps };
        } else {
            return sensor;
        }
    });

    return {
        ...state,
        sensors: sensors,
        filteredSensors: sensors.toChunks(state.chunkSize),
    }
}

const sensorReducer = (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_SENSORS:
            return clearSensors(state, action);
        case FETCH_SENSORS:
            return fetchSensors(state, action);
        case FILTER_SENSORS:
            return filterSensors(state, action);
        case FETCH_SENSOR_BY_ID:
            return fetchSensorById(state, action);
        case FETCH_SENSOR_MEASUREMENTS:
            return fetchSensorMeasurements(state, action);
        case FETCH_SENSOR_MULTIPLE_DATA:
            return fetchSensorMultipleData(state, action);
        case CLEAR_MEASUREMENTS:
            return clearMeasurements(state, action);
        case POST_SENSOR:
            return postSensor(state, action);
        case EDIT_SENSOR:
            return editSensor(state, action);
        case ACTIVATE_SENSOR:
            return activateSensor(state, action);
        case DELETE_SENSOR:
            return deleteSensor(state, action);
        case CHANGE_LOADING_STATUS:
            return changeLoadinStatus(state, action);
        case CHANGE_GRAPH_LOADING_STATUS:
            return changeGraphLoadingStat(state, action);
        case CHANGE_SUCCESS_STATUS:
            return changeSuccessStat(state, action);
        case HANDLE_REMOTE_ERROR:
            return handleRemoteError(state, action);
        case CLEAR_REMOTE_ERROR:
            return clearRemoteError(state, action);
        case SET_CHUNK_SIZE:
            return setChunkSize(state, action);
        case CHANGE_CHUNK:
            return changeChunk(state, action);
        case PROTECT_DEVICE:
            return protectDeviceState(state, action);
        default:
            return state;
    }
};

export default sensorReducer;
