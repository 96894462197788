import firebase from 'firebase/app';
import 'firebase/messaging';

const config = {
	apiKey: 'AIzaSyBnO0O67H_k6Lg4pBXX3wYHHdD01vAVS5s',
	authDomain: 'iotpoolapp.firebaseapp.com',
	databaseURL: 'https://iotpoolapp.firebaseio.com',
	projectId: 'iotpoolapp',
	storageBucket: 'iotpoolapp.appspot.com',
	messagingSenderId: '1035274570037',
	appId: '1:1035274570037:web:fb8c07ff63bab41d1ca47c',
};

let isInitialized = false;
let messaging = null;

export const isInited = () => isInitialized;

export const initialize = () => {
	if (firebase.messaging.isSupported()) {
		firebase.initializeApp(config);
		messaging = firebase.messaging();
		isInitialized = true;
		return firebase;
	}

	return null;
};

export const getToken = () => {
	if (isInitialized) {
		// return messaging.getToken({ vapidKey: 'BCd4PciETOVm1Tc6Q9PLlo2ah2BDPdNA1JkRk1pT287ioW4tM3jiArQZWjRuOGU1OcuUCf-O0O5UtUlFoFhsfAk' });
		return messaging
			.getToken({ vapidKey: 'BCd4PciETOVm1Tc6Q9PLlo2ah2BDPdNA1JkRk1pT287ioW4tM3jiArQZWjRuOGU1OcuUCf-O0O5UtUlFoFhsfAk' })
			.then(() => {
				return messaging.getToken({ vapidKey: 'BCd4PciETOVm1Tc6Q9PLlo2ah2BDPdNA1JkRk1pT287ioW4tM3jiArQZWjRuOGU1OcuUCf-O0O5UtUlFoFhsfAk' });
			})
			.then(() => {
				return messaging.getToken({ vapidKey: 'BCd4PciETOVm1Tc6Q9PLlo2ah2BDPdNA1JkRk1pT287ioW4tM3jiArQZWjRuOGU1OcuUCf-O0O5UtUlFoFhsfAk' });
			});
	} else {
		return Promise.reject(null);
	}
};

export const deleteToken = () => {
	if (isInitialized) {
		return messaging.deleteToken();
	}
};

export const requestPermissions = () => {
	if (isInitialized) {
		return messaging.requestPermission().then(() => {
			return getToken();
		});
	}
};

export const getPermissionState = () => {
	if (isNotifSupportedByBrowser()) {
		return Notification.permission;
	} else {
		return 'notSupported';
	}
};

export const isNotifSupportedByBrowser = () => {
	return 'Notification' in window && firebase.messaging.isSupported();
};

export default firebase;

//dPNaLqierH1MHdHDx7OyH2:APA91bG9Y0NVCtOZHGgz8fhyWylke13h6FrLxHjf1wsL9lYESR_OgrjOgJVp0KqMscm4yEsV5Smz9FqolB5O2ATD4UbpmNgwydvAnUiLKZjqjWrvkSN0MAm1CpzfdFXOjddwL9VJ70VQ
