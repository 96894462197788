import React from "react";

import { injectIntl } from "react-intl";
import ComparatorItem from "./ComparatorItem/ComparatorItem";


import { getDefinition } from "./sensorDefinitions/definitons"

import sensors from "./../../../../utils/sensorTypes";


function getAllTypes() {
  const types = [];
  for (const t in sensors) types.push(sensors[t].iot_label);

  return types;
}

const disabledTypes = []; //['simpleLeak_03'];

const comparator = (props) => {
  const supportedTypes = getAllTypes();
  if (!supportedTypes.includes(props.sensor.sensor_type) || props.disabled)
    return null;

  const changeHandler = (id, checked, data) => {
    const output = {
      id: id,

      name: data.dataset,
      type: props.sensor.sensor_type,
      checked: checked,
      ...data,
    };

    props.onChange(output);
  };
  const units = [];

  if (supportedTypes.includes(props.sensor.sensor_type)) {
    const measurements = getDefinition(
      props.sensor.sensor_type,
      props.sensor,
      props.intl
    ).map((def) => ({
      checked: props.checkedTypes.includes(def.dataset),
      onInputChange: (id, checked) => changeHandler(id, checked, def),
      disabled:
        disabledTypes.includes(props.sensor.sensor_type) || def.disabled,
      iconType: def.icon,
      name: def.tooltip,
      units: def.unit,
      key: props.sensor.id + def.dataset,
      id: props.sensor.id,
      value: def.value,
    }));

    units.push(
      <ComparatorItem
        key={props.sensor.id}
        header={props.sensor.name}
        measurements={measurements}
      />
    );
  }

  return (
    <div>
      {units}
    </div>
  );
};

export default injectIntl(comparator);
