import React from 'react';
import { FormattedMessage } from 'react-intl';

import Arrow from './../../icons/Arrow/Arrow.jsx';
import { DropDownList } from '@progress/kendo-react-dropdowns';

import classes from './ChunkDivisor.module.scss';

const chunkDivisor = props => {
	let defalutValue = props.data && props.data.length > 0 ? props.data[0] : 'N/A';
	if (props.selectedSize && props.data && props.data.includes(props.selectedSize)) {
		defalutValue = props.selectedSize;
	}

	const disabled = classes.disabled;
	let ar1 = null;
	let ar2 = null;
	let ar3 = null;
	let ar4 = null;
	if (props.actChunk === props.chunksCount) {
		ar3 = ar4 = disabled;
	}
	if (props.actChunk <= 1) {
		ar1 = ar2 = disabled;
	}

	const chunkChanged = e => {
		if (props.chunkChanged) {
			props.chunkChanged(e);
		}
	};

	return (
		<div className={classes.line}>
			<div className={classes.infoPanel}>
				<p className={classes.text}>
					<FormattedMessage id="select.itemsOnPage" />
					{': '}
				</p>
				<DropDownList
					className={classes.pageItemsCount}
					data={props.data}
					defaultValue={defalutValue}
					onChange={props.onChunkSizeChanged}
					style={{ color: 'black' }}
				/>
			</div>
			<div className={classes.actualStateInfo}>
				<p className={classes.text}>
					{`${props.actChunk} - ${props.actChunk} `}
					<FormattedMessage id="select.of" />
					{` ${props.chunksCount}`}
				</p>
			</div>
			<Arrow
				className={[classes.arrow2, classes.rotate, ar1].join(' ')}
				type="toEnd"
				onClick={() => chunkChanged('toBeg')}
			/>
			<Arrow className={[classes.arrow, ar2].join(' ')} onClick={() => chunkChanged('prevChunk')} />
			<Arrow
				className={[classes.arrow, classes.rotate, ar3].join(' ')}
				onClick={() => chunkChanged('nextChunk')}
			/>
			<Arrow className={[classes.arrow2, ar4].join(' ')} type="toEnd" onClick={() => chunkChanged('toEnd')} />
		</div>
	);
};

export default chunkDivisor;
