// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../../../store/actions/actions.js';

import SensorsList from './../../../components/sensorsList/SensorsList.jsx';

import { injectIntl } from 'react-intl';
import { TOKEN_PATTERN } from './../../../utils/InputPatterns.js';

import ModalDelete from '../../../components/modals/modalDelete/ModalDelete.jsx';
import ModalSensorActivation from './../../../components/modals/SensorActivation/SensorActivation.jsx';
import Comparator from './Comparator/Comparator';

//import classes from './SensorsList.module.scss';
import './SensorsList.module.scss';

class SensorsListPage extends Component {
	deleteData = false;

	state = {
		isModalOpen: {
			activate: false,
			delete: false,
			comparator: false,
		},
		sensorId: null,
		sensorName: null,
		sensorType: null,
		periodSelection: {},
		token: {
			isValid: false,
			value: ['', '', '', '', ''],
			validationRules: {
				pattern: TOKEN_PATTERN,
			},
		},
		filter: '',
		deletingInProgress: false,
		sensorsToCompare: [],
	};

	componentDidMount() {
		if(this.props.isAdminRegime){
			this.props.fetchSensors(this.props.sensors.length < 1, true)
		}else{
			this.props.fetchSensorsDirect(this.props.sensors.length < 1);
		}
	}

	componentDidUpdate(prevProps) {
		if (this.state.isModalOpen.delete && this.props.sensors.length < prevProps.sensors.length) {
			this.onCloseModalHandle();
		}

		if (this.props.global_error !== '' && (this.state.isModalOpen.activate || this.state.isModalOpen.delete)) {
			this.onCloseModalHandle();
		}
	}

	openModal = (type, sensorId, sensorName, sensorType) => {
		const updateState = {
			...this.state,
			sensorId: sensorId,
			sensorName: sensorName,
			sensorType: sensorType,
			isModalOpen: {
				...this.state.isModalOpen,
				[type]: true,
			},
			deletingInProgress: false,
		};
		this.setState(updateState);
	};

	onDeleteButtonClickHandle = (e, sensorId, sensorName, sensorType) => {
		e.stopPropagation();
		this.openModal('delete', sensorId, sensorName, sensorType);
	};

	onActivateClickHandle = (e, sensorId, sensorName, sensorType) => {
		e.stopPropagation();
		this.openModal('activate', sensorId, sensorName, sensorType);
	};
	onProtectClick = (e, receivedSensor) => {
		e.stopPropagation();
		const name = typeof receivedSensor.name === 'string' ? receivedSensor.name : receivedSensor.origName;
		this.props.protectSensor(receivedSensor.id, receivedSensor.sensor_type, name, !receivedSensor.protected);
	};

	onSearchInputHandler = (e) => {
		this.props.filterSensors(e.target.value.toLowerCase());
	};

	onCloseModalHandle = () => {
		this.setState({
			...this.state,
			isModalOpen: {
				...this.state.isModalOpen,
				delete: false,
				activate: false,
				comparator: false,
			},
			token: {
				...this.state.token,
				value: ['', '', '', '', ''],
				isValid: false,
			},
		});
		this.props.changeActivationState('INIT');
	};

	onModalValueChangedHandle = (e, newEvt) => {
		const value = newEvt.value.join('');
		const regex = new RegExp(this.state.token.validationRules.pattern);
		const isValid = regex.test(value);

		this.setState({
			...this.state,
			token: {
				...this.state.token,
				isValid: isValid,
				value: newEvt.value,
			},
		});
	};

	handleClick = (e, linkTo) => {
		e.stopPropagation();
		this.props.history.push(linkTo);
	};

	handleDelete = (sensorId, sensorType) => {
		this.setState({ ...this.state, deletingInProgress: true });
		this.props.deleteSensor(sensorId, sensorType, this.deleteData);
	};

	handleActivation = (e, info) => {
		if (info) {
			this.props.changeActivationState('CONFIRMATION');
			this.setState({
				...this.state,
				periodSelection: info,
			});
		} else {
			const body = {
				type: this.state.sensorType,
				action: '0001',
				activation_code: this.state.periodSelection.id,
			};
			this.props.performAction(this.state.sensorId, body);
		}
	};
	chunkChangedHandler = (e) => {
		let chunk = null;
		switch (e) {
			case 'toEnd':
				chunk = this.props.filteredSensors.length;
				break;
			case 'nextChunk':
				if (this.props.selectedChunk < this.props.filteredSensors.length) chunk = this.props.selectedChunk + 1;
				break;
			case 'prevChunk':
				if (this.props.selectedChunk > 1) chunk = this.props.selectedChunk - 1;
				break;
			default:
				chunk = 1;
				break;
		}
		if (chunk) this.props.changeChunk(chunk);
	};

	onChunkSizeChangedHandler = (e) => {
		this.props.setChunkSize(e.target.value);
	};

	onComparatorChangehandler = (e) => {
		if (e.checked) {
			this.setState({
				sensorsToCompare: [...this.state.sensorsToCompare, e],
			});
			//this.state.sensorsToCompare.push(e);
		} else {
			const newSenors = this.state.sensorsToCompare.filter((s) => !(s.id === e.id && s.type === e.type && s.unit === e.unit && s.name === e.name));
			this.setState({
				sensorsToCompare: newSenors,
			});
		}
	};

	onCompareClickhandler = (e) => {
		if (this.state.sensorsToCompare.length > 0) {
			window.scrollTo(0, 0); //Important for DropDownList (for example: the graph type selection and oters) - bad position without this scroll !!!
			this.setState({
				...this.state,
				isModalOpen: {
					...this.state.isModalOpen,
					comparator: true,
				},
			});
		}
	};

	onDeleteDataChangeHandler = (e) => {
		const value = e.target.checked;
		if (this.state.isModalOpen.delete && this.deleteData !== value) {
			this.deleteData = value;
		}
	};

	render() {
		const { width } = this.props;
		let modalWindow = null;
		//const sensors = this.applySensorFiltration();
		let sensors = [];

		if (this.props.filteredSensors.length > 0) {
			sensors = this.props.filteredSensors[this.props.selectedChunk - 1];
		}

		let sensorsList = (
			<SensorsList
				sensors={sensors}
				onLinkClick={this.handleClick}
				onDeleteClick={this.onDeleteButtonClickHandle}
				onActivateClick={this.onActivateClickHandle}
				onProtectClick={this.onProtectClick}
				onSearchInputChange={this.onSearchInputHandler}
				loading={this.props.isLoading}
				mobileRegime={width <= 767}
				tabletRegime={width > 767 && width <= 1000}
				filterValue={this.props.filter}
				selectedChunk={this.props.selectedChunk}
				chunksCount={this.props.filteredSensors.length > 0 ? this.props.filteredSensors.length : 1}
				chunks={[25, 50, 100]}
				chunkChanged={this.chunkChangedHandler}
				onChunkSizeChanged={this.onChunkSizeChangedHandler}
				selectedChunkSize={this.props.selectedChunkSize}
				onComparatorChange={this.onComparatorChangehandler}
				onCompareClick={this.onCompareClickhandler}
				comparatorModeOn={this.props.chartModeOn}
				selectedGraphs={this.state.sensorsToCompare}
			/>
		);

		if (this.state.isModalOpen.delete) {
			modalWindow = (
				<ModalDelete
					isOpen={this.state.isModalOpen.delete}
					loading={this.state.deletingInProgress}
					close={this.onCloseModalHandle}
					continue={() => this.handleDelete(this.state.sensorId, this.state.sensorType)}
					sensorID={this.state.sensorId}
					sensorName={this.state.sensorName}
					onDeletedataChange={this.onDeleteDataChangeHandler}
				/>
			);
		} else if (this.state.isModalOpen.activate) {
			modalWindow = (
				<ModalSensorActivation
					isOpen={this.state.isModalOpen.activate}
					close={this.onCloseModalHandle}
					sensorId={this.state.sensorId}
					sensorName={this.state.sensorName}
					selection={this.state.periodSelection}
					selectionClick={this.handleActivation}
					continue={this.handleActivation}
					state={this.props.activationState}
					prices={this.props.prices[this.state.sensorType]}
				/>
			);
		} else if (this.state.isModalOpen.comparator) {
			modalWindow = <Comparator isOpen={this.state.isModalOpen.comparator} close={this.onCloseModalHandle} sensors={this.state.sensorsToCompare} />;
		}

		return (
			<div>
				<div>
					{sensorsList}
					{modalWindow}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	sensors: state.sensors.sensors,
	filteredSensors: state.sensors.filteredSensors,
	selectedChunk: state.sensors.selectedChunk,
	selectedChunkSize: state.sensors.chunkSize,
	filter: state.sensors.filter,
	isLoading: state.sensors.isLoading,
	isFetchSuccess: state.sensors.isFetchSuccess,
	activationState: state.coins.activationState,
	global_error: state.sensors.network_error,
	prices: state.coins.prices,
	isAdminRegime: state.users.adminRegime
});

export default connect(mapStateToProps, {
	fetchSensorsDirect: actions.fetchSensorsDirect,
	fetchSensors: actions.fetchSensors,
	deleteSensor: actions.deleteSensor,
	changeActivationState: actions.changeActivationState,
	performAction: actions.performAction,
	filterSensors: actions.filterSensors,
	changeChunk: actions.changeChunk,
	setChunkSize: actions.setChunkSize,
	fetchMultipleData: actions.fetchMultipleData,
	protectSensor: actions.protectSensor,
})(injectIntl(SensorsListPage));
