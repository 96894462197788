import React from "react";

import GeneralModal from "./../../General/General.jsx";
import { FormattedMessage } from "react-intl";

import classes from "./ConfirmModal.module.scss";

const modalActivate = props => {
  let envelope = null;

  if (props.envelopeAnimation) {
    envelope = (
      <i
        className={`fas fa-envelope ${classes.message}`}
        onAnimationEnd={() => {}}
      />
    );
  }

  const content = (
    <div>
      <h6 className="modalMessage">{props.confirmMsg}</h6>
      {envelope}
    </div>
  );

  const OKButton = {
    className: "btn btn-warning",
    action: props.continue,
    isDisabled: props.isDisabled,
    text: <FormattedMessage id="button.OK" defaultMessage="OK" />
  };

  return (
    <GeneralModal
      actionBtn={OKButton}
      isOpen={props.isOpen}
      close={props.close}
    >
      {content}
    </GeneralModal>
  );
};

export default modalActivate;
