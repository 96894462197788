import React from 'react';

import { injectIntl } from 'react-intl';

import NAIcon from './../../../../icons/NA/NA.jsx';
import classes from './NotAvailable.module.scss';

const notAvailable = props => {
	let label = props.intl.formatMessage({ id: 'sensor.notavailable' });

	let content = null;
	if (!props.hidden) {
		content = (
			<div>
				<NAIcon className={classes.NAICON} tooltip={label} />
			</div>
		);
	}
	return content;
};

export default injectIntl(notAvailable);
