// @flow
import { CHANGE_LANG } from "../actions/localeActionTypes";

const initialState = {
  lang: "en"
};

const changeLang = (state, action) => {
  return {
    ...state,
    lang: action.payload.lang
  };
};

const localeReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_LANG:
      return changeLang(state, action);
    default:
      return state;
  }
};

export default localeReducer;
