import React from 'react';

import { injectIntl } from 'react-intl';

import Unit from "./../../../../../icons/Unit/Unit";

import Id from "./../../../../../icons/Id/Id";
import Time from "./../../Time/Time";
import NotAvailable from "./../../NotAvailable/NotAvailable";

import TextIcon from "./../../TextIcon/TextIcon";

import {allTypes} from "./../../../../../../utils/binaryInputs";
import * as infos from "./../infos";

const ste2 = (props) => {

    let icon = null;
    let typeInfo = props.type.split(":");
    let type = props.type;
    let value = props.value;

    if (typeInfo.length === 3) type = typeInfo[1];

    let sensorLabel = "(" + props.intl.formatMessage({ id: 'sensor.sensor' }) + ": " + typeInfo[2] + ")";
    let subType = "binary";
    if(props.data){
        const customSensorLabel = props.data.find(d => d.id === typeInfo[2]);
        if(customSensorLabel && customSensorLabel.name) sensorLabel = "("+ customSensorLabel.name + ")";
        if(customSensorLabel && customSensorLabel.subType) subType = customSensorLabel.subType;
    }

    switch (type) { 
        case "[°C]":
            icon = <Unit
                type="temperature"
                tooltip={
                    props.intl.formatMessage({ id: 'tooltip.info.lastTemperature' }) + ". " +
                    sensorLabel
                }
            />;
            break;
        case "[%RH]":
            icon = <Unit
                type="humidity"
                tooltip={
                    props.intl.formatMessage({ id: 'tooltip.info.lastHumidity' }) + ". " +
                    sensorLabel
                }
            />;
            break;
        case "[bin]":
            if(!allTypes.includes(subType)) subType = "binary";
            
            const val = props.intl.formatMessage({ id: `tooltip.binInput.${subType}.${value}`});
            icon = <Unit
                type="binary"
                tooltip={
                    props.intl.formatMessage({ id: 'sensor.binaryInput' }) + ": " + typeInfo[2] + " - " + val.toUpperCase()
                }
                closed={+value}
            />;
            value="";
            break;
        case infos.id:

            icon = <Id tooltip={props.intl.formatMessage({ id: 'tooltip.info.id' })} />;
            break;
        case infos.last_msg_time:
            return (
                <Time
                    lang={props.lang}
                    time={props.value}
                    tooltip={props.intl.formatMessage({ id: 'tooltip.info.lastMsgTime' })}
                />
            );
        default:
            return <NotAvailable />;
    }

    return <TextIcon iconComponent={icon} text={value} />;
};


export default injectIntl(ste2);