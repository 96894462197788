import React from 'react';

import { FormattedMessage } from 'react-intl';

import classes from './Exporter.module.scss';


const exporter = (props) => {

    const name = (
        <tr className={classes.exportRow}>
            <td className={classes.colName}>
                <FormattedMessage id="chart.export.name" />
                {": "}
            </td>
            <td className={classes.colValue}>
                <input
                    value={props.nameValue}
                    onChange={props.onChange}
                    maxLength={64}
                    type="text"
                    name="name"
                />
            </td>
        </tr>
    );

    const delimiter = (
        <tr className={classes.exportRow}>
            <td className={classes.colName}>
                <FormattedMessage id="chart.export.delimiter" />
                {": "}
            </td>
            <td className={classes.colValue}>
                <input
                    className={classes.valueInput}
                    value={props.delimiterValue}
                    onChange={props.onChange}
                    maxLength={1}
                    type="text"
                    name="delimiter"
                />
            </td>
        </tr>
    );

    const decimalSeparator = (
        <tr className={classes.exportRow}>
            <td className={classes.colName}>
                <FormattedMessage id="chart.export.decimalSeparator" />
                {": "}
            </td>
            <td className={classes.colValue}>
                <input
                    className={classes.valueInput}
                    value={props.decimalSeparatorValue}
                    onChange={props.onChange}
                    maxLength={1}
                    type="text"
                    name="decimalSeparator"
                />
            </td>
        </tr>
    );

    const decimalPlaces = (
        <tr className={classes.exportRow}>
            <td className={classes.colName}>
                <FormattedMessage id="chart.export.decimalPlaces" />
                {": "}
            </td>
            <td className={classes.colValue}>
                <input
                    className={classes.valueInput}
                    value={props.decimalPlacesValue}
                    onChange={props.onChange}
                    maxLength={1}
                    type="tel"
                    name="decimalPlaces"
                    pattern="[0-9]*"
                />
            </td>
        </tr>
    );

    let tableBody = null;

    switch (props.type) {
        case "xlsx":
            tableBody = (
                <tbody>
                    {name}
                    {decimalPlaces}
                </tbody>
            );
            break;
        case "csv":
            tableBody = (
                <tbody>
                    {name}
                    {delimiter}
                    {decimalSeparator}
                    {decimalPlaces}
                </tbody>
            );
            break;
        default:
            tableBody = null;
            break;
    }

    const containerClasses = [classes.exporterContainer];
    if(props.containerClassName) containerClasses.push(props.containerClassName);

    return (
        <div className={containerClasses.join(" ")} >
            <h4>
                <FormattedMessage id="chart.export.setting" />
            </h4>
            <div className={classes.cross} onClick={props.onClose}>
                <svg width="100%">
                    <path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z" />
                </svg>
            </div>
            <div >
                <table className={classes.settingTable}>
                    {tableBody}
                </table>
            </div>
            <button className={`orangeBtn ${classes.exportBtn}`} onClick={props.onExportClick}>
                <FormattedMessage id="button.export" />
            </button>
        </div >
    );
};

export default exporter;