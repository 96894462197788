import React from 'react';

import Tooltip from "./../../../Tooltip/Tooltip";

const temperature = props => {
    return (
        <div {...props}>
            <Tooltip tooltip={props.tooltip}>
                <svg width="100%" viewBox="0 0 256 256">
                    <path
                        d="M192.986 214.931c0 10.282-8.335 18.618-18.618 18.618H81.632c-10.283 0-18.618-8.336-18.618-18.618V41.067c0-10.282 8.335-18.618 18.618-18.618h92.736c10.283 0 18.618 8.336 18.618 18.618v173.864z"
                        fill="#AD6E1F"
                    />
                    <linearGradient
                        gradientUnits="userSpaceOnUse"
                        id="temperature_prefix__a"
                        x1={69.895}
                        x2={181.71}
                        y1={11.79}
                        y2={235.42}
                    >
                        <stop offset={0} stopColor="#e2c397" />
                        <stop offset={0.155} stopColor="#d9b37d" />
                        <stop offset={0.355} stopColor="#d0a263" />
                        <stop offset={0.561} stopColor="#c99650" />
                        <stop offset={0.773} stopColor="#c58e45" />
                        <stop offset={1} stopColor="#c48c41" />
                    </linearGradient>
                    <path
                        d="M81.632 228.895c-7.7 0-13.964-6.264-13.964-13.964V41.067c0-7.7 6.264-13.964 13.964-13.964h92.736c7.7 0 13.964 6.264 13.964 13.964v173.864c0 7.7-6.264 13.964-13.964 13.964H81.632z"
                        fill="url(#temperature_prefix__a)"
                    />
                    <linearGradient
                        gradientUnits="userSpaceOnUse"
                        id="temperature_prefix__b"
                        x1={66.386}
                        x2={191.421}
                        y1={67.659}
                        y2={190.109}
                    >
                        <stop offset={0} stopColor="#ead6bb" />
                        <stop offset={0.125} stopColor="#e7d2b5" />
                        <stop offset={0.299} stopColor="#e0c6a3" />
                        <stop offset={0.501} stopColor="#d5b285" />
                        <stop offset={0.725} stopColor="#c5965c" />
                        <stop offset={0.964} stopColor="#b07427" />
                        <stop offset={1} stopColor="#ad6e1f" />
                    </linearGradient>
                    <path
                        d="M174.368 225.792H81.632c-5.988 0-10.861-4.873-10.861-10.861V41.067c0-5.988 4.873-10.861 10.861-10.861h92.736c5.988 0 10.861 4.873 10.861 10.861v173.864c0 5.988-4.873 10.861-10.861 10.861zM81.632 33.31c-4.277 0-7.758 3.482-7.758 7.758v173.864c0 4.279 3.48 7.758 7.758 7.758h92.736c4.277 0 7.758-3.479 7.758-7.758V41.067c0-4.276-3.48-7.758-7.758-7.758H81.632z"
                        fill="url(#temperature_prefix__b)"
                    />
                    <path
                        d="M128.002 215.586c-17.071 0-30.961-13.888-30.961-30.961 0-11.23 5.915-21.351 15.564-26.861V58.319a15.29 15.29 0 014.511-10.885 15.293 15.293 0 0110.886-4.515c8.489 0 15.397 6.909 15.397 15.4v99.445c9.647 5.509 15.561 15.63 15.561 26.861-.001 17.073-13.889 30.961-30.958 30.961z"
                        fill="#AD6E1F"
                    />
                    <linearGradient
                        gradientUnits="userSpaceOnUse"
                        id="temperature_prefix__c"
                        x1={122.126}
                        x2={133.874}
                        y1={102.025}
                        y2={102.025}
                    >
                        <stop offset={0} stopColor="#c3d6e8" />
                        <stop offset={0.065} stopColor="#d6e0ed" />
                        <stop offset={0.133} stopColor="#e2e7f1" />
                        <stop offset={0.2} stopColor="#e6e9f2" />
                        <stop offset={0.327} stopColor="#d4deea" />
                        <stop offset={0.487} stopColor="#c6d4e3" />
                        <stop offset={0.682} stopColor="#becfdf" />
                        <stop offset={1} stopColor="#bbcdde" />
                    </linearGradient>
                    <path
                        d="M133.874 151.607V58.319a5.882 5.882 0 00-5.873-5.876 5.881 5.881 0 00-5.873 5.876h-.003v93.288h11.749z"
                        fill="url(#temperature_prefix__c)"
                    />
                    <linearGradient
                        gradientTransform="matrix(1 0 0 1.0877 0 -50.15)"
                        gradientUnits="userSpaceOnUse"
                        id="temperature_prefix__d"
                        x1={122.126}
                        x2={133.874}
                        y1={173.06}
                        y2={173.06}
                    >
                        <stop offset={0} stopColor="#ea2127" />
                        <stop offset={0.021} stopColor="#ec3231" />
                        <stop offset={0.073} stopColor="#ef5648" />
                        <stop offset={0.122} stopColor="#f27059" />
                        <stop offset={0.166} stopColor="#f37f63" />
                        <stop offset={0.2} stopColor="#f48566" />
                        <stop offset={0.36} stopColor="#f3775d" />
                        <stop offset={0.663} stopColor="#ef5146" />
                        <stop offset={1} stopColor="#ea2127" />
                    </linearGradient>
                    <path
                        fill="url(#temperature_prefix__d)"
                        d="M122.126 109.558h11.748v57.061h-11.748z"
                    />
                    <linearGradient
                        gradientUnits="userSpaceOnUse"
                        id="temperature_prefix__e"
                        x1={100.144}
                        x2={155.856}
                        y1={129.252}
                        y2={129.252}
                    >
                        <stop offset={0.037} stopColor="#c3d6e8" />
                        <stop offset={0.455} stopColor="#e6e9f2" />
                        <stop offset={0.541} stopColor="#d4deea" />
                        <stop offset={0.65} stopColor="#c6d4e3" />
                        <stop offset={0.783} stopColor="#becfdf" />
                        <stop offset={1} stopColor="#bbcdde" />
                    </linearGradient>
                    <path
                        d="M140.295 159.628V58.319c0-6.782-5.515-12.297-12.294-12.297-6.782 0-12.297 5.515-12.297 12.297h.003v101.309a27.828 27.828 0 00-15.564 24.997c0 15.361 12.497 27.858 27.858 27.858 15.358 0 27.855-12.497 27.855-27.858a27.83 27.83 0 00-15.561-24.997zm-12.293 48.761c-13.103 0-23.764-10.661-23.764-23.764a23.746 23.746 0 0114.415-21.849l1.145-.491V58.319h.003a8.2 8.2 0 1116.4 0v103.967l1.145.491a23.746 23.746 0 0114.418 21.849c.001 13.102-10.663 23.763-23.762 23.763z"
                        fill="url(#temperature_prefix__e)"
                    />
                    <radialGradient
                        cx={123.627}
                        cy={176.639}
                        gradientUnits="userSpaceOnUse"
                        id="temperature_prefix__f"
                        r={18.968}
                    >
                        <stop offset={0} stopColor="#f48566" />
                        <stop offset={0.2} stopColor="#f3775d" />
                        <stop offset={0.579} stopColor="#ef5146" />
                        <stop offset={1} stopColor="#ea2127" />
                    </radialGradient>
                    <circle cx={128.002} cy={184.625} fill="url(#temperature_prefix__f)" r={21.436} />
                    <g fill="#AD6E1F">
                        <path d="M152.161 117.774a.747.747 0 00-.745-.748h-8.18v1.497h8.18a.749.749 0 00.745-.749zM152.161 142.722a.748.748 0 00-.745-.749h-8.18v1.494h8.18c.411 0 .745-.33.745-.745zM143.235 61.51h13.656a.87.87 0 000-1.743h-13.656v1.743zM152.161 94.704a.746.746 0 00-.745-.751h-8.18v1.497h8.18a.745.745 0 00.745-.746zM156.891 105.931h-13.656v1.742h13.656a.87.87 0 000-1.742zM156.891 129.249h-13.656v1.742h13.656a.872.872 0 000-1.742zM156.891 82.219h-13.656v1.748h13.656a.874.874 0 000-1.748zM156.891 154.207h-13.656v1.743h13.656a.87.87 0 000-1.743zM152.161 72.249a.748.748 0 00-.745-.752h-8.18v1.5h8.18a.747.747 0 00.745-.748z" />
                        <g>
                            <path d="M103.839 117.774c0-.412.333-.748.746-.748h8.18v1.497h-8.18a.75.75 0 01-.746-.749zM103.839 142.722c0-.412.333-.749.746-.749h8.18v1.494h-8.18a.743.743 0 01-.746-.745zM112.765 61.51H99.109a.87.87 0 010-1.743h13.656v1.743zM103.839 94.704c0-.418.333-.751.746-.751h8.18v1.497h-8.18a.745.745 0 01-.746-.746zM99.109 105.931h13.656v1.742H99.109a.87.87 0 010-1.742zM99.109 129.249h13.656v1.742H99.109a.872.872 0 010-1.742zM99.109 82.219h13.656v1.748H99.109a.874.874 0 010-1.748zM99.109 154.207h13.656v1.743H99.109a.87.87 0 010-1.743zM103.839 72.249c0-.415.333-.752.746-.752h8.18v1.5h-8.18a.747.747 0 01-.746-.748z" />
                        </g>
                    </g>
                </svg>
            </Tooltip>
        </div>
    );
};

export default temperature;
