import React from 'react';
import Modal from 'react-responsive-modal';

import classes from './General.module.scss';

const general = props => {
	let actionButton = null;
	let cancelButton = null;

	let closeOnEsc = true;
	let closeOnOverlayClick = true;

	if (typeof props.closeOnEsc === 'boolean') {
		closeOnEsc = props.closeOnEsc;
	}
	if (typeof props.closeOnOverlayClick === 'boolean') {
		closeOnOverlayClick = props.closeOnOverlayClick;
	}

	if (props.actionBtn) {
		actionButton = (
			<button
				className={props.actionBtn.className}
				onClick={props.actionBtn.action}
				disabled={props.actionBtn.isDisabled}
				style={props.actionBtn.style}
			>
				{props.actionBtn.text}
			</button>
		);
	}

	if (props.cancelBtn) {
		cancelButton = (
			<button
				className={props.cancelBtn.className}
				onClick={props.cancelBtn.action}
				disabled={props.cancelBtn.isDisabled}
				style={props.cancelBtn.style}
			>
				{props.cancelBtn.text}
			</button>
		);
	}

	let modalClass = classes.modalWindow;
	let closeIconClass = classes.closeIcon;
	let overlayClass = classes.overlay;
	if (props.className) {
		modalClass = props.className;
	}
	if (props.iconClassName) {
		closeIconClass = props.iconClassName;
	}
	if(props.containerClass){
		overlayClass = props.containerClass;
	}

	return (
		<Modal
			open={props.isOpen}
			onClose={props.close}
			center
			classNames={{ modal: modalClass, closeIcon: closeIconClass, overlay: overlayClass }}
			closeOnOverlayClick={closeOnOverlayClick}
			closeOnEsc={closeOnEsc}
			animationDuration={0}
			focusTrapped={false}
		>
			{props.children}
			{actionButton}
			{cancelButton}
		</Modal>
	);
};

export default general;
