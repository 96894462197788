import React, { Component } from 'react';
import { connect } from 'react-redux';


import Auth from '@aws-amplify/auth';
import { FormattedMessage, injectIntl } from 'react-intl';
import Input from './../../../components/UI/Inputs/AuthInputs/SimpleInput/SimpleInput.jsx';
import SubmitButton from './../../../components/UI/Inputs/AuthInputs/SubmitInput/SubmitInput.jsx';
import ConfirmModal from './../../../components/modals/AuthModals/ConfirmModal/ConfirmModal.jsx';
import LangPanel from './../../../components/common/LangPanel/LangPanel.jsx';
import Spinner from './../../../components/Spinner/Spinner.jsx';

import { setLocale } from './../../../store/actions/localeActions';
import classes from './../Auth.module.scss';

const loader = document.getElementById('root-loader');

const texts = {
	signIn: <FormattedMessage id="login.signIn" defaultMessage="Sign In" />,
	register: <FormattedMessage id="login.register" defaultMessage="Register" />,
	lostPassword: <FormattedMessage id="login.lostPassword" defaultMessage="Lost your password" />
};
const inputs = [
	{
		key: 'login.email',
		name: 'email',
		type: 'text',
		emptyId: 'login.email'
	},
	{
		key: 'login.password',
		name: 'password',
		type: 'password',
		emptyId: 'login.password'
	}
];

class SignIn extends Component {
	state = {
		email: '',
		password: '',
		error: null,
		loading: false,
		modalOpen: false
	};

	initState = () => {
		this.setState({
			error: null,
			loading: false,
			//email: '',
			//password: '',
			modalOpen: false
		});
	};

	onInputChange = e => {
		this.setState({
			...this.state,
			[e.target.name]: e.target.value,
			error: null
		});
	};
	componentDidMount(){
		if(this.props.authState === 'signIn')
			loader.classList.add('root_loader_hide');
	}

	componentDidUpdate(prevProps) {
		if (this.props.authState === 'signIn' && prevProps.authState === 'loading') {
			//initiate All redux states. This is called after reload or after logout;
			//after page reaload is redux cleared by default so this code is mainly for logout.

			const lang = this.props.lang;
			this.props.clearAll();
			this.props.setlang(lang);
			loader.classList.add('root_loader_hide');
		}
	}

	onSubmit = e => {
		e.preventDefault();

		if (!this.state.email || !this.state.password) {
			this.setState({
				...this.state,
				error: { code: 'UserNotFoundException' }
			});
			return;
		}

		this.setState({ ...this.state, loading: true });

		Auth.signIn(this.state.email, this.state.password)
			.then(user => {
				this.initState();
				if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
					this.props.onStateChange('requireNewPassword', { user, email: this.state.email, force: true });
				} else {
					this.props.onStateChange('signedIn', user);
				}
			})
			.catch(err => {
				if (err.name === 'UserNotConfirmedException') {
					this.setState({ ...this.state, modalOpen: true, loading: false });
					this.props.onStateChange('confirmSignUp', { email: this.state.email });
				} else {
					this.setState({
						...this.state,
						error: err,
						loading: false
					});
				}
			});
	};

	onLostPasswordHandler = () => {
		this.props.onStateChange('forgotPassword', { email: this.state.email });
		this.initState();
	};

	onSignUpHandler = () => {
		this.initState();
		this.props.onStateChange('signUp', {});
	};

	onModalCloseHandler = () => {
		this.initState();
	};

	render() {
		let signIn = null;
		let error = null;
		let modal = null;

		let buttonLoader = (
			<SubmitButton
				value={this.props.intl.formatMessage({ id: 'button.signin' })}
				isEnabled
				name="button"
				onSubmit={this.onSubmit}
			/>
		);

		if (this.state.loading) {
			buttonLoader = (
				<Spinner type="dotCircle" size="8px" style={{ margin: '40px 0', marginLeft: '30%', float: 'left' }} />
			); //<div className={classes2.loader} />;
		}
		if (this.state.error) {
			error = (
				<h4>
					<FormattedMessage
						id={'error.signin.' + this.state.error.code}
						defaultMessage={this.state.error.message}
					/>
				</h4>
			);
		}
		if (this.state.modalOpen) {
			modal = (
				<ConfirmModal
					isOpen={this.state.modalOpen}
					close={this.onModalCloseHandler}
					continue={this.onModalCloseHandler}
					confirmMsg={this.props.intl.formatMessage({
						id: 'login.signin.notconfirmed'
					})}
				/>
			);
		}

		const jsxInputs = inputs.map(input => {
			return (
				<Input
					key={input.key}
					type={input.type}
					name={input.name}
					emptyText={this.props.intl.formatMessage({ id: input.emptyId })}
					highLight="Off"
					onInputChange={this.onInputChange}
				/>
			);
		});

		if (this.props.authState === 'signIn') {
			signIn = (
				<div className={classes.outter}>
					<div className={classes.middle}>
						<div className={classes.container}>
							<section className={classes.content}>
								<span className={classes.topPanel}>
									<img
										className={`img-fluid ${classes.iotIcon}`}
										src={process.env.PUBLIC_URL + '/assets/img/IoTpool_logo.png'}
										alt="iot-logo"
									/>
									<LangPanel className={classes.langPanel} />
								</span>
								<form action="">
									<h1>{texts.signIn}</h1>
									{error}
									{jsxInputs}
									{buttonLoader}
									<div>
										<p onClick={this.onLostPasswordHandler}>{texts.lostPassword}</p>
										<p onClick={this.onSignUpHandler}>{texts.register}</p>
									</div>
								</form>
							</section>
						</div>
					</div>
				</div>
			);
		}
		return (
			<div>
				{signIn}
				{modal}
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		lang: state.locale.lang
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setlang: lang => dispatch(setLocale(lang)),
		clearAll: () => dispatch({ type: 'APP_LOGOUT' })
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SignIn));
