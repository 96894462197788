import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from './../../../../axios/axios.js';

import { FormattedMessage, injectIntl } from 'react-intl';
import Input from './../../../../components/UI/Inputs/Input/Input';
import PhoneInput from './../../../../components/UI/Inputs/PhoneInput/PhoneInput';
import TwoStateInput from './../../../../components/UI/Inputs/TwoStateInput/TwoStateInput';
import CoinIcon from './../../../../components/icons/Coin/Coin';
import FormattedLabel from './../../../../components/UI/Labels/FormattedLabel/FormattedLabel';
import NavigationButtons from './../../../../components/UI/FormNavigation/FormNavigation2';
import Spinner from './../../../../components/Spinner/SpinnerWithResult/SpinnerWithResult';
import PageSpinner from './../../../../components/Spinner/Spinner.jsx';

import { Button } from '@progress/kendo-react-buttons';
import { toast } from 'react-toastify';

import * as firebase from './../../../../firebase.js';
import * as inputValidator from './../../../../utils/inputsValidator.js';
import * as patterns from './../../../../utils/InputPatterns.js';
import { fetchUser, putUser } from './../../../../store/actions/userActions.js';
import { performAction, changeActivationState } from './../../../../store/actions/coinsActions.js';
import { unmarshall } from './../../../../utils/awsMarshaller';

import classes from './Notifications.module.scss';
const SMS_price = 3.5;
const prices = {
	20: Math.floor(20 * SMS_price),
	50: Math.floor(50 * SMS_price),
	100: Math.floor(100 * SMS_price),
	200: Math.floor(200 * SMS_price),
	500: Math.floor(500 * SMS_price),
	1000: Math.floor(1000 * SMS_price),
};

const discounts = {
	20: 1,
	50: 0.98,
	100: 0.94,
	200: 0.9,
	500: 0.84,
	1000: 0.78,
};

const stateNameToSensorPropDic = {
	email: { value: 'email', default: '' },
	phone_number: { value: 'phone_number', default: '+420' },
};

class Notifications extends Component {
	isComponentMounted = false;
	token = null;
	column = 3;
	successfullyUpdated = false;
	state = {
		email: {
			value: '',
			clicked: false,
			valid: false,
			required: false,
			enabled: false,
			type: 'basic',
			active: false,
			validationRules: {
				pattern: patterns.EMAIL_PATTERN,
				maxLength: 64,
			},
		},
		phone_number: {
			value: '+420',
			clicked: false,
			valid: false,
			required: false,
			enabled: false,
			type: 'basic',
			active: false,
			validationRules: {
				pattern: patterns.PHONE_NUMBER,
			},
		},
		sms_packages: {
			20: false,
			50: false,
			100: false,
			200: false,
			500: false,
			1000: false,
		},
		totalCredits: 0,
		totalPrice: 0,
		totalSale: 0,
		perSMS: 0,
		selectedSMSPackages: [],
		inSmsStore: false,
		init: true,
		autoCreditConsuming: { enabled: false },
		pushNotifEnabled: false,
		permState: firebase.getPermissionState(),
		isPushNotifDevicesLoading: firebase.getPermissionState() === 'granted',
	};

	checkToken() {
		if (this.state.permState === 'granted') {
			firebase
				.getToken()
				.then((token) => {
					this.token = token;

					return axios.get('/users/notifications/push/device', { params: { entity: 'IOT_GSOL', id: token } });
					//* fetch token from DB here - if this token is not in database - the notifications are turned off
					//* if the fetched token is in db - then check if is disabled or enabled (its property)
				})
				.then((result) => {
					const pushDevice = unmarshall(result.data.device.Item);

					let isPushNotifsEnabled = false;
					if (Object.keys(pushDevice).length > 0) {
						//* This token is contained in the DB - check if the push notfs are enabled;
						isPushNotifsEnabled = true;
					}

					this.setState({
						...this.state,
						pushNotifEnabled: isPushNotifsEnabled,
						isPushNotifDevicesLoading: false,
					});
				})
				.catch((err) => {
					this.setState({ ...this.state, isPushNotifDevicesLoading: false });
				});
		}
	}

	componentDidMount() {
		this.isComponentMounted = true;
		this.props.fetchNotifications('IOT_GSOL');
		if (!this.props.isTokenRefreshing) this.checkToken();

		if ('permissions' in navigator) {
			navigator.permissions.query({ name: 'notifications' }).then((notificationPerm) => {
				notificationPerm.onchange = () => {
					let isPushEnabled = this.state.pushNotifEnabled;

					if (notificationPerm.state !== 'granted') {
						//* Delete "this.token" from the database
						isPushEnabled = false;
						if (this.token) {
							axios
								.delete('/users/notifications/push/device', { params: { entity: 'IOT_GSOL', id: this.token } })
								.then(() => {
									return firebase.deleteToken();
								})
								.then(() => {
									this.token = null;
								})
								.catch((err) => {
									console.log('Device token deleting failed: ', err);
								});
						}
					}

					if (this.isComponentMounted) {
						this.setState({
							...this.state,
							permState: notificationPerm.state,
							pushNotifEnabled: isPushEnabled,
						});
					}
				};
			});
		}
	}

	componentWillUnmount() {
		this.isComponentMounted = false;
	}

	componentDidUpdate(prevProps) {
		if (this.props.global_error !== '' && this.props.smsPurchasingState !== 'INIT') {
			this.smsPurchasedModalContinue();
		}
		if (this.props.user.notifications && this.props.user.notifications !== prevProps.user.notifications) {
			const updatedState = { ...this.state };
			const propsToStateMap = inputValidator.extractStateMapValues(this.props.user.notifications, stateNameToSensorPropDic);
			for (let mapStateName in propsToStateMap) {
				updatedState[mapStateName] = inputValidator.recomputeState(this.state[mapStateName], propsToStateMap[mapStateName]);
			}
			updatedState.email.enabled = this.props.user.notifications.email_enabled;
			updatedState.phone_number.enabled = this.props.user.notifications.sms_enabled;
			updatedState.autoCreditConsuming.enabled = this.props.user.notifications.auto_credit;
			if (this.successfullyUpdated) {
				toast.success(<FormattedMessage id="modal.user.editNotifSuccess" defaultMessage="Sensor was successfully created" />, {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2000,
				});
				this.successfullyUpdated = false;
			}
			this.setState(updatedState);
		}
		if (prevProps.isTokenRefreshing && !this.props.isTokenRefreshing) {
			this.checkToken();
		}
	}

	generateSMSPacks = () => {
		const packs = [];

		for (const price in prices) {
			let finalPrice = Math.floor(prices[price] * discounts[price]);

			const addInfo = (
				<div className={classes.labelAddInfo}>
					{'('}
					<FormattedMessage id="user.global.price" />
					{`: ${finalPrice} `}
					<CoinIcon className={classes.coinIcon} />
					{')'}
				</div>
			);
			const label = (
				<FormattedLabel
					mainText={price + ' SMS' /*+ this.props.intl.formatMessage({ id: 'user.global.credits' }).toLowerCase()*/}
					additionalInfo={addInfo}
				/>
			);
			packs.push(
				<TwoStateInput
					key={price + ' credits'}
					title={label}
					inputChange={this.onSmsPackageSelected}
					name={price}
					state={this.state.sms_packages[price]}
					column={this.column}
					useNotifStyles
				/>
			);
		}

		return packs;
	};

	initStore = (init = false) => {
		this.setState({
			...this.state,
			init: init,
			inSmsStore: false,
			sms_packages: {
				20: false,
				50: false,
				100: false,
				200: false,
				500: false,
				1000: false,
			},
			totalCredits: 0,
			totalPrice: 0,
			totalSale: 0,
			perSMS: 0,
			selectedSMSPackages: [],
		});
	};

	onSmsPackageSelected = (e) => {
		const creditsValueNum = e.target.checked ? +e.target.name : 0; //-+e.target.name;
		const priceValNum = e.target.checked ? prices[e.target.name] : 0; //-prices[e.target.name];

		/*************** 
			let selSMS = [...this.state.selectedSMSPackages];
	 
			if (e.target.checked) {
				selSMS.push(creditsValueNum);
			} else {
				selSMS = selSMS.filter(val => val !== Math.abs(creditsValueNum));
			}
			***************** */
		let selSMS = [creditsValueNum];

		let sale = discounts[e.target.name];

		const totalCredits = creditsValueNum; //this.state.totalCredits + creditsValueNum;
		const totalPrice = Math.floor(priceValNum * sale); //this.state.totalPrice + priceValNum;

		let perSMS = 0;
		if (totalCredits > 0) {
			perSMS = Math.round((totalPrice / totalCredits) * 100) / 100;
		}

		/******  This code allow only one package selction *********/
		const smsPackageSelection = { ...this.state.sms_packages };
		for (const pack in smsPackageSelection) smsPackageSelection[pack] = false;
		smsPackageSelection[e.target.name] = e.target.checked;
		/*****************************************************/

		this.setState({
			...this.state,
			totalPrice: totalPrice,
			totalCredits: totalCredits,
			totalSale: sale,
			perSMS: perSMS,
			selectedSMSPackages: selSMS,
			sms_packages: smsPackageSelection,
		});
	};
	topupSmsCredits = () => {
		this.setState({
			...this.state,
			inSmsStore: true,
			init: false,
		});
	};
	backFromStore = () => {
		this.initStore();
	};

	onTwoStateInputChange = (e) => {
		this.setState({
			...this.state,
			[e.target.name]: {
				...this.state[e.target.name],
				enabled: e.target.checked,
			},
		});
	};

	onSimpleInputChange = (e, newEvt) => {
		let stateName = newEvt === undefined ? e.target.name.toLowerCase() : newEvt.name;
		let value = newEvt === undefined ? e.target.value : newEvt.value;

		let updatedState = inputValidator.recomputeState(this.state[stateName], value);

		updatedState = {
			...this.state,
			[stateName]: updatedState,
		};

		this.setState(updatedState);
	};

	purchaseSelectedSMSPackages = () => {
		const body = {
			action: '0003',
			selectedSMSPackages: this.state.selectedSMSPackages,
		};

		this.props.performAction('0', body);
	};

	confirmSettingsHandler = (e) => {
		e.preventDefault();
		const body = {
			entity: 'IOT_GSOL',
			part: 'notifications',
			email: this.state.email.active ? this.state.email.value : 'N/A',
			phone_number: this.state.phone_number.value,
			email_enabled: this.state.email.enabled,
			sms_enabled: this.state.phone_number.enabled,
			auto_credit: this.state.autoCreditConsuming.enabled,
		};
		if (this.token) {
			body.device_token = this.token;
			body.delete_device = !this.state.pushNotifEnabled;
		}

		this.successfullyUpdated = true;

		this.props.putUser(body);
	};

	smsPurchasedModalContinue = () => {
		this.props.changeActivationState('INIT');
		this.initStore(true);
	};

	onEnablePushNotifHandler = (e) => {
		let isEnabled = e.target.checked;

		if (isEnabled) {
			switch (this.state.permState) {
				case 'granted':
					firebase.getToken().then((token) => {
						if (token) {
							this.token = token;

							this.setState({
								...this.state,
								pushNotifEnabled: isEnabled,
							});
						}
					});
					break;
				case 'denied':
					//No action - toggle button is disabled
					break;
				default:
					firebase
						.requestPermissions()
						.then((token) => {
							if (token) {
								this.token = token;

								this.setState({
									...this.state,
									pushNotifEnabled: isEnabled,
								});
							}
						})
						.catch((err) => {
							//err.code === "messaging/permission-blocked")
							this.token = null;
						});
					break;
			}
		} else {
			this.setState({
				...this.state,
				pushNotifEnabled: isEnabled,
			});
		}
	};

	render() {
		let creditStateStyle = {
			color: 'green',
			border: '1px solid green',
		};

		let sms_credit = 'N/A';
		if (this.props.user.notifications) {
			sms_credit = this.props.user.notifications.sms_credits;
		}

		if (sms_credit < 10) {
			creditStateStyle.color = 'red';
			creditStateStyle.border = '1px solid red';
		}

		let smsContentClasses1 = [classes.notifSMSContent1];
		let smsContentClasses2 = [classes.notifSMSContent2];
		if (this.state.inSmsStore) {
			smsContentClasses1.push(classes.out);
			smsContentClasses2.push(classes.in);
		} else if (!this.state.init) {
			smsContentClasses1.push(classes.in);
			smsContentClasses2.push(classes.out);
		}

		let errMessage = null;
		let isDisabled = !firebase.isNotifSupportedByBrowser() || this.state.permState === 'denied';

		if (isDisabled) {
			let message = <FormattedMessage id="errorMessage.notifNotSupported" />;
			if (this.state.permState === 'denied') message = <FormattedMessage id="errorMessage.notifDenied" />;

			errMessage = (
				<div className={classes.pushNotifErrMsgContainer}>
					<i className={`fas fa-exclamation-triangle ${classes.errMsgicon}`}></i>
					<span className={classes.errMsgText}>{message}</span>
				</div>
			);
		}
		const pushNotifications = (
			<div className={[classes.pushNotification].join(' ')}>
				<h4 className={classes.notifLabel}>
					<FormattedMessage id="title.pushNotifications" />
				</h4>
				<div className={classes.pushNotifContainer}>
					<TwoStateInput
						title={<FormattedMessage id="button.enable" />}
						inputChange={this.onEnablePushNotifHandler}
						name="pushNotif"
						state={this.state.pushNotifEnabled}
						column={this.column}
						disabled={isDisabled}
					/>
					{errMessage}
				</div>
			</div>
		);

		const EmailNotifications = (
			<div className={classes.notification}>
				<h4 className={classes.notifLabel}>
					<FormattedMessage id="title.emailnotifications" />
				</h4>
				<Input
					label={<FormattedMessage id="sensor.notifEmail" defaultMessage="Email" />}
					name={'email'}
					inputChange={this.onSimpleInputChange}
					touched={this.state.email.clicked}
					valid={this.state.email.valid}
					required={this.state.email.required}
					value={this.state.email.value}
					defaultValue="N/A"
					errorMessage={<FormattedMessage id="errorMessage.notifEmail" defaultMessage="Invalid input" />}
					maxLength={this.state.email.validationRules.maxLength}
					column={this.column}
					disabled={!this.state.email.enabled}
				/>
				<TwoStateInput
					title={<FormattedMessage id="button.enable" />}
					inputChange={this.onTwoStateInputChange}
					name="email"
					state={this.state.email.enabled}
					column={this.column}
				/>
				{/*<TwoStateInput title="Two-way regime" />*/}
			</div>
		);

		const SMSNotificationsPart1 = (
			<div className={smsContentClasses1.join(' ')}>
				<div style={{ paddingTop: '40px' }} />
				<h4 className={classes.notifLabel}>
					<FormattedMessage id="title.smsnotifications" />
				</h4>
				<div>
					<label className={classes.creditsState} style={creditStateStyle}>
						{this.props.intl.formatMessage({ id: 'user.global.totalCredits' })}
						{`: ${sms_credit}`}
					</label>
					<Button className="orangeBtn" iconClass="fas fa-plus" onClick={this.topupSmsCredits} />
				</div>
				<PhoneInput
					key="sensor.phonenumber"
					label={
						<div style={{ lineHeight: '18px' }}>
							{this.props.intl.formatMessage({ id: 'sensor.phonenumber' }) + '\n'}
							<small style={{ fontSize: '11px' }}>{'(' + this.props.intl.formatMessage({ id: 'sensor.forsmsnotif' }) + ')'}</small>
						</div>
					}
					name="phone_number"
					value={this.state.phone_number.value}
					disabled={!this.state.phone_number.enabled}
					inputChange={this.onSimpleInputChange}
					column={this.column}
				/>
				<TwoStateInput
					title={<FormattedMessage id="button.enable" />}
					inputChange={this.onTwoStateInputChange}
					name="phone_number"
					state={this.state.phone_number.enabled}
					column={this.column}
				/>
				{/*<TwoStateInput title="Two-way regime" />*/}
				{/*<TwoStateInput
					title={<FormattedMessage id="user.global.autocredit" />}
					className={classes.twoStateInput}
					inputChange={this.onTwoStateInputChange}
					name="autoCreditConsuming"
					state={this.state.autoCreditConsuming.enabled}
					column={this.column}
				/>*/}
			</div>
		);

		const SMSNotificationsPart2 = (
			<div className={smsContentClasses2.join(' ')}>
				<Button icon="close" className={classes.closeButton} onClick={this.backFromStore} />
				<h4 className={classes.notifLabel}>
					<FormattedMessage id="user.global.notifManagment" />
				</h4>
				<div className={classes.smsManContainer}>{this.generateSMSPacks()}</div>
				<div className={classes.finalInfoContainer}>
					<p className={classes.summaryP}>
						<FormattedMessage id="user.global.totalCredits" />
						{`: ${this.state.totalCredits}`}
					</p>
					<span className={`${classes.summaryP} ${classes.totalPriceSummary}`}>
						<span style={{ display: 'flex', justifyContent: 'center' }}>
							<FormattedMessage id="user.global.totalPrice" />
							{`: ${this.state.totalPrice} `}
							<CoinIcon style={{ width: '15px', marginLeft: '5px' }} />
						</span>
						<span className={classes.perSmsInfo}>
							{`(${this.state.perSMS}`}
							<CoinIcon style={{ width: '10px', margin: ' 0 5px 0 2px', fill: 'red' }} />
							<FormattedMessage id="user.global.perSMS" />
							{' )'}
						</span>
					</span>
				</div>
				<div>
					<button className={`orangeBtn ${classes.buyButton}`} onClick={this.purchaseSelectedSMSPackages} disabled={this.state.totalPrice === 0}>
						<FormattedMessage id="user.global.buy" />
					</button>
				</div>
			</div>
		);

		const navButtons = (
			<NavigationButtons
				confirmText={<FormattedMessage id="button.saveSettings" />}
				backButtonHidden
				confirm={this.confirmSettingsHandler}
				confirmBtnDisabled={!inputValidator.checkFormValidity(this.state)}
			/>
		);

		let smsContent = (
			<React.Fragment>
				{SMSNotificationsPart1}
				{SMSNotificationsPart2}
			</React.Fragment>
		);
		if (this.props.smsPurchasingState !== 'INIT') {
			const sms_purchasing = this.props.smsPurchasingState === 'IN_PROGRESS';
			const success = this.props.smsPurchasingState === 'SUCCESS';
			let msg = '';
			switch (this.props.smsPurchasingState) {
				case 'SUCCESS':
					msg = (
						<p>
							<FormattedMessage id="modal.smstopup.success" />
						</p>
					);
					break;
				case 'IN_PROGRESS':
					msg = (
						<p>
							<FormattedMessage id="sensor.proccessing" />
							{'...'}
						</p>
					);
					break;
				default:
					msg = (
						<p>
							<FormattedMessage id="modal.activation.failed" />
						</p>
					);
					break;
			}
			smsContent = (
				<div className={classes.spinnerContainer}>
					<div style={{ display: 'block' }}>
						{msg}
						<Spinner loading={sms_purchasing} resultSuccess={success} />
						<div>
							<button
								className="orangeBtn"
								onClick={this.smsPurchasedModalContinue}
								disabled={sms_purchasing}
								style={{ marginRight: '10px', padding: '10px 0' }}
							>
								<FormattedMessage id="button.OK" defaultMessage="OK" />
							</button>
						</div>
					</div>
				</div>
			);
		}

		let content = <PageSpinner type="rectangles" size="11px" style={{ marginTop: '100px' }} />;
		if (!this.props.isLoading && !this.state.isPushNotifDevicesLoading) {
			content = (
				<div className={`centerContainer ${classes.notifContainer}`}>
					<h2 className={classes.title}>
						<FormattedMessage id="title.globalnotifsettings" defaultMessage="Settings" />
					</h2>
					{pushNotifications}
					{EmailNotifications}
					<div className={classes.notification}>{smsContent}</div>
					{navButtons}
				</div>
			);
		}
		return content;
	}
}

const mapStateToProps = (state) => {
	return {
		isLoading: state.users.isLoading,
		isTokenRefreshing: state.users.tokenRefreshing,
		user: state.users.logged_user,
		smsPurchasingState: state.coins.activationState,
		global_error: state.sensors.network_error,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		fetchNotifications: (entity) => dispatch(fetchUser(entity, 'settings_notifications')),
		performAction: (sensor_id, body) => dispatch(performAction(sensor_id, body)),
		changeActivationState: (state) => dispatch(changeActivationState(state)),
		putUser: (body) => dispatch(putUser(body)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Notifications));
