
import { getValue } from "./utils";
import * as fields from "../../sensorDescriptors/Info/infos"

export function simpleLeakDef(sensor, intl) {
    const valueNumber = getValue(sensor, fields.flood);
    let valueStr;
    if (valueNumber || valueNumber === 0) {
        valueStr = intl.formatMessage({ id: `tooltip.binInput.flood.${valueNumber}` })
    }

    return [
        {
            dataset: "leakage",
            value: valueStr,
            unit: "",
            icon: "flood",
            tooltip: intl.formatMessage({ id: "tooltip.flood" }),
            legend:
                intl.formatMessage({ id: "tooltip.flood" }) + `(${sensor.origName})`,
            chartType: "step",
            subType: "flood",
        },
    ]
}