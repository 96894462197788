import React from 'react';
import TooltipTrigger from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';

import classes from './Tooltip.module.scss';

const Tooltip = ({ children, tooltip, hideArrow, ...props }) => {
	let content = children;
	const containerClasses = [classes['tooltip-container']];
	if (props.containerClass) {
		containerClasses.push(props.containerClass);
	}

	if (tooltip) {
		content = (
			<TooltipTrigger
				placement="top"
				trigger={window.innerWidth <= 1000 ? 'right-click' : 'hover'}
				//followCursor
				//placement="auto"
				delayShow={300}
				{...props}
				tooltip={({ arrowRef, tooltipRef, getArrowProps, getTooltipProps, placement }) => (
					<div
						{...getTooltipProps({
							ref: tooltipRef,
							className: `${containerClasses.join(' ')}`
						})}
					>
						{!hideArrow && (
							<div
								{...getArrowProps({
									ref: arrowRef,
									className: `${classes['tooltip-arrow']}`,
									'data-placement': placement
								})}
							/>
						)}
						{tooltip}
					</div>
				)}
			>
				{({ getTriggerProps, triggerRef }) => (
					<span
						{...getTriggerProps({
							ref: triggerRef,
							className: 'trigger'
						})}
					>
						{children}
					</span>
				)}
			</TooltipTrigger>
		);
	}
	return content;
};

export default Tooltip;
