import React from "react";

import sensors from "./../../../utils/sensorTypes";
import classes from "./Pic.module.scss";

const pic = (props) => {
    let src = null;
    let alt = "";
    let picClass = classes.picture;

    switch (props.type) {
        case sensors.siglink.iot_label:
            //src = ' https://iot-backend-err-pic.s3.eu-central-1.amazonaws.com/Siglink.png'; //process.env.PUBLIC_URL + '/assets/img/Siglink.png';
            src = process.env.PUBLIC_URL + "/assets/img/Siglink.png";
            alt = "Siglink TEMP_HUM";
            break;
        case sensors.wicub.iot_label:
            //src = ' https://iot-backend-err-pic.s3.eu-central-1.amazonaws.com/Wicub2.png'; //process.env.PUBLIC_URL + '/assets/img/Wicub2.png';
            src = process.env.PUBLIC_URL + "/assets/img/Wicub2.png";
            alt = "Wicub 2";
            break;
        case sensors.simpleLeak.iot_label:
            //src = ' https://iot-backend-err-pic.s3.eu-central-1.amazonaws.com/Wicub2.png'; //process.env.PUBLIC_URL + '/assets/img/Wicub2.png';
            src = process.env.PUBLIC_URL + "/assets/img/SimpleLeak3.png";
            alt = "Simple Leak 3.0";
            picClass = classes.simpleLeak;
            break;
        case sensors.ste2.iot_label:
            src = process.env.PUBLIC_URL + "/assets/img/STE2.png";
            alt = "STE 2";
            picClass = classes.ste2;
            break;
        case sensors.siotech_gps.iot_label:
            src = process.env.PUBLIC_URL + "/assets/img/siotech_gps.png";
            alt = "Siotech GPS";
            break;
        case sensors.siotech_gps_industrial.iot_label:
            src = process.env.PUBLIC_URL + "/assets/img/siotech_gps_industrial.png";
            alt = "Siotech GPS";
            break;
        case sensors.siotech_unior_alarm.iot_label:
            src = process.env.PUBLIC_URL + "/assets/img/unior_alarm.png";
            alt = "Siotech GPS";
            picClass = classes.unior;
            break;
        case sensors.siotech_unior_security.iot_label:
            src = process.env.PUBLIC_URL + "/assets/img/unior_security.png";
            alt = "Siotech GPS";
            picClass = classes.unior;
            break;
        default:
            //no defaults
            break;
    }

    return <img src={src} alt={alt} className={picClass} />;
};

export default pic;
