import React, { Component } from "react";
import { connect } from "react-redux";

import Modal from "./../../../../../components/modals/General/General";

import { FormattedMessage } from "react-intl";
import { logOut } from "./../../../../../utils/authWatchDog.js";
import { deleteUser } from "./../../../../../store/actions/userActions.js";
import Spinner from "./../../../../../components/Spinner/SpinnerWithResult/SpinnerWithResult";

import classes from "./DeleteAccount.module.scss";

class deleteAccount extends Component {
    state = {
        isModalOpen: false,
    };

    componentDidUpdate() {
        if (this.props.global_error !== "" && this.state.isModalOpen) {
            this.onUserCancelDeletionHandler(null);
        }
    }

    onUserOpenDeleteModalHandler = (e) => {
        this.setState({ ...this.state, isModalOpen: true });
    };
    onUserCancelDeletionHandler = (e) => {
        this.setState({ ...this.state, isModalOpen: false });
    };
    onUserConfirmDeletionHandler = (e) => {
        this.props.deleteUser();
    };
    onConfirm = (e) => {
        logOut();
    };

    render() {
        let modal = null;
        let closeHandler = this.onUserCancelDeletionHandler;
        if (this.state.isModalOpen) {
            let deleteBtn = {
                className: "btn btn-md btn-danger mr-3",
                action: this.onUserConfirmDeletionHandler,
                isDisabled: false,
                text: <FormattedMessage id="button.delete" defaultMessage="Delete" />,
            };

            let cancelBtn = {
                className: "btn btn-md btn-secondary",
                action: this.onUserCancelDeletionHandler,
                isDisabled: false,
                text: <FormattedMessage id="button.cancel" defaultMessage="Cancel" />,
            };

            let modalContent = (
                <div className={classes.modalText}>
                    <FormattedMessage id="modal.user.delete.text5" />
                </div>
            );
            const isUserEmpty = Object.keys(this.props.user).length === 0;

            if (this.props.isLoading || (!this.props.loading && isUserEmpty)) {
                let text = <FormattedMessage id="sensor.deleting" defaultMessage="Deleting" />;
                if (isUserEmpty) {
                    text = <FormattedMessage id="modal.user.deleted" defaultMessage="Deletet successfully" />;
                }
                modalContent = (
                    <div>
                        {text}
                        <div style={{ margin: "20px 0" }}>
                            <Spinner loading={this.props.isLoading} resultSuccess={isUserEmpty} />
                        </div>
                    </div>
                );
                deleteBtn = {
                    className: "orangeBtn",
                    action: this.onConfirm,
                    isDisabled: this.props.isLoading,
                    text: <FormattedMessage id="button.OK" defaultMessage="OK" />,
                };
                cancelBtn = null;
                closeHandler = this.onConfirm;
            }

            modal = (
                <Modal
                    actionBtn={deleteBtn}
                    cancelBtn={cancelBtn}
                    isOpen={this.state.isModalOpen}
                    close={closeHandler}
                    closeOnEsc={!this.props.isLoading}
                    closeOnOverlayClick={!this.props.isLoading}
                >
                    {modalContent}
                </Modal>
            );
        }
        return (
            <div className={classes.container}>
                <h3 className={`text-danger ${classes.modalheader}`}>
                    <FormattedMessage id="modal.user.delete.header" />
                </h3>
                <div className={classes.textBody}>
                    <h6 className="modalMessage">
                        {/*'- '*/}
                        <i className={`fas fa-exclamation-triangle text-danger ${classes.modalMark}`} />
                        <FormattedMessage id="modal.user.delete.text" />
                    </h6>
                    <h6 className="modalMessage">
                        {/*'- '*/}
                        <i className={`fas fa-exclamation-triangle text-danger ${classes.modalMark}`} />
                        <FormattedMessage id="modal.user.delete.text2" />
                    </h6>
                    <h6 className="modalMessage">
                        {/*'- '*/}
                        <i className={`fas fa-exclamation-triangle text-danger ${classes.modalMark}`} />
                        <FormattedMessage id="modal.user.delete.text3" />
                    </h6>
                    <h6 className="modalMessage">
                        {/*'- '*/}
                        <i className={`fas fa-exclamation-triangle text-danger ${classes.modalMark}`} />
                        <FormattedMessage id="modal.user.delete.text4" />
                    </h6>
                    <button className={`${classes.deleteButton} btn btn-danger`} onClick={this.onUserOpenDeleteModalHandler}>
                        <FormattedMessage id="button.delete" defaultMessage="Delete" />
                    </button>
                </div>
                {modal}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.users.logged_user,
        isLoading: state.users.isLoading,
        global_error: state.sensors.network_error,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        deleteUser: () => dispatch(deleteUser()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(deleteAccount);
