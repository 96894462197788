import React from "react";
import Tooltip from "./../../../Tooltip/Tooltip";

import { injectIntl } from "react-intl";
import CoinIcon from "./../../../icons/Coin/Coin.jsx";

import classes from "./Coin.module.scss";

const coin = props => {
  let coinStyle = {};
  let labelStyle = {};

  let coinClass = classes.coin;
  let coinTextClass = classes.coinText;
  let containerClasses = [classes.coinContainer];

  if (props.coinStyle) {
    coinStyle = props.coinStyle;
  }
  if (props.labelStyle) {
    labelStyle = props.labelStyle;
  }
  if (props.coinClassName) {
    coinClass = props.coinClassName;
  }
  if (props.coinTextClassName) {
    coinTextClass = props.coinTextClassName;
  }
  if (props.className) {
    containerClasses.push(props.className);
  }

  let content = (
    <div className={containerClasses.join(" ")}>
      {/*}
      <h5
        className={coinTextClass}
        style={labelStyle}
      >{`${props.label}${props.value}`}</h5>*/}
      {/*<img
        src={process.env.PUBLIC_URL + '/assets/img/wallet.svg'}
        alt="iot-logo"
        className={classes.wallet}
      />*/}
      <Tooltip tooltip={props.intl.formatMessage({ id: "tooltip.creditsCount" })}>
        <span className={classes.valueCoinContainer}>
          <h5 className={coinTextClass}>{props.value}</h5>
          {/*<h5>Cr.</h5>*/}

          {/*<img
          src={process.env.PUBLIC_URL + '/assets/img/coins.svg'}
          alt="iot-logo"
          className={coinClass}
          style={coinStyle}
        />*/}
          <CoinIcon className={coinClass} style={coinStyle} />
        </span>
      </Tooltip>
    </div>
  );

  if (props.label) {
    content = (
      <div className={containerClasses.join(" ")}>
        <h5
          className={coinTextClass}
          style={labelStyle}
        >{`${props.label}${props.value}`}</h5>
        {/*<img
          src={process.env.PUBLIC_URL + '/assets/img/coins.svg'}
          alt="iot-logo"
          className={coinClass}
          style={coinStyle}
        />*/}
        <CoinIcon className={coinClass} style={coinStyle} />
      </div>
    );
  }

  return content;
};

export default injectIntl(coin);
