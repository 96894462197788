// @flow
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./containers/App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "./store/store";
//import { addLocaleData } from 'react-intl';
//import en from 'react-intl/locale-data/en';
//import cs from 'react-intl/locale-data/cs';
import { setLocale } from "./store/actions/localeActions";
import LocaleProvider from "./components/hoc/LocaleProvider.jsx";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import QuickGraphView from "./containers/pages/QuickGraph/QuickGraph.jsx";
import ReactGA from "react-ga";
import GoogleAnalytics from "./analytics/GoogleAnalytics/GoogleAnalytics";
import { LoadScript } from "@react-google-maps/api";

import { appInit, appUpdate } from "./store/actions/appActions";

const placesLibUrl = ["https://maps.googleapis.com/maps/api/js?key=&libraries=places"];

//addLocaleData(en);
//addLocaleData(cs);

if (localStorage.getItem("lang")) {
    store.dispatch(setLocale(localStorage.getItem("lang")));
} else {
    store.dispatch(setLocale("cs"));
}

ReactGA.initialize("UA-76797582-3");
const root = document.getElementById("root");

const renderDOM = (s) => {
    //"/show/cs/003B9E43-1q7md7k9tqme38"

    ReactDOM.render(
        <Provider store={store}>
            <LocaleProvider>
                <BrowserRouter>
                    <Route path="/" render={() => <GoogleAnalytics pageview={ReactGA.pageview} />} />
                    <Switch>
                        <Route path="/show/:lang/:identifier" render={({ match, history }) => <QuickGraphView history={history} match={match} />} />
                        <Route
                            render={() => {
                                return (
                                    <LoadScript googleMapsApiKey="AIzaSyCEMQ4Bwx8uZnx9tUZXuE5AcNZFzFJCxq8" libraries={placesLibUrl} language={"en"} loadingElement={" "}>
                                        <App store={store} />
                                    </LoadScript>
                                );
                            }}
                        />
                    </Switch>
                </BrowserRouter>
            </LocaleProvider>
        </Provider>,
        root
    );
};

if (root) {
    renderDOM();
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register({
    onSuccess: () => store.dispatch(appInit()),
    onUpdate: (reg) => store.dispatch(appUpdate(reg)),
});
