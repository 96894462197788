import React from 'react';

//TODO: dependecy import
import CircleSpinner from "./SpinnerCircle.module.scss";
import DotCircleSpinner from "./DotCircleSpinner.module.scss";
import RectanglesSpinner from "./SpinnerRectangles.module.scss";

const spinner = (props) => {

    let spinnerClass = null;
    let style = {
        fontSize: "8px"
    };

    switch (props.type) {
        case "dotCircle":
            spinnerClass = DotCircleSpinner.loader;
            break;
        case "rectangles":
            spinnerClass = RectanglesSpinner.loader;
            break;
        default:
            spinnerClass = CircleSpinner.loader;
            break;
    }
    if (props.size) {
        style.fontSize = props.size;
    }
    if(props.style){
        style={
            ...style,
            ...props.style
        };
    }
    switch (props.component) {
        case "td":
            return <td className={spinnerClass} style={style} />
        default:
            return <div className={spinnerClass} style={style} />
    }
}

export default spinner;