// @flow
import React from 'react';

import HeaderDesktop from './Desktop/HeaderDesktop.jsx';
import HeaderMobile from './Mobile/HeaderMobile.jsx';

const Header = ({ lang, btnEnabled, width, coinLabel, coinValue, donateTokens }) => {
	return (
		<div>
			{width > 767 ? (
				<HeaderDesktop
					lang={lang}
					btnEnabled={btnEnabled}
					coinLabel={coinLabel}
					coinValue={coinValue}
					donateTokens={donateTokens}
				/>
			) : (
				<HeaderMobile
					lang={lang}
					btnEnabled={btnEnabled}
					coinLabel={coinLabel}
					coinValue={coinValue}
					donateTokens={donateTokens}
				/>
			)}
		</div>
	);
};

export default Header;
