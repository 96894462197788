import React from 'react';

import UnknownEmoji from './../../../icons/Errors/Unknown.jsx';
import LowCreditsEmoji from './../../../icons/Errors/LowCredits.jsx';
import AlreadyAddedEmoji from './../../../icons/Errors/AlreadyAdded.jsx';
import LowPermEmoji from './../../../icons/Errors/lowPerm.jsx';
import InvalidFieldEmoji from './../../../icons/Errors/InvalidField.jsx';
import InvalidPassword from "./../../../icons/Errors/InvalidPassword.jsx";
import ActivationError from "./../../../icons/Errors/Activation.jsx";

import classes from './ErrorIcon.module.scss';

const ErrorIcon = props => {
	let emojiIcon = null;

	switch (props.type) {
		case 'https.addSensor.insufficientAmmount':
			emojiIcon = <LowCreditsEmoji className={classes.emojiLow} />;
			break;
		case 'https.addSensor.alreadyAdded':
			emojiIcon = <AlreadyAddedEmoji className={classes.emojiAdded} />;
			break;
		case 'https.lowPermissions':
			emojiIcon = <LowPermEmoji className={classes.emojiLowPerm} />;
			break;
		case 'https.invalidField':
			emojiIcon = <InvalidFieldEmoji className={classes.invalidFieldEmoji} />;
			break;
		case 'https.invalidPassword':
			emojiIcon = <InvalidPassword className={classes.invalidPassword} />;
			break;
		case 'https.limitExceeded':
			emojiIcon = <InvalidPassword className={classes.invalidPassword} />;
			break;
		case 'https.activationLimit':
			emojiIcon = <ActivationError className={classes.activationError} />;
			break;
		case 'https.addSensor.sensorTypeMissmatch':
			emojiIcon = emojiIcon = <AlreadyAddedEmoji className={classes.emojiAdded} />;
			break;
		default:
			emojiIcon = <UnknownEmoji className={classes.emoji} />;
			break;
	}
	return emojiIcon;
};

export default ErrorIcon;
