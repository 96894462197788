import React, { useState, useEffect } from "react";

import Toolbar from "./../Toolbar/Toolbar";
import { injectIntl } from "react-intl";

//import classes from "./Player.module.scss";

let interval;
let count = 0;

const Player = (props) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const [isBackDisabled, setIsBackDisabled] = useState(true);
    const [isForwardDisabled, setIsForwardDisabled] = useState(false);

    useEffect(() => {
        return () => {
            clearInterval(interval);
        };
    }, []);

    const onPLayHandler = () => {
        if (props.onPlay && !isPlaying && !isPaused) props.onPlay();
        if (props.onContinue && !isPlaying && isPaused) props.onContinue();

        setIsPlaying(true);
        setIsPaused(false);
        setIsForwardDisabled(false);

        if (isFinite(props.interval) && isFinite(props.count) && props.onIntervalTick) {
            interval = setInterval(() => {
                if (count < props.count) {
                    props.onIntervalTick();
                    count++;
                    if (isBackDisabled && count > 0) setIsBackDisabled(false);
                } else {
                    onStophandler();
                }
            }, props.interval);
        }
    };

    const onPauseHandler = () => {
        setIsPaused(true);
        setIsPlaying(false);
        if (props.onPause) props.onPause();
        clearInterval(interval);
    };

    const onStophandler = () => {
        setIsPaused(false);
        setIsPlaying(false);
        setIsBackDisabled(true);
        setIsForwardDisabled(true);
        if (props.onStop) props.onStop();
        clearInterval(interval);
        count = 0;
    };

    const onForwardHandler = () => {
        count++;
        if (props.onForward) props.onForward();
        if (!isForwardDisabled && count >= props.count) setIsForwardDisabled(true);
        if (isBackDisabled && count > 0) setIsBackDisabled(false);
        //if (count >= props.count) setTimeout(onStophandler, 1000);
    };

    const onBackhandler = () => {
        if (count > 0) count--;
        if (!isBackDisabled && count === 0) setIsBackDisabled(true);
        if (isForwardDisabled && count < props.count) setIsForwardDisabled(false);
        if (props.onBack) props.onBack();
    };

    const config = [
        {
            id: "player.back",
            tip: props.intl.formatMessage({ id: "chart.menu.player.stepBack" }),
            toggable: false,
            icon: "rewind",
            disabled: (!isPlaying && !isPaused) || isBackDisabled,
            handler: onBackhandler,
        },
        {
            id: "player.stop",
            tip: props.intl.formatMessage({ id: "chart.menu.player.stop" }),
            toggable: false,
            icon: "stop",
            handler: onStophandler,
            hidden: !isPlaying && !isPaused,
        },
        {
            id: "player.pause",
            tip: props.intl.formatMessage({ id: "chart.menu.player.pause" }),
            toggable: false,
            icon: "pause",
            handler: onPauseHandler,
            hidden: !isPlaying || isPaused,
        },
        {
            id: "player.play",
            tip: props.intl.formatMessage({ id: "chart.menu.player.play" }),
            toggable: false,
            icon: "play",
            handler: onPLayHandler,
            hidden: isPlaying && !isPaused,
            disabled: props.disabled,
        },
        {
            id: "player.forward",
            tip: props.intl.formatMessage({ id: "chart.menu.player.stepForward" }),
            toggable: false,
            icon: "forward",
            handler: onForwardHandler,
            disabled: (!isPlaying && !isPaused) || isForwardDisabled,
        },
    ];
    return <Toolbar buttons={config} />;
};

export default injectIntl(Player);
