
import { getValue } from "./utils";
import * as fields from "../../sensorDescriptors/Info/infos"

export function siglinkDef(sensor, intl) {
    return [
        {
            dataset: "temperature",
            value: getValue(sensor, fields.temperature),
            unit: "°C",
            icon: "temperature",
            tooltip: intl.formatMessage({ id: "tooltip.temperature" }),
            legend:
                intl.formatMessage({ id: "tooltip.temperature" }) +
                `(${sensor.origName})`,
        },
        {
            dataset: "humidity",
            value: getValue(sensor, fields.humidity),
            unit: "%",
            icon: "humidity",
            tooltip: intl.formatMessage({ id: "tooltip.humidity" }),
            legend:
                intl.formatMessage({ id: "tooltip.humidity" }) + `(${sensor.origName})`,
        },
    ]
}