import React from "react";
import styles from "./HeaderMobile.module.scss";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import LangPanel from "../../../common/LangPanel/LangPanel.jsx";
import { FormattedMessage, injectIntl } from "react-intl";
import CoinPanel from "./../../../common/CoinPanel/CoinPanel.jsx";

class HeaderMobile extends React.Component {
  constructor(props) {
    super(props);

    this._isMounted = false;
  }
  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleSignOut = () => {
    if (this._isMounted) Auth.signOut();
  };

  render() {
    return (
      <div className={styles.header}>
        <div className={styles.headerPanel}>
          <Link to="/">
            <img
              className={`img-fluid ${styles.iotIcon}`}
              src={process.env.PUBLIC_URL + '/assets/img/IoTpool_logo_inv.png'}
              alt="iot-logo"
            />
          </Link>
          <span className={styles.rightPanel}>
            <CoinPanel
              btnEnabled={this.props.btnEnabled}
              donateTokens={this.props.donateTokens}
              coinValue={this.props.coinValue}
            />
            <div className={styles.signoutDiv}>
              {/*<Link to="/">*/}
                <button
                  className="Button__button___1FrBC"
                  onClick={this.handleSignOut}
                >
                  <FormattedMessage
                    id="button.signout"
                    defaultMessage="Sign Out"
                  />
                </button>
              {/*</Link>*/}
              <LangPanel lang={this.props.lang} style={{color: "white", backgroundColor: "transparent"}}/>
            </div>
          </span>
        </div>
      </div>
    );
  }
}

export default injectIntl(HeaderMobile);
