import React from 'react';

import Tooltip from "./../../Tooltip/Tooltip";

const id = props => {
    return (
        <div {...props}>
            <Tooltip tooltip={props.tooltip}>
                <svg width="100%" viewBox="0 0 1.27 1.27">
                    <path fill="none" d="M-1-1h582v402H-1z" />
                    <g>
                        <title>{"Layer 1"}</title>
                        <path fill="none" className="identifier_icon_prefix__fil0" d="M0 .023h1.27v1.27H0z" />
                        <path
                            fill="none"
                            strokeLinejoin="round"
                            strokeLinecap="round"
                            strokeWidth={0.042}
                            stroke="#191919"
                            d="M.18.292h.91c.041 0 .074.033.074.073V.95c0 .04-.033.073-.073.073H.179A.073.073 0 01.106.951V.365c0-.04.033-.073.073-.073z"
                            className="identifier_icon_prefix__fil0 identifier_icon_prefix__str0"
                        />
                        <path
                            fill="#191919"
                            className="identifier_icon_prefix__fil1"
                            d="M.322.432v.452H.254V.432zM.38.432v.452H.346V.432zM.473.432v.452H.405V.432zM.523.432v.452H.497V.432zM.573.432v.452H.547V.432zM.64.432v.452H.597V.432zM.715.432v.452h-.05V.432zM.774.432v.452H.74V.432zM.824.432v.452H.798V.432zM.899.432v.452h-.05V.432zM.957.432v.452H.924V.432zM1.016.432v.452H.982V.432z"
                        />
                    </g>
                </svg>
            </Tooltip>
        </div>
    );
};

export default id;
