// @flow
import { CHANGE_LANG } from './localeActionTypes';

export const changeLang = lang => ({
	type: CHANGE_LANG,
	payload: { lang }
});

export const setLocale = lang => dispatch => {
	localStorage.setItem('lang', lang);
	dispatch(changeLang(lang));
};
