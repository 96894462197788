export const EMAIL_PATTERN =
    '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';
export const PASSWORD_PATTERN = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})";
export const CODE_PATTERN = "\\d{4,16}";
export const PAC_PATTERN = "[0-9a-fA-F]{16}";
export const TOKEN_PATTERN = "[0-9a-zA-Z]{25}";
export const TX_PERIOD_PATTERN = "\\d{1,2}";
export const ID_PATTERN_SIGLINK = "[0-9a-fA-F]{8}";
export const ID_PATTERN_WICUB = "[0-9a-fA-F]{6}";
export const NAME_PATTERN = ".{1,64}";
export const TEMP_PATTERN = "^((N/A)|([-]?\\d+(\\.?\\d+)?))$";
export const HUM_PATTERN = "^((N/A)|([-]?\\d+(\\.?\\d+)?))$";
export const PHONE_NUMBER = "^\\+\\d{0,15}";
export const SERIAL_NUMBER = "\\d{10,}";
export const MAC_ADDRESS = "^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$";
export const NUMBER_ONLY = "[0-9]+";
