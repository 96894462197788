import axios from "axios";


export const API_URL = "https://backend.iotpool.io"; //"https://83zchtbo01.execute-api.eu-central-1.amazonaws.com/Prod";
    //'https://smetak13-proxy.herokuapp.com/https://83zchtbo01.execute-api.eu-central-1.amazonaws.com/Prod/sensors'

const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: { "content-type": "application/json" }
});


export default axiosInstance;