import React from 'react';

import Tooltip from './../../../Tooltip/Tooltip';

const doorOpen = (props) => {
	const disabledColor = "lightgray";
	let doorState = (
		<div {...props}>
			<Tooltip tooltip={props.tooltip}>
				<svg width="100%" viewBox="0 0 45 45">
					<g>
						<path
							fill={props.disabled ? disabledColor :"red"}
							d="M20.377 25.566c.232-.401.201-.917-.086-1.415L12.213 10.16c-.287-.497-.718-.782-1.182-.782s-.894.285-1.181.782L1.771 24.151c-.287.497-.318 1.013-.086 1.414.232.402.694.633 1.268.633H19.11c.574 0 1.035-.231 1.267-.632zm-9.346-1.569a1.048 1.048 0 11-.002-2.096 1.048 1.048 0 01.002 2.096zm.884-3.573h-1.767l-.166-6.514h2.098l-.165 6.514z"
						/>
						<path
							fill={props.disabled ? disabledColor :"#7f3f00"}
							d="M43.104 3.645a.998.998 0 00-.636-.226l-26.001-.003a1 1 0 00-1 1v7.381l2 3.464V5.417h14.635l-7.797 1.519a1 1 0 00-.809.981v27.958h-6.029v-7.677h-2v8.677a1 1 0 001 1h7.029v2.709c0 .299.133.581.364.771a1.007 1.007 0 00.827.21l17.972-3.5a1 1 0 00.809-.981V4.417a.995.995 0 00-.364-.772zM27.865 27.117c-.886 0-1.604-1.238-1.604-2.767s.718-2.767 1.604-2.767c.885 0 1.603 1.238 1.603 2.767s-.718 2.767-1.603 2.767z"
						/>
					</g>
				</svg>
			</Tooltip>
		</div>
	);

	if (props.closed) {
		doorState = (
			<div {...props}>
				<Tooltip tooltip={props.tooltip}>
					<svg width="100%" viewBox="0 0 32 32">
						<g>
							<circle fill={props.disabled ? disabledColor :"#333"} r={0.5} cy={15.5} cx={20.5} />
							<path
								fill={props.disabled ? disabledColor :"#414042"}
								d="M28.5 29H26V5h.5c.28 0 .5-.22.5-.5v-2c0-.28-.22-.5-.5-.5h-21c-.28 0-.5.22-.5.5v2c0 .28.22.5.5.5H6v24H3.5c-.28 0-.5.22-.5.5s.22.5.5.5H8V4.5c0-.28.22-.5.5-.5h15c.28 0 .5.22.5.5V30h4.5c.28 0 .5-.22.5-.5s-.22-.5-.5-.5z"
							/>
							<path fill={props.disabled ? disabledColor :"#7f3f00"} d="M9 30h14V5H9v25zm11.5-16c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5-1.5-.67-1.5-1.5.67-1.5 1.5-1.5z" />
						</g>
					</svg>
				</Tooltip>
			</div>
		);
	}

	return doorState;
};

export default doorOpen;
