import React from 'react';

import Label from './../Label/Label';
import LogRecord from './LogRecord/LogRecord';

import { FormattedMessage } from 'react-intl';
import * as dateFormatter from './../../../utils/dateFunction';

import classes from './Log.module.scss';

const log = props => {
	const dic = {
		leakage: {
			iconClass: 'fas fa-water',
			iconColor: {
				Off: 'grey',
				On: 'rgb(0, 162, 255)'
			},
			borderColor: 'rgb(223, 100, 63)',
			highLighColor: 'rgba(223, 100, 63, 0.256)'
		},
		position: {
			iconClass: 'fas fa-eraser',
			iconColor: {
				Off: 'grey',
				On: 'rgb(221, 168, 33)'
			},
			borderColor: 'rgb(221, 168, 33)',
			highLighColor: 'rgba(221, 168, 33, 0.256)'
		},
		arming: {
			iconClass: 'fas fa-power-off',
			iconColor: {
				Off: 'red',
				On: 'limegreen'
			},
			borderColor: 'rgb(0, 162, 255)',
			highLighColor: 'rgba(0, 162, 255, 0.256)'
		}
	};
	const dic_val = ['Off', 'On'];

	let logsCount = 15;

	if (props.count) logsCount = props.count;

	const uniqueData = [];
	let lastItemType = null;
	let lastItem = null;
	const allTypes = Object.keys(dic);

	props.data.forEach(item => {
		let itemType = getType(item, allTypes);
		if (lastItem) {
			if (lastItemType !== itemType || lastItem[lastItemType] !== item[itemType]) {
				uniqueData.push(item);
				lastItem = item;
			}
		} else {
			uniqueData.push(item);
			lastItemType = itemType;
			lastItem = item;
		}
	});


	let logs = uniqueData.map(log => {
		const actType = getType(log, allTypes);
		if (dic[actType]) {
			return (
				<LogRecord
					key={log.timestamp}
					timestamp={dateFormatter.formateDatetime(log.timestamp, props.lang)}
					description={
						<FormattedMessage
							id={'chart.gantt.log.' + actType + dic_val[+log[actType]]}
							defaulValue="Unknown event"
						/>
					}
					iconClass={dic[actType].iconClass}
					iconColor={dic[actType].iconColor[dic_val[+log[actType]]]}
				//borderColor={dic[log.event].borderColor}
				// highLighColor={dic[log.event].highLighColor}
				/>
			);
		}
		return null;
	});

	if (logs.length > logsCount) {
		logs = logs.slice(logs.length - logsCount - 1, logs.length).reverse();
	}

	//   logs = logs.slice().reverse();

	return (
		<div className={classes.logContainer}>
			<Label showAlarmIcon alarmRaised={false} style={null} text={<FormattedMessage id="chart.last15Events" />} />
			{logs}
		</div>
	);
};


const getType = (item, types) => {

	for (let i = 0; i < types.length; i++) {
		const type = types[i];
		if (item.hasOwnProperty(type)) {
			return type;
		}
	}
	return null;
};

export default log;
