import React from 'react';

import classes from './Label.module.scss';

const label = props => {
	let alarmIcon = null;
	const labelClasses = [classes.labelContainer, classes.noAlarm];

	if (props.alarmRaised && props.showAlarmIcon) {
		alarmIcon = (
			<i
				className={`fas fa-exclamation-triangle`}
				style={{ marginRight: '10px' }} //cursor: 'pointer'
				onClick={props.alarmClick}
			/>
		);
		labelClasses[1] = classes.alarmRaised;
	}

	return (
		<span className={labelClasses.join(' ')}>
			<h1 style={props.style}>
				{alarmIcon}
				{props.text}
			</h1>
		</span>
	);
};

export default label;
