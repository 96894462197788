import React from 'react';

import SiglinkInfo from './Siglink/Siglink';
import WicubInfo from './Wicub2/Wicub2';
import SimpleLeakInfo from './SimpleLeak/SimpleLeak';
import Ste2 from './Ste2/Ste2';
import SiotechGPS from './siotech/SiotechGPS/SiotechGPS';
import SiotechUAlarm from './siotech/unior/SiotechUniorAlarm/SiotechUniorAlarm';
import SiotechUSecurity from './siotech/unior/SiotechUniorSecurity/SiotechUniorSecurity';

import NotAvailable from './../NotAvailable/NotAvailable';

import sensors from './../../../../../utils/sensorTypes';

const info = (props) => {
	let value = props.value;
	let TargetComponent = null;

	switch (props.type) {
		case sensors.siglink.iot_label:
			TargetComponent = SiglinkInfo;
			break;
		case sensors.wicub.iot_label:
			TargetComponent = WicubInfo;
			break;
		case sensors.simpleLeak.iot_label:
			TargetComponent = SimpleLeakInfo;
			break;
		case sensors.ste2.iot_label:
			TargetComponent = Ste2;
			break;
		case sensors.siotech_gps.iot_label:
			TargetComponent = SiotechGPS;
			break;
		case sensors.siotech_unior_alarm.iot_label:
			TargetComponent = SiotechUAlarm;
			break;
		case sensors.siotech_unior_security.iot_label:
			TargetComponent = SiotechUSecurity;
			break;
		default:
			TargetComponent = NotAvailable;
			break;
	}

	return <TargetComponent type={props.infoType} value={value} data={props.data} />;
};

export default info;
