import React from 'react';

import classes from './Signal.module.scss';
import NotAvailable from './../NotAvailable/NotAvailable.jsx';
import SignalIcon from './../../../../icons/Signal/Signal.jsx';

const signal = props => {
	let signl = <NotAvailable />;

	/*
	if (props.value) {
		signl = (
			<img
				src={`/assets/img/${signal_levels[0]}`}
				//src="/assets/img/banner.svg"
				alt={`signal ${props.value}`}
				className={classes.signalIcon}
				data-tip={props.tooltip}
				data-for="descriptorSignal"
			/>
		);
	}
  */

	if (props.value) {
		signl = (
			<SignalIcon
				value={props.value}
				className={classes.signalIcon}
				tooltip={props.tooltip}
			/>
		);
	}

	let content = null;
	if (!props.hidden) {
		content = (
			<span className={props.className}>
				{props.label ? <p className={classes.label}>{props.label} :</p> : null}
				<div style={{ position: 'relative' }}>{signl}</div>
			</span>
		);
	}
	return content;

};

export default signal;
