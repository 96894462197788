import * as appActions from './appActionsTypes';

export const appInit = () => {
	return {
		type: appActions.SW_INIT
	};
};

export const appUpdate = reg => {
	return {
		type: appActions.SW_UPDATE,
		payload: reg
	};
};

export const refuseUpdate = () => {
	return {
		type: appActions.REFUSE_UPDATE
	};
};
