import React, { Component } from 'react';

import Sidebar from './../../../components/layout/Sidebar/Sidebar';

import { withRouter } from 'react-router-dom';

class Menu extends Component {
	constructor(props) {
		super(props);
		let path = props.history.location.pathname;
		path = path.substring(1, path.length);
		path = path === '' ? 'home' : path;
		this.state = {
			select: path
		};
	}

	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			let path = this.props.location.pathname;
			if (path === '/') path = 'home';
			else path = path.substring(1, path.length);

			this.setState({
				...this.state,
				select: path
			});
		}
	}

	onSelect = selection => {
		this.props.history.push('/' + selection);
	};
	render() {
		return <Sidebar onSelect={this.onSelect} select={this.state.select} />;
	}
}

export default withRouter(Menu);
