import React from 'react';

import Tooltip from "./../../Tooltip/Tooltip";

const StateIcon = props => {
	return (
		<div {...props}>
			<Tooltip tooltip={props.tooltip}>
				<svg width="100%" viewBox="0 0 256 256">
					<linearGradient
						gradientUnits="userSpaceOnUse"
						id="prefix__a"
						x1={128}
						x2={128}
						y1={-0.862}
						y2={239.402}
					/>
					<path
						d="M48.7 74.3c0-10 3.2-20.2 9.7-30.5C64.8 33.5 74.3 25 86.6 18.2 99 11.4 113.4 8 129.9 8c15.3 0 28.9 2.8 40.6 8.5 11.7 5.7 20.8 13.3 27.2 23.1 6.4 9.7 9.6 20.3 9.6 31.7 0 9-1.8 16.9-5.5 23.6-3.6 6.8-8 12.6-13 17.5s-14 13.2-27 24.8c-3.6 3.3-6.5 6.2-8.6 8.6-2.2 2.5-3.8 4.8-4.8 6.8-1.1 2.1-1.9 4.1-2.5 6.2-.6 2.1-1.5 5.7-2.6 10.9-2 11-8.3 16.5-18.9 16.5-5.5 0-10.1-1.8-13.9-5.4-3.8-3.6-5.6-8.9-5.6-16 0-8.9 1.4-16.6 4.1-23.1 2.7-6.5 6.4-12.2 10.9-17.1 4.5-4.9 10.7-10.8 18.4-17.5 6.8-5.9 11.7-10.4 14.7-13.4s5.5-6.4 7.6-10.1c2.1-3.7 3.1-7.7 3.1-12 0-8.5-3.1-15.6-9.4-21.4-6.3-5.8-14.4-8.7-24.3-8.7-11.6 0-20.2 2.9-25.7 8.8S94.2 64.8 90.4 76.2C86.8 88.1 80 94.1 70 94.1c-5.9 0-10.9-2.1-15-6.3-4.2-4.2-6.3-8.7-6.3-13.5zM126.1 248c-6.4 0-12.1-2.1-16.9-6.3-4.8-4.2-7.2-10-7.2-17.5 0-6.7 2.3-12.3 7-16.8 4.6-4.5 10.4-6.8 17.1-6.8 6.7 0 12.3 2.3 16.8 6.8s6.8 10.1 6.8 16.8c0 7.4-2.4 13.2-7.1 17.4-4.8 4.3-10.3 6.4-16.5 6.4z"
						fill="grey"
					/>
					<path
						d="M194.5 41.6c-6-9.1-14.6-16.4-25.7-21.7-11.2-5.4-24.3-8.1-38.9-8.1-15.8 0-29.7 3.3-41.4 9.7-11.7 6.4-20.8 14.6-26.8 24.3-6 9.6-9.1 19.2-9.1 28.5 0 .5 0 .9.1 1.4.5-8.5 3.5-17.3 9-26.1 6.1-9.7 15.1-17.9 26.8-24.3 11.7-6.4 25.7-9.7 41.4-9.7 14.7 0 27.8 2.7 38.9 8.1 11 5.3 19.7 12.6 25.7 21.7 5.5 8.4 8.5 17.7 8.9 27.5v-1.7c0-10.6-3-20.5-8.9-29.6zm-30.6 43.9c-2.2 4-5 7.7-8.2 10.9-3.1 3.1-8.1 7.6-14.8 13.6-7.6 6.6-13.7 12.4-18.1 17.2-4.2 4.6-7.7 10-10.2 16-2.5 6-3.8 13.3-3.8 21.6v1.4c.2-7.3 1.5-13.8 3.8-19.2 2.6-6.1 6-11.5 10.2-16 4.4-4.8 10.5-10.6 18.1-17.2 6.8-5.9 11.8-10.5 14.8-13.6 3.2-3.2 6-6.9 8.2-10.9 2.4-4.3 3.6-8.9 3.6-13.9 0-.7 0-1.3-.1-2-.2 4.3-1.4 8.3-3.5 12.1zm-37.8 118.9c-5.8 0-10.5 1.9-14.5 5.7-3.9 3.8-5.8 8.4-5.8 14.1 0 .7 0 1.4.1 2 .4-4.8 2.3-8.9 5.7-12.3 3.9-3.9 8.7-5.7 14.5-5.7 5.7 0 10.3 1.9 14.1 5.7 3.4 3.4 5.3 7.4 5.6 12.3 0-.6.1-1.3.1-2 0-5.7-1.9-10.3-5.7-14.1-3.8-3.8-8.4-5.7-14.1-5.7z"
						fill="#fff"
						opacity={0.2}
					/>
				</svg>
			</Tooltip>
		</div>
	);
};

export default StateIcon;
