import React from 'react';

import { Switch } from '@progress/kendo-react-inputs';
import Tooltip from './../../../Tooltip/Tooltip';

import classes from './Type1.module.scss';

const type1 = (props) => {
	const mainClasses = ['formItem', classes.toggleButtonContainer];
	const buttonClasses = ['UI-input', classes.toggleButton];

	if (props.formStylesDisabled) {
		mainClasses.shift();
		buttonClasses.shift();
	}

	return (
		<div className={mainClasses.join(' ')}>
			<label className={`inputTitle`}>{props.title ? props.title : null}</label>
			<div className={buttonClasses.join(' ')}>
				<Switch onChange={props.click} checked={props.value} />
				<span className={classes.labelContainer}>
					<label className={classes.descrText}>{props.descriptionText}</label>
					{props.tooltip && (
						<Tooltip tooltip={props.tooltip}>
							<i className={['fas fa-info-circle', classes.info].join(' ')}></i>
						</Tooltip>
					)}
				</span>
			</div>
		</div>
	);
};

export default type1;
