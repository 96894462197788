import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import CSSTransition from 'react-transition-group/CSSTransition';

import Sensor from './sensor/Sensor.jsx';
import Spinner from './../Spinner/Spinner.jsx';
import Comparator from './sensor/Comparator/Comparator';
//import CryingEmoji from "./../icons/Errors/lowPerm";

import ChunkDivisor from './ChunkDivisor/ChunkDivisor.jsx';

import classes from './SensorsList.module.scss';
import './SensorListAnimation.scss';

let columns = [
	<FormattedMessage id="sensor.name" defaultMessage="Name" width="20%" tabWidth="26%" mobWidth="40%" />,
	<FormattedMessage id="sensor.state" defaultMessage="State"  tabWidth="20%" />,
	<FormattedMessage id="sensor.type" defaultMessage="Type"  tabWidth="20%" />,
	<FormattedMessage id="sensor.info1" defaultMessage="Info 1" tabWidth="20%" mobWidth="40%" />,
	<FormattedMessage id="sensor.info2" defaultMessage="Info 2"  />,
	//<FormattedMessage id="sensor.delete" defaultMessage="Delete" />,
	<FormattedMessage id="sensor.actions" defaultMessage="Actions" width="180px" tabWidth="10%" mobWidth="20%" />,
];

const sensorsList = (props) => {
	let sensors = props.sensors.map((sensor) => {
		let sensorType = null;
		if (!props.comparatorModeOn) {
			sensorType = sensor.status === 'loading' ? 'loading_sensor' : 'Table_Row';
		}

		let checkedTypes = props.selectedGraphs.filter((item) => item.id === sensor.id).map((item) => item.name);

		return (
			<CSSTransition key={sensor.id} classNames="fade" timeout={1000}>
				<Sensor
					key={sensor.id}
					sensor={sensor}
					type={sensorType}
					className={classes.comparisonMode}
					mobileRegimeOn={props.mobileRegime}
					tabletRegimeOn={props.tabletRegime}
					columns={columns}
					click={(e) => props.onLinkClick(e, `/display/${sensor.id}?type=${sensor.sensor_type}`)}
					onEditClick={(e) => props.onLinkClick(e, `/edit/${sensor.id}?type=${sensor.sensor_type}`)}
					onDeleteClick={(e) => props.onDeleteClick(e, sensor.id, sensor.name, sensor.sensor_type)}
					onActivateClick={(e) => props.onActivateClick(e, sensor.id, sensor.name, sensor.sensor_type)}
					onProtectClick={(e) => props.onProtectClick(e, sensor)}
				>
					<Comparator sensor={sensor} onChange={props.onComparatorChange} disabled={!props.comparatorModeOn} checkedTypes={checkedTypes} />
				</Sensor>
			</CSSTransition>
		);
	});

	let cols = columns;
	let actWidth = 'width';

	if (props.mobileRegime) {
		//cols = cols.filter((val, index) => index < 1 || index > 4);
		cols = [cols[0], cols[3], cols[5]];
		actWidth = 'mobWidth';
	} else if (props.tabletRegime) {
		cols = cols.filter((val, index) => index !== 4);
		actWidth = 'tabWidth';
	}

	if (sensors.length === 0) {
		if (props.comparatorModeOn) {
			sensors = (
				<CSSTransition classNames="fade" timeout={1000}>
					<div className={classes.noSensorRow}>
						<div className={classes.noSensorColumn}>
							<FormattedMessage id="sensor.noSensor" />
							{/* <CryingEmoji className={classes.cryingEmoji} /> */}
						</div>
					</div>
				</CSSTransition>
			);
		} else {
			sensors = (
				<CSSTransition classNames="fade" timeout={1000}>
					<tr className={classes.noSensorRow}>
						<td className={classes.noSensorColumn}>
							<FormattedMessage id="sensor.noSensor" />
							{/* <CryingEmoji className={classes.cryingEmoji} /> */}
						</td>
					</tr>
				</CSSTransition>
			);
		}
	}

	let headerClass = null;
	let tableContent = <TransitionGroup component="tbody">{sensors}</TransitionGroup>;
	let tableContentClass = null;
	let content = null;
	let buttonClass = null; //classes.buttonRottateBack;
	let buttonTextClass = null; //classes.textAddSensor;
	let buttonText = <FormattedMessage id="button.addDevice" defaultMessage="Add device" />;
	let tableStyle = { position: 'relative', top: '0', right: '0' };

	if (props.comparatorModeOn) {
		tableStyle = null;
		headerClass = classes.hiddenTableHeader;
		tableContent = null;
		content = (
			<div className={classes.cmprNodes} style={{ marginBottom: '27px' }}>
				{<TransitionGroup component="div">{sensors}</TransitionGroup>}
			</div>
		);
	}

	if (props.loading) {
		if (props.comparatorModeOn) {
			content = (
				<div className={classes.noSensorRow}>
					<div className={classes.noSensorColumn}>
						<Spinner type="rectangles" size="11px" style={{ marginTop: '100px' }} />
					</div>
				</div>
			);
		} else {
			tableContent = (
				<tbody>
					<tr className={classes.noSensorRow}>
						<td className={classes.noSensorColumn}>
							<Spinner type="rectangles" size="11px" style={{ marginTop: '100px' }} />
						</td>
					</tr>
				</tbody>
			);
		}
	}

	const tbl = (
		<React.Fragment>
			{!props.comparatorModeOn ? (
				<table className={`${classes.table} ${tableContentClass}`}>
					<tbody className={headerClass}>
						<tr className={classes.tableRow}>
							{cols.map((column) => (
								<td key={column.props.id} className={classes.tableCol} style={{ minWidth: column.props[actWidth] }}>
									{column}
								</td>
							))}
						</tr>
					</tbody>
					{tableContent}
				</table>
			) : null}
			{content}
		</React.Fragment>
	);

	return (
		<div className={`centerContainer`} style={{ position: 'relative' }}>
			<h2 className={classes.title}>
				<FormattedMessage id="title.sensorsList" defaultMessage="Sensors List" />
			</h2>

			<span className={classes.buttonWrapper} style={tableStyle}>
				<span className={classes.searchBox}>
					<input
						value={props.filterValue}
						className={classes.searchInput}
						type="search"
						name="searchInput"
						placeholder={props.intl.formatMessage({ id: 'select.sensor' })}
						onChange={props.onSearchInputChange}
						required
					/>
					<button className={classes.searchButton} href="#">
						<i className="fas fa-search" />
					</button>
				</span>
				{!props.comparatorModeOn ? (
					<Link to="/add">
						<button className={`btn btn-md btn-primary ${buttonClass}`}>
							<div className={buttonTextClass}>{buttonText}</div>
						</button>
					</Link>
				) : null}
			</span>
			{props.comparatorModeOn ? (
				<button
					className={[classes.compareBtn].join(' ')}
					onClick={props.onCompareClick}
					disabled={props.selectedGraphs && props.selectedGraphs.length === 0}
				>
					<i className="fas fa-chart-area" />
				</button>
			) : null}
			{tbl}
			<ChunkDivisor
				data={props.chunks}
				chunksCount={props.chunksCount}
				actChunk={props.selectedChunk}
				selectedSize={props.selectedChunkSize}
				chunkChanged={props.chunkChanged}
				onChunkSizeChanged={props.onChunkSizeChanged}
			/>
			{/*props.loading ? <Spinner type="rectangles" size="11px" style={{ marginTop: "100px" }} /> : null*/}
			{/*<Menu/>*/}
		</div>
	);
};

export default injectIntl(sensorsList);
