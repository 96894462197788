import React from "react";
import MySignIn from "./SignIn/SignIn.jsx";
import MySignUp from "./SignUp/SignUp.jsx";
import MyConfirmSignUp from "./ConfirmCode/ConfirmCode.jsx";
import MyForgotPassword from "./ForgotPassword/ForgotPassword.jsx";
import MyRequireNewPassword from "./RequireNewPassword/RequireNewPassword.jsx";
import { ConfirmSignIn, VerifyContact } from "aws-amplify-react";

const auth = [
  <MySignIn />,
  <ConfirmSignIn />,
  <VerifyContact />,
  <MySignUp />,
  <MyConfirmSignUp />,
  <MyForgotPassword />,
  <MyRequireNewPassword />
];

export default auth;
