import * as sensorActions from './sensorActions.js';
import * as localeActions from './localeActions.js';
import * as coinsActions from './coinsActions.js';
import * as userActions from './userActions.js';
import * as appActions from "./appActions.js";


const actions = {
	...sensorActions,
	...localeActions,
	...coinsActions,
	...userActions,
	...appActions
};

export default actions;
