import React from 'react';

import Tooltip from "./../../Tooltip/Tooltip";

const ToTopIcon = props => {
	return (
		<div {...props}>
			<Tooltip tooltip={props.tooltip}>
				<svg width="100%" viewBox="0 0 100 100">
					<path fill="none" d="M-1-1h582v402H-1z" />
					<g>
						<path d="M95.277 25.523s-3.1-14.8-16.5-20c-6.3-2.5-17.8-3.7-29.1-3.7s-22.8 1.2-29.1 3.6c-13.4 5.2-16.5 20-16.5 20-2 8.4-2.7 16.9-2.6 24.4-.1 7.6.5 16.2 2.6 24.5 0 0 3.1 14.8 16.5 20 6.3 2.4 17.8 3.6 29.1 3.7 11.3-.1 22.8-1.2 29.1-3.7 13.4-5.2 16.5-20 16.5-20 2-8.4 2.7-16.9 2.6-24.4.1-7.6-.5-16.1-2.6-24.4zm-25.7 22.6c-1.4 1.4-3.6 1.4-5 0l-11.4-11.5v32c0 1.9-1.6 3.5-3.5 3.5s-3.5-1.6-3.5-3.5v-32l-11.5 11.5c-.7.7-1.6 1-2.5 1s-1.8-.3-2.5-1c-1.4-1.4-1.4-3.6 0-4.9l17.5-17.5c1.4-1.4 3.6-1.4 5 0l17.4 17.4c1.4 1.4 1.4 3.6 0 5z" />
					</g>
				</svg>
			</Tooltip>
		</div>
	);
};

export default ToTopIcon;
