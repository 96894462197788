import React, { Component } from 'react';

import Auth from '@aws-amplify/auth';
import { FormattedMessage, injectIntl } from 'react-intl';
import LangPanel from './../../../components/common/LangPanel/LangPanel.jsx';
import Input from './../../../components/UI/Inputs/AuthInputs/SimpleInput/SimpleInput.jsx';
import SubmitButton from './../../../components/UI/Inputs/AuthInputs/SubmitInput/SubmitInput.jsx';
import ConfirmModal from './../../../components/modals/AuthModals/ConfirmModal/ConfirmModal.jsx';
import { CODE_PATTERN, PASSWORD_PATTERN } from './../../../utils/InputPatterns.js';
import Spinner from './../../../components/Spinner/Spinner.jsx';

import classes from './../Auth.module.scss';

const texts = {
	resetPassword: <FormattedMessage id="login.resetPsw" defaultMessage="Password reset" />,
	resend: <FormattedMessage id="login.newCode" defaultMessage="Send a new code" />,
	haveAccount: <FormattedMessage id="login.haveAccount" defaultMessage="I already have an account" />,
	setPassword: <FormattedMessage id="login.setPsw" defaultMessage="Password set" />
};

const inputs = [
	{
		key: 'login.code.tip',
		name: 'code',
		type: 'text',
		tipId: 'login.code.tip',
		emptyId: 'login.code'
	},
	{
		key: 'login.password',
		name: 'password',
		type: 'password',
		tipId: 'login.password.tip',
		emptyId: 'login.newPassword'
	},
	{
		key: 'login.passwordAgain',
		name: 'passwordAgain',
		type: 'password',
		tipId: 'login.newPasswordAgain.tip',
		emptyId: 'login.newPasswordAgain'
	}
];

class RequiredNewPassword extends Component {

	constructor(props) {
		super(props);
		this.state = {
			code: {
				value: '',
				isValid: false,
				pattern: CODE_PATTERN,
				touched: false
			},
			password: {
				value: '',
				isValid: false,
				touched: false,
				pattern: PASSWORD_PATTERN
			},
			passwordAgain: {
				value: '',
				isValid: false,
				touched: false,
				pattern: '.'
			},
			btnEnabled: false,
			loading: false,
			error: null,
			codeModalIsOpen: false,
			successModalIsOpen: false,
			forcePswResetRegime: props.authState.force
		};
	}




	initState = () => {

		this.setState({
			...this.state,
			code: {
				...this.state.code,
				value: '',
				isValid: false,
				touched: false
			},
			password: {
				...this.state.password,
				value: '',
				isValid: false,
				touched: false
			},
			passwordAgain: {
				...this.state.passwordAgain,
				value: '',
				isValid: false,
				touched: false
			},
			btnEnabled: false,
			loading: false,
			error: null,
			codeModalIsOpen: false,
			successModalIsOpen: false
		});
	};

	onSubmit = e => {
		e.preventDefault();

		this.setState({ ...this.state, loading: true });

		if (this.state.forcePswResetRegime) {
			Auth.completeNewPassword(
				this.props.authData.user,
				this.state.password.value,
				{
					email: this.props.authData.email,
				}
			).then(user => {
				// at this time the user is logged in if no MFA required
				this.setState({
					...this.state,
					successModalIsOpen: true,
					loading: false
				});
			}).catch(err => {
				this.setState({ ...this.state, error: err, loading: false });
			});
		} else {
			Auth.forgotPasswordSubmit(this.props.authData.email, this.state.code.value, this.state.password.value)
				.then(result => {
					this.setState({
						...this.state,
						successModalIsOpen: true,
						loading: false
					});
				})
				.catch(err => {
					this.setState({ ...this.state, error: err, loading: false });
				});
		}
	};

	onInputChange = e => {
		const name = e.target.name;
		const value = e.target.value;
		let isValid = this.isValid(this.state[name].pattern, value);

		let updatedState = {
			...this.state,
			[name]: {
				...this.state[name],
				value: value,
				isValid: isValid,
				touched: true
			},
			error: null
		};
		updatedState.passwordAgain.isValid =
			updatedState.passwordAgain.value === updatedState.password.value && updatedState.passwordAgain.isValid;

		updatedState.btnEnabled =
			(updatedState.code.isValid || this.state.forcePswResetRegime) &&
			updatedState.password.isValid &&
			updatedState.password.value === updatedState.passwordAgain.value;

		this.setState(updatedState);
	};

	onResendCodeHandler = () => {
		this.setState({ ...this.state, loading: true });

		Auth.forgotPassword(this.props.authData.email)
			.then(result => {
				this.setState({ ...this.state, codeModalIsOpen: true, loading: false });
			})
			.catch(err => {
				this.setState({
					...this.state,
					error: err,
					loading: false
				});
			});
	};
	onHaveAccountHandler = () => {
		this.initState();
		this.props.onStateChange('signIn', {});
	};

	isValid = (pattern, value) => {
		const regex = new RegExp(pattern);
		return regex.test(value);
	};

	onCodeModalCloseHandler = () => {
		this.setState({ ...this.state, codeModalIsOpen: false });
	};

	onSuccessModalCloseHandler = e => {
		this.initState();
		this.props.onStateChange('signIn', {});
	};

	render() {
		let reqNewPassword = null;
		let button = (
			<Spinner type="dotCircle" size="8px" style={{ margin: '40px 0', marginLeft: '30%', float: 'left' }} />
		);
		let error = null;
		let codeModal = null;
		let successModal = null;
		let resendLink = (
			<div>
				<p onClick={this.onResendCodeHandler}>{texts.resend}</p>
			</div>
		);

		if (this.state.error) {
			error = (
				<h4>
					<FormattedMessage
						id={'error.code.' + this.state.error.code}
						defaultMessage={this.state.error.message}
					/>
				</h4>
			);
		}

		if (this.state.codeModalIsOpen) {
			codeModal = (
				<ConfirmModal
					isOpen={this.state.codeModalIsOpen}
					close={this.onCodeModalCloseHandler}
					continue={this.onCodeModalCloseHandler}
					confirmMsg={this.props.intl.formatMessage({
						id: 'login.code.sent'
					})}
					envelopeAnimation
				/>
			);
		}

		if (this.state.successModalIsOpen) {
			successModal = (
				<ConfirmModal
					isOpen={this.state.successModalIsOpen}
					close={this.onSuccessModalCloseHandler}
					continue={this.onSuccessModalCloseHandler}
					confirmMsg={this.props.intl.formatMessage({
						id: 'login.code.updated'
					})}
				/>
			);
		}

		if (!this.state.loading) {
			button = (
				<SubmitButton
					value={this.props.intl.formatMessage({ id: 'login.confirm' })}
					isEnabled={this.state.btnEnabled}
					name="button"
					onSubmit={this.onSubmit}
				/>
			);
		}
		const jsxInputs = inputs.map(input => {
			return (
				<Input
					key={input.key}
					type={input.type}
					name={input.name}
					emptyText={this.props.intl.formatMessage({ id: input.emptyId })}
					tooltip={this.props.intl.formatMessage({ id: input.tipId })}
					isValid={this.state[input.name].isValid}
					touched={this.state[input.name].touched}
					onInputChange={this.onInputChange}
				/>
			);
		});
		if (this.state.forcePswResetRegime) {
			resendLink = (
				<div>
					<p onClick={this.onHaveAccountHandler}>{texts.haveAccount}</p>
				</div>
			);
			jsxInputs.shift();
		}

		if (this.props.authState === 'requireNewPassword') {
			reqNewPassword = (
				<div className={classes.outter}>
					<div className={classes.middle}>
						<div className={classes.container}>
							<section className={classes.content}>
								<span className={classes.topPanel}>
									<img
										className={`img-fluid ${classes.iotIcon}`}
										src={process.env.PUBLIC_URL + '/assets/img/IoTpool_logo.png'}
										alt="iot-logo"
									/>
									<LangPanel className={classes.langPanel} />
								</span>
								<form action="">
									<h1>{this.state.forcePswResetRegime? texts.setPassword : texts.resetPassword}</h1>
									{error}
									{jsxInputs}
									{button}
									{resendLink}
								</form>
							</section>
						</div>
					</div>
				</div>
			);
		}
		return (
			<div>
				{reqNewPassword}
				{codeModal}
				{successModal}
			</div>
		);
	}
}

export default injectIntl(RequiredNewPassword);
