import React from 'react';

import classes from './FormattedLabel.module.scss';

const formattedLabel = props => {
	const mainClasses = [classes.container, props.className];
	const secClasses = [classes.additionalInfo, props.addClassName];

	let addInfo = null;
	if (props.additionalInfo) {
		addInfo = (
			<small className={secClasses.join(' ')} style={props.addStyle}>
				{props.additionalInfo}
			</small>
		);
	}

	return (
		<div className={mainClasses.join(' ')} style={props.style}>
			{props.mainText + '\n'}
			{addInfo}
		</div>
	);
};

export default formattedLabel;
