import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changeTopupState, topupTokens } from './../../../store/actions/coinsActions.js';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';

import HeaderDesktop from './../../../components/layout/Header/Header.jsx';

import { refuseUpdate, appUpdate } from './../../../store/actions/appActions.js';
import { TOKEN_PATTERN } from './../../../utils/InputPatterns.js';
import DonationModal from './../../../components/modals//modalTopUp/ModalTopUp.jsx';

class Header extends Component {
	updateToastActive = false
	state = {
		isModalOpen: false,
		token: {
			isValid: false,
			value: ['', '', '', '', ''],
			validationRules: {
				pattern: TOKEN_PATTERN,
			},
		},
	};


	componentDidUpdate() {
		if (this.props.global_error !== '' && this.state.isModalOpen) {
			this.onCloseModalHandle();
		}
		//if (this.props.isServiceWorkerInitialized) this.showAppInitToast();
		//console.log("props-x", this.props);
		if (this.props.isServiceWorkerUpdated && !this.props.updateRefused && !this.updateToastActive) this.showAppUpdateToast();
	}

	donateCoinsHandler = () => {
		this.setState({ ...this.state, isModalOpen: true });
	};

	showAppInitToast = () => {
		toast.success('Page has been saved for offline use', {
			position: toast.POSITION.TOP_CENTER,
			autoClose: 15000,
		});
	};

	showAppUpdateToast = () => {
		toast.success(
			<div>
				<FormattedMessage id="serviceWorker.appUpdate" defaultMessage="There is a new version available." />
				<button className="orangeBtn" onClick={this.updateServiceWorker}>
					<FormattedMessage id="button.update" defaultMessage="Update" />
				</button>
			</div>,
			{
				position: toast.POSITION.TOP_CENTER,
				autoClose: false,
				onOpen: () => this.updateToastActive = true,
				onClose: () => {
					this.updateToastActive = false;
					this.props.refuseUpdate();
				},
			}
		);
	};

	updateServiceWorker = () => {
		const registrationWaiting = this.props.serviceWorkerRegistration.waiting;
		if (registrationWaiting) {
			registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
			registrationWaiting.addEventListener('statechange', (e) => {
				if (e.target.state === 'activated') {
					window.location.reload();
				}
			});
		}
	};

	onCloseModalHandle = () => {
		this.setState({
			...this.state,
			isModalOpen: false,
			token: {
				...this.state.token,
				isValid: false,
				value: ['', '', '', '', ''],
			},
		});
		this.props.changeTopupState('INIT');
	};
	handleActivation = () => {
		this.props.topupTokens(this.state.token.value.join(''));
	};

	onModalValueChangedHandle = (e, newEvt) => {
		const value = newEvt.value.join('');
		const regex = new RegExp(this.state.token.validationRules.pattern);
		const isValid = regex.test(value);

		this.setState({
			...this.state,
			token: {
				...this.state.token,
				isValid: isValid,
				value: newEvt.value,
			},
		});
	};

	render() {
		return (
			<React.Fragment>
				<HeaderDesktop
					lang={this.props.lang}
					width={this.props.width}
					coinLabel="Tokens: "
					coinValue={this.props.coins}
					btnEnabled
					donateTokens={this.donateCoinsHandler}
				/>
				<DonationModal
					isOpen={this.state.isModalOpen}
					close={this.onCloseModalHandle}
					activate={this.handleActivation}
					continue={this.onCloseModalHandle}
					state={this.props.activationState}
					value={this.state.token.value}
					onValueChanged={this.onModalValueChangedHandle}
					isDisabled={!this.state.token.isValid}
				/>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		coins: state.coins.totalCoins,
		activationState: state.coins.topupState,
		global_error: state.sensors.network_error,
		isServiceWorkerInitialized: state.app.serviceWorkerInitialized,
		isServiceWorkerUpdated: state.app.serviceWorkerUpdated,
		serviceWorkerRegistration: state.app.serviceWorkerRegistration,
		updateRefused: state.app.updateRefused,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		changeTopupState: () => dispatch(changeTopupState()),
		topupTokens: (token) => dispatch(topupTokens(token)),
		refuseUpdate: () => dispatch(refuseUpdate()),
		update: (reg) => dispatch(appUpdate(reg)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
