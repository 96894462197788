import React, { Component } from "react";
import { GoogleMap, Circle, Autocomplete, Polyline, Marker } from "@react-google-maps/api";

import { FormattedMessage, injectIntl } from "react-intl";

import ChartToolbar from "./../../../components/charts/Toolbar/Toolbar";
import Spinner from "./../../../components/Spinner/Spinner.jsx";
import Input from "./../../../components/UI/Inputs/Input/Input";
import Limits from "./../../../components/UI/Inputs/LimitsInput/LimitsInput";
import Exporter from "./../../../components/charts/Export/Exporter";
import Player from "./../../../components/charts/Player/Player";

import * as patterns from "./../../../utils/InputPatterns.js";
import * as inputValidator from "./../../../utils/inputsValidator";
import * as chartExports from "./../../../utils/chartExports.js";
import * as timeFormatter from "./../../../utils/dateFunction";

import classes from "./Map.module.scss";

class Map extends Component {
    constructor(props) {
        super(props);
        this.interval = null;
        this.mapMarkers = [];
        this.markersToReply = [];
        this.polylinePath = null;
        this.initPosChanged = false;
        this.autocomplete = null;
        this.center = { lat: 50.08804, lng: 14.42076 };
        if (this.props.initAreaCenter && this.props.initAreaCenter.hasOwnProperty("lat") && this.props.initAreaCenter.hasOwnProperty("lng")) {
            this.center = this.props.initAreaCenter;
        }
        this.pathCoordinates = [];
        this.zoom = 13;
        this.geocoder = null;
        this.inputRef = React.createRef();
        this.alarm = {
            center: null,
            area: null,
        };
        this.bounds = null;
        this.isValid = null;
        this.areaCenter = null;
        this.areaRadius = null;
        this.state = {
            map: null,
            mapVisible: true,
            address: {
                required: true,
                valid: false,
                value: "",
                type: "basic",
                clicked: false,
                active: false,
                validationRules: {
                    pattern: patterns.NAME_PATTERN,
                    maxLength: 64,
                },
            },
            area: {
                value: {
                    low: "N/A",
                    high: "N/A",
                },
                clicked: false,
                valid: [false, false],
                required: true,
                type: "limits",
                active: false,
                validationRules: {
                    pattern: patterns.TEMP_PATTERN,
                    maxLength: 8,
                    interval: { low: 0.05, high: 1500 },
                },
            },
            isModalOpen: {
                csv: false,
                xlsx: false,
            },
            exportName: "gps_tracker_export",
            exportDecPlaces: "",
            exportDecSeparator: ".",
            exportDelimiter: ";",
            exportFailed: false,
            exporting: false,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.path !== prevProps.path && this.state.map) {
            this.pathCoordinates = [];
            this.mapMarkers.forEach((marker) => {
                window.google.maps.event.clearInstanceListeners(marker);
                marker.setMap(null);
            });
            this.mapMarkers = [];

            for (let i = 0; i < this.props.path.length; i++) {
                const record = this.props.path[i];
                const lat = record.gps_coordinates.lat;
                const lng = record.gps_coordinates.lng;
                this.pathCoordinates.push({ lat: lat, lng: lng });
                const position = new window.google.maps.LatLng(lat, lng);
                this.bounds.extend(position);
                let flagIcon;
                let labelText = `${i + 1}`;
                if (i === 0) {
                    flagIcon = {
                        path: "M 0,0 -1,-2 V -43 H 1 V -2 z M 1,-40 H 30 V -20 H 1 z",
                        fillColor: "#2f2",
                        fillOpacity: 1,
                        strokeColor: "#555",
                        strokeWeight: 2,
                        scale: 0.7,
                    };
                    labelText = {
                        text: `${i + 1}`,
                        color: "#000",
                        backgroundColor: "#FFF",
                        fontSize: "20px",
                        fontWeight: "bold",
                    };
                } else if (i === this.props.path.length - 1) {
                    /*flagIcon = {
                        path: "M 0,0 -1,-2 V -43 H 1 V -2 z M 1,-40 H 30 V -20 H 1 z",
                        fillColor: "#f00",
                        fillOpacity: 1,
                        strokeColor: "#555",
                        strokeWeight: 2,
                        scale: 0.7,
                    };*/
                    flagIcon = {
                        url: "https://iot-backend-err-pic.s3.eu-central-1.amazonaws.com/gps_target_icon.png",
                        size: new window.google.maps.Size(50, 50),
                        scaledSize: new window.google.maps.Size(50, 50),
                        labelOrigin: new window.google.maps.Point(25, 38),
                    };
                    /*labelText = {
                        text: `${i + 1}`,
                        color: "#000",
                        backgroundColor: "#FFF",
                        fontSize: "20px",
                        fontWeight: "bold",
                    };*/
                }

                const marker = new window.google.maps.Marker({
                    position: { lat: lat, lng: lng },
                    label: labelText,
                    labelAnchor: new window.google.maps.Point(0, 0),
                    map: this.state.map,
                    icon: flagIcon,
                });

                const location = this.props.intl.formatMessage({ id: "chart.gps.location" }) + ": ";
                const timeName = this.props.intl.formatMessage({ id: "chart.gps.time" }) + ": ";
                const time = timeFormatter.formateDatetime(record.timestamp, this.props.lang);

                const infowindow = new window.google.maps.InfoWindow({
                    content: `<span>${location}${Math.round(lat * 1000000) / 1000000},${Math.round(lng * 1000000) / 1000000}<br/>${timeName}${time}</span>`,
                });
                window.google.maps.event.addListener(marker, "click", () => {
                    this.state.map.setZoom(13);
                    this.state.map.panTo(marker.getPosition());
                    infowindow.open(this.state.map, marker);
                });
                this.mapMarkers.push(marker);
            }
            this.state.map.fitBounds(this.bounds);
        }
    }

    onLoad = (autocomplete) => {
        this.autocomplete = autocomplete;
    };

    onPlaceChanged = () => {
        if (this.autocomplete !== null) {
            const selected_Addr = this.inputRef.current.value;
            let loc = this.autocomplete.getPlace();

            if (loc.geometry && loc.geometry.location) {
                loc = loc.geometry.location;

                if (this.alarm.center && this.alarm.area) {
                    this.alarm.center.setPosition({ lat: loc.lat(), lng: loc.lng() });
                    if (!this.alarm.center.getVisible()) this.alarm.center.setVisible(true);

                    const radValue = +this.state.area.value.low;
                    if (radValue >= +this.state.area.validationRules.interval.low && radValue <= +this.state.area.validationRules.interval.high) {
                        this.alarm.area.setVisible(true);
                        this.alarm.area.setRadius(+radValue * 1000);
                    }
                }

                this.center = { lat: loc.lat(), lng: loc.lng() };
                const addrState = inputValidator.recomputeState(this.state.address, selected_Addr);
                const updatedState = {
                    ...this.state,
                    address: addrState,
                };
                const isValid = inputValidator.checkFormValidity(updatedState);
                this.onAreaCenterChanged({ lat: loc.lat(), lng: loc.lng() });
                this.onValidityChanged(isValid);
                this.setState(updatedState);
            }
        }
    };

    onInputChangedHandler = (e, newEvt) => {
        let stateName = newEvt === undefined ? e.target.name.toLowerCase() : newEvt.name;
        let value = newEvt === undefined ? e.target.value : newEvt.value;

        let updatedState = inputValidator.recomputeState(this.state[stateName], value);

        if (stateName === "address") {
            updatedState.valid = false;
            this.alarm.area.setVisible(false);
            this.alarm.center.setVisible(false);
        } else if (stateName === "area" && this.alarm.area) {
            if (this.state.address.valid) {
                this.alarm.area.setVisible(true);
                if (+value.low >= +this.state.area.validationRules.interval.low && +value.low <= +this.state.area.validationRules.interval.high) {
                    this.alarm.area.setRadius(+value.low * 1000);
                } else {
                    this.alarm.area.setVisible(false);
                }
            }
        }

        updatedState = {
            ...this.state,
            [stateName]: updatedState,
        };

        const isValid = inputValidator.checkFormValidity(updatedState);
        this.onValidityChanged(isValid);
        this.setState(updatedState);
    };

    actualizeArea = () => {
        if (window.google) {
            this.bounds = new window.google.maps.LatLngBounds(null);
            this.bounds.union(this.alarm.area.getBounds());
            this.state.map.fitBounds(this.bounds);
            this.state.map.panToBounds(this.bounds);
        }
    };

    onAreaCenterChanged = (center) => {
        if (this.props.onAreaCenterChanged) {
            if (this.alarm.center) {
                if (!this.areaCenter || this.areaCenter.lat !== center.lat || this.areaCenter.lng !== center.lng) {
                    this.props.onAreaCenterChanged(center);
                    this.areaCenter = { ...center };
                }
            }
        }
    };
    onAreaRadiusChanged = (rad) => {
        if (this.props.onAreaRadiusChanged) {
            if (this.areaRadius !== rad) {
                this.props.onAreaRadiusChanged({
                    radius: rad,
                });
                this.areaRadius = rad;
            }
        }
    };
    onValidityChanged = (valid) => {
        if (this.props.onValidityChanged) {
            if (this.isValid !== valid) {
                this.props.onValidityChanged(valid);
                this.isValid = valid;
            }
        }
    };

    saveXLSX = () => {
        this.setState({
            ...this.state,
            isModalOpen: {
                xlsx: true,
                csv: false,
            },
        });
    };
    saveCSV = () => {
        this.setState({
            ...this.state,
            isModalOpen: {
                xlsx: false,
                csv: true,
            },
        });
    };

    onExportModalCloseHandler = () => {
        this.setState({
            ...this.state,
            isModalOpen: {
                csv: false,
                xlsx: false,
            },
            exportName: "gps_tracker_export",
            exportDecPlaces: "",
            exportDecSeparator: ".",
            exportDelimiter: ";",
            exportFailed: false,
        });
    };

    onExportSettingHandler = (e) => {
        const name = e.target.name;
        let value = e.target.value;
        let stateName = null;

        switch (name) {
            case "decimalPlaces":
                stateName = "exportDecPlaces";
                break;
            case "decimalSeparator":
                stateName = "exportDecSeparator";
                break;
            case "delimiter":
                stateName = "exportDelimiter";
                break;
            default:
                stateName = "exportName";
                break;
        }

        this.setState({
            ...this.state,
            [stateName]: value,
        });
    };

    onExportClikHandler = () => {
        const type = this.state.isModalOpen.csv ? "csv" : "xlsx";
        let data = [
            {
                timestamp: null,
                latitude: null,
                longitude: null,
            },
        ];
        if (this.props.path.length > 0) {
            data = this.props.path.map((rec) => {
                return {
                    timestamp: new Date(rec.timestamp * 1000),
                    latitude: rec.gps_coordinates.lat,
                    longitude: rec.gps_coordinates.lng,
                };
            });
        }
        this.setState({
            ...this.state,
            exporting: true,
            exportFailed: false,
            isModalOpen: {
                csv: false,
                xlsx: false,
            },
        });

        if (type === "csv") {
            const config = {
                name: this.state.exportName,
                decimalPlaces: this.state.exportDecPlaces,
                delimiter: this.state.exportDelimiter,
                decimalSeparator: this.state.exportDecSeparator,
                mergeData: false,
            };

            if (!config.name) config.name = "gps_tracker_export";
            if (config.decimalPlaces && isNaN(+config.decimalPlaces)) config.decimalPlaces = undefined;
            if (!config.delimiter) config.delimiter = ";";
            if (!config.decimalSeparator) config.decimalSeparator = ".";

            setTimeout(() => {
                try {
                    chartExports.saveCsv({ data }, config);
                    this.setState({ ...this.state, exporting: false });
                } catch (err) {
                    this.setState({
                        ...this.state,
                        exportFailed: true,
                        exporting: false,
                    });
                }
            }, 100);
        } else if (type === "xlsx") {
            const config = {
                name: this.state.exportName,
                decimalPlaces: this.state.exportDecPlaces,
                mergeData: false,
            };

            if (!config.name) config.name = "gps_tracker_export";
            if (config.decimalPlaces && isNaN(+config.decimalPlaces)) config.decimalPlaces = undefined;

            setTimeout(() => {
                try {
                    chartExports.saveXlsx({ data }, config);
                    this.setState({ ...this.state, exporting: false });
                } catch (err) {
                    this.setState({
                        ...this.state,
                        exportFailed: true,
                        exporting: false,
                    });
                }
            }, 100);
        }
    };

    onAreaCenterPositionChanged = () => {
        if (this.alarm.center) {
            const pos = this.alarm.center.getPosition();
            this.state.map.panTo({ lat: pos.lat(), lng: pos.lng() });
            this.geocoder.geocode(
                {
                    location: { lat: pos.lat(), lng: pos.lng() },
                },
                (result, error) => {
                    let addrState = null;
                    if (error !== "OK" || result.length === 0) {
                        addrState = inputValidator.recomputeState(this.state.address, `Lat: ${pos.lat()}, Lng: ${pos.lng()}`);
                    } else {
                        addrState = inputValidator.recomputeState(this.state.address, result[0].formatted_address);
                    }
                    const updatedState = {
                        ...this.state,
                        address: addrState,
                    };

                    const isValid = inputValidator.checkFormValidity(updatedState);
                    this.onAreaCenterChanged({ lat: pos.lat(), lng: pos.lng() });
                    this.onValidityChanged(isValid);
                    this.setState(updatedState);
                }
            );
        }
    };

    zoomToLastPosition = () => {
        if (this.props.path && this.props.path.length > 0 && this.state.map) {
            const point = this.props.path[this.props.path.length - 1].gps_coordinates;
            this.state.map.setZoom(18);
            this.state.map.panTo(point);
        }
    };

    onPlayHandler = () => {
        this.markersToReply = this.mapMarkers.concat([]);
        this.markersToReply.forEach((marker) => marker.setMap(null));
        this.polylinePath.setPath([]);
        this.markersToReply = this.markersToReply.reverse();
    };
    onStopHandler = () => {
        this.markersToReply = [];
        this.bounds = new window.google.maps.LatLngBounds(null);
        const pathCoordinates = [];

        this.mapMarkers.forEach((marker) => {
            marker.setMap(this.state.map);
            const position = marker.getPosition();
            pathCoordinates.push({ lat: position.lat(), lng: position.lng() });
            this.bounds.extend(position);
        });

        this.state.map.fitBounds(this.bounds);
        this.state.map.panToBounds(this.bounds);
        this.polylinePath.setPath(pathCoordinates);
    };
    onTickHandler = (dircetion = 1) => {
        const actMarker = this.markersToReply.pop();
        if (actMarker) {
            const actPosition = actMarker.getPosition();
            this.polylinePath.getPath().push(actPosition);
            actMarker.setMap(this.state.map);
            this.state.map.setZoom(13);
            this.state.map.panTo(actPosition);
        }
    };
    onBackHandler = () => {
        const actPath = this.polylinePath.getPath();
        const position = actPath.pop();
        const actMarker = this.mapMarkers.find((m) => m.getPosition().equals(position));
        if (actMarker) {
            actMarker.setMap(null);
            this.markersToReply.push(actMarker);
            this.state.map.panTo(actPath.getAt(actPath.length - 1));
        }
    };
    onForwardHandler = () => {
        this.onTickHandler();
    };

    render() {
        let path = null;
        //let pathMarkers = null;
        let spinner = null;
        let toolbar = null;
        let autocomplete = null;
        let radiusInput = null;
        let googleMaps = null;
        let containerStyle = null;
        let exportModal = null;

        let containerClasses = [classes.mapContainer, classes.loaded, classes.mapContainerNoBootstrap];
        if (this.props.bootstrapFormatted) {
            containerClasses = ["UI-input", classes.mapContainer, classes.loaded];
        }

        if ((this.props.loading || !this.state.map) && this.props.mode === "showPath") {
            containerClasses = [classes.mapContainer];
            spinner = (
                <div className={classes.loaderContainer} style={this.props.loaderStyle}>
                    <p>
                        <FormattedMessage id="chart.loading" defaultMessage="Loading..." />
                    </p>
                    <Spinner type="circle" />
                </div>
            );
        } else if (this.state.exporting) {
            containerClasses = [classes.mapContainer];
            spinner = (
                <div className={classes.loaderContainer} style={this.props.loaderStyle}>
                    <p>
                        <FormattedMessage id="chart.exporting" defaultMessage="Exporting..." />
                    </p>
                    <Spinner type="circle" />
                </div>
            );
        } else if (this.state.exportFailed) {
            containerClasses = [classes.mapContainer];
            spinner = (
                <div className={classes.exportFailed}>
                    <div style={{ color: "red" }}>
                        <FormattedMessage id="chart.export.failed" defaultMessage="Export failed" />
                        {/*<p>{this.state.exportFailedMsg}</p>*/}
                        <div className={classes.exportFailedButton}>
                            <button className="btn btn-danger" onClick={this.onExportModalCloseHandler}>
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            );
        }

        if (this.state.isModalOpen.xlsx || this.state.isModalOpen.csv) {
            containerClasses = [classes.mapContainer];
            const type = this.state.isModalOpen.csv ? "csv" : "xlsx";

            exportModal = (
                <Exporter
                    type={type}
                    containerClassName={classes.exportContainer}
                    nameValue={this.state.exportName}
                    delimiterValue={this.state.exportDelimiter}
                    decimalSeparatorValue={this.state.exportDecSeparator}
                    decimalPlacesValue={this.state.exportDecPlaces}
                    onChange={this.onExportSettingHandler}
                    onClose={this.onExportModalCloseHandler}
                    onExportClick={this.onExportClikHandler}
                />
            );
        }

        if (this.props.className) containerClasses.push(this.props.className);
        if (this.props.style) containerStyle = this.props.style;

        if (!this.props.toolbarDisabled) {
            const toolbarConfig = [
                {
                    id: "export.gps.pdf",
                    type: "exportPdf",
                    tip: this.props.intl.formatMessage({ id: "chart.menu.pdf" }),
                    toggable: false,
                    icon: "pdf",
                    hidden: true,
                    //handler: this.savePdf,
                },
                {
                    id: "export.gps.csv",
                    type: "exportCsv",
                    tip: this.props.intl.formatMessage({ id: "chart.menu.csv" }),
                    toggable: false,
                    icon: "csv",
                    handler: this.saveCSV,
                },
                {
                    id: "export.gps.xlsx",
                    type: "exportXlsx",
                    tip: this.props.intl.formatMessage({ id: "chart.menu.xlsx" }),
                    toggable: false,
                    icon: "xls",
                    handler: this.saveXLSX,
                },
                {
                    id: "export.gps.locateCurrPos",
                    type: "locateCurrentPossition",
                    tip: this.props.intl.formatMessage({ id: "chart.menu.ZoomToLastPosition" }),
                    toggable: false,
                    imageUrl: process.env.PUBLIC_URL + "/assets/img/target_location.svg",
                    handler: this.zoomToLastPosition,
                    disabled: !this.props.path || this.props.path.length === 0 || !this.state.map,
                },
            ];

            toolbar = (
                <div className={classes.panelMenu}>
                    <ChartToolbar buttons={toolbarConfig} />
                    <Player
                        interval={1000}
                        count={this.props.path.length}
                        onIntervalTick={this.onTickHandler}
                        onPlay={this.onPlayHandler}
                        onStop={this.onStopHandler}
                        onForward={this.onForwardHandler}
                        onBack={this.onBackHandler}
                        disabled={!this.props.path || this.props.path.length === 0 || !this.state.map}
                    />
                </div>
            );
        }

        if (this.props.path && this.props.path.length > 0 && this.state.map) {
            /*let i = 0;
            this.pathCoordinates = [];

            pathMarkers = this.props.path.map((rec) => {
                const lat = rec.gps_coordinates.lat;
                const lng = rec.gps_coordinates.lng;
                i++;
                this.pathCoordinates.push({ lat: lat, lng: lng });
                const position = new window.google.maps.LatLng(lat, lng);
                this.bounds.extend(position);
                //console.log({ lat: lat, lng: lng });

                //bounds.union(circle.getBounds());
                return (
                    <Marker
                        key={`${lat}-${lng}`}
                        position={{ lat: lat, lng: lng }}
                        label={`${i}`}
                        onLoad={(marker) => {
                            const location = this.props.intl.formatMessage({ id: "chart.gps.location" }) + ": ";
                            const timeName = this.props.intl.formatMessage({ id: "chart.gps.time" }) + ": ";
                            const time = timeFormatter.formateDatetime(rec.timestamp, this.props.lang);

                            const infowindow = new window.google.maps.InfoWindow({
                                content: `<span>${location}${Math.round(lat * 1000000) / 1000000},${
                                    Math.round(lng * 1000000) / 1000000
                                }<br/>${timeName}${time}</span>`,
                            });

                            //this.mapMarkers.push(marker);
                            window.google.maps.event.addListener(marker, "click", () => {
                                this.state.map.setZoom(13);
                                this.state.map.panTo(marker.getPosition());
                                infowindow.open(this.state.map, marker);
                            });
                        }}
                    />
                );
            });

            this.state.map.fitBounds(this.bounds);*/
            path = (
                <Polyline
                    path={this.pathCoordinates}
                    onLoad={(path) => (this.polylinePath = path)}
                    options={{
                        strokeColor: "#FF0000",
                        strokeOpacity: 1.0,
                        strokeWeight: 2,
                        geodesic: true,
                        icons: [
                            {
                                icon: {
                                    path: "M 0,0 5,15 -5,15 0,0 z",
                                    fillColor: "red",
                                    fillOpacity: 1.0,
                                    strokeColor: "red",
                                    scale: 0.7,
                                    strokeWeight: 1,
                                },
                                offset: "100%",
                                repeat: "10%",
                            },
                        ],
                    }}
                />
            );
        }

        if (this.props.mode === "restrictArea") {
            autocomplete = (
                <Autocomplete onLoad={this.onLoad} onPlaceChanged={this.onPlaceChanged}>
                    <Input
                        label={<FormattedMessage id="sensor.address" defaultMessage="Address" />}
                        name="address"
                        value={this.state.address.value}
                        inputChange={this.onInputChangedHandler}
                        touched={this.state.address.clicked}
                        valid={this.state.address.valid}
                        required={this.state.address.required}
                        inputRef={this.inputRef}
                        errorMessage={<FormattedMessage id="errorMessage.address" defaultMessage="Invalid input" />}
                        maxLength={256}
                    />
                </Autocomplete>
            );
            radiusInput = (
                <Limits
                    key="sensor.radius"
                    label={this.props.intl.formatMessage({ id: "sensor.radius" })}
                    labelsDisabled
                    inputsCount={1}
                    touched={this.state.area.clicked}
                    valid={this.state.area.valid}
                    required={this.state.area.required}
                    units="km"
                    name="area"
                    maxLength={this.state.area.validationRules.maxLength}
                    inputChange={this.onInputChangedHandler}
                    value={this.state.area.value}
                    limits={this.state.area.validationRules.interval}
                    className={classes.radiusInput}
                    errorMessage={this.props.intl.formatMessage({
                        id: "errorMessage.radius",
                    })}
                />
            );
        }

        if (this.state.mapVisible) {
            const alarmMarker = (
                <Marker
                    key={`X-area`}
                    draggable={this.props.mode === "restrictArea"}
                    position={this.props.initAreaCenter ? this.props.initAreaCenter : this.center}
                    label={"X"}
                    visible={false}
                    onLoad={(marker) => {
                        this.alarm.center = marker;
                        if (this.props.initAreaCenter) {
                            this.alarm.center.setVisible(true);
                            this.onAreaCenterPositionChanged();
                        }
                    }}
                    onPositionChanged={() => {
                        if (this.alarm.center) {
                            const pos = this.alarm.center.getPosition();
                            this.alarm.area.setCenter({ lat: pos.lat(), lng: pos.lng() });
                        }
                    }}
                    onDragEnd={this.onAreaCenterPositionChanged}
                />
            );
            const area = (
                <Circle
                    center={this.center}
                    radius={0}
                    editable={this.props.mode === "restrictArea"}
                    visible={false}
                    onCenterChanged={() => {
                        if (this.alarm.area && this.alarm.center) {
                            const newCenter = this.alarm.area.getCenter();
                            const centerPosition = this.alarm.center.getPosition();
                            if (newCenter.lat() !== centerPosition.lat() || newCenter.lng() !== centerPosition.lng()) {
                                this.alarm.center.setPosition({ lat: newCenter.lat(), lng: newCenter.lng() });
                                this.onAreaCenterPositionChanged();
                            }
                        }
                    }}
                    onLoad={(area) => {
                        this.alarm.area = area;
                        if (this.props.initAreaRadius) {
                            this.alarm.area.setVisible(true);
                            this.alarm.area.setRadius(this.props.initAreaRadius);
                        }
                    }}
                    onRadiusChanged={() => {
                        if (this.alarm.area) {
                            const value = Math.round((this.alarm.area.getRadius() / 1000) * 10) / 10;
                            let addressState = inputValidator.recomputeState(this.state.area, { low: value, high: "N/A" });
                            if (this.alarm.center.getVisible()) {
                                this.actualizeArea();
                            }
                            this.onAreaRadiusChanged(value);

                            if (+addressState.value.low !== +this.state.area.value.low) {
                                const updatedState = {
                                    ...this.state,
                                    area: addressState,
                                };
                                const isValid = inputValidator.checkFormValidity(updatedState);
                                this.onValidityChanged(isValid);
                                this.setState(updatedState);
                            }
                        }
                    }}
                    options={{
                        strokeColor: "#FF0000",
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: "#FF0000",
                        fillOpacity: 0.35,
                        zIndex: 1,
                    }}
                />
            );
            googleMaps = (
                <GoogleMap
                    id="goole_map_id"
                    mapContainerClassName={containerClasses.join(" ")}
                    mapContainerStyle={containerStyle}
                    center={this.center}
                    zoom={this.zoom}
                    onUnmount={() => {
                        if (this.alarm.area && this.alarm.center) {
                            this.alarm.area.setMap(null);
                            this.alarm.center.setMap(null);
                            this.alarm.center.setVisible(false);
                            this.alarm.area.setVisible(false);
                        }
                    }}
                    onLoad={(map) => {
                        this.setState({ ...this.state, map: map });
                        this.bounds = new window.google.maps.LatLngBounds();
                        this.geocoder = new window.google.maps.Geocoder();
                        setTimeout(() => {
                            if (this.isValid) {
                                this.alarm.center.setPosition(this.areaCenter);
                                this.alarm.area.setCenter(this.areaCenter);
                                this.alarm.area.setRadius(this.areaRadius * 1000);
                                this.alarm.center.setVisible(true);
                                this.alarm.area.setVisible(true);
                            }
                        }, 0);
                    }}
                >
                    {alarmMarker}
                    {area}
                    {/*pathMarkers*/}
                    {path}
                </GoogleMap>
            );
            if (this.props.bootstrapFormatted) {
                googleMaps = (
                    <div className="formItem">
                        <label className={`inputTitle`}>{""}</label>
                        {googleMaps}
                    </div>
                );
            }
        }

        return (
            <div>
                {toolbar}
                <div style={{ position: "relative" }}>
                    {autocomplete}
                    {radiusInput}
                    {googleMaps}
                    {spinner}
                    {exportModal}
                </div>
            </div>
        );
    }
}

export default injectIntl(Map);
