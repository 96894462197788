import React from "react";

import Temperature from "./Temperature/Temperature";
import Humidity from "./Humidity/Humidity";
import Pressure from "./Pressure/Pressure";
import Iaq from "./Iaq/Iaq";
import Flood from "./Flood/Flood";
import Binary from "./Binary/Binary";
import Gps from "./Gps/Gps";
import Smoke from "./Smoke/Smoke";
import DoorOpen from "./DoorOpen/DoorOpen";
import Motion from "./Motion/Motion";
import NA from "./../NA/NA";

const unit = (props) => {
    switch (props.type) {
        case "temperature":
            return <Temperature {...props} />;
        case "humidity":
            return <Humidity {...props} />;
        case "pressure":
            return <Pressure {...props} />;
        case "iaq":
            return <Iaq {...props} />;
        case "flood":
            return <Flood {...props} />;
        case "binary":
            return <Binary {...props} />;
        case "gps":
            return <Gps {...props} />;
        case "smoke":
            return <Smoke {...props} />;
        case "doorOpen":
            return <DoorOpen {...props} />;
        case "motion":
            return <Motion {...props} />;
        default:
            return <NA {...props} />;
    }
};

export default unit;
