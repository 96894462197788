// @flow
import React, { Component } from "react";
import Sensor from "./../../../../../components/sensorsList/sensor/Sensor";

import Battery from "./../../../../../components/sensorsList/sensor/sensorDescriptors/battery/Battery.jsx";
import Signal from "./../../../../../components/sensorsList/sensor/sensorDescriptors/signal/Signal.jsx";
import Text from "./../../../../../components/sensorsList/sensor/sensorDescriptors/text/Text.jsx";
import ActionButton from "./../../../../../components/sensorsList/sensor/sensorActionButton/ActionButton.jsx";
import LogRecord from "./../../../../../components/charts/Log/LogRecord/LogRecord";
import Label from "./../../../../../components/charts/Label/Label";
import Map from "./../../../../charts/Map/Map";

import { connect } from "react-redux";
import { fetchSensorMeasurements } from "../../../../../store/actions/sensorActions";
import { FormattedMessage, injectIntl } from "react-intl";
import { isEnabled } from "./../../../../../utils/inputsValidator";

import LineChart from "./../../../../charts/Line/Line.jsx";
import DatePanel from "./../../../../../components/charts/ChartsPanel/ChartsPanel.jsx";
import { formateDate, formateDatetime } from "./../../../../../utils/dateFunction.js";
import * as dateFormatter from "./../../../../../utils/dateFunction";

import sensors from "./../../../../../utils/sensorTypes";
import classes from "./../../SensorDetail.module.scss";
import siotechClasses from "./SiotechGPS.module.scss";

const oneDayTimestamp = 24 * 60 * 60;
const oneWeekTimestamp = oneDayTimestamp * 7;
const oneMonthTimestamp = oneDayTimestamp * 30;

const labels = {
    name: <FormattedMessage id="sensor.name" defaultMessage="Name" />,
    id: <FormattedMessage id="sensor.id" defaultMessage="ID" />,
    battery: <FormattedMessage id="sensor.battery" defaultMessage="Battery" />,
    signal: <FormattedMessage id="sensor.signal" defaultMessage="Signal" />,
    pac: <FormattedMessage id="sensor.pac" defaultMessage="PAC" />,
    lastMsg: <FormattedMessage id="sensor.lastMessage" defaultMessage="Last Message" />,
    state: <FormattedMessage id="sensor.state" defaultMessage="State" />,
    stateExpired: <FormattedMessage id="tooltip.state.expired" defaultMessage="Expired" />,
    stateActive: <FormattedMessage id="tooltip.state.active" defaultMessage="Active" />,
    exp: <FormattedMessage id="sensor.expirationDate" defaultMessage="Expiration Date" />,
    type: <FormattedMessage id="sensor.type" defaultMessage="Type" />,
};
const logDic = {
    timeout: {
        iconClass: "fas fa-history",
        iconColor: "orange",
        tooltip: "tooltip.msgType.timeout",
    },
    service: {
        iconClass: "fas fa-tools",
        iconColor: "rgb(0, 162, 255)",
        tooltip: "tooltip.msgType.service",
    },
    gps: {
        iconClass: "fas fa-map-marker",
        iconColor: "red",
        tooltip: "tooltip.msgType.gps",
    },
};

class SiotechGPS extends Component {
    constructor(props) {
        super(props);
        const dateFromX = new Date();
        const dateToX = new Date();
        let startPeriod = oneWeekTimestamp;
        let multiplier = 1;


        dateFromX.setHours(0);
        dateFromX.setMinutes(0);
        dateFromX.setSeconds(0);

        this.lockAxis = false;
        this.frstLoad = true;

        if (props.startPeriod) {
            switch (props.startPeriod) {
                case "day":
                    startPeriod = oneDayTimestamp;
                    break;
                case "month":
                    startPeriod = oneMonthTimestamp;
                    break;
                default:
                    startPeriod = oneWeekTimestamp;
                    break;
            }
        }

        if (props.multiplier && typeof props.multiplier === "number") {
            multiplier = props.multiplier;
        }
        this.state = {
            dateFrom: new Date(dateFromX.getTime() - startPeriod * multiplier * 1000),
            dateTo: dateToX,
            selectedPeriod: "",
            isFilteringEnabled: true,
        };

        if (this.props.externalLoading) {
            this.state.dateFrom = new Date(this.props.from * 1000);
            this.state.dateTo = new Date(this.props.to * 1000);
            this.lockAxis = this.props.lockXAxis;
        }
    }

    componentDidMount() {
        const id = this.props.id;
        const type = this.props.type;
        const timestampFrom = this.state.dateFrom.getTime() / 1000;
        const timestampTo = this.state.dateTo.getTime() / 1000;

        if (!this.props.externalLoading) this.props.fetchSensorMeasurements(id, type, timestampFrom, timestampTo, 15, { msg_type: "gps" });
    }

    componentDidUpdate(prevProps) {
        const meas = this.props.sensorMeasurements;

        const cond = this.frstLoad && meas !== prevProps.sensorMeasurements &&
            this.props.sensor && meas[this.props.sensor.id] && meas[this.props.sensor.id].length > 0 &&
            new Date(meas[this.props.sensor.id][0].timestamp * 1000).getDate() !== this.state.dateFrom.getDate();
        
        if(cond){
            this.setState({dateFrom: new Date(meas[this.props.sensor.id][0].timestamp * 1000)});
        }
    }



    getCurrentTimestamp = () => {
        const currentTimestamp = Math.round(new Date().getTime() / 1000);
        return currentTimestamp;
    };

    handlePeriodSelect = (e) => {
        switch (e.value) {
            case "last24hours":
                this.setState({
                    ...this.state,
                    dateFrom: new Date((this.getCurrentTimestamp() - oneDayTimestamp) * 1000),
                    dateTo: new Date(),
                    selectedPeriod: "last24hours",
                    isFilteringEnabled: true,
                });
                break;
            case "last7days":
                this.setState({
                    ...this.state,
                    dateFrom: new Date((this.getCurrentTimestamp() - oneWeekTimestamp) * 1000),
                    dateTo: new Date(),
                    selectedPeriod: "last7days",
                    isFilteringEnabled: true,
                });
                break;
            case "last30days":
                this.setState({
                    ...this.state,
                    dateFrom: new Date((this.getCurrentTimestamp() - oneMonthTimestamp) * 1000),
                    dateTo: new Date(),
                    selectedPeriod: "last30days",
                    isFilteringEnabled: true,
                });
                break;

            default:
                this.setState({ ...this.state, selectedPeriod: "", isFilteringEnabled: false });
                break;
        }
    };

    handleDateFromChange = (date) => {
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);

        let enableFiltering = true;
        if (!date) enableFiltering = false;
        this.setState({ ...this.state, dateFrom: date, selectedPeriod: "", isFilteringEnabled: enableFiltering });
    };

    handleDateToChange = (date) => {
        date.setHours(23);
        date.setMinutes(59);
        date.setSeconds(59);

        if (date < this.state.dateFrom) {
            date = this.state.dateFrom;
        }
        let enableFiltering = true;
        if (!date) enableFiltering = false;
        this.setState({ ...this.state, dateTo: date, selectedPeriod: "", isFilteringEnabled: enableFiltering });
    };

    handleMeasurementsFilter = () => {
        this.lockAxis = true;
        this.frstLoad = false;

        if (this.state.dateFrom && this.state.dateTo) {
            const { id, type } = this.props;
            const dateFrom = Math.round(this.state.dateFrom.getTime() / 1000);
            const dateTo = Math.ceil(this.state.dateTo.getTime() / (1000 * 60 * 60 * 24)) * 60 * 60 * 24;

            if (!this.props.externalLoading) {
                this.props.fetchSensorMeasurements(id, type, dateFrom, dateTo);
            }
        }
    };

    onEditHandler = () => {
        this.props.history.push(`/edit/${this.props.sensor.id}?type=${this.props.sensor.sensor_type}`);
    };

    renderDocument() { }
    render() {
        const { dateFrom, dateTo, selectedPeriod } = this.state;
        let { lang, sensor, sensorMeasurements } = this.props;

        sensorMeasurements = sensorMeasurements[sensor.id] ? sensorMeasurements[sensor.id] : [];

        let graphLoading = this.props.isGraphLoading;

        if (this.props.externalLoading) {
            sensorMeasurements = this.props.externalData;
            graphLoading = this.props.isExternalDataLoading;
        }
        const pathCoordinates = sensorMeasurements.filter((d) => d.msg_type === "gps");

        const editButton = <ActionButton type="Edit" click={this.onEditHandler} tooltip={this.props.intl.formatMessage({ id: "tooltip.edit" })} />;

        const sensorOverView = (
            <div id="sensorOverview" className={classes.sensorOV}>
                <Sensor className={classes.sensor} type="overview">
                    <Text className={classes.descriptor} label={labels.name} value={sensor.name} />
                    <Text className={classes.descriptor} label={labels.id} value={sensor.id} />
                    <Battery className={classes.descriptor} label={labels.battery} value={sensor.battery} />
                    <Signal className={classes.descriptor} label={labels.signal} value={sensor.signal} />
                    <Text className={classes.descriptor} label={labels.pac} value={sensor.pac} />
                    <Text className={classes.descriptor} label={labels.type} value={sensors.siotech_gps.pretty_label} />
                    <Text className={classes.descriptor} label={labels.state} value={sensor.state === "ACTIVE" ? labels.stateActive : labels.stateExpired} />
                    <Text className={classes.descriptor} label={labels.exp} value={formateDate(sensor.expiration, lang)} />
                    <Text className={classes.descriptor} label={labels.lastMsg} value={formateDatetime(sensor.last_msg_time, lang)} />
                </Sensor>
            </div>
        );

        const datePanel = (
            <DatePanel
                lang={lang}
                selectedPeriod={selectedPeriod}
                dateFrom={dateFrom}
                dateTo={dateTo}
                onSelect={this.handlePeriodSelect}
                onDateFromChange={this.handleDateFromChange}
                onDateToChange={this.handleDateToChange}
                onFilterButtonClick={this.handleMeasurementsFilter}
                filterButtonDisabled={!this.state.isFilteringEnabled}
            />
        );

        let h1Style = null;
        let initCenter = null;
        let initRadius = null;
        if (sensor.alarm_raised) {
            h1Style = { color: "red" };
            if (sensor.alarms && sensor.alarms.length === 1) {
                initCenter = sensor.alarms[0].center;
                initRadius = sensor.alarms[0].radius;
            }
        }
        const map = (
            <div style={{ position: "relative", marginBottom: "75px" }}>
                <div style={{ marginBottom: "25px" }}>
                    <Label showAlarmIcon alarmRaised={sensor.alarm_raised} text={<FormattedMessage id="chart.receivedGPSCoordinates" />} style={h1Style} />
                </div>
                <Map
                    path={pathCoordinates}
                    loading={graphLoading}
                    mode="showPath"
                    lang={this.props.lang}
                    initAreaCenter={initCenter}
                    initAreaRadius={initRadius}
                />
            </div>
        );

        const temperature = (
            <LineChart
                id="tempChart"
                label={this.props.intl.formatMessage({ id: "chart.temperature" })}
                data={sensorMeasurements}
                sensor={sensor}
                from={dateFrom}
                to={dateTo}
                activeOnTap
                mapping={[
                    {
                        id: sensor.id,
                        pretty_name: `${sensor.id}_` + this.props.intl.formatMessage({ id: "chart.temperature" }),
                        mode: "default",
                        data: "temperature",
                        time: "timestamp",
                        units: "°C",
                        legend: this.props.intl.formatMessage({
                            id: "chart.temperature",
                        }),
                        color: "rgb(255, 115, 0)",
                    },
                ]}
                commonTimeAxis="timestamp"
                loading={graphLoading}
                lockXAxis={false}
            />
        );

        let i = 1;
        const log = (
            <div style={{ position: "relative" }}>
                <Label showAlarmIcon alarmRaised={false} text={<FormattedMessage id="chart.gpsMsgLogs" />} />
                <div className={siotechClasses.logsContainer}>
                    {[...sensorMeasurements]
                        .map((sm) => {
                            let description = "";
                            let label = null;
                            switch (sm.msg_type) {
                                case "gps":
                                    description = `Lat: ${Math.round(sm.gps_coordinates.lat * 100000) / 100000}, Lng: ${
                                        Math.round(sm.gps_coordinates.lng * 100000) / 100000
                                        }`;
                                    label = `${i}`;
                                    i++;
                                    break;
                                case "service":
                                    description = this.props.intl.formatMessage({ id: "chart.gps.receivedSerisData" });
                                    break;
                                default:
                                    description = this.props.intl.formatMessage({ id: "chart.gps.noSignal" });
                                    break;
                            }

                            return (
                                <LogRecord
                                    key={String(sm.timestamp)}
                                    timestamp={dateFormatter.formateDatetime(sm.timestamp, this.props.lang)}
                                    description={description}
                                    iconTooltip={
                                        this.props.intl.formatMessage({ id: "tooltip.msgType" }) +
                                        ": " +
                                        this.props.intl.formatMessage({ id: logDic[sm.msg_type].tooltip })
                                    }
                                    iconStyle={{ cursor: "help" }}
                                    iconClass={logDic[sm.msg_type].iconClass}
                                    iconColor={logDic[sm.msg_type].iconColor}
                                    iconLabelstyle={{ top: "40%" }}
                                    iconLabel={label}
                                />
                            );
                        })
                        .reverse()}
                </div>
            </div>
        );

        if (!sensor) return null;
        else
            return (
                <div>
                    <span className={classes.title}>
                        <h2>{sensor.name}</h2>
                        {isEnabled(this.props.config, "editButton") ? editButton : null}
                    </span>
                    {isEnabled(this.props.config, "sensorOverview") ? sensorOverView : null}
                    {isEnabled(this.props.config, "datePanel") ? datePanel : null}
                    {isEnabled(this.props.config, "map") ? map : null}
                    {isEnabled(this.props.config, "temperature") ? temperature : null}
                    {isEnabled(this.props.config, "log") ? log : null}
                </div>
            );
    }
}

const mapStateToProps = (state, props) => ({
    sensorMeasurements: state.sensors.sensorMeasurements,
    isGraphLoading: state.sensors.isGraphLoading,
    lang: state.locale.lang,
});

export default connect(mapStateToProps, {
    fetchSensorMeasurements,
})(injectIntl(SiotechGPS));
