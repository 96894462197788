import React, { Component } from 'react';
import { connect } from 'react-redux';

//import { Input } from '@progress/kendo-react-inputs';
import Checkbox from './../../../../../components/UI/checkBoxes/Type2/Type2';
import Spinner from './../../../../../components/Spinner/SpinnerWithResult/SpinnerWithResult';

import { createUser } from './../../../../../store/actions/userActions';

import classes from './Subusers.module.scss';

class Subusers extends Component {
	form = {
		subuserEmail: '',
		subuserName: '',
		subuserSurname: ''
	};
	perms = [];
	state = {
		submited: false
	};

	onInputChange = e => {
		this.form[e.target.name] = e.target.value;
	};
	onPermChange = e => {
		if (e.target.checked) {
			this.perms.push(e.target.id);
		} else {
			this.perms = this.perms.filter(perm => perm !== e.target.id);
		}
	};
	onSubmit = () => {
		const body = {
			email: this.form.subuserEmail,
			name: this.form.subuserName,
			surname: this.form.subuserSurname,
			permissions: this.perms
		};

		this.props.createUser(body);
		this.setState({ ...this.state, submited: true });
		this.perms = [];
	};
	onOKClick = e => {
		this.setState({ ...this.state, submited: false });
	};
	render() {
		let content = (
			<table className={classes.table}>
				<tbody>
					<tr>
						<td>
							<div>
								<Spinner
									loading={this.props.isLoading}
									resultSuccess={this.props.state === 'SUCCESS'}
								/>
							</div>
							{this.props.loading ? null : (
								<button className="orangeBtn" style={{ marginRight: '5px' }} onClick={this.onOKClick}>
									OK
								</button>
							)}
						</td>
					</tr>
				</tbody>
			</table>
		);

		if (!this.props.isLoading && !this.state.submited) {
			content = (
				<form className="k-form-inline">
					<label className="k-form-field">
						<span>Email</span>
						<input
							className="k-textbox"
							placeholder="Sub-user email"
							name="subuserEmail"
							onChange={this.onInputChange}
						/>
					</label>
					<label className="k-form-field">
						<span>Name</span>
						<input
							className="k-textbox"
							placeholder="Sub-user name"
							name="subuserName"
							onChange={this.onInputChange}
						/>
					</label>
					<label className="k-form-field">
						<span>Surname</span>
						<input
							className="k-textbox"
							placeholder="Sub-user surname"
							name="subuserSurname"
							onChange={this.onInputChange}
						/>
					</label>
					<div className="k-form-field">
						<span>Permissions</span>
						<span className={`${classes.checkboxesContainer}`}>
							<div className={classes.checkbox}>
								<Checkbox text="READ" id="READ" inputChange={this.onPermChange} />
							</div>
							<div className={classes.checkbox}>
								<Checkbox text="WRITE" id="WRITE" inputChange={this.onPermChange} />
							</div>
							<div className={classes.checkbox}>
								<Checkbox text="DELETE" id="DELETE" inputChange={this.onPermChange} />
							</div>
							<div className={classes.checkbox}>
								<Checkbox text="ADMIN" id="ADMIN" inputChange={this.onPermChange} />
							</div>
						</span>
					</div>
					<div >
						<button type="button" className="k-button k-primary" onClick={this.onSubmit}>
							Create
						</button>
					</div>
				</form>
			);
		}
		return (
			<div className={classes.prepare}>
				<div className="card">
					<div className={`card-block ${classes.block}`}>{content}</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		user: state.users.logged_user,
		isLoading: state.users.isLoading,
		state: state.users.state
	};
};

const mapDispatchToProps = dispatch => {
	return {
		createUser: body => dispatch(createUser(body))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Subusers);
