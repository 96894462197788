import * as userActions from './../actions/userActionTypes';

const initialState = {
	logged_user: {},
	sub_users: [],
	isLoading: false,
	state: "INIT",
	tokenRefreshing: false,
	adminRegime: false
};

const loading = (state, action) => {
	return {
		...state,
		isLoading: action.value
	};
};
const updateUser = (state, action) => {
	const regexp = new RegExp('([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})+');

	let user = { ...state.logged_user };
	let sub_user = [...state.sub_users];

	action.user_data.forEach(data => {
		const info = data.info;
		delete data.info;
		const splitted_info = info.split('_');

		const name = splitted_info[splitted_info.length - 1];



		if (regexp.test(splitted_info[1]) && action.is_subuser) {
			//logged as subuser so for this account it is user
			user = {
				...user,
				[name]: {
					...user[name],
					...data
				}
			}

		} else if (regexp.test(splitted_info[1])) {
			//logged as user and this are its subuser infos
			sub_user[splitted_info[1]] = {
				...sub_user[splitted_info[1]],
				[name]: {
					...data
				}
			};
		} else {
			//logged as user and this is  the user info
			user = {
				...user,
				[name]: {
					...user[name],
					...data
				}
			};
		}
	});

	/*console.log({
		logged_user: user,
		sub_user: sub_user
	});*/
	return {
		...state,
		logged_user: user,
		sub_user: sub_user
	};
};

const updateUserItem = (state, action) => {
	return {
		...state,
		logged_user: {
			...state.logged_user,
			[action.main_field]: {
				...state.logged_user[action.main_field],
				[action.sub_field]: action.value
			}
		}
	};
};


const deleteUser = (state, action) => {
	return {
		...state,
		logged_user: {},
		sub_users: []
	};
};

const chnageState = (state, action) => {
	if (state.state !== action.state) {
		return {
			...state,
			state: action.state
		}
	}

	return state;
};

const updateTokenState = (state, action) => {
	const actTokenState = action.payload.isRefreshing;
	if (state.tokenRefreshing !== actTokenState) {
		return {
			...state,
			tokenRefreshing: actTokenState
		}
	}
	return state;
}

const changeAdminregime = (state, action) => {
	return {
		...state,
		adminRegime: action.payload.value
	}

}



const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case userActions.LOADING:
			return loading(state, action);
		case userActions.UPDATE_USER:
			return updateUser(state, action);
		case userActions.UPDATE_USER_ITEM:
			return updateUserItem(state, action);
		case userActions.DELETE_USER:
			return deleteUser(state, action);
		case userActions.CHANGE_STATE:
			return chnageState(state, action);
		case userActions.REFRESH_TOKEN:
			return updateTokenState(state, action);
		case userActions.CHANGE_ADMIN_REGIME:
			return changeAdminregime(state, action);
		default:
			return state;
	}
};

export default userReducer;
