import React from 'react';

import Tooltip from "./../../Tooltip/Tooltip";

const SignalIcon = props => {
	let content = null;
	let viewBox = '0 0 70 72';
	switch (props.value) {
		case 5:
			content = (
				<g>
					<path
						strokeMiterlimit={10}
						stroke="#green"
						d="M9.783 70.159H3.87c-.711 0-1.288-.618-1.288-1.38V56.653c0-.762.577-1.38 1.288-1.38h5.912c.71 0 1.288.618 1.288 1.38v12.126c0 .762-.576 1.38-1.288 1.38zM23.799 70.159h-5.887c-.718 0-1.3-.54-1.3-1.205v-18.66c0-.666.582-1.206 1.3-1.206h5.887c.718 0 1.3.54 1.3 1.206v18.659c0 .665-.581 1.206-1.3 1.206zM37.825 70.159h-5.88c-.72 0-1.304-.56-1.304-1.25V41.279c0-.69.584-1.25 1.304-1.25h5.88c.72 0 1.304.56 1.304 1.25V68.91c-.001.69-.584 1.249-1.304 1.249zM51.86 70.159h-5.895c-.716 0-1.296-.658-1.296-1.47V26.08c0-.812.58-1.47 1.296-1.47h5.896c.716 0 1.296.658 1.296 1.47V68.69c0 .81-.58 1.47-1.296 1.47zM65.834 70.159h-5.785c-.752 0-1.362-.85-1.362-1.896V3.979c0-1.047.61-1.897 1.362-1.897h5.785c.752 0 1.362.85 1.362 1.897v64.284c0 1.047-.61 1.896-1.362 1.896z"
					/>
				</g>
			);
			break;
		case 4:
			content = (
				<g>
					<path
						strokeMiterlimit={10}
						stroke="green"
						d="M9.783 70.159H3.87c-.711 0-1.288-.618-1.288-1.38V56.653c0-.762.577-1.38 1.288-1.38h5.912c.71 0 1.288.618 1.288 1.38v12.126c0 .762-.576 1.38-1.288 1.38zM23.799 70.159h-5.887c-.718 0-1.3-.54-1.3-1.205v-18.66c0-.666.582-1.206 1.3-1.206h5.887c.718 0 1.3.54 1.3 1.206v18.659c0 .665-.581 1.206-1.3 1.206zM37.825 70.159h-5.88c-.72 0-1.304-.56-1.304-1.25V41.279c0-.69.584-1.25 1.304-1.25h5.88c.72 0 1.304.56 1.304 1.25V68.91c-.001.69-.584 1.249-1.304 1.249zM51.86 70.159h-5.895c-.716 0-1.296-.658-1.296-1.47V26.08c0-.812.58-1.47 1.296-1.47h5.896c.716 0 1.296.658 1.296 1.47V68.69c0 .81-.58 1.47-1.296 1.47z"
					/>
					<path
						fill="#b2b2b2"
						strokeMiterlimit={10}
						stroke="#000"
						d="M65.834 70.159h-5.785c-.752 0-1.362-.85-1.362-1.896V3.979c0-1.047.61-1.897 1.362-1.897h5.785c.752 0 1.362.85 1.362 1.897v64.284c0 1.047-.61 1.896-1.362 1.896z"
					/>
				</g>
			);
			break;
		case 3:
			content = (
				<g>
					<path
						strokeMiterlimit={10}
						stroke="green"
						d="M9.783 70.159H3.87c-.711 0-1.288-.618-1.288-1.38V56.653c0-.762.577-1.38 1.288-1.38h5.912c.71 0 1.288.618 1.288 1.38v12.126c0 .762-.576 1.38-1.288 1.38zM23.799 70.159h-5.887c-.718 0-1.3-.54-1.3-1.205v-18.66c0-.666.582-1.206 1.3-1.206h5.887c.718 0 1.3.54 1.3 1.206v18.659c0 .665-.581 1.206-1.3 1.206zM37.825 70.159h-5.88c-.72 0-1.304-.56-1.304-1.25V41.279c0-.69.584-1.25 1.304-1.25h5.88c.72 0 1.304.56 1.304 1.25V68.91c-.001.69-.584 1.249-1.304 1.249z"
					/>
					<path
						fill="#b2b2b2"
						strokeMiterlimit={10}
						stroke="#000"
						d="M51.86 70.159h-5.895c-.716 0-1.296-.658-1.296-1.47V26.08c0-.812.58-1.47 1.296-1.47h5.896c.716 0 1.296.658 1.296 1.47V68.69c0 .81-.58 1.47-1.296 1.47zM65.834 70.159h-5.785c-.752 0-1.362-.85-1.362-1.896V3.979c0-1.047.61-1.897 1.362-1.897h5.785c.752 0 1.362.85 1.362 1.897v64.284c0 1.047-.61 1.896-1.362 1.896z"
					/>
				</g>
			);
			break;
		case 2:
			content = (
				<g>
					<path
						strokeMiterlimit={10}
						stroke="#000"
						fill="#ff6600"
						d="M9.783 70.159H3.87c-.711 0-1.288-.618-1.288-1.38V56.653c0-.762.577-1.38 1.288-1.38h5.912c.71 0 1.288.618 1.288 1.38v12.126c0 .762-.576 1.38-1.288 1.38zM23.799 70.159h-5.887c-.718 0-1.3-.54-1.3-1.205v-18.66c0-.666.582-1.206 1.3-1.206h5.887c.718 0 1.3.54 1.3 1.206v18.659c0 .665-.581 1.206-1.3 1.206z"
					/>
					<path
						fill="#b2b2b2"
						strokeMiterlimit={10}
						stroke="#000"
						d="M37.825 70.159h-5.88c-.72 0-1.304-.56-1.304-1.25V41.279c0-.69.584-1.25 1.304-1.25h5.88c.72 0 1.304.56 1.304 1.25V68.91c-.001.69-.584 1.249-1.304 1.249zM51.86 70.159h-5.895c-.716 0-1.296-.658-1.296-1.47V26.08c0-.812.58-1.47 1.296-1.47h5.896c.716 0 1.296.658 1.296 1.47V68.69c0 .81-.58 1.47-1.296 1.47zM65.834 70.159h-5.785c-.752 0-1.362-.85-1.362-1.896V3.979c0-1.047.61-1.897 1.362-1.897h5.785c.752 0 1.362.85 1.362 1.897v64.284c0 1.047-.61 1.896-1.362 1.896z"
					/>
				</g>
			);
			break;
		default:
			content = (
				<g>
					<path
						strokeMiterlimit={10}
						fill="#d40000"
						stroke="#000"
						d="M9.783 70.159H3.87c-.711 0-1.288-.618-1.288-1.38V56.653c0-.762.577-1.38 1.288-1.38h5.912c.71 0 1.288.618 1.288 1.38v12.126c0 .762-.576 1.38-1.288 1.38z"
					/>
					<path
						fill="#b2b2b2"
						strokeMiterlimit={10}
						stroke="#000"
						d="M23.799 70.159h-5.887c-.718 0-1.3-.54-1.3-1.205v-18.66c0-.666.582-1.206 1.3-1.206h5.887c.718 0 1.3.54 1.3 1.206v18.659c0 .665-.581 1.206-1.3 1.206zM37.825 70.159h-5.88c-.72 0-1.304-.56-1.304-1.25V41.279c0-.69.584-1.25 1.304-1.25h5.88c.72 0 1.304.56 1.304 1.25V68.91c-.001.69-.584 1.249-1.304 1.249zM51.86 70.159h-5.895c-.716 0-1.296-.658-1.296-1.47V26.08c0-.812.58-1.47 1.296-1.47h5.896c.716 0 1.296.658 1.296 1.47V68.69c0 .81-.58 1.47-1.296 1.47zM65.834 70.159h-5.785c-.752 0-1.362-.85-1.362-1.896V3.979c0-1.047.61-1.897 1.362-1.897h5.785c.752 0 1.362.85 1.362 1.897v64.284c0 1.047-.61 1.896-1.362 1.896z"
					/>
				</g>
			);
			break;
	}
	return (
		<div {...props}>
			<Tooltip tooltip={props.tooltip}>
				<svg width="100%" viewBox={viewBox}>
					{content}
				</svg>
			</Tooltip>
		</div>
	);
};

export default SignalIcon;
