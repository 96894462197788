const interpolate = datasets => {
	const data = datasets.reduce((tot, set) => tot.concat(set.data), []).sort(compare);
	const output = {};

	datasets.forEach(set => {
		const uniqueName = set.id + '_' + set.setname;
		output[uniqueName] = [];

		let j = 0;
		let lastRefTime = null;

		for (let i = 0; i < data.length; i++) {
			const refTime = data[i].timestamp;

			let time = null;

			if (set.data[j]) {
				time = set.data[j].timestamp;
			}

			if (
				(refTime === time || lastRefTime === refTime) &&
				data[i].hasOwnProperty(set.setname) &&
				data[i].id === set.id
			) {
				output[uniqueName].push({
					timestamp: data[i].timestamp,
					//[set.setname]: data[i][set.setname]
					[uniqueName]: data[i][set.setname]
				});
				j++;
			} else if (refTime !== time && lastRefTime !== refTime) {
				if (set.data[j - 1] && set.data[j]) {
					const prevTime = set.data[j - 1].timestamp;
					const prevValue = set.data[j - 1][set.setname];
					const value = set.data[j][set.setname];

					const computedValue = interp(prevTime, prevValue, time, value, data[i].timestamp);

					if (checkValue(computedValue)) {
						output[uniqueName].push({
							timestamp: data[i].timestamp,
							//[set.setname]: computedValue,
							[uniqueName]: computedValue,
							disabled: true
						});
					}
				}

				//lastRefTime = null;
			}

			lastRefTime = refTime;
		}

		output[uniqueName].forEach(item => {
			item.timestamp = new Date(item.timestamp * 1000);
		});
	});

	return output;
};

const compare = (item1, item2) => {
	if (item1.timestamp < item2.timestamp) {
		return -1;
	}
	if (item1.timestamp > item2.timestamp) {
		return 1;
	}

	return 0;
};

const interp = (from, fromValue, to, toValue, when) => {
	if (checkValue(from) && checkValue(fromValue) && checkValue(to) && checkValue(toValue) && checkValue(when)) {
		return fromValue * (when - to) / (from - to) + toValue * (when - from) / (to - from);
	}

	return null;
};

const checkValue = value => {
	return typeof value !== 'undefined' && value !== null;
};

export default interpolate;
