import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import GeneralModal from './../General/General.jsx';
import ErrorEmoji from './ErrorIcon/ErrorIcon.jsx';
import actions from './../../../store/actions/actions';

import classes from './RemoteError.module.scss';

const modalActivate = props => {
	if (!props.isOpen) {
		return null;
	}

	//let status = null;
	let envelopeClass = [classes.message, classes.send];

	//if (props.error.status) {
	//	status = <p>Status code: {props.error.status}</p>;
	//}

	let explanation = (
		<div className={classes.explanation}>
			<FormattedMessage id={props.error.eventId} defaultMessage="Network error" />
		</div>
	);

	const sendErrorReport = e => {
		//envelopeClass.push(classes.send);
		const report = {
			status: props.error.status,
			message: props.error.message,
			time: new Date().getTime() / 1000,
			event: props.error.eventId,
			action: props.error.eventName,
			description: props.error.description
		};

		const body = {
			action: '0002',
			error: report
		};

		props.performAction('0', body);
	};

	let errorreporter = null;
	if (props.error.eventId === 'https.unknown') {
		errorreporter = (
			<span className={classes.reporter}>
				<p>
					<FormattedMessage id="https.unknown.sendReport" />
				</p>

				<div className={classes.envelopeConatiner}>
					<input id="proof" type="checkbox" />
					<label
						htmlFor="proof"
						className={`fas fa-envelope ${envelopeClass.join(' ')}`}
						onAnimationEnd={() => {}}
						onClick={sendErrorReport}
					/>
					<label className={classes.emailSend}>
						<FormattedMessage id="modal.report.sent" />
						{' :-)'}
						<p>
							<FormattedMessage id="modal.report.thanks" />
							{'!'}
						</p>
					</label>
				</div>
			</span>
		);
	}

	const content = (
		<div>
			<h6 className={classes.modalHeader}>
				<FormattedMessage id={props.error.eventId + '.header'} defaultMessage="Network error" />
			</h6>
			<ErrorEmoji className={classes.errorEmoji} type={props.error.eventId} />
			{/*status*/}
			{explanation}
			{errorreporter}
			{/*<p className={classes.modalMessage}>{`(server response: ${props.error.message})`}</p>*/}
		</div>
	);

	const OKButton = {
		className: `btn btn-warning ${classes.btn}`,
		action: props.continue,
		text: <FormattedMessage id="button.close" defaultMessage="Close" />
	};

	return (
		<GeneralModal actionBtn={OKButton} isOpen={props.isOpen} close={props.close} className={classes.modal}>
			{content}
		</GeneralModal>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		performAction: (sensor_id, body) => dispatch(actions.performAction(sensor_id, body))
	};
};
export default connect(null, mapDispatchToProps)(modalActivate);
