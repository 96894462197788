import React from 'react';

import { injectIntl } from 'react-intl';

import Unit from './../../../../../../icons/Unit/Unit';
import Battery from './../../../battery/Battery';
import Signal from './../../../signal/Signal';
import Id from './../../../../../../icons/Id/Id';
import Time from './../../../Time/Time';
import NotAvailable from './../../../NotAvailable/NotAvailable';

import TextIcon from './../../../TextIcon/TextIcon';

import * as infos from './../../infos';

const siotechGPS = (props) => {
	let icon = null;

	switch (props.type) {
		case infos.temperature:
			icon = <Unit type="temperature" tooltip={props.intl.formatMessage({ id: 'tooltip.info.lastTemperature' })} />;
			break;
		case infos.battery_idle_lvl:
			return <Battery value={props.value} tooltip={props.intl.formatMessage({ id: 'tooltip.battery.idle' }) + ` [1-5]  :  ${props.value}`} />;
		case infos.battery_lvl:
			return <Battery value={props.value} tooltip={props.intl.formatMessage({ id: 'tooltip.battery' }) + ` [1-5]  :  ${props.value}`} />;
		case infos.signal_lvl:
			return <Signal value={props.value} tooltip={props.intl.formatMessage({ id: 'tooltip.signal' }) + ` [1-5]  :  ${props.value}`} />;
		case infos.id:
			icon = <Id tooltip={props.intl.formatMessage({ id: 'tooltip.info.id' })} />;
			break;
		case infos.last_msg_time:
			return <Time lang={props.lang} time={props.value} tooltip={props.intl.formatMessage({ id: 'tooltip.info.lastMsgTime' })} />;
		default:
			return <NotAvailable />;
	}

	return <TextIcon iconComponent={icon} text={props.value} />;
};

export default injectIntl(siotechGPS);
