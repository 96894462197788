import React from 'react';

import { injectIntl } from 'react-intl';

import Unit from './../../../../../../../icons/Unit/Unit';
import Battery from './../../../../battery/Battery';
import Signal from './../../../../signal/Signal';
import Id from './../../../../../../../icons/Id/Id';
import Time from './../../../../Time/Time';
import NotAvailable from './../../../../NotAvailable/NotAvailable';

import TextIcon from './../../../../TextIcon/TextIcon';

import * as infos from './../../../infos';

const siotechUniorSecurity = (props) => {
	let icon = null;
	let value = props.value;

	switch (props.type) {
		case infos.temperature_inner:
			icon = <Unit type="temperature" tooltip={props.intl.formatMessage({ id: 'tooltip.info.lastTemperature.inner' })} />;
			break;
		case infos.temperature_ext:
			icon = <Unit type="temperature" tooltip={props.intl.formatMessage({ id: 'tooltip.info.lastTemperature.ext' })} />;
			break;
		case infos.humidity:
			icon = <Unit type="humidity" tooltip={props.intl.formatMessage({ id: 'tooltip.info.lastHumidity' })} />;
			break;
		case infos.pir:
			icon = (
				<Unit
					type="motion"
					disabled={props.data}
					closed={value === 0 ? 1 : 0}
					tooltip={props.intl.formatMessage({ id: 'tooltip.binInput.motion.' + value })}
				/>
			);
			value = '';
			break;
		case infos.smoke:
			icon = (
				<Unit
					type="smoke"
					disabled={props.data}
					closed={value === 0 ? 1 : 0}
					tooltip={props.intl.formatMessage({ id: 'tooltip.binInput.smoke.' + value })}
				/>
			);
			value = '';
			break;
		case infos.battery_lvl:
			return <Battery value={props.value} tooltip={props.intl.formatMessage({ id: 'tooltip.battery' }) + ` [1-5]  :  ${props.value}`} />;
		case infos.signal_lvl:
			return <Signal value={props.value} tooltip={props.intl.formatMessage({ id: 'tooltip.signal' }) + ` [1-5]  :  ${props.value}`} />;
		case infos.id:
			icon = <Id tooltip={props.intl.formatMessage({ id: 'tooltip.info.id' })} />;
			break;
		case infos.last_msg_time:
			return <Time lang={props.lang} time={props.value} tooltip={props.intl.formatMessage({ id: 'tooltip.info.lastMsgTime' })} />;
		default:
			return <NotAvailable />;
	}

	return <TextIcon iconComponent={icon} text={value} />;
};

export default injectIntl(siotechUniorSecurity);
