import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { postSensor } from '../../../store/actions/sensorActions';

import Spinner from './../../../components/Spinner/Spinner.jsx';
import OverViewItem from './SensorsOverView/OverViewItem/OverViewItem.jsx';

import sensors from './../../../utils/sensorTypes';
import classes from './SensorAdd.module.scss';

const Siglink = React.lazy(() => import('./Siglink/Siglink.jsx'));
const Wicub2 = React.lazy(() => import('./Wicub2/Wicub2.jsx'));
const SimpleLeak = React.lazy(() => import('./SimpleLeak/SimpleLeak.jsx'));
const SiotechGPS = React.lazy(() => import('./siotech/SiotechGPS/SiotechGPS.jsx'));
const SiotechUniorAlarm = React.lazy(() => import('./siotech/unior/SiotechUniorAlarm/SiotechUniorAlarm.jsx'));
const SiotechUniorSecurity = React.lazy(() => import('./siotech/unior/SiotechUniorSecurity/SiotechUniorSecurity.jsx'));
const Ste2 = React.lazy(() => import('./STE2/STE2'));

const overviewTypes = Object.keys(sensors);

class SensorAdd extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedType: null,
		};
	}

	selectionChanged = (e) => {
		this.setState({
			...this.state,
			selectedType: e.type,
		});
	};
	backHandler = (e) => {
		this.setState({
			...this.state,
			selectedType: null,
		});
	};

	onSubmit = (data) => {
		this.props.postSensor(data, true, '/', this.props.history);
	};

	render() {
		const overView = overviewTypes.map((type) => {
			return <OverViewItem type={sensors[type].iot_label} key={type} onChange={this.selectionChanged} />;
		});

		const spinner = <Spinner type="rectangles" size="11px" style={{ marginTop: '100px' }} />;
		let label = '';
		let content = (
			<div>
				<div className={classes.container}>{overView}</div>
				<div className={classes.buttonWrapper}>
					<Link to="/">
						<button className="btn btn-md btn-secondary">
							<FormattedMessage id="button.back" defaultMessage="Back" />
						</button>
					</Link>
				</div>
			</div>
		);

		let TargetComponent = null;

		switch (this.state.selectedType) {
			case sensors.siglink.iot_label:
				TargetComponent = Siglink;
				label = sensors.siglink.pretty_label;
				break;
			case sensors.wicub.iot_label:
				TargetComponent = Wicub2;
				label = sensors.wicub.pretty_label;
				break;
			case sensors.simpleLeak.iot_label:
				TargetComponent = SimpleLeak;
				label = sensors.simpleLeak.pretty_label;
				break;
			case sensors.ste2.iot_label:
				TargetComponent = Ste2;
				label = sensors.ste2.pretty_label;
				break;
			case sensors.siotech_gps_industrial.iot_label:
				TargetComponent = SiotechGPS;
				label = sensors.siotech_gps_industrial.pretty_label;
				break;
			case sensors.siotech_gps.iot_label:
				TargetComponent = SiotechGPS;
				label = sensors.siotech_gps.pretty_label;
				break;
			case sensors.siotech_unior_alarm.iot_label:
				TargetComponent = SiotechUniorAlarm;
				label = sensors.siotech_unior_alarm.pretty_label;
				break;
			case sensors.siotech_unior_security.iot_label:
				TargetComponent = SiotechUniorSecurity;
				label = sensors.siotech_unior_security.pretty_label;
				break;
			default:
				TargetComponent = null;
				break;
		}

		if (TargetComponent) {
			let sType = this.state.selectedType;
			if (sType === sensors.siotech_gps_industrial.iot_label) {
				//for industrial component we will use classic GPS - this is the same product we can consider
				sType = sensors.siotech_gps.iot_label;
			}
			content = (
				<Suspense fallback={spinner}>
					<TargetComponent
						onBack={this.backHandler}
						sensorType={sType}
						history={this.props.history}
						prices={this.props.prices[this.state.selectedType]}
						onSubmit={this.onSubmit}
					/>
				</Suspense>
			);
		}

		let title = <FormattedMessage id="title.addDevice" defaultMessage="Add Device" />;
		if (label) {
			title = (
				<React.Fragment>
					<FormattedMessage id="title.addDevice" defaultMessage="Add Device" />
					{' - ' + label}
				</React.Fragment>
			);
		}

		return (
			<div className={`centerContainer ${classes.addSensor}`}>
				<h2 className={classes.title}>{title}</h2>
				{content}
			</div>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		postSensor: (sensor, loader, renderOnSuccess, history) => dispatch(postSensor(sensor, loader, renderOnSuccess, history)),
	};
};

const mapStateToProps = (state) => {
	return {
		lastAddedSensor: state.sensors.lastPostedSensor,
		prices: state.coins.prices,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SensorAdd);
