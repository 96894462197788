
import { getValue } from "./utils";
import * as fields from "../../sensorDescriptors/Info/infos"

export function siotechUAlarmDef(sensor, intl) {
    let pirValue = getValue(sensor, fields.pir);
    let doorValue = getValue(sensor, fields.door);
    if (pirValue || pirValue === 0) pirValue = intl.formatMessage({ id: `tooltip.binInput.motion.${pirValue}` });
    if (doorValue || doorValue === 0) doorValue = intl.formatMessage({ id: `tooltip.binInput.doorOpen.${doorValue}` });
    return [
        {
            dataset: "temperature_ext",
            value: getValue(sensor, fields.temperature_ext),
            unit: "°C",
            icon: "temperature",
            tooltip: intl.formatMessage({ id: "chart.temperature" }),
            legend:
                intl.formatMessage({ id: "chart.temperature" }) +
                `(${sensor.origName})`,
        },
        {
            dataset: "humidity",
            value: getValue(sensor, fields.humidity),
            unit: "%",
            icon: "humidity",
            tooltip: intl.formatMessage({ id: "tooltip.humidity" }),
            legend:
                intl.formatMessage({ id: "tooltip.humidity" }) + `(${sensor.origName})`,
        },
        {
            dataset: "pir",
            value: pirValue,
            unit: "",
            icon: "motion",
            tooltip: intl.formatMessage({ id: "tooltip.binInput.motion" }),
            legend:
                intl.formatMessage({ id: "tooltip.binInput.motion" }) +
                `(${sensor.origName})`,
            chartType: "step",
            subType: "motion",
        },
        {
            dataset: "door",
            value: doorValue,
            unit: "",
            icon: "doorOpen",
            tooltip: intl.formatMessage({ id: "sensor.input.binary.type.doorOpen" }),
            legend:
                intl.formatMessage({ id: "sensor.input.binary.type.doorOpen" }) +
                `(${sensor.origName})`,
            chartType: "step",
            subType: "doorOpen",
        },
    ]
}