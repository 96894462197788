import React from "react";

import Tooltip from "./../../../Tooltip/Tooltip";

import classes from "./LogRecord.module.scss";

const logRecord = (props) => {
    const defColor = props.borderColor ? props.borderColor : "black";

    const recordStyle = {
        border: props.borderColor ? `2px solid ${props.borderColor}` : "2px solid grey",
        boxShadow: props.highLighColor ? `1px 1px 3px 4px ${props.highLighColor}` : "none",
        ...props.recordStyle,
    };

    const iconStyle = {
        fontSize: props.iconSize ? props.iconSize : "26px",
        color: props.iconColor ? props.iconColor : "black",
        ...props.iconStyle,
    };

    const stampStyle = {
        borderRight: `1px solid ${defColor}`,
        borderLeft: `1px solid ${defColor}`,
    };

    return (
        <div className={classes.record} style={recordStyle}>
            <div className={classes.icon}>
                <Tooltip tooltip={props.iconTooltip} containerClass={classes.tooltip}>
                    <i className={[classes.iconMarker, props.iconClass].join(" ")} style={iconStyle}>
                        <span className={[classes.iconLabel, props.iconLabelClass].join(" ")} style={props.iconLabelstyle}>
                            {props.iconLabel}
                        </span>
                    </i>
                </Tooltip>
            </div>
            <div className={classes.timestamp} style={stampStyle}>
                {props.timestamp}
            </div>
            <div className={classes.description}>{props.description}</div>
        </div>
    );
};

export default logRecord;
