import React from "react";
import { injectIntl } from "react-intl";

import classes from "./Input.module.scss";

const input = (props) => {
    const inputChangeHandler = (e) => {
        if (props.defaultValue && e.target.value === "") {
            e.target.value = props.defaultValue;
        }
        if (props.inputChange) {
            if (props.customizer && typeof props.customizer === "function") props.inputChange(props.customizer(e));
            else props.inputChange(e);
        }
    };

 
    const value = props.defaultValue === props.value && props.value !== undefined ? "" : props.value;

    const baseClasses = [`UI-input ${classes.inputField}`];
    let errMessage = null;

    if (props.readOnly) baseClasses.push(classes.readOnly);

    if (props.touched && (value !== "" || props.required)) {
        if (props.valid) {
            baseClasses.push(classes.successInput);
        } else {
            baseClasses.push(classes.errorInput);
            errMessage = (
                <div className={`formError ${classes.errText}`}>
                    <p className="formErrorMessage">{props.errorMessage}</p>
                </div>
            );
        }
    }

    return (
        <div className={`formItem`}>
            <label className={'inputTitle'}>{props.label}</label> 
            <input
                className={baseClasses.join(" ")}
                placeholder={props.intl.formatMessage({ id: props.label.props.id })}
                value={value}
                type={props.type ? props.type : "text"}
                name={props.name}
                onChange={inputChangeHandler}
                maxLength={props.maxLength}
                disabled={props.disabled}
                readOnly={props.readOnly}
                ref={props.inputRef}
                id={props.id}
            />
            {errMessage}
        </div>
    );
};

export default injectIntl(input);
