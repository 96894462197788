const sensors = {
    siglink: {
        company_label: "SIGLINK_TEMP_HUM",
        iot_label: "siglink_03",
        pretty_label: "SIGLINK (T&H)",
    },
    wicub: {
        company_label: "WiCub 2",
        iot_label: "wicub_02",
        pretty_label: "WiCub 2",
    },
    simpleLeak: {
        company_label: "SimpleLeak 3.0",
        iot_label: "simpleLeak_03",
        pretty_label: "WaterLeak",
    },
    ste2: {
        company_label: "STE2",
        iot_label: "ste_02",
        pretty_label: "STE 2",
    },
    siotech_gps: {
        company_label: "Siotech GPS",
        iot_label: "siotech_gps_01",
        pretty_label: "GPS tracker",
    },
    siotech_gps_industrial: {
        company_label: "Siotech GPS",
        iot_label: "siotech_gps_industrial_01",
        pretty_label: "GPS tracker (Industrial)",
    },
    siotech_unior_alarm: {
        company_label: "Unior alarm platinum",
        iot_label: "siotech_unior_alarm_01",
        pretty_label: "Security A",
    },
    siotech_unior_security: {
        company_label: "Unior security platinum",
        iot_label: "siotech_unior_security_01",
        pretty_label: "Security B",
    },
};

export default sensors;
