import React from 'react';

import Tooltip from "./../../../Tooltip/Tooltip";

const gps = props => {
    return (
        <div {...props}>
            <Tooltip tooltip={props.tooltip}>
                <svg width="100%" viewBox="0 0 623 658">
                    <path fill="none" d="M-1-1h582v402H-1z" />
                    <g>
                        <circle
                            fill="#e1e3e8"
                            r={312}
                            cy={346}
                            cx={312}
                        />
                        <path
                            fill="#fff"
                            d="M180 64l156 288c4 7 1 15-6 19-6 4-15 1-18-6L156 76l24-12zm272 561c8-4 16-9 24-14l-97-180c-4-6-12-9-19-5-7 3-9 12-6 19l98 180z"

                        />
                        <path
                            fill="#fff"
                            d="M317 347c15-8 31-9 45-5s27 14 35 29c8 14 9 30 4 44-4 15-14 27-28 35s-30 9-45 5c-14-5-27-14-35-29-7-14-9-30-4-44 4-15 14-28 28-35zm37 22c-8-3-16-2-24 2-7 4-12 11-15 18-2 8-1 17 3 24 4 8 11 13 18 15 8 3 16 2 24-2 7-4 13-11 15-19 2-7 2-16-2-23-5-8-11-13-19-15zM320 35l-89 137c-4 7-13 9-19 4-7-4-8-12-4-19l79-121c8-1 16-1 25-1h8zM5 292l119-5 125-67c7-4 15-1 19 5 3 7 1 15-6 19l-127 69h-1c-1 1-3 1-5 1L1 320c1-9 2-19 4-28z"

                        />
                        <path
                            fill="#fff"
                            d="M472 79c-21 18-53 61-13 127 70 116-68 183-68 183-7 4-15 1-19-6-3-7 0-15 6-18 1 0 112-55 57-145-45-76-15-128 12-155 8 4 17 9 25 14zM299 408c7-4 15-2 19 5 3 7 1 15-6 19L81 556l-18-21 236-127zM575 513l-28-18-147 16c-8 0-15-5-16-13 0-7 5-14 13-15l152-16c3-1 7 0 9 2l31 20c-5 9-9 17-14 24z"

                        />
                        <path
                            fill="#c3c6cf"
                            d="M362 240c57 0 86 14 65 32-6 4-15 8-25 12-2 1-4 2-6 2l-165 59-19-59c-2-4 0-9 6-14 22-18 86-32 144-32z"

                        />
                        <path
                            fill="#d7dae0"
                            d="M316 315l-27 9-37-68c9-3 18-5 28-7l36 66z"

                        />
                        <path
                            fill="#53b4ed"
                            d="M156 76l81 150-113 61-119 5c16-92 73-170 151-216z"

                        />
                        <path
                            fill="#fff"

                            d="M124 384l49 91-24 13-49-91zM326 589l90-52 14 24-90 52z"
                        />
                        <path
                            fill="#72cf7b"
                            d="M406 482l143-15c3-1 7 0 9 2l31 20c-2 4-4 8-7 12 26-46 41-98 41-155 0-113-60-212-151-267-21 18-53 61-13 127 58 95-26 158-57 177 3 11 3 22-1 32-3 10-8 20-17 27l22 40z"

                        />
                        <path
                            fill="#ff576b"
                            d="M231 0c57 0 104 47 104 104 0 17-4 33-12 48l-92 193-93-193c-7-15-11-31-11-48C127 47 173 0 231 0z"

                        />
                        <path
                            fill="#f2384e"
                            d="M231 0c57 0 104 47 104 104 0 17-4 33-12 48l-92 193 79-193c8-15 12-31 12-48 0-55-43-100-98-104h7z"

                        />
                        <path
                            fill="#ff8795"
                            d="M231 0c6 0 13 1 19 2-74-1-138 72-100 152l81 190v1l-93-193c-7-15-11-31-11-48C127 47 173 0 231 0z"

                        />
                        <circle
                            fill="#f5f5f5"
                            r={36}
                            cy={98}
                            cx={231}

                        />
                        <path
                            fill="#c4ceda"
                            d="M247 66c12 6 20 18 20 32 0 20-16 36-36 36-8 0-15-3-21-7 5 3 11 4 16 4 20 0 36-16 36-36 0-12-6-23-15-29z"

                        />
                    </g>
                </svg>
            </Tooltip>
        </div>
    );
};

export default gps;
