import * as coinsActions from "./../actions/coinsActionTypes.js";

import senTypes from "./../../utils/sensorTypes";

const initialState = {
    totalCoins: 0,
    topupState: "INIT",
    activationState: "INIT",
    prices: {
        [senTypes.siglink.iot_label]: {
            registration: 0,
            periods: [{ "3": 215 }, { "6": 260 }, { "12": 390 }, { "24": 690 }],
        },
        [senTypes.wicub.iot_label]: {
            registration: 0,
            periods: [{ "1": 40 }, { "6": 150 }, { "12": 250 }, { "24": 410 }],
        },
        [senTypes.simpleLeak.iot_label]: {
            registration: 0,
            periods: [{ "3": 215 }, { "6": 260 }, { "12": 390 }, { "24": 690 }],
        },
        [senTypes.ste2.iot_label]: {
            registration: 0,
            periods: [{ "1": 50 }, { "6": 230 }, { "12": 390 }, { "24": 640 }],
        },
        [senTypes.siotech_gps.iot_label]: {
            registration: 0,
            periods: [{ "3": 215 }, { "6": 260 }, { "12": 390 }, { "24": 690 }],
        },
        [senTypes.siotech_gps_industrial.iot_label]: {
            registration: 0,
            periods: [{ "3": 215 }, { "6": 260 }, { "12": 390 }, { "24": 690 }],
        },
        [senTypes.siotech_unior_alarm.iot_label]: {
            registration: 0,
            periods: [{ "3": 215 }, { "6": 260 }, { "12": 390 }, { "24": 690 }],
        },
        [senTypes.siotech_unior_security.iot_label]: {
            registration: 0,
            periods: [{ "3": 215 }, { "6": 260 }, { "12": 390 }, { "24": 690 }],
        },
    },
};

const setCoins = (state, action) => {
    //localStorage.setItem("tokens", action.value);
    return {
        ...state,
        totalCoins: action.value,
    };
};

const addCoins = (state, action) => {
    let newCoinsVal = state.totalCoins + action.value;
    if (newCoinsVal < 0) {
        newCoinsVal = 0;
    }

    //localStorage.setItem("tokens", newCoinsVal);

    return {
        ...state,
        totalCoins: newCoinsVal,
    };
};

const changeTopupState = (state, action) => {
    return {
        ...state,
        topupState: action.state,
    };
};

const changeActivationState = (state, action) => {
    return {
        ...state,
        activationState: action.state,
    };
};

const coinsReducer = (state = initialState, action) => {
    switch (action.type) {
        case coinsActions.ADD_COINS:
            return addCoins(state, action);
        case coinsActions.SET_COINS:
            return setCoins(state, action);
        case coinsActions.CHANGE_TOPUP_STATE:
            return changeTopupState(state, action);
        case coinsActions.CHANGE_ACTIVATION_STATE:
            return changeActivationState(state, action);
        default:
            return state;
    }
};

export default coinsReducer;
