import React from "react";

import Checkbox from "./../../ToggleButtons/Type2/Type2";

import classes from "./TwoStateInput.module.scss";

const twoStateInput = (props) => {
    let formClass = ["formItem"];
    let inputTitleClass = ["inputTitle"];
    let inputClass = ["UI-input", classes.state];

    if (props.useNotifStyles) {
        formClass = [classes.container];
        inputTitleClass = [classes.inputTitle];
        inputClass = [classes.state];
    }

    if (props.className) {
        formClass = [props.className];
    }

    if (props.titleClass) {
        inputTitleClass = [props.titleClass];
    }

    if (props.inputContainerClass) {
        inputClass = [props.inputContainerClass];
    }
    return (
        <div className={formClass.join(" ")} style={props.style}>
            <label className={inputTitleClass.join(" ")}>{props.title}</label>
            <div className={inputClass.join(" ")}>
                <Checkbox inputChange={props.inputChange} name={props.name} checked={Boolean(props.state)} disabled={props.disabled} />
            </div>
        </div>
    );
};

export default twoStateInput;
