import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';

import Spinner from './../../../components/Spinner/Spinner.jsx';

import sensors from './../../../utils/sensorTypes';
import { fetchSensorByIdDirect,editSensor } from './../../../store/actions/sensorActions.js';

//import classes from "./SensorEdit.module.scss";

const Siglink = React.lazy(() => import('./Siglink/Siglink.jsx'));
const Wicub2 = React.lazy(() => import('./Wicub2/Wicub2.jsx'));
const SimpleLeak = React.lazy(() => import('./SimpleLeak/SimpleLeak.jsx'));
const SiotechGPS = React.lazy(() => import('./siotech/SiotechGPS/SiotechGPS.jsx'));
const SiotechUAlarm = React.lazy(() => import('./siotech/unior/SiotechUniorAlarm/SiotechUniorAlarm.jsx'));
const SiotechUSecurity = React.lazy(() => import('./siotech/unior/SiotechUniorSecurity/SiotechUniorSecurity.jsx'));
const Ste2 = React.lazy(() => import('./STE2/STE2'));

class SensorEdit extends Component {
	constructor(props) {
		super(props);
		this.id = props.id;
		this.firstInit = false;
		const regex = new RegExp('\\?type=.+$');
		this.type = regex.exec(this.props.history.location.search);
		if (this.type) {
			this.type = this.type[0].split('=')[1];
		}
	}

	componentDidMount() {
		this.props.fetchSensorById(this.id, this.type);
		this.firstInit = true;
	}

	onSubmit = (data) => {
		this.props.updateSensor(data, true, "/", this.props.history);
	}

	render() {
		let type = this.props.sensor ? this.props.sensor.sensor_type : null;

		const spinner = <Spinner type="rectangles" size="11px" style={{ marginTop: '100px' }} />;
		let content = spinner;

		let TargetComponent = null;
		switch (type) {
			case sensors.siglink.iot_label:
				TargetComponent = Siglink;
				break;
			case sensors.wicub.iot_label:
				TargetComponent = Wicub2;
				break;
			case sensors.simpleLeak.iot_label:
				TargetComponent = SimpleLeak;
				break;
			case sensors.ste2.iot_label:
				TargetComponent = Ste2;
				break;
			case sensors.siotech_unior_alarm.iot_label:
				TargetComponent = SiotechUAlarm;
				break;
			case sensors.siotech_unior_security.iot_label:
				TargetComponent = SiotechUSecurity;
				break;
			case sensors.siotech_gps_industrial.iot_label:
			case sensors.siotech_gps.iot_label:
				TargetComponent = SiotechGPS;
				break;
			default:
				TargetComponent = null;
				break;
		}

		if (!this.props.loading) {
			content = TargetComponent && (
				<Suspense fallback={spinner}>
					<TargetComponent id={this.id} type={this.type} history={this.props.history} sensor={this.props.sensor} prices={this.props.prices[type]} onSubmit={this.onSubmit} />
				</Suspense>
			);
		}

		return content;
	}
}

const mapStateToProps = (state, props) => {
	return {
		loading: state.sensors.isLoading,
		sensor: state.sensors.sensors.find((sensor) => sensor.id === props.id),
		prices: state.coins.prices,
	};
};

const mapDispatchToState = (dispatch) => {
	return {
		updateSensor: (sensor, loader, path, history) => dispatch(editSensor(sensor, loader, path, history)),
		fetchSensorById: (sensor_id, type) => dispatch(fetchSensorByIdDirect(sensor_id, type)),
	};
};

export default connect(mapStateToProps, mapDispatchToState)(SensorEdit);
