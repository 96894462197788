export default {
	en: {
		'login.haveAccount': 'Back to sign in',
		'login.phone': 'Phone number',
		'login.signUp': 'Sign up',
		'login.signUp.success':
			'Your account was successfully created. To complete the procedure, please insert the verification code we have sent to your email address.',
		'login.signIn': 'Sign in',
		'login.verification': 'Verification',
		'login.verify': 'Verify',
		'login.verify.success': 'Successfully verified.',
		'login.back': 'Back to sign in',
		'login.email': 'Email',
		'login.email.tip': 'Email has to be in format: example@domain',
		'login.email.sent': 'Email was successfully sent.',
		'login.password': 'Password',
		'login.password.tip':
			'Password must contain:\n' +
			'- at least 1 lowercase character\n' +
			'- at least 1 uppercase character\n' +
			'- at least 1 numeric character\n' +
			'- length must be at least six characters',
		'login.actualPassword': 'Actual password',
		'login.actualPassword.tip': 'Password has to contain at least six characters.',
		'login.newPassword': 'New password',
		'login.newPasswordAgain': 'New password again',
		'login.passwordAgain': 'Password again',
		'login.newPasswordAgain.tip': 'Both passwords must be the same.',
		'login.resetPsw': 'Password reset',
		'login.setPsw': 'Password setting',
		'login.code': 'Authorization code',
		'login.code.sent': 'Your authorization code was sent to your email address.',
		'login.code.updated': 'Your password was successfully updated.',
		'login.code.tip': 'The authorization code has to be a number at least 4 digits long.',
		'login.newCode': 'Send a new code',
		'login.sendCode': 'Send code',
		'login.register': 'Sign up',
		'login.lostPassword': 'Lost your password?',
		'login.confirm': 'Confirm',
		'login.signin.notconfirmed':
			'Your email has not been confirmed yet. Please enter the authorization code, which was sent to your email address after the registration.',

		'title.sensorsList': 'Device list',
		'title.addDevice': 'Add device',
		'title.addSensor': 'Add sensor',
		'title.editSensor': 'Edit',
		'title.login': 'Please login..',
		'title.globalnotifsettings': 'Global notifications',
		'title.smsnotifications': 'SMS notifications',
		'title.emailnotifications': 'Email notifications',
		'title.pushNotifications': 'Web push notifications',
		'title.user': 'User',

		'menu.home': 'Home',
		'menu.user': 'User',
		'menu.notifications': 'Notifications',
		'menu.groups': 'Groups',
		'menu.setting': 'Global settings',
		'menu.devices': 'Devices',
		'menu.chartsMode': 'Comparison',

		'menu.devices.autoRenewal': 'Automatically activate for next year after expiration.',
		'menu.devices.expEmailNotif': 'Send e-mail when device expires.',

		'user.global.remains': 'Remain',
		'user.global.autocredit': 'Auto credit consuming',
		'user.global.credits': 'Credits',
		'user.global.price': 'Price',
		'user.global.totalPrice': 'Total price',
		'user.global.totalCredits': 'Total SMS',
		'user.global.perSMS': 'per SMS',
		'user.global.buy': 'Buy',
		'user.global.notifManagment': 'SMS management',
		'user.name': 'Name',
		'user.surname': 'Surname',
		'user.submenu.info': 'Information',
		'user.submenu.subusers': 'Sub-users',
		'user.submenu.changePassword': 'Change password',
		'user.submenu.deleteAccount': 'Delete account',

		'sensor.name': 'Name',
		'sensor.state': 'State',
		'sensor.battery': 'Battery',
		'sensor.signal': 'Signal',
		'sensor.type': 'Type',
		'sensor.info1': 'Info 1',
		'sensor.info2': 'Info 2',
		'sensor.actions': 'Actions',
		'sensor.lastMessage': 'Last message',
		'sensor.edit': 'Edit',
		'sensor.delete': 'Delete',
		'sensor.activate': 'Activate',
		'sensor.activation': 'Activation...',
		'sensor.proccessing': 'Processing',
		'sensor.deleting': 'Deleting...',
		'sensor.id': 'ID',
		'sensor.pac': 'PAC',
		'sensor.serialNumber': 'Serial number',
		'sensor.mac': 'MAC address',
		'sensor.fw': 'Firmware version',
		'sensor.topupCode': 'Top up code',
		'sensor.transmissionPeriod': 'Transmission period',
		'sensor.notifEmail': 'Notification email',
		'sensor.phonenumber': 'Phone number',
		'sensor.forsmsnotif': 'For SMS notifications',
		'sensor.forcall': 'For contacting',
		'sensor.notifLang': 'Notification language',
		'sensor.email': 'Email',
		'sensor.token': 'Token',
		'sensor.expirationDate': 'Expiration date',
		'sensor.hours': 'Hours',
		'sensor.minutes': 'Minutes',
		'sensor.minutesDescription': 'minutes',
		'sensor.hoursDescription': 'hours',
		'sensor.lowLimit': 'Low limit',
		'sensor.highLimit': 'High limit',
		'sensor.alarmRaised': 'Alarm detected !',
		'sensor.notActivated': 'Not activated !',
		'sensor.alarmOK': 'Alarm inactive',
		'sensor.tempAlarms': 'Temperature alarm',
		'sensor.tempAlarms.inner': 'Temperature alarm (inner)',
		'sensor.tempAlarms.ext': 'Temperature alarm (ext)',
		'sensor.humAlarms': 'Humidity alarm',
		'sensor.activationPeriod': 'Activation period',
		'sensor.activationSeason': 'Activation period',
		'sensor.notavailable': 'Not available',
		'sensor.meassure': 'Measures',
		'sensor.device': 'Device',
		'sensor.position': 'Position',
		'sensor.correct': 'Correct',
		'sensor.incorrect': 'Incorrect',
		'sensor.flood': 'Water leak',
		'sensor.detected': 'Detected',
		'sensor.notDetected': 'Not detected',
		'sensor.connectedSensors': 'Connected sensors',
		'sensor.binaryInput': 'Binary input',
		'sensor.ioInput': 'I/O input',
		'sensor.sensor': 'Sensor',
		'sensor.address': 'Address',
		'sensor.radius': 'Radius',
		'sensor.noSensor': 'No registered devices.',

		'sensor.input.binary.alarm.flood': 'Alarm when water leakage',
		'sensor.input.binary.alarm.doorOpen': 'Alarm when door open',
		'sensor.input.binary.alarm.doorClosed': 'Alarm when door closed',
		'sensor.input.binary.alarm.doorActivity': 'Alarm when door state changed',
		'sensor.input.binary.alarm.smoke': 'Alarm when smoke detected',
		'sensor.input.binary.alarm.motion': 'Alarm when motion detected',
		'sensor.input.risingEdge': 'Alarm when closed',
		'sensor.input.fallingEdge': 'Alarm when open',

		'sensor.input.binary.type.flood': 'Flood detector',
		'sensor.input.binary.type.doorOpen': 'Door contact detector',
		'sensor.input.binary.type.smoke': 'Smoke detector',
		'sensor.input.binary.type.motion': 'Motion detector',
		'sensor.input.binary.type.general': 'General detector',

		'button.saveSettings': 'Save settings',
		'button.addDevice': 'Add device',
		'button.OK': 'OK',
		'button.send': 'Send',
		'button.home': 'Home',
		'button.back': 'Back',
		'button.addAndActivate': 'Add and activate',
		'button.add': 'Add',
		'button.saveSensor': 'Save',
		'button.login': 'Login',
		'button.signin': 'Sign in',
		'button.delete': 'Delete',
		'button.cancel': 'Cancel',
		'button.signout': 'Sign out',
		'button.toggle.protection': 'Turn on protection',
		'button.toggle.activation': 'Activate',
		'button.toggle.notifications': 'Set notifications',
		'button.toggle.useGlobalNotif': 'Use global settings',
		'button.toggle.autoRenewal': 'Automatic activation renewal',
		'button.filter': 'Filter',
		'button.topup': 'Top up',
		'button.continue': 'Continue',
		'button.close': 'Close',
		'button.enable': 'Enable',
		'button.update': 'Update',
		'button.save': 'Save',
		'button.compare': 'Compare',
		'button.selectDate': 'Select date',
		'button.export': 'Export',
		'button.buyCredit': 'Buy credit',
		'button.show': 'Show',
		'button.closeAll': 'Close all',

		'modal.deviceIsLocked': 'Failed! Device is locked.',
		'modal.deviceIsLocked.protected': 'Next day the device reaches the state ENCODED.',
		'modal.deviceIsLocked.unprotected': 'Next day the device reaches the state DECODED.',
		'modal.postSuccess': 'Device was successfully created.',
		'modal.editSuccess': 'Device was successfully updated.',
		'modal.encodedSuccessfully': 'Device was successfull encoded',
		'modal.decodedSuccessfully': 'Device was successfull decoded',
		'modal.user.editNotifSuccess': 'Notifications were successfully updated.',
		'modal.user.userUpdated': 'Information was successfully updated.',
		'modal.devices.deviceUpdated': 'Setting was successfully updated.',
		'modal.deleteSuccess': 'Device was successfully deleted.',
		'modal.delete1': 'If you delete the device, all related data will be permanently lost.',
		'modal.delete2': 'Are you sure you want to delete the device?',
		'modal.deleteData': 'Delete also all related data.',
		'modal.insertTopupCode': 'Insert your top up code.',
		'modal.activation.success': 'Device activation was successfull.',
		'modal.activation.failed': 'Activation failed. Unsufficient amount of credits.',
		'modal.smstopup.success': 'Your SMS count was successfully increased.',
		'modal.activation.summary': 'Activation summary: ',
		'modal.activation.continue': 'Do you want to continue ?',
		'modal.activation.summary.price': 'Price',
		'modal.activation.summary.period': 'Period',
		'modal.topup.period': 'Select an activation period.',
		'modal.topup.verifying': 'Code verifying ...',
		'modal.topup.success': 'Your credits were successfully increased.',
		'modal.topup.failed': 'Error - invalid code provided.',
		'modal.globalError': 'Ups - something went wrong.',
		'modal.report.sent': 'Successfully sent.',
		'modal.report.thanks': 'Thank you',
		'modal.user.delete.header': 'User account deletion.',
		'modal.user.delete.text': 'This user account will be completely removed. The user will not be able to login anymore after the deletion.',
		'modal.user.delete.text2': 'All devices related to this account will be deleted, including all their data.',
		'modal.user.delete.text3': "All 'Active' devices will remain activated.",
		'modal.user.delete.text4': 'All user credit will be deleted.',
		'modal.user.delete.text5': 'Are you sure you want to delete this user account ?',
		'modal.user.deleted': 'You account was successfully deleted.',

		'error.signin.UserNotFoundException': 'User does not exist !',
		'error.signin.NotAuthorizedException': 'Incorrect email or password !',
		'error.signup.UsernameExistsException': 'An account with the given email address already exists.',
		'error.code.CodeMismatchException': 'Invalid verification code provided.',
		'error.code.UserNotFoundException': 'Account does not exist. It could not be verified.',
		'error.code.NotAuthorizedException': 'This account was already verified.',
		'error.code.LimitExceededException': 'Attempt limit exceeded, try again later.',
		'error.code.ExpiredCodeException': 'Your authorization code expired. Please, generate a new one.',

		'errorMessage.name': 'The length must be between 1 and 64 characters.',
		'errorMessage.id.siglink': 'ID must have 8 HEX characters.',
		'errorMessage.id.wicub': 'ID must have 6 HEX characters.',
		'errorMessage.pac': 'PAC must have 16 HEX characters.',
		'errorMessage.mac': 'Invalid MAC address format.',
		'errorMessage.serialNumber': 'Serial number has to cotain at least 10 characters.',
		'errorMessage.notifEmail': 'Incorrect e-mail format.',
		'errorMessage.hoursAndMinutes': 'Transmission period is not within the allowed interval.',
		'errorMessage.limits': 'Invalid value or low limit is higher then high limit',
		'errorMessage.address': 'Unknown address.',
		'errorMessage.radius': 'Invalid value of the area radius.',
		'errorMessage.notifNotSupported': 'Your browser does not support push notifications messages.',
		'errorMessage.notifDenied': 'You have to grant permissions in you browser to receive push notifications messages.',

		'chart.temperature': 'Temperature',
		'chart.temperature.inner': 'Inner temperature',
		'chart.temperature.ext': 'External temperature',
		'chart.humidity': 'Humidity',
		'chart.pressure': 'Pressure',
		'chart.iaq': 'Air quality',
		'chart.leakageDiagram': 'Diagrams',
		'chart.period': 'Period',
		'chart.from': 'From',
		'chart.to': 'To',
		'chart.title': 'Charts',
		'chart.loading': 'Loading data...',
		'chart.exporting': 'Filling report...',
		'chart.export.failed': 'Export failed.',
		'chart.gantt.deviceOn': 'Device ON',
		'chart.gantt.wrongPosition': 'Wrong position',
		'chart.gantt.leakage': 'Leakage',
		'chart.last15Events': 'Last 15 events',
		'chart.gpsMsgLogs': 'List of received messages',
		'chart.receivedGPSCoordinates': 'Received coordinates',

		'chart.menu.bullets.on': 'Enable/Disable points',
		'chart.menu.bullets.off': 'Enable/Disable points',
		'chart.menu.pdf': 'Export to .pdf',
		'chart.menu.csv': 'Export to .csv',
		'chart.menu.xlsx': 'Export to .xlsx (excel)',
		'chart.menu.limits': 'Show/Hide limits',
		'chart.menu.zoomin': 'Maximize',
		'chart.menu.zoomout': 'Minimize',
		'chart.menu.zoomAlarm': 'Zoom alarm',
		'chart.menu.average': 'Arithmetic mean',
		'chart.menu.min': 'Minimum',
		'chart.menu.max': 'Maximum',
		'chart.menu.multiAxis': 'Show multiple axes',

		'chart.menu.lineType.line': 'Line',
		'chart.menu.lineType.bar': 'Bar',
		'chart.menu.aggrType.sum': 'Sum',
		'chart.menu.aggrType.diff': 'Difference',
		'chart.menu.aggrType.min': 'Minimum',
		'chart.menu.aggrType.max': 'Maximum',
		'chart.menu.aggrType.average': 'Average',
		'chart.menu.periodType.year': 'Year',
		'chart.menu.periodType.month': 'Month',
		'chart.menu.periodType.week': 'Week',
		'chart.menu.periodType.day': 'Day',
		'chart.menu.periodType.hour': 'Hour',
		'chart.menu.periodType.minute': 'Minute',
		'chart.menu.ZoomToLastPosition': 'Zoom to last known position',

		'chart.menu.player.stepBack': 'Step back',
		'chart.menu.player.stop': 'Stop',
		'chart.menu.player.pause': 'Pause',
		'chart.menu.player.play': 'Play',
		'chart.menu.player.stepForward': 'Step forward',

		'chart.gantt.log.armingOn': 'Device was turned on.',
		'chart.gantt.log.armingOff': 'Device was turned off.',
		'chart.gantt.log.positionOn': 'Device is in invalid position.',
		'chart.gantt.log.positionOff': 'Device came back to valid position.',
		'chart.gantt.log.leakageOn': 'Water leak detected.',
		'chart.gantt.log.leakageOff': 'Flood level decreased under 2 milimeters.',

		'chart.gps.noSignal': 'GPS signal not found.',
		'chart.gps.receivedSerisData': 'Service data received.',
		'chart.gps.location': 'Location',
		'chart.gps.time': 'Time',

		'chart.export.setting': 'Export setting',
		'chart.export.name': 'Export name',
		'chart.export.delimiter': 'Delimiter',
		'chart.export.decimalSeparator': 'Decimal separator',
		'chart.export.decimalPlaces': 'Decimal places',

		'select.24hours': 'Last 24 hours',
		'select.7days': 'Last 7 days',
		'select.30days': 'Last 30 days',
		'select.sensor': 'Search',
		'select.period.days': 'Days',
		'select.period.free': 'For free',
		'select.period.1': '1 month',
		'select.period.3': '3 months',
		'select.period.6': '6 months',
		'select.period.12': '1 year',
		'select.period.24': '2 years',
		'select.period.autoRenewal': 'Automatic renewal to the selected period after expiration.',
		'select.itemsOnPage': 'Items on page',
		'select.of': 'of',

		'tooltip.state': 'State',
		'tooltip.state.active': 'Active',
		'tooltip.state.expired': 'Expired',
		'tooltip.state.encoded': 'Encoded',
		'tooltip.state.decoded': 'Decoded',
		'tooltip.state.locked': 'Locked',
		"tooltip.state.disabled": "Disabled",
		'tooltip.battery': 'Battery level',
		'tooltip.battery.idle': 'Idle battery level',
		'tooltip.signal': 'Signal level',
		'tooltip.edit': 'Edit device',
		'tooltip.deleteSensor': 'Delete device',
		'tooltip.activateSensor': 'Activate device',
		'tooltip.protectSensor': 'On/Off protection',
		'tooltip.pressure': 'Pressure',
		'tooltip.humidity': 'Humidity',
		'tooltip.temperature': 'Temperature',
		'tooltip.iaq': 'Indoor air quality',
		'tooltip.creditsCount': 'Credits',
		'tooltip.flood': 'Water leak',
		'tooltip.binary': 'Binary input',
		'tooltip.gpsTracking': 'GPS tracking',
		'tooltip.freeActivationPeriod': 'Will be applied only on first device registration.',
		'tooltip.msgType': 'Message type',
		'tooltip.msgType.service': 'Service',
		'tooltip.msgType.gps': 'GPS coordinates',
		'tooltip.msgType.timeout': 'GPS timeout',
		'tooltip.unknown': 'Unknown',
		'tooltip.autoRenewal': 'The device will be auto activated to the selected period after every expiration.',

		'tooltip.binInput.doorOpen': 'Door detector',
		'tooltip.binInput.smoke': 'Smoke detector',
		'tooltip.binInput.flood': 'Flood detector',
		'tooltip.binInput.motion': 'Motion detector',
		'tooltip.binInput.binary': 'General detector',

		'tooltip.binInput.doorOpen.0': 'Doors closed',
		'tooltip.binInput.doorOpen.1': 'Doors open',
		'tooltip.binInput.smoke.0': 'No Smoke detected',
		'tooltip.binInput.smoke.1': 'Smoke detected',
		'tooltip.binInput.flood.0': 'Not flooded',
		'tooltip.binInput.flood.1': 'Flooded',
		'tooltip.binInput.motion.0': 'No motion detected',
		'tooltip.binInput.motion.1': 'Motion detected',
		'tooltip.binInput.binary.0': 'Disconnected',
		'tooltip.binInput.binary.1': 'Connected',

		'tooltip.info.lastTemperature': 'Actual temperature',
		'tooltip.info.lastTemperature.inner': 'Actual inner temperature',
		'tooltip.info.lastTemperature.ext': 'Actual extern temperature',
		'tooltip.info.lastHumidity': 'Actual humidity',
		'tooltip.info.lastPressure': 'Actual pressure',
		'tooltip.info.lastIaq': 'Actual iaq',
		'tooltip.info.lastMsgTime': 'Last received message',
		'tooltip.info.id': 'Device ID',
		'tooltip.info.leakageON': 'Flooded',
		'tooltip.info.leakageOFF': 'Not flooded',
		'tooltip.info.positionKO': 'Wrong position',
		'tooltip.info.positionOK': 'Correct position',
		'tooltip.info.charging': 'Charging',
		'tooltip.info.notCharging': 'Not charging',
		'tooltip.info.devicePowerON': 'Device ON',
		'tooltip.info.devicePowerOFF': 'Device OFF',
		'tooltip.info.switchState': 'Switch state',
		'tooltip.info.open': 'Open',
		'tooltip.info.closed': 'Closed',

		'serviceWorker.appUpdate': 'There is a new version available.',

		'https.unknown': 'Unexpected error has occured. Please send error details.',
		'https.unknown.header': 'Ups - something went wrong.',
		'https.unknown.sendReport': 'Click to send error details.',
		'https.addSensor.insufficientAmmount': 'You have insufficient ammount of credits.',
		'https.addSensor.insufficientAmmount.header': 'Top up your credits.',
		'https.addSensor.alreadyAdded': "This device is in use on yours or someone else's account.",
		'https.addSensor.alreadyAdded.header': 'Duplicit device.',
		'https.addSensor.sensorTypeMissmatch':
			'You are adding a device which is not compatible with the selected type. Please go back and select a correct device type.',
		'https.addSensor.sensorTypeMissmatch.header': 'Device type missmatch.',
		'https.invalidField': 'One of the fields contains an invalid value.',
		'https.invalidField.header': 'Invalid value.',
		'https.lowPermissions': "You don't have required permissions to perform this request.",
		'https.lowPermissions.header': 'Access denied.',
		'https.invalidPassword': 'Inserted password does not match your current password.',
		'https.invalidPassword.header': 'Invalid password.',
		'https.limitExceeded': 'Limit of password changes was exceeded. Try it again later.',
		'https.limitExceeded.header': 'Limit exceeded.',
		'https.activationLimit': 'Activation was already made. The next activation can be done in one hour after the previous activation.',
		'https.activationLimit.header': 'Activation limit exceeded',
	},
	cs: {
		'login.haveAccount': 'Zpět na přihlášení',
		'login.phone': 'Telefonní číslo',
		'login.signUp': 'Registrovat',
		'login.signUp.success': 'Váš účet byl úspěšně vytvořen. Pro dokončení prosím vložte ověřovací kód, který byl zaslán na Vaši emailovou adresu.',
		'login.signIn': 'Přihlášení',
		'login.verification': 'Ověření',
		'login.verify': 'Ověř',
		'login.verify.success': 'Ověření bylo úspěšné.',
		'login.back': 'Zpět na přihlášení',
		'login.email': 'Email',
		'login.email.tip': 'Email musí být ve formátu: example@domain',
		'login.email.sent': 'Email byl úspěšně zaslán.',
		'login.password': 'Heslo',
		'login.password.tip': 'Heslo musí obsahovat: \n - nejméně 1 znak [a-z]\n - nejméně 1 znak [A-Z]\n - nejméně 1 číslo\n - minimálně 6 znaků',
		'login.actualPassword': 'Současné heslo',
		'login.actualPassword.tip': 'Heslo musí obsahovat alespoň šest znaků.',
		'login.newPassword': 'Nové heslo',
		'login.newPasswordAgain': 'Nové heslo znovu',
		'login.passwordAgain': 'Heslo znovu',
		'login.newPasswordAgain.tip': 'Obě hesla se musí shodovat.',
		'login.resetPsw': 'Obnovení hesla',
		'login.setPsw': 'Nastavení hesla',
		'login.code': 'Autorizační kód',
		'login.code.sent': 'Váš autorizační kód byl zaslán na Váš email.',
		'login.code.updated': 'Vaše heslo bylo úspěšně aktualizováno.',
		'login.code.tip': 'Autorizační kód musí být číslo, obsahující nejméně 4 číslice.',
		'login.newCode': 'Zaslat nový kód',
		'login.sendCode': 'Odeslat kód',
		'login.register': 'Registrace',
		'login.lostPassword': 'Ztratil jsi heslo?',
		'login.confirm': 'Potvrdit',
		'login.signin.notconfirmed': 'Váš email stále nebyl autorizován. Zadejte prosím autorizační kód, který Vám byl po registraci zaslán na email.',

		'title.sensorsList': 'Seznam zařízení',
		'title.addDevice': 'Přidat zařízení',
		'title.addSensor': 'Přidat senzor',
		'title.editSensor': 'Editace',
		'title.login': 'Přihlaste se..',
		'title.globalnotifsettings': 'Globální notifikace',
		'title.smsnotifications': 'SMS notifikace',
		'title.emailnotifications': 'Email notifikace',
		'title.pushNotifications': 'Webové push notifikace',
		'title.user': 'Uživatel',
		'user.submenu.info': 'Informace',
		'user.submenu.subusers': 'Poduživatelé',
		'user.submenu.changePassword': 'Změna hesla',
		'user.submenu.deleteAccount': 'Smazání účtu',

		'menu.home': 'Domů',
		'menu.user': 'Uživatel',
		'menu.notifications': 'Notifikace',
		'menu.groups': 'Skupiny',
		'menu.setting': 'Globální nastavení',
		'menu.devices': 'Zařízení',
		'menu.chartsMode': 'Porovnání',

		'menu.devices.autoRenewal': 'Po expiraci automaticky aktivovat na další rok.',
		'menu.devices.expEmailNotif': 'Zaslat e-mail při expiraci zařízení.',

		'user.global.remains': 'Zbývá',
		'user.global.autocredit': 'Auto obnova kreditů',
		'user.global.credits': 'Kreditů',
		'user.global.price': 'Cena',
		'user.global.totalPrice': 'Celková cena',
		'user.global.totalCredits': 'Celkem SMS',
		'user.global.perSMS': 'za SMS',
		'user.global.buy': 'Koupit',
		'user.global.notifManagment': 'Správa SMS',
		'user.name': 'Jméno',
		'user.surname': 'Příjmení',

		'sensor.name': 'Název',
		'sensor.state': 'Stav',
		'sensor.battery': 'Baterie',
		'sensor.signal': 'Signál',
		'sensor.type': 'Typ',
		'sensor.info1': 'Info 1',
		'sensor.info2': 'Info 2',
		'sensor.actions': 'Akce',
		'sensor.lastMessage': 'Poslední zpráva',
		'sensor.edit': 'Editovat',
		'sensor.delete': 'Odstranit',
		'sensor.activate': 'Aktivovat',
		'sensor.activation': 'Aktivuji...',
		'sensor.proccessing': 'Zpracovávám',
		'sensor.deleting': 'Odstraňuji...',
		'sensor.id': 'ID',
		'sensor.pac': 'PAC',
		'sensor.serialNumber': 'Sériové číslo',
		'sensor.mac': 'MAC adresa',
		'sensor.fw': 'Verze firmware',
		'sensor.topupCode': 'Dobíjecí kód',
		'sensor.transmissionPeriod': 'Četnost přenosu',
		'sensor.notifEmail': 'Notifikační email',
		'sensor.phonenumber': 'Telefonní číslo',
		'sensor.forsmsnotif': 'Pro SMS notifikace',
		'sensor.forcall': 'Pro kontaktování',
		'sensor.notifLang': 'Jazyk notifikací',
		'sensor.email': 'Email',
		'sensor.token': 'Token',
		'sensor.expirationDate': 'Datum expirace',
		'sensor.hours': 'Hodiny',
		'sensor.minutes': 'Minuty',
		'sensor.minutesDescription': 'minut',
		'sensor.hoursDescription': 'hodin',
		'sensor.lowLimit': 'Spodní limit',
		'sensor.highLimit': 'Horní limit',
		'sensor.alarmRaised': 'Alarm aktivní !',
		'sensor.notActivated': 'Není aktivován !',
		'sensor.alarmOK': 'Alarm neaktivní',
		'sensor.tempAlarms': 'Alarm teploty',
		'sensor.tempAlarms.inner': 'Alarm teploty (vnitřní)',
		'sensor.tempAlarms.ext': 'Alarm teploty (externí)',
		'sensor.humAlarms': 'Alarm vlhkosti',
		'sensor.activationPeriod': 'Aktivační období',
		'sensor.activationSeason': 'Aktivační perioda',
		'sensor.notavailable': 'Není dostupné',
		'sensor.meassure': 'Měří',
		'sensor.device': 'Zařízení',
		'sensor.position': 'Pozice',
		'sensor.correct': 'Korektní',
		'sensor.incorrect': 'Nevalidní',
		'sensor.flood': 'Zaplavení',
		'sensor.detected': 'Detekováno',
		'sensor.notDetected': 'Nedetekováno',
		'sensor.connectedSensors': 'Připojené senzory',
		'sensor.binaryInput': 'Binární vstup',
		'sensor.ioInput': 'I/O vstup',
		'sensor.sensor': 'Senzor',
		'sensor.address': 'Adresa',
		'sensor.radius': 'Poloměr',
		'sensor.noSensor': 'Žádné registrované zařízení.',

		'sensor.input.binary.alarm.flood': 'Alarm při zaplavení',
		'sensor.input.binary.alarm.doorOpen': 'Alarm při otevření dveří',
		'sensor.input.binary.alarm.doorClosed': 'Alarm při zavření dveří',
		'sensor.input.binary.alarm.doorActivity': 'Alarm při zmněně stavu dveří',
		'sensor.input.binary.alarm.smoke': 'Alarm při detekci kouře',
		'sensor.input.binary.alarm.motion': 'Alarm při detekci pohybu',
		'sensor.input.risingEdge': 'Alarm při sepnutí',
		'sensor.input.fallingEdge': 'Alarm při rozepnutí',

		'sensor.input.binary.type.flood': 'Detektor zaplavení',
		'sensor.input.binary.type.doorOpen': 'Detektor otevření dvěří',
		'sensor.input.binary.type.smoke': 'Detektor kouře',
		'sensor.input.binary.type.motion': 'Detektor pohybu',
		'sensor.input.binary.type.general': 'Obecný detector',

		'button.addDevice': 'Přidat zařízení',
		'button.OK': 'OK',
		'button.send': 'Poslat',
		'button.home': 'Domů',
		'button.back': 'Zpět',
		'button.addAndActivate': 'Přidat a aktivovat',
		'button.add': 'Přidat',
		'button.saveSensor': 'Uložit',
		'button.login': 'Přihlásit',
		'button.signin': 'Přihlásit',
		'button.delete': 'Smazat',
		'button.cancel': 'Zrušit',
		'button.signout': 'Odhlásit',
		'button.toggle.protection': 'Zapnout ochranu',
		'button.toggle.activation': 'Aktivovat',
		'button.toggle.notifications': 'Nastavit notifikace',
		'button.toggle.useGlobalNotif': 'Použít globální nastavení',
		'button.toggle.autoRenewal': 'Automatická obnova aktivace',
		'button.filter': 'Filtrovat',
		'button.topup': 'Dobít',
		'button.continue': 'Pokračovat',
		'button.close': 'Zavřít',
		'button.enable': 'Povolit',
		'button.update': 'Aktualizovat',
		'button.save': 'Uložit',
		'button.saveSettings': 'Uložit nastavení',
		'button.compare': 'Porovnat',
		'button.selectDate': 'Vybrat datum',
		'button.export': 'Exportovat',
		'button.buyCredit': 'Koupit kredit',
		'button.show': 'Zobrazit',
		'button.closeAll': 'Zavřít vše',

		'modal.deviceIsLocked': 'Selhalo! Zařízení je zamčené.',
		'modal.deviceIsLocked.protected': 'Následující den zařízení přejde do stavu ZAKÓDOVÁNO.',
		'modal.deviceIsLocked.unprotected': 'Následující den zařízení přejde do stavu ODKÓDOVÁNO.',
		'modal.postSuccess': 'Zařízení bylo úspěšně vytvořeno.',
		'modal.editSuccess': 'Zařízení bylo úspěšně aktualizováno.',
		'modal.encodedSuccessfully': 'Zařízení bylo úspěšně zakódováno',
		'modal.decodedSuccessfully': 'Zařízení bylo úspěšně odkódováno',
		'modal.user.editNotifSuccess': 'Notifikace byly úspěšně aktualizovány.',
		'modal.user.userUpdated': 'Informace byly úspěšně aktualizovány.',
		'modal.devices.deviceUpdated': 'nastavení bylo úspěšně aktualizováno.',
		'modal.deleteSuccess': 'Zařízení bylo úspěšně smazáno.',
		'modal.delete1': 'V případě smazání zařízení budou nenávratně smazána všechna jeho data.',
		'modal.delete2': 'Opravdu chcete zařízení smazat?',
		'modal.deleteData': 'Smazat také všechna uložená data.',
		'modal.insertTopupCode': 'Vložte svůj dobíjecí kód.',
		'modal.activation.success': 'Aktivace zařízení proběhla úspěšně.',
		'modal.activation.failed': 'Aktivace byla neúspěšná. Nedostatečný počet kreditů.',
		'modal.smstopup.success': 'Vaše SMS byly úspěšně navýšeny.',
		'modal.activation.summary': 'Souhrn aktivace: ',
		'modal.activation.continue': 'Chcete pokračovat?',
		'modal.activation.summary.price': 'Cena',
		'modal.activation.summary.period': 'Období',
		'modal.topup.period': 'Vyberte časové období.',
		'modal.topup.verifying': 'Ověřování kódu ...',
		'modal.topup.success': 'Vaše kredity byly úspěšně navýšeny.',
		'modal.topup.failed': 'Chyba - nevalidní dobíjecí kód.',
		'modal.globalError': 'Ups - někde se stala chyba.',
		'modal.report.sent': 'Úspěšně odesláno.',
		'modal.report.thanks': 'Děkujeme',
		'modal.user.delete.header': 'Vymazání uživatelského účtu.',
		'modal.user.delete.text': 'Tento uživatelský účet bude kompletně vymazán z databáze. Poté se k tomuto účtu již nebude možné přihlásit.',
		'modal.user.delete.text2': 'Všechna zařízení přidružená k tomuto účtu budou nenávratně smázána včetně všech jejich dat.',
		'modal.user.delete.text3': "Všechna 'Aktivní' zařízení zůstanou aktivovaná.",
		'modal.user.delete.text4': 'Všechen uživatelský kredit bude vymazán.',
		'modal.user.delete.text5': 'Opravdu chcete smazat tento uživatelský účet ?',
		'modal.user.deleted': 'Váš účet byl úspěšně smazán.',

		'errorMessage.name': 'Název musí obsahovat 1 až 64 znaků.',
		'errorMessage.id.siglink': 'ID musí obsahovat 8 HEX znaků.',
		'errorMessage.id.wicub': 'ID musí obsahovat 6 HEX znaků.',
		'errorMessage.pac': 'PAC musí obsahovat 16 HEX znaků.',
		'errorMessage.mac': 'MAC adresa je v nevalidním formátu.',
		'errorMessage.serialNumber': 'Sériové číslo musí obsahovat minimálně 10 znaků.',
		'errorMessage.notifEmail': 'E-mail je v nesprávném formátu.',
		'errorMessage.activationToken': 'Aktivační klíč musí obsahovat 25 alfanumerických znaků.',
		'errorMessage.hoursAndMinutes': 'Četnost přenosu je mimo povolený interval.',
		'errorMessage.limits': 'Nevalidní hodnota, nebo je spodní limit větší než horní limit.',
		'errorMessage.address': 'Neznámá adresa.',
		'errorMessage.radius': 'Nevalidní hodnota poloměru plochy.',
		'errorMessage.notifNotSupported': 'Váš prohlížeč nepodporuje push notifikace.',
		'errorMessage.notifDenied': 'Musíte udělit práva pro zasílání push notifikací ve vašem prohlížeči.',

		'error.signin.UserNotFoundException': 'Uživatel neexistuje !',
		'error.signin.NotAuthorizedException': 'Chybný email nebo heslo !',
		'error.signup.UsernameExistsException': 'Účet s tímto emailem již existuje !',
		'error.code.CodeMismatchException': 'Neplatný verifikační kód.',
		'error.code.UserNotFoundException': 'Neexistující účet, nelze ověřit.',
		'error.code.NotAuthorizedException': 'Tento účet je již ověřen.',
		'error.code.LimitExceededException': 'Překročen limit, zkuste opět za chvíli.',
		'error.code.ExpiredCodeException': 'Váš authorizační kód expiroval. Nechte si, prosím, zaslat nový.',

		'chart.temperature': 'Teplota',
		'chart.temperature.inner': 'Vnitřní teplota',
		'chart.temperature.ext': 'Externí teplota',
		'chart.humidity': 'Vlhkost',
		'chart.pressure': 'Tlak',
		'chart.iaq': 'Kvalita vzduchu',
		'chart.leakageDiagram': 'Diagramy',
		'chart.period': 'Období',
		'chart.from': 'Od',
		'chart.to': 'Do',
		'chart.title': 'Grafy',
		'chart.loading': 'Načítám data...',
		'chart.exporting': 'Plním report...',
		'chart.export.failed': 'Export nebyl úspěšný.',
		'chart.gantt.deviceOn': 'Zařízení zapnuto',
		'chart.gantt.wrongPosition': 'Nevalidní poloha',
		'chart.gantt.leakage': 'Zaplavení',
		'chart.last15Events': 'Posledních 15 událostí',
		'chart.gpsMsgLogs': 'Výpis přijatých zpráv',
		'chart.receivedGPSCoordinates': 'Přijaté souřadnice',

		'chart.menu.bullets.on': 'Zobrazit/Skrýt body',
		'chart.menu.bullets.off': 'Zobrazit/Skrýt body',
		'chart.menu.pdf': 'Export do .pdf',
		'chart.menu.csv': 'Export do .csv',
		'chart.menu.xlsx': 'Export do .xlsx (excel)',
		'chart.menu.limits': 'Zobrazit/Skrýt limity',
		'chart.menu.zoomin': 'Zvětšit',
		'chart.menu.zoomout': 'Zmenšit',
		'chart.menu.zoomAlarm': 'Přiblížit alarm',
		'chart.menu.average': 'Aritmetický průměr',
		'chart.menu.min': 'Minimum',
		'chart.menu.max': 'Maximum',
		'chart.menu.multiAxis': 'Zobraz více os',
		'chart.menu.ZoomToLastPosition': 'Přiblížit poslední známou pozici',

		'chart.menu.lineType.line': 'Čárový',
		'chart.menu.lineType.bar': 'Sloupcový',
		'chart.menu.aggrType.sum': 'Součet',
		'chart.menu.aggrType.diff': 'Rozdíl',
		'chart.menu.aggrType.min': 'Minimum',
		'chart.menu.aggrType.max': 'Maximum',
		'chart.menu.aggrType.average': 'Průměr',
		'chart.menu.periodType.year': 'Rok',
		'chart.menu.periodType.month': 'Měsíc',
		'chart.menu.periodType.week': 'Týden',
		'chart.menu.periodType.day': 'Den',
		'chart.menu.periodType.hour': 'Hodina',
		'chart.menu.periodType.minute': 'Minuta',

		'chart.menu.player.stepBack': 'Krok zpět',
		'chart.menu.player.stop': 'Zastavit',
		'chart.menu.player.pause': 'Pozastavit',
		'chart.menu.player.play': 'Přehrát',
		'chart.menu.player.stepForward': 'Krok vpřed',

		'chart.gantt.log.armingOn': 'Zařízení bylo zapnuto.',
		'chart.gantt.log.armingOff': 'Zařízení bylo vypnuto.',
		'chart.gantt.log.positionOn': 'Zařízení je v nevalidní poloze.',
		'chart.gantt.log.positionOff': 'Zařízení přešlo do korektní polohy.',
		'chart.gantt.log.leakageOn': 'Detekováno zaplavení.',
		'chart.gantt.log.leakageOff': 'Hladina zaplavení klesla pod 2 milimetry.',

		'chart.gps.noSignal': 'GPS signál nenalezen.',
		'chart.gps.receivedSerisData': 'Prijata servisní data.',
		'chart.gps.location': 'Lokace',
		'chart.gps.time': 'Čas',

		'chart.export.setting': 'Nastavení exportu',
		'chart.export.name': 'Jméno exportu',
		'chart.export.delimiter': 'Oddělovač',
		'chart.export.decimalSeparator': 'Desetinný oddělovač',
		'chart.export.decimalPlaces': 'Desetinná místa',

		'select.24hours': 'Posledních 24 hodin',
		'select.7days': 'Posledních 7 dnů',
		'select.30days': 'Posledních 30 dnů',
		'select.sensor': 'Hledat',
		'select.period.days': 'Dní',
		'select.period.free': 'Zdarma',
		'select.period.1': '1 měsíc',
		'select.period.3': '3 měsíce',
		'select.period.6': '6 měsíců',
		'select.period.12': '1 rok',
		'select.period.24': '2 roky',
		'select.period.autoRenewal': 'Po expiraci automaticky prodloužit na zvolenou periodu.',
		'select.itemsOnPage': 'Položek na stránce',
		'select.of': 'z',

		'tooltip.state': 'Stav zařízení',
		'tooltip.state.active': 'Aktivní',
		'tooltip.state.expired': 'Expirováno',
		'tooltip.state.encoded': 'Zakódováno',
		'tooltip.state.decoded': 'Odkódováno',
		'tooltip.state.locked': 'Uzamčeno',
		"tooltip.state.disabled": "Neaktivní",
		'tooltip.battery': 'Úroveň baterie',
		'tooltip.battery.idle': 'Úroveň nezatížené baterie',
		'tooltip.signal': 'Úroveň signálu',
		'tooltip.edit': 'Editovat zařízení',
		'tooltip.deleteSensor': 'Smazat zařízení',
		'tooltip.activateSensor': 'Aktivovat zařízení',
		'tooltip.protectSensor': 'Zapnout/vypnout ochranu',
		'tooltip.pressure': 'Tlak',
		'tooltip.humidity': 'Vlhkost',
		'tooltip.temperature': 'Teplota',
		'tooltip.iaq': 'Kvalita vzduchu',
		'tooltip.creditsCount': 'Kredity',
		'tooltip.flood': 'Zaplavení',
		'tooltip.binary': 'Binární vstup',
		'tooltip.gpsTracking': 'GPS sledování',
		'tooltip.freeActivationPeriod': 'Bude aplikováno pouze při první registraci zařízení.',
		'tooltip.msgType': 'Typ zprávy',
		'tooltip.msgType.service': 'Servisní',
		'tooltip.msgType.gps': 'GPS souřadnice',
		'tooltip.msgType.timeout': 'GPS nenalezeno',
		'tooltip.unknown': 'Neznamé',
		'tooltip.autoRenewal': 'Po každé expiraci bude zařízení automaticky aktivováno na zvolenou periodu.',

		'tooltip.binInput.doorOpen': 'Detektor otevření',
		'tooltip.binInput.smoke': 'Detektor kouře',
		'tooltip.binInput.flood': 'Detektor zaplavení',
		'tooltip.binInput.motion': 'Detektor pohybu',
		'tooltip.binInput.binary': 'Obecný detektor',

		'tooltip.binInput.doorOpen.0': 'Dveře zavřeny',
		'tooltip.binInput.doorOpen.1': 'Dveře otevřeny',
		'tooltip.binInput.smoke.0': 'Kouř nedetekován',
		'tooltip.binInput.smoke.1': 'Kouř detekován',
		'tooltip.binInput.flood.0': 'Nezaplaveno',
		'tooltip.binInput.flood.1': 'Zaplaveno',
		'tooltip.binInput.motion.0': 'Pohyb nedetekován',
		'tooltip.binInput.motion.1': 'Pohyb detekován',
		'tooltip.binInput.binary.0': 'Rozepnuto',
		'tooltip.binInput.binary.1': 'Sepnuto',

		'tooltip.info.lastTemperature': 'Aktuální teplota',
		'tooltip.info.lastTemperature.inner': 'Aktuální vnitřní teplota',
		'tooltip.info.lastTemperature.ext': 'Aktuální venkovní teplota',
		'tooltip.info.lastHumidity': 'Aktuální vlhkost',
		'tooltip.info.lastPressure': 'Aktuální tlak',
		'tooltip.info.lastIaq': 'Aktuální kvalita vzduchu',
		'tooltip.info.lastMsgTime': 'Poslední přijatá zpráva',
		'tooltip.info.id': 'ID zařízení',
		'tooltip.info.leakageON': 'Zaplaveno',
		'tooltip.info.leakageOFF': 'Nezaplaveno',
		'tooltip.info.positionKO': 'Špatná poloha',
		'tooltip.info.positionOK': 'Korektní poloha',
		'tooltip.info.charging': 'Nabíjí se',
		'tooltip.info.notCharging': 'Nenabíjí se',
		'tooltip.info.devicePowerON': 'Zapnuto',
		'tooltip.info.devicePowerOFF': 'Vypnuto',
		'tooltip.info.switchState': 'Stav spínače',
		'tooltip.info.open': 'Otevřeno',
		'tooltip.info.closed': 'Zavřeno',

		'serviceWorker.appUpdate': 'K dispozici je nová verze aplikace.',

		'https.unknown': 'Nastala neočekávaná chyba. Prosím, odešlete záznam o chybě.',
		'https.unknown.header': 'Ups - něco se pokazilo',
		'https.unknown.sendReport': 'Klikněte pro odeslání záznamu o chybě.',
		'https.addSensor.insufficientAmmount': 'Nedostatečný počet kreditů.',
		'https.addSensor.insufficientAmmount.header': 'Dobijte kredity.',
		'https.addSensor.alreadyAdded': 'Toto zařízení je již použiváno na Vašem či jiném účtě.',
		'https.addSensor.alreadyAdded.header': 'Duplicitní zařízení.',
		'https.addSensor.sensorTypeMissmatch': 'Přidáváte zařízení, které není kompatibilní se zvoleným typem. Vraťte se zpět a zvolte správný typ zařízení.',
		'https.addSensor.sensorTypeMissmatch.header': 'Špatný typ zařízení',
		'https.invalidField': 'Jedno z vyplněných polí obsahuje nevalidní hodnotu',
		'https.invalidField.header': 'Nevalidní hodnota.',
		'https.lowPermissions': 'Nemáte dostatečná práva pro vykonání tohoto požadavku.',
		'https.lowPermissions.header': 'Přístup zamítnut.',
		'https.invalidPassword': 'Vámi zadané heslo se neshoduje s vaším současným heslem.',
		'https.invalidPassword.header': 'Nevalidní heslo.',
		'https.limitExceeded': 'Překročen limit pro opakovanou změnu hesla. Zkuste to, prosím, opět za chíli.',
		'https.limitExceeded.header': 'Překročen limit.',
		'https.activationLimit': 'Aktivace již proběhla. Další aktivaci je možné vykonat až po uplynutí jedné hodiny od předchozí aktivace.',
		'https.activationLimit.header': 'Překročen limit aktivací',
	},
};
