import React from 'react';

const mainStates = {
	ACTIVE: 'ACTIVE',
	EXPIRED: 'EXPIRED',
	NEW: 'NEW',
};

const withSensorState = (WrappedComponent) => {
	function WithSensorState(props) {
		let labelId = null;

		if (props.sensor) {
			const { state, force_locked, protected: isProtected } = props.sensor;
			if (state === mainStates.EXPIRED || state === mainStates.NEW) {
				labelId = 'tooltip.state.expired';
			} else if (state === mainStates.ACTIVE && force_locked === undefined && isProtected === undefined) {
				labelId = 'tooltip.state.active';
			} else if (force_locked) {
				labelId = 'tooltip.state.disabled';
			} else {
				labelId = isProtected ? 'tooltip.state.encoded' : 'tooltip.state.decoded';
			}
		}

		return <WrappedComponent {...props} labelId={labelId} />;
	}

	return WithSensorState;
};

export default withSensorState;
