import React, { Component } from 'react';
import { connect } from 'react-redux';

import { fetchMultipleData } from './../../../../store/actions/sensorActions.js';

import ComparatorModal from './../../../../components/modals/ComaparatorModal/ComparatorModal';


const oneDayTimestamp = 24 * 60 * 60;
const oneWeekTimestamp = oneDayTimestamp * 7;
const oneMonthTimestamp = oneDayTimestamp * 30;

class Comparator extends Component {
	constructor(props) {
		super(props);

		let startPeriod = oneDayTimestamp;
		let multiplier = 3;

		this.state = {
			dateFrom: new Date(new Date().getTime() - startPeriod * multiplier * 1000),
			dateTo: new Date(),
			//dateFrom: new Date(2020, 0, 29, 12, 30 , 0),
			//dateTo: new Date(2020, 1, 28, 12, 30 , 0),
			selectedPeriod: '',
			isFilteringEnabled: true,
			isGraphLoading: false,
			dateSelectionOn: false,
			dateSelectionOpened: false,
			isClosing: false
		};
	}

	componentDidMount() {
		const timestampFrom = this.state.dateFrom.getTime() / 1000;
		const timestampTo = this.state.dateTo.getTime() / 1000;

		this.props.fetchMultipleData(concatSensors(this.props.sensors), timestampFrom, timestampTo);
	}

	getCurrentTimestamp = () => {
		const currentTimestamp = Math.round(new Date().getTime() / 1000);
		return currentTimestamp;
	};

	handlePeriodSelect = e => {
		switch (e.value) {
			case 'last24hours':
				this.setState({
					...this.state,
					dateFrom: new Date((this.getCurrentTimestamp() - oneDayTimestamp) * 1000),
					dateTo: new Date(),
					selectedPeriod: 'last24hours',
					isFilteringEnabled: true
				});
				break;
			case 'last7days':
				this.setState({
					...this.state,
					dateFrom: new Date((this.getCurrentTimestamp() - oneWeekTimestamp) * 1000),
					dateTo: new Date(),
					selectedPeriod: 'last7days',
					isFilteringEnabled: true
				});
				break;
			case 'last30days':
				this.setState({
					...this.state,
					dateFrom: new Date((this.getCurrentTimestamp() - oneMonthTimestamp) * 1000),
					dateTo: new Date(),
					selectedPeriod: 'last30days',
					isFilteringEnabled: true
				});
				break;

			default:
				this.setState({ ...this.state, selectedPeriod: '', isFilteringEnabled: false });
				break;
		}
	};

	handleDateFromChange = date => {
		date.setHours(0);
		date.setMinutes(0);
		date.setSeconds(0);

		let enableFiltering = true;
		if (!date) enableFiltering = false;

		this.setState({ ...this.state, dateFrom: date, selectedPeriod: '', isFilteringEnabled: enableFiltering });
	};

	handleDateToChange = date => {
		date.setHours(23);
		date.setMinutes(59);
		date.setSeconds(59);

		if (date < this.state.dateFrom) {
			date = this.state.dateFrom;
		}
		let enableFiltering = true;
		if (!date) enableFiltering = false;

		this.setState({ ...this.state, dateTo: date, selectedPeriod: '', isFilteringEnabled: enableFiltering });
	};

	handleMeasurementsFilter = () => {
		if (this.state.dateFrom && this.state.dateTo) {
			const dateFrom = Math.round(this.state.dateFrom.getTime() / 1000);
			const dateTo = Math.ceil(this.state.dateTo.getTime() / 1000);
			this.closeModal();

			this.props.fetchMultipleData(concatSensors(this.props.sensors), dateFrom, dateTo);
		}
	};

	onOpenDateSelectionHandler = e => {
		if (!this.state.dateSelectionOpened) {
			this.setState({
				...this.state,
				dateSelectionOpened: true,
				isClosing: false
			});
		}
	};
	selectionClosedHandler = () => {
		this.closeModal();
	};

	closeModal = () => {
		if (this.state.dateSelectionOpened) {
			this.setState({
				...this.state,
				isClosing: true
			});
			setTimeout(() => {
				this.setState({
					...this.state,
					dateSelectionOpened: false
				});
			}, 500);
		}
	};
	render() {
		return (

			<ComparatorModal
				isOpen={this.props.isOpen}
				close={this.props.close}
				lang={this.props.lang}
				selectedPeriod={this.state.selectedPeriod}
				dateFrom={this.state.dateFrom}
				dateTo={this.state.dateTo}
				onSelect={this.handlePeriodSelect}
				onDateFromChange={this.handleDateFromChange}
				onDateToChange={this.handleDateToChange}
				onFilterButtonClick={this.handleMeasurementsFilter}
				filterDisabled={!this.state.isFilteringEnabled}
				dateSelectionOn={this.state.dateSelectionOn}
				graphLoading={this.props.isGraphLoading}
				data={this.props.measurements}
				sensors={this.props.sensors}
				onOpenDateSelection={this.onOpenDateSelectionHandler}
				closeDateSelection={this.selectionClosedHandler}
				selectionOn={this.state.dateSelectionOpened}
				isClosing={this.state.isClosing}
			/>
		);
	}
}

const concatSensors = sensors => {
	const parts = [];
	const output = [];

	let order = sensors.reduce((total, sensor) => {
		if (!total.includes(sensor.id)) return [...total, sensor.id];
		else return total;
	}, []);

	sensors.forEach(sensor => {
		parts[sensor.id] = {
			type: sensor.type,
			id: sensor.id,
			series: parts[sensor.id] ? [...parts[sensor.id].series, sensor.name] : [sensor.name]
		};
	});

	order.forEach(id => {
		output.push(parts[id]);
	});

	return output;
};

const mapStateToProps = state => ({
	isGraphLoading: state.sensors.isGraphLoading,
	measurements: state.sensors.sensorMeasurements,
	lang: state.locale.lang
});

export default connect(mapStateToProps, {
	fetchMultipleData
})(Comparator);
