import React from 'react';


import classes from './State.module.scss';
import State from './../../../../icons/State/State';

const state = props => {
	const state = props.state.toLowerCase();

	/*
	let stateClasses = [];
	switch (state) {
		case 'active':
			stateClasses = ['far fa-check-square', classes.active];
			break;
		case 'expired':
			stateClasses = ['fas fa-ban', classes.expired];
			break;
		case 'new':
			stateClasses = ['fas fa-plus', classes.new]; //fas fa-exclamation-triangle
			break;
		default:
			stateClasses = ['fas fa-ban', classes.expired];
			break;
	}
	*/
	let content = null;
	if (!props.hidden) {
		content = (
			<div className={props.className}>
				{props.label ? <p className={classes.label}>{props.label} :</p> : null}
				{/* disabled state only for state "protected" and "unprotected" for now */}
				<State
					disabled={props.disabled}
					state={state}
					className={classes.iconState}
					tooltip={props.tooltip}
				/>
			</div>
		);
	}
	return content;
};

export default state;
