import React from "react";


import classes from  "./Menu.module.scss";

const menu = (props) => {


    const item1Classes = [classes["menu-item"], classes["grey"]];
    const item2Classes = [classes["menu-item"], classes["grey"]];
    const item3Classes = [classes["menu-item"], classes["grey"]];
    const item4Classes = [classes["menu-item"], classes["grey"]];

    if(props.item1Class) item1Classes.push(props.item1Class);
    if(props.item2Class) item2Classes.push(props.item2Class);
    if(props.item3Class) item3Classes.push(props.item3Class);
    if(props.item4Class) item4Classes.push(props.item4Class);


    return (
        <div className={classes.menu} id={"menu-open-container" + r()}>
            <input type="checkbox"  className={classes["menu-open"]} name="menu-open" id={"menu-open-" + props.id}/>
            <label id={"menu-open-label" + r()} className={classes["menu-open-button"]} htmlFor={"menu-open-" +props.id}>
                <span id={"menu-open-line1" +r()} className={[classes["lines"], classes["line-1"]].join(" ")} />
                <span id={"menu-open-line2" + r()} className={[classes["lines"], classes["line-2"]].join(" ")} />
                <span id={"menu-open-line3" + r()} className={[classes["lines"], classes["line-3"]].join(" ")} />
            </label>
            <i id={"menu-open-item1" + r()} className={item1Classes.join(" ")}> {props.item1} </i>
            <i id={"menu-open-item2" + r()} className={item2Classes.join(" ")}> {props.item2} </i>
            {/*<a href="#" className="menu-item red"> <i className="fa fa-heart" /> </a>
            <a href="#" className="menu-item purple"> <i className="fa fa-microphone" /> </a>
    <a href="#" className="menu-item orange"> <i className="fa fa-star" /> </a>*/}
            <i id={"menu-open-item3" + r()} className={item3Classes.join(" ")}> {props.item3} </i>
            {props.item4 ? <i id={"menu-open-item4" + r()} className={item4Classes.join(" ")}> {props.item4} </i> :null }
        </div>
    );
};

const r = () =>{
    return String(Math.round(Math.random() * 1000));
};

export default menu;