import React from 'react';

import Tooltip from "./../../Tooltip/Tooltip";

const Arrow = props => {
	let content = null;
	let viewBox = '0 0 50 50';
	switch (props.type) {
		case 'toEnd':
			content = (
				<React.Fragment>
					<g>
						<path
							fillRule="evenodd"
							stroke="black"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeMiterlimit={10}
							d="M37.612 1.98h-5.996v15.125L13.612 2.011l-.062 7.97H.612v17.968H13.55v8.031l18.066-15.375v15.373h5.996V1.98z"
						/>
					</g>
				</React.Fragment>
			);
			viewBox = '0 0 39 38';
			break;
		default:
			content = (
				<React.Fragment>
					<g>
						<path
							fillRule="evenodd"
							stroke="black"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeMiterlimit={10}
							d="M34.882 10.711v16.994H21.929v8.483l-19.96-17L21.923 1.944v8.772l12.959-.005z"
						/>
					</g>
				</React.Fragment>
			);
			viewBox = '0 0 38 38';
			break;
	}
	return (
		<div {...props}>
			<Tooltip tooltip={props.tooltip}>
				<svg width="100%" viewBox={viewBox}>
					{content}
				</svg>
			</Tooltip>
		</div>
	);
};

export default Arrow;
