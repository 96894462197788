import React, { Component } from 'react';

import Auth from '@aws-amplify/auth';
import { FormattedMessage, injectIntl } from 'react-intl';
import LangPanel from './../../../components/common/LangPanel/LangPanel.jsx';
import Input from './../../../components/UI/Inputs/AuthInputs/SimpleInput/SimpleInput.jsx';
import SubmitButton from './../../../components/UI/Inputs/AuthInputs/SubmitInput/SubmitInput.jsx';
import { EMAIL_PATTERN } from './../../../utils/InputPatterns.js';
import ConfirmModal from './../../../components/modals/AuthModals/ConfirmModal/ConfirmModal.jsx';
import Spinner from './../../../components/Spinner/Spinner.jsx';

import classes from './../Auth.module.scss';

const texts = {
	back: <FormattedMessage id="login.back" defaulMessage="Back to sign in" />,
	resetPassword: <FormattedMessage id="login.resetPsw" defaulMessage="Reset password" />
};

const inputs = [
	{
		key: 'login.email',
		name: 'email',
		type: 'text',
		emptyId: 'login.email',
		tipId: 'login.email.tip'
	}
];

class ForgotPassword extends Component {
	state = {
		email: '',
		pattern: EMAIL_PATTERN,
		isValid: false,
		loading: false,
		touched: false,
		error: null,
		modal: {
			isOpen: false,
			type: 'none'
		}
	};

	initState = () => {
		this.setState({
			...this.state,
			email: '',
			isValid: false,
			loading: false,
			error: null,
			touched: false,
			modal: {
				isOpen: false,
				type: 'none'
			}
		});
	};

	onSubmit = e => {
		e.preventDefault();

		this.setState({ ...this.state, loading: true });

		Auth.forgotPassword(this.state.email)
			.then(result => {
				this.setState({ ...this.state, modal: { isOpen: true, type: 'success' }, loadig: false });
				this.props.onStateChange('requireNewPassword', {
					email: this.state.email,
					force: false
				});
			})
			.catch(err => {
				if (
					err.name === 'InvalidParameterException' &&
					err.message ===
						'Cannot reset password for the user as there is no registered/verified email or phone_number'
				) {
					this.setState({ ...this.state, modal: { isOpen: true, type: 'notConfirmed' }, loading: false });
					this.props.onStateChange('confirmSignUp', {
						email: this.state.email
					});
				} else {
					this.setState({
						...this.state,
						error: err,
						loading: false
					});
				}
			});
	};

	onInputChange = e => {
		const value = e.target.value;
		const regex = new RegExp(this.state.pattern);
		const isValid = regex.test(value);

		this.setState({
			...this.state,
			email: value,
			isValid: isValid,
			error: null,
			touched: true
		});
	};

	onBackClickHandler = () => {
		this.initState();
		this.props.onStateChange('signIn', {});
	};

	onModalCloseHandler = () => {
		this.initState();
	};

	render() {
		let forgotPassword = null;
		let button = (
			<Spinner type="dotCircle" size="8px" style={{ margin: '40px 0', marginLeft: '30%', float: 'left' }} />
		);
		let error = null;
		let modal = null;

		if (this.state.error) {
			error = (
				<h4>
					<FormattedMessage
						id={'error.signin.' + this.state.error.code}
						defaultMessage={this.state.error.message}
					/>
				</h4>
			);
		}

		if (this.state.modal.isOpen) {
			const msg_id = this.state.modal.type === 'success' ? 'login.code.sent' : 'login.signin.notconfirmed';
			modal = (
				<ConfirmModal
					isOpen={this.state.modal.isOpen}
					close={this.onModalCloseHandler}
					continue={this.onModalCloseHandler}
					confirmMsg={this.props.intl.formatMessage({
						id: msg_id
					})}
					envelopeAnimation={this.state.modal.type === 'success'}
				/>
			);
		}

		if (!this.state.loading) {
			button = (
				<SubmitButton
					value={this.props.intl.formatMessage({ id: 'login.sendCode' })}
					isEnabled={this.state.isValid}
					name="button"
					onSubmit={this.onSubmit}
				/>
			);
		}

		const jsxInputs = inputs.map(input => {
			return (
				<Input
					key={input.key}
					type={input.type}
					name={input.name}
					emptyText={this.props.intl.formatMessage({ id: input.emptyId })}
					tooltip={this.props.intl.formatMessage({ id: input.tipId })}
					isValid={this.state.isValid}
					touched={this.state.touched}
					onInputChange={this.onInputChange}
				/>
			);
		});

		if (this.props.authState === 'forgotPassword') {
			forgotPassword = (
				<div className={classes.outter}>
					<div className={classes.middle}>
						<div className={classes.container}>
							<section className={classes.content}>
								<span className={classes.topPanel}>
									<img
										className={`img-fluid ${classes.iotIcon}`}
										src={process.env.PUBLIC_URL + '/assets/img/IoTpool_logo.png'}
										alt="iot-logo"
									/>
									<LangPanel className={classes.langPanel} />
								</span>
								<form action="">
									<h1>{texts.resetPassword}</h1>
									{error}
									{jsxInputs}
									{button}
									<div>
										<p onClick={this.onBackClickHandler}>{texts.back}</p>
									</div>
								</form>
							</section>
						</div>
					</div>
				</div>
			);
		}
		return (
			<div>
				{forgotPassword}
				{modal}
			</div>
		);
	}
}

export default injectIntl(ForgotPassword);
