import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import Spinner from './../../../components/Spinner/Spinner.jsx';
import ToTopIcon from './../../../components/icons/ToTop/ToTop.jsx';

import sensors from './../../../utils/sensorTypes';

import { fetchSensorByIdDirect } from './../../../store/actions/sensorActions.js';

import classes from './SensorDetail.module.scss';

const Siglink = React.lazy(() => import('./Siglink/Siglink.jsx'));
const Wicub2 = React.lazy(() => import('./Wicub2/Wicub2.jsx'));
const SimpleLeak = React.lazy(() => import('./SimpleLeak/SimpleLeak.jsx'));
const SiotechGPS = React.lazy(() => import('./siotech/SiotechGPS/SiotechGPS.jsx'));
const SiotechUAlarm = React.lazy(() => import('./siotech/unior/SiotechUniorAlarm/SiotechUniorAlarm.jsx'));
const SiotechUSecurity = React.lazy(() => import('./siotech/unior/SiotechUniorSecurity/SiotechUniorSecurity.jsx'));
const Ste2 = React.lazy(() => import('./STE2/STE2'));

class SensorDetail extends Component {
	constructor(props) {
		super(props);
		this.id = props.id;
		const regex = new RegExp('\\?type=.+$');
		this.type = regex.exec(this.props.history.location.search);

		if (this.type) {
			this.type = this.type[0].split('=')[1];
		}
	}

	componentDidMount() {
		this.props.fetchSensorById(this.id, this.type);
	}

	render() {
		let type = this.props.sensor ? this.props.sensor.sensor_type : null;
		const spinner = <Spinner type="rectangles" size="11px" style={{ marginTop: '100px' }} />;

		let content = spinner;
		let buttons = null;

		let TargetComponent = null;
		let startPeriod = 'day';
		let multiplier = 3;

		switch (type) {
			case sensors.siglink.iot_label:
				TargetComponent = Siglink;
				break;
			case sensors.wicub.iot_label:
				TargetComponent = Wicub2;
				break;
			case sensors.simpleLeak.iot_label:
				TargetComponent = SimpleLeak;
				startPeriod = 'month';
				multiplier = 1;
				break;
			case sensors.ste2.iot_label:
				TargetComponent = Ste2;
				break;
			case sensors.siotech_unior_alarm.iot_label:
				TargetComponent = SiotechUAlarm;
				break;
			case sensors.siotech_unior_security.iot_label:
				TargetComponent = SiotechUSecurity;
				break;
			case sensors.siotech_gps_industrial.iot_label:
			case sensors.siotech_gps.iot_label:
				TargetComponent = SiotechGPS;
				multiplier = 1;
				break;
			default:
				TargetComponent = null;
				break;
		}

		if (!this.props.loading) {
			content = TargetComponent && (
				<Suspense fallback={spinner}>
					<TargetComponent
						id={this.id}
						type={this.type}
						history={this.props.history}
						sensor={this.props.sensor}
						startPeriod={startPeriod}
						multiplier={multiplier}
					/>
				</Suspense>
			);
			buttons = TargetComponent && (
				<div className={classes.buttonWrapper}>
					<ToTopIcon className={classes.toTop} onClick={() => window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })} />

					<Link to="/">
						<button className="btn btn-md btn-secondary">
							<FormattedMessage id="button.home" defaultMessage="Back" />
						</button>
					</Link>
				</div>
			);
		}

		return (
			<div className={'centerContainer'}>
				<div className={classes.buttonWrapperSimple}>
					<Link to="/">
						<button className="btn btn-md btn-secondary">
							<FormattedMessage id="button.home" defaultMessage="Back" />
						</button>
					</Link>
				</div>
				{content}
				{buttons}
			</div>
		);
	}
}

const mapStateToProps = (state, props) => {
	return {
		sensor: state.sensors.sensors.find((sensor) => sensor.id === props.id),
		loading: state.sensors.isLoading,
	};
};

const mapDispatchToState = (dispatch) => {
	return {
		fetchSensorById: (sensor_id, type) => dispatch(fetchSensorByIdDirect(sensor_id, type)),
	};
};

export default connect(mapStateToProps, mapDispatchToState)(SensorDetail);
