import React from "react";

import { FormattedMessage } from "react-intl";
import SideNav, { NavItem, NavIcon, NavText } from "@trendmicro/react-sidenav";

import classes from "./Sidebar.module.scss";

const sidebar = (props) => {
    return (
        <div className={classes.container}>
            <SideNav onSelect={props.onSelect} className={classes.navBar} style={props.style}>
                {/*<div className={classes.iconContainer}>
				<img
					className={`img-fluid ${classes.iotIcon}`}
					src={process.env.PUBLIC_URL + '/assets/img/favicon.png'}
					alt="iot-logo"
				/>
			</div>*/}
                <SideNav.Toggle className={classes.closeButton} />
                <SideNav.Nav>
                    <NavItem eventKey="home" navitemClassName={classes.navItem} active={props.select === "home"}>
                        <NavIcon>
                            <i className={`fa fa-fw fa-home ${classes.icon}`} />
                        </NavIcon>
                        <NavText>
                            <p className={classes.text}>
                                <FormattedMessage id="menu.home" />
                            </p>
                        </NavText>
                    </NavItem>
                </SideNav.Nav>
                <SideNav.Nav>
                    <NavItem eventKey="user" navitemClassName={classes.navItem} active={props.select === "user"}>
                        <NavIcon>
                            <i className={`fas fa-user ${classes.icon}`} />
                        </NavIcon>
                        <NavText>
                            <p className={classes.text}>
                                <FormattedMessage id="menu.user" />
                            </p>
                        </NavText>
                    </NavItem>
                </SideNav.Nav>
                <SideNav.Nav>
                    <NavItem eventKey="notifications" navitemClassName={classes.navItem} active={props.select === "notifications"}>
                        <NavIcon>
                            <i className={`fas fa-bell ${classes.icon}`} />
                        </NavIcon>
                        <NavText>
                            <p className={classes.text}>
                                <FormattedMessage id="menu.notifications" />
                            </p>
                        </NavText>
                    </NavItem>
                </SideNav.Nav>
                {/* <SideNav.Nav>
					<NavItem eventKey="devices" navitemClassName={classes.navItem} active={props.select === 'devices'}>
						<NavIcon>
							<i className={`fas fa-microchip ${classes.icon}`} />
						</NavIcon>
						<NavText>
							<p className={classes.text}>
								<FormattedMessage id="menu.devices" />
							</p>
						</NavText>
					</NavItem>
				</SideNav.Nav> */}
                <SideNav.Nav>
                    <NavItem eventKey="charts" navitemClassName={classes.navItem} active={props.select === "charts"}>
                        <NavIcon>
                            <i className={`fas fa-chart-area ${classes.icon}`} />
                        </NavIcon>
                        <NavText>
                            <p className={classes.text}>
                                <FormattedMessage id="menu.chartsMode" />
                            </p>
                        </NavText>
                    </NavItem>
                </SideNav.Nav>
            </SideNav>
        </div>
    );
};

export default sidebar;
