import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import classes from './TokenInput.module.scss';

class TokenInput extends Component {
	constructor(props) {
		super(props);

		this.fieldCount = 5;
		this.inputRefs = [];
		for (let i = 0; i < this.fieldCount; i++) {
			this.inputRefs.push(React.createRef());
		}
		this.inputHandler = null;
		this.column = 2;
		if (props.column) this.column = props.column;
	}

	componentDidMount() {
		for (let i = 0; i < this.fieldCount; i++) {
			if (this.props.value[i].length < 5) {
				this.inputRefs[i].current.focus();
				break;
			}
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.value.join('').length < 25) {
			for (let i = 0; i < this.fieldCount; i++) {
				if (this.props.value[i].length === 5) {
					if (prevProps.value[i] !== this.props.value[i]) {
						if (i < 4) {
							this.inputRefs[i + 1].current.focus();
							break;
						}
					}
				}
			}
		} else {
			this.inputRefs[this.fieldCount - 1].current.focus();
		}
	}

	transformReturnValue = e => {
		const part = e.target.name.match('\\d{1}$')[0];
		const value = e.target.value;
		let newValue = [...this.props.value];
		//const reg = new RegExp("^([0-9A-Z]{5}-){4}[0-9A-Z]{5}$");
		const reg = new RegExp('^([0-9A-Z]{5}){4}[0-9A-Z]{5}$');
		const reg2 = new RegExp('^([0-9A-Z]{5}[-]?){4}[0-9A-Z]{5}$');

		if (reg.test(value)) {
			//newValue = value.split("-");
			newValue = value.match(/.{5}/g);
		} else if (reg2.test(value)) {
			newValue = value.split('-');
		} else {
			newValue[part] = value.substring(0, 5);
		}

		return {
			name: this.props.name,
			value: newValue
		};
	};

	createComponent = () => {
		if (this.props.inputChange) {
			this.inputHandler = e => this.props.inputChange(e, this.transformReturnValue(e));
		}
		let fields = [];

		for (let i = 0; i < this.fieldCount; i++) {
			fields.push(
				<input
					key={`part-${i}`}
					className={`col-8 ${classes.inputField}`}
					type="text"
					onChange={this.inputHandler}
					value={this.props.value[i]}
					name={`token-part-${i}`}
					ref={this.inputRefs[i]}
				/>
			);
			if (i < this.fieldCount - 1) {
				fields.push(<div key={`dash-${i}`} className={classes.dash} />);
			}
		}

		return fields;
	};

	render() {
		return (
			<div className={`${classes.formItem}`}>
				<label className={`${classes.inputTitle}`}>
					<FormattedMessage id="sensor.topupCode" defaultMessage="Top-up code" />
					{':'}
				</label>
				<div className={classes.inputs}>{this.createComponent()}</div>
			</div>
		);
	}
}

export default TokenInput;
