
import { getValue } from "./utils";
import * as fields from "../../sensorDescriptors/Info/infos"

export function siotechUSecurityDef(sensor, intl) {
    let pirValue = getValue(sensor, fields.pir);
    let smokeValue = getValue(sensor, fields.smoke);

    if (pirValue || pirValue === 0) pirValue = intl.formatMessage({ id: `tooltip.binInput.motion.${pirValue}` });
    if (smokeValue || smokeValue === 0) smokeValue = intl.formatMessage({ id: `tooltip.binInput.smoke.${smokeValue}` });

    return [
        {
            dataset: "temperature_ext",
            value: getValue(sensor, fields.temperature_ext),
            unit: "°C",
            icon: "temperature",
            tooltip: intl.formatMessage({ id: "chart.temperature" }),
            legend:
                intl.formatMessage({ id: "chart.temperature" }) +
                `(${sensor.origName})`,
        },
        {
            dataset: "humidity",
            value: getValue(sensor, fields.humidity),
            unit: "%",
            icon: "humidity",
            tooltip: intl.formatMessage({ id: "tooltip.humidity" }),
            legend:
                intl.formatMessage({ id: "tooltip.humidity" }) + `(${sensor.origName})`,
        },
        {
            dataset: "pir",
            value: pirValue,
            unit: "",
            icon: "motion",
            tooltip: intl.formatMessage({ id: "tooltip.binInput.motion" }),
            legend:
                intl.formatMessage({ id: "tooltip.binInput.motion" }) +
                `(${sensor.origName})`,
            chartType: "step",
            subType: "motion",
        },
        {
            dataset: "smoke",
            value: smokeValue,
            unit: "",
            icon: "smoke",
            tooltip: intl.formatMessage({ id: "sensor.input.binary.type.smoke" }),
            legend:
                intl.formatMessage({ id: "sensor.input.binary.type.smoke" }) +
                `(${sensor.origName})`,
            chartType: "step",
            subType: "smoke",
        },
    ]
}