import React from 'react';

import classes from './Type2.module.scss';

const type2 = props => {
	let inputStyle = undefined;
	if(props.disabled){
		inputStyle = { cursor: 'not-allowed' };
	}
	return (
		<div className={classes.container}>
			<label className={classes.checker} style={inputStyle}>
				<input
					className={classes.checkbox}
					type="checkbox"
					onChange={props.inputChange}
					name={props.name}
					checked={props.checked}
					disabled={props.disabled}
				/>
				<div className={classes['check-bg']} />
				<div className={classes.checkmark}>
					<svg viewBox="0 0 100 100">
						<path
							d="M20,55 L40,75 L77,27"
							fill="none"
							stroke="#FFF"
							strokeWidth="15"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</div>
			</label>
		</div>
	);
};

export default type2;
