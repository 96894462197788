import React from 'react';
import { connect } from 'react-redux';

import { IntlProvider } from 'react-intl';
import messages from './../../utils/messages';

const localeProvider = props => {
	return (
		<IntlProvider locale={props.lang} messages={messages[props.lang]}>
			{props.children}
		</IntlProvider>
	);
};

const mapStateToProps = state => ({
	lang: state.locale.lang
});

export default connect(mapStateToProps)(localeProvider);
