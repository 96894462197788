import React from 'react';

import State from './State/State';

import { FormattedMessage } from 'react-intl';

import classes from './QuickStatesView.module.scss';

const quickStatesView = props => {
	const flipedData = props.data.slice().reverse();
	const deviceLastStateIdx = flipedData.findIndex(item => item.hasOwnProperty("arming"));
	const posLastStateIdx = flipedData.findIndex(item => item.hasOwnProperty("position"));
	const floodLastStateIdx = flipedData.findIndex(item => item.hasOwnProperty("leakage"));

	let devState = false;
	let posState = false;
	let floodState = false;

	if (flipedData.length > 0) {
		if (deviceLastStateIdx !== -1) devState = flipedData[deviceLastStateIdx].arming=== 1;
		if (posLastStateIdx !== -1) posState = flipedData[posLastStateIdx].position === 1;
		if (floodLastStateIdx !== -1) floodState = flipedData[floodLastStateIdx].leakage === 1;
	}

	if(!devState && (posState || floodState)){
		// device is ON - no "arming" event is among data but position or flood state are presented - that means the device is ON
		devState = true;
	}


	return (
		<span className={classes.statesContainer}>
			<div className={classes.states}>
				<State
					stateName={<FormattedMessage id="sensor.device" />}
					stateOkVal="ON"
					stateAlarmVal="OFF"
					alarmRaised={!devState}
					iconClass="fas fa-power-off"
					onIconClass={classes.onDevice}
					offIconClass={classes.offDevice}
					loading={props.isLoading}
					disabled={false}
				/>
				<State
					stateName={<FormattedMessage id="sensor.position" />}
					stateOkVal={<FormattedMessage id="sensor.correct" />}
					stateAlarmVal={<FormattedMessage id="sensor.incorrect" />}
					alarmRaised={posState}
					iconClass="fas fa-eraser"
					onIconClass={classes.onPosition}
					offIconClass={classes.offPosition}
					loading={props.isLoading}
					disabled={!devState}
				/>
				<State
					stateName={<FormattedMessage id="sensor.flood" />}
					stateOkVal={<FormattedMessage id="sensor.notDetected" />}
					stateAlarmVal={<FormattedMessage id="sensor.detected" />}
					alarmRaised={floodState}
					iconClass="fas fa-water"
					onIconClass={classes.onFlood}
					offIconClass={classes.offFlood}
					loading={props.isLoading}
					disabled={!devState}
				/>
			</div>
		</span>
	);
};

export default quickStatesView;
