import React from 'react';
import Tooltip from './../../../../Tooltip/Tooltip';

import NotAvailable from "./../NotAvailable/NotAvailable.jsx";

import classes from "./Text.module.scss";

const name = props => {

  let value = <NotAvailable />;
  let tooltip = null;

  if (props.value || (isFinite(props.value) && props.value === 0)) {
    value = props.value;
    tooltip = props.tooltip;
  }


  let content = null;
  if (!props.hidden) {
    content = (
      <div className={props.className}>
        <Tooltip tooltip={tooltip}>
          {props.label ? <p className={classes.label}>{props.label} :</p> : null}
          <div className={classes.value} >
            {value}
          </div>
        </Tooltip>
      </div>
    );
  }
  return content;

};

export default name;
