import React from 'react';

const Unknown = props => {
	return (
		<div {...props}>
			<svg width="100%" viewBox="0 0 472 202">
				<g>
					<path
						opacity={0.32}
						fill="#3E3E3E"
						d="M328.342 183.248c0-8.626-41.174-15.618-91.965-15.618-50.79 0-91.964 6.992-91.964 15.618 0 2.826 4.442 5.474 12.173 7.761-2.495 1.896 39.417 9.745 91.129 7.734 51.71-2.01 80.627-7.52 80.627-15.495z"
					/>
					<path
						fill="#5EAD46"
						d="M313.35 66.394c-28.562 17.778-37.355 51.043-12.584 49.435 14.583-.947 42.276-29.977 52.002-47.596 9.728-17.617-10.855-19.613-39.418-1.839z"
					/>
					<path
						fill="#51943B"
						d="M289.528 91.782c.238 6.13 2.25 11.807 7.77 13.98 9.603 3.774 31.929-8.11 31.948-8.12-10.154 9.881-21.026 17.703-28.48 18.188-14.92.966-17.66-10.716-11.238-24.048z"
					/>
					<path
						fill="#5EAD46"
						d="M175.592 77.131c-27.285-19.682-61.398-15.198-50.513 7.112 6.408 13.135 43.775 27.741 63.766 30.057 19.99 2.316 14.029-17.486-13.253-37.169z"
					/>
					<path
						fill="#51943B"
						d="M135.667 94.642c14.257 9.557 38.435 17.95 53.177 19.658 13.153 1.523 15.068-6.528 7.072-17.819-8.58 2.093-27.535 6.4-34.822 5.468-6.384-.816-18.252-4.77-25.427-7.307z"
					/>
					<path
						fill="#5EAD46"
						d="M281.389 182.26c-24.004-5.818-58.797-6.182-83.747 1.278-9.49 3.255-40.746 10.078-37.583-19.104 9.216-47.79 14.836-93.28 12.076-133.406 0-9.488 7.762-17.25 17.25-17.25l96.604-10.925c9.488 0 17.25 7.762 17.25 17.25-3.324 42.35 3.943 92.255 13.8 144.331 4.314 27.888-24.841 20.647-35.65 17.826z"
					/>
					<path
						fill="#51943B"
						d="M179.694 25.182c6.529-7.379 38.354-10.825 44.623-13.444 1.037-.433 2.157-1.242 3.302-2.283l-38.234 4.324c-9.488 0-17.25 7.762-17.25 17.25.597 8.674.8 17.598.662 26.742 1.398-13.95 3.618-28.883 6.897-32.589z"
					/>
					<path
						fill="#4F192C"
						d="M290.246 39.54c-11.443 1.588-22.917 6.62-31.295 14.636l-.94-1.744c10.216-2.547 23.18-3.353 32.285 3-12.145-1.74-23.032-2.723-35.764-.226 9.149-7.666 23.344-17.589 35.714-15.666z"
					/>
					<path
						fill="#3F2945"
						d="M290.173 57.003c-3.657 0-7.964-2.193-11.823-2.424-6.71-.346-13.642.015-20.23 1.306-8.374 1.642-2.435-3.652 1.15-6.423 2.607-2.206 5.843-4.124 8.949-5.527 2.74-1.333 23.604-8.655 23.378-4.524.02.213-21.761 8.28-24.57 10.094 4.643-.462 9.388-.316 13.963.63 2.439.468 5.078 1.217 7.234 2.45.6.283 6.113 4.418 1.949 4.418z"
					/>
					<path
						fill="#4F192C"
						d="M182.463 46.64c12.623-4.853 24.518-.797 35.486 5.704-5.728-.38-11.336-.408-17.045.39-5.51.79-10.934 3.075-15.954 4.17 8.11-6.392 18.819-8.1 28.884-6.893-.23.626-.46 1.252-.688 1.878-10.291-6.844-19.445-5.75-30.683-5.249z"
					/>
					<path
						fill="#3F2945"
						d="M185.085 58.468c-4.953 0 5.505-6.74 6.73-7.185 3.684-1.496 7.562-2.365 11.506-2.79-4.098-1.302-8.444-1.807-12.733-1.57-2.01.11-6.946 2.015-8.646 1.164-3.616-1.806 3.42-3.768 4.147-3.937 10.978-2.5 20.78.047 30.187 5.623 3.907 2.316 3.236 4.034-1.228 3.74a64.68 64.68 0 00-11.5.278c-3.672.408-7.601.772-11.105 2.016-1.88.55-5.44 2.661-7.358 2.661z"
					/>
					<path
						fill="#8F3D7C"
						d="M175.797 99.738s-4.11 36.808 20.077 54.884c19.618 12.917 32.85-28.413 49.736-11.62 16.88 16.786 38.609 26.486 52.472-5.813 15.512-36.159.455-69.086-25.093-69.737-25.554-.644-22.36 14.207-52.021 0-29.657-14.2-41.061 6.457-45.171 32.286z"
					/>
					<path
						fill="#BB4E9D"
						d="M195.874 83.594c1.588-8.975 4.86-15.557 9.148-20.71-18.05-.978-25.952 16.29-29.225 36.854 0 0-4.11 36.808 20.077 54.884 3.836 2.526 7.423 2.963 10.843 2.178-12.218-19.485-15.49-49.112-10.843-73.206zM245.838 116.85c-4.565-4.519-12.322 1.614-15.058 6.78-3.424-7.42-9.123-15.82-13.687-5.492-3.085 6.98 2.887 19.692 7.089 27.214 6.032-4.915 11.939-8.693 18.3-4.843 8.698-10.453 7.542-19.51 3.356-23.659z"
					/>
					<path
						fill="#D6D7D5"
						d="M210.247 97.153c15.285 8.398 46.312 12.271 56.808 3.876 7.536-6.028 15.194-21.715 18.91-30.08-3.881-2.145-8.248-3.376-12.975-3.498-25.554-.644-22.36 14.207-52.021 0-13.173-6.31-22.738-5.728-29.667-.877 2.81 9.605 8.744 24.98 18.945 30.58z"
					/>
					<path
						fill="#FFF"
						d="M241.502 96.51c9.129-2.579 29.888-4.515 24.893-21.63-.783-2.223-2.497-8.199-10.355-5.003-9.655 3.248-14.355 7.502-35.071-2.426-12.711-6.089-22.057-5.746-28.921-1.359 7.878 22.425 21.854 35.63 49.454 30.417z"
					/>
					<path
						fill="#52C7E3"
						d="M322.624 13.472l-.058-.015-17.061 24.12 29.205-4.489.009-.062a12.51 12.51 0 005.026-1.783c5.927-3.668 7.76-11.445 4.09-17.376-3.665-5.928-11.441-7.757-17.37-4.09a12.613 12.613 0 00-3.841 3.695z"
					/>
					<path
						fill="#13B2CB"
						d="M343.835 13.867c-3.666-5.928-11.442-7.757-17.37-4.09a12.602 12.602 0 00-3.842 3.695l-.058-.015-17.061 24.12"
					/>
					<path
						fill="#52C7E3"
						d="M326.359 41.09l-.015-.033-17.502 1.07 12.93 11.845.034-.018a7.491 7.491 0 0012.254-2.901 7.488 7.488 0 00-4.563-9.558 7.5 7.5 0 00-3.138-.405z"
					/>
					<path
						fill="#13B2CB"
						d="M334.06 51.054a7.488 7.488 0 00-4.563-9.558 7.485 7.485 0 00-3.138-.405l-.015-.033-17.502 1.07"
					/>
					<path
						fill="#52C7E3"
						d="M369.175 33.663l-.033-.05-28.768 6.724L365.2 56.358l.048-.04a12.5 12.5 0 004.944 2c6.867 1.19 13.4-3.412 14.59-10.285 1.19-6.867-3.412-13.397-10.28-14.587a12.65 12.65 0 00-5.327.217z"
					/>
					<path
						fill="#13B2CB"
						d="M384.782 48.033c1.19-6.867-3.412-13.397-10.28-14.587a12.65 12.65 0 00-5.327.216l-.033-.05-28.768 6.724"
					/>
					<path
						fill="#52C7E3"
						d="M394.31 67.017l-.022-.056-29.53.908 21.174 20.61.055-.029a12.523 12.523 0 004.45 2.937c6.498 2.524 13.81-.698 16.335-7.198 2.525-6.497-.698-13.808-7.195-16.33a12.614 12.614 0 00-5.266-.842z"
					/>
					<path
						fill="#13B2CB"
						d="M406.77 84.19c2.526-6.498-.697-13.809-7.194-16.332a12.605 12.605 0 00-5.265-.84l-.023-.057-29.53.908"
					/>
					<path
						fill="#52C7E3"
						d="M374.514 86.634l.018-.033-11.396-13.327-1.668 17.457.034.015a7.49 7.49 0 009.696 8.034 7.49 7.49 0 003.316-12.146z"
					/>
					<path fill="#13B2CB" d="M371.199 98.781a7.49 7.49 0 003.315-12.146l.018-.033-11.396-13.327" />
					<path
						fill="#52C7E3"
						d="M406.23 116.34l.02-.058-22.694-18.915 2.163 29.467.062.015A12.47 12.47 0 00387.16 132c3.187 6.2 10.795 8.642 16.998 5.453 6.199-3.185 8.639-10.793 5.453-16.99a12.603 12.603 0 00-3.38-4.124z"
					/>
					<path
						fill="#13B2CB"
						d="M404.159 137.452c6.199-3.185 8.639-10.793 5.453-16.99a12.638 12.638 0 00-3.381-4.123l.02-.057-22.695-18.915"
					/>
					<path
						fill="#52C7E3"
						d="M84.879 70.794l.023-.056 29.529.908-21.173 20.61-.055-.029a12.51 12.51 0 01-4.451 2.937c-6.497 2.524-13.81-.698-16.334-7.198-2.525-6.497.698-13.808 7.195-16.33a12.596 12.596 0 015.266-.842z"
					/>
					<path
						fill="#13B2CB"
						d="M72.419 87.965c-2.525-6.497.698-13.808 7.195-16.33a12.605 12.605 0 015.265-.842l.023-.056 29.529.908"
					/>
					<path
						fill="#52C7E3"
						d="M104.676 90.41l-.018-.033 11.396-13.327 1.668 17.457-.034.015a7.49 7.49 0 01-9.696 8.034 7.489 7.489 0 01-3.316-12.146z"
					/>
					<path fill="#13B2CB" d="M107.99 102.557a7.489 7.489 0 01-3.314-12.146l-.018-.033 11.396-13.327" />
					<path
						fill="#52C7E3"
						d="M72.959 120.115l-.02-.057 22.695-18.915-2.163 29.467-.062.015a12.47 12.47 0 01-1.38 5.151c-3.186 6.2-10.794 8.642-16.997 5.453-6.2-3.185-8.64-10.793-5.453-16.99a12.601 12.601 0 013.38-4.124z"
					/>
					<path
						fill="#13B2CB"
						d="M75.03 141.228c-6.2-3.185-8.64-10.793-5.453-16.99a12.625 12.625 0 013.38-4.123l-.02-.057 22.696-18.915"
					/>
					<path
						fill="#52C7E3"
						d="M148.552 19.066l.058-.015 17.06 24.12-29.204-4.489-.01-.062a12.515 12.515 0 01-5.025-1.783c-5.927-3.668-7.761-11.445-4.091-17.376 3.666-5.928 11.442-7.757 17.37-4.09 1.582.977 2.87 2.25 3.842 3.695z"
					/>
					<path
						fill="#13B2CB"
						d="M127.34 19.461c3.667-5.928 11.443-7.757 17.37-4.09 1.583.977 2.87 2.25 3.843 3.695l.058-.015 17.06 24.12"
					/>
					<path
						fill="#52C7E3"
						d="M144.817 46.684l.015-.033 17.502 1.07-12.931 11.845-.033-.018a7.491 7.491 0 01-12.254-2.901 7.488 7.488 0 014.563-9.558 7.485 7.485 0 013.138-.405z"
					/>
					<path
						fill="#13B2CB"
						d="M137.116 56.647a7.488 7.488 0 014.563-9.558 7.484 7.484 0 013.138-.405l.015-.033 17.502 1.07"
					/>
					<path
						fill="#52C7E3"
						d="M102 39.256l.034-.05 28.768 6.724-24.826 16.021-.048-.04a12.506 12.506 0 01-4.944 2c-6.867 1.19-13.4-3.412-14.59-10.285-1.192-6.867 3.412-13.397 10.28-14.587a12.666 12.666 0 015.327.217z"
					/>
					<path
						fill="#13B2CB"
						d="M86.394 53.627c-1.192-6.867 3.412-13.397 10.28-14.587a12.65 12.65 0 015.327.216l.033-.05 28.768 6.724"
					/>
					<path
						fill="#65C9D6"
						d="M336.204 181.134l12.612-9.65c1.064-.813 3.017-1.314 5.147-1.314h14.048l13.22-10.108c1.588-1.21 4.5-1.959 7.677-1.959h37.638c3.166 0 6.088.747 7.658 1.96l8.798 6.717h15.383c1.745 0 3.347.405 4.21 1.072l10.335 7.906c.85.665.85 1.475 0 2.147l-10.335 7.9c-.864.66-2.466 1.07-4.21 1.07h-16.486l-7.695 5.884c-1.57 1.205-4.492 1.952-7.658 1.952h-37.638c-2.053 0-3.85-.4-5.39-.947-1.117.56-2.598.947-4.318.947h-25.236c-2.13 0-4.083-.503-5.147-1.306l-12.612-9.654c-1.067-.81-1.067-1.807-.001-2.617z"
					/>
					<path
						fill="#82D1DF"
						d="M443.277 166.78h-.275l-8.798-6.718c-1.57-1.21-4.492-1.959-7.658-1.959h-37.638c-3.177 0-6.09.747-7.676 1.96L368.01 170.17h-14.048c-2.13 0-4.083.501-5.147 1.314l-12.612 9.65c-1.066.81-1.066 1.806 0 2.617l1.004.767h11.743c1.72 0 3.203-.39 4.316-.947 1.546.547 3.336.947 5.386.947h37.643c3.166 0 6.089-.747 7.656-1.95l7.695-5.886h16.486c1.745 0 3.35-.41 4.212-1.069l10.335-7.9c.375-.294.552-.61.597-.933z"
					/>
					<path
						fill="#65C9D6"
						d="M1.892 181.134l12.612-9.65c1.064-.813 3.017-1.314 5.147-1.314h14.048l13.22-10.108c1.588-1.21 4.5-1.959 7.677-1.959h37.638c3.166 0 6.088.747 7.658 1.96l8.798 6.717h15.383c1.745 0 3.347.405 4.21 1.072l10.335 7.906c.85.665.85 1.475 0 2.147l-10.335 7.9c-.864.66-2.466 1.07-4.21 1.07h-16.486l-7.695 5.884c-1.57 1.205-4.492 1.952-7.658 1.952H54.596c-2.053 0-3.85-.4-5.39-.947-1.117.56-2.598.947-4.318.947H19.65c-2.13 0-4.083-.503-5.147-1.306l-12.612-9.654c-1.066-.81-1.066-1.807 0-2.617z"
					/>
					<path
						fill="#82D1DF"
						d="M108.965 166.78h-.275l-8.798-6.718c-1.57-1.21-4.492-1.959-7.658-1.959H54.596c-3.177 0-6.09.747-7.676 1.96l-13.221 10.106H19.65c-2.13 0-4.083.501-5.147 1.314l-12.612 9.65c-1.066.81-1.066 1.806 0 2.617l1.004.767h11.743c1.72 0 3.203-.39 4.316-.947 1.546.547 3.336.947 5.386.947h37.643c3.166 0 6.089-.747 7.656-1.95l7.695-5.886H93.82c1.745 0 3.35-.41 4.212-1.069l10.335-7.9c.375-.293.552-.609.597-.932z"
					/>
				</g>
			</svg>
		</div>
	);
};

export default Unknown;
