import React from 'react';

const Activation = props => {
    return (
        <div {...props}>
            <svg width="100%" viewBox="0 0 512 512">
                <path
                    d="M326.894 290.565c-50.455-18.122-100.72 3.773-74.223 33.143 15.599 17.288 79.257 23.203 110.89 17.939 31.636-5.26 13.785-32.958-36.667-51.082z"
                    fill="#5EAD46"
                />
                <path
                    d="M297.938 266.415c-9.768.379-18.814 3.583-22.274 12.382-6.015 15.302 12.922 50.879 12.937 50.908-15.746-16.18-28.209-33.506-28.981-45.381-1.543-23.778 17.074-28.142 38.318-17.909z"
                    fill="#51943B"
                />
                <path
                    d="M344.555 440.897c0-13.746-65.612-24.887-146.548-24.887-80.935 0-146.547 11.142-146.547 24.887 0 4.503 7.079 8.723 19.397 12.367-3.976 3.022 62.812 15.529 145.215 12.325 82.402-3.206 128.483-11.986 128.483-24.692z"
                    fill="#3E3E3E"
                    opacity={0.32}
                />
                <path
                    d="M43.178 313.522c-25.626 47.09-11.68 100.113 21.398 78.421C84.049 379.17 99.628 317.167 99.269 285.1c-.36-32.068-30.465-18.663-56.091 28.422z"
                    fill="#5EAD46"
                />
                <path
                    d="M78.885 373.114c12.241-24.459 20.648-64.368 20.383-88.014-.236-21.098-13.35-22.508-29.592-7.597 5.037 13.142 15.653 42.24 15.647 53.948-.008 10.255-3.873 29.813-6.438 41.663z"
                    fill="#51943B"
                />
                <path
                    d="M260.923 436.256c-38.251-9.272-93.693-9.851-133.452 2.037-15.122 5.187-64.929 16.059-59.89-30.444 14.686-76.156 23.641-148.643 19.243-212.583 0-15.119 12.369-27.489 27.489-27.489l153.939-17.41c15.119 0 27.49 12.369 27.49 27.488-5.298 67.483 6.281 147.009 21.991 229.993 6.873 44.441-39.586 32.902-56.81 28.408z"
                    fill="#5EAD46"
                />
                <path
                    d="M98.87 185.95c10.404-11.758 61.117-17.251 71.108-21.424 1.653-.691 3.437-1.981 5.261-3.639l-60.926 6.891c-15.119 0-27.489 12.369-27.489 27.489.951 13.821 1.275 28.042 1.055 42.613 2.227-22.23 5.765-46.025 10.991-51.93z"
                    fill="#51943B"
                />
                <g opacity={0.5} fill="#0D6C3F">
                    <ellipse
                        cx={145.395}
                        cy={183.725}
                        opacity={0.3}
                        rx={11.145}
                        ry={8.469}
                        transform="rotate(-67.728 145.393 183.723)"
                    />
                    <ellipse
                        cx={184.611}
                        cy={194.529}
                        opacity={0.3}
                        rx={8.303}
                        ry={6.31}
                        transform="rotate(-67.734 184.618 194.536)"
                    />
                    <ellipse
                        cx={233.228}
                        cy={174.362}
                        opacity={0.3}
                        rx={10.843}
                        ry={9.301}
                        transform="rotate(-65.821 233.21 174.356)"
                    />
                    <circle cx={266.859} cy={174.363} opacity={0.3} r={9.361} />
                </g>
                <path
                    d="M206.779 367.803a5.2 5.2 0 01-4.317-.152c-.726-.366-17.211-8.291-31.863 4.965-2.108 1.917-5.359 1.743-7.27-.36a5.144 5.144 0 01.356-7.274c20.011-18.115 42.486-7.016 43.433-6.536 2.539 1.307 3.547 4.409 2.258 6.939a5.163 5.163 0 01-2.597 2.418z"
                    fill="#51943B"
                />
                <path
                    d="M378.149 378.75V270.553c0-1.105 2.17-1.999 3.276-1.999h107.959c1.105 0 .723.895.723 1.999V378.75c0 11.792-8.99 21.753-21.142 21.753h-68.271c-11.728.001-22.545-9.757-22.545-21.753z"
                    fill="#7855A3"
                />
                <path
                    d="M489.384 268.554c1.105 0 .723.895.723 1.999V378.75c0 11.792-8.99 21.753-21.142 21.753h-34.838v-131.95h55.257z"
                    fill="#694B90"
                />
                <path
                    d="M456.12 378.513a5.994 5.994 0 005.998-5.998v-75.971a5.998 5.998 0 10-11.996 0v75.971a5.994 5.994 0 005.998 5.998zM416.134 378.513a5.994 5.994 0 005.998-5.998v-75.971a5.998 5.998 0 10-11.996 0v75.971a5.995 5.995 0 005.998 5.998z"
                    fill="#FFF"
                    opacity={0.1}
                />
                <path
                    d="M380.977 366.462a5.997 5.997 0 005.996-5.998 5.997 5.997 0 00-5.996-5.998c-16.642 0-29.56-22.769-29.56-42.36 0-14.431 6.357-18.383 29.56-18.383a5.995 5.995 0 005.996-5.998 5.996 5.996 0 00-5.996-5.998c-14.519 0-41.555 0-41.555 30.378-.001 25.712 17.067 54.357 41.555 54.357z"
                    fill="#7855A3"
                />
                <circle cx={246.901} cy={258.7} fill="#FFF" r={60.654} />
                <circle cx={134.615} cy={258.7} fill="#FFF" r={60.654} />
                <ellipse cx={247.098} cy={275.72} fill="#3F2945" rx={12.92} ry={12.925} />
                <ellipse cx={134.772} cy={275.72} fill="#3F2945" rx={12.92} ry={12.925} />
                <path
                    d="M247.389 196.851c-25.37 0-47.09 15.582-56.142 37.693-9.054-22.111-30.774-37.693-56.144-37.693-33.498 0-60.654 27.157-60.654 60.655a60.569 60.569 0 002.96 18.726h227.674a60.598 60.598 0 002.96-18.726c.001-33.498-27.153-60.655-60.654-60.655z"
                    fill="#5EAD46"
                />
                <g>
                    <path
                        fill="#3F2945"
                        d="M316.493 239.963l-92.974-17.615 9.547-34.937 89.541 30.247z"
                    />
                </g>
                <path
                    fill="#3F2945"
                    d="M66.954 247.28l90.125-28.842-13.744-33.511-85.174 40.96z"
                />
                <path
                    d="M471.937 134.995c.076-.275.112-.537.193-.816 2.574-8.54 7.13-13.14 11.934-20.144 22.392-32.653 15.001-94.784-35.594-89.531-42.599 4.423-79.487 59.578-52.479 98.415 18.496 26.603 57.612 45.077 39.126 83.275-8.68 17.932-17.617 30.57-4.18 44.97-.05 1.22-.048 2.502.012 3.863.189 4.356 6.894 4.56 6.828.132-.089-6.003 1.811-10.782 4.791-14.865 2.136-2.054 4.077-4.125 5.811-6.23 5.972-5.24 13.353-9.487 19.101-14.503 8.56-7.463 11.151-10.909 13.048-22.522 2.046-12.506-1.453-20.899-5.461-32.843-2.839-8.472-5.163-15.958-4.081-24.429.437-1.556.756-3.159.951-4.772"
                    fill="#D1D3D4"
                />
            </svg>
        </div>
    );
};

export default Activation;
