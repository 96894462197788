// @flow
import React, { Component } from "react";
import Sensor from "./../../../../components/sensorsList/sensor/Sensor.jsx";

import Signal from "./../../../../components/sensorsList/sensor/sensorDescriptors/signal/Signal.jsx";
import Text from "./../../../../components/sensorsList/sensor/sensorDescriptors/text/Text.jsx";
import ActionButton from "./../../../../components/sensorsList/sensor/sensorActionButton/ActionButton.jsx";
import QuickStatesView from "./../../../../components/charts/QuickStatesView/QuickStatesView";

import { connect } from "react-redux";
import { fetchSensorMeasurements } from "../../../../store/actions/sensorActions";
import { FormattedMessage, injectIntl } from "react-intl";
import { isEnabled } from "./../../../../utils/inputsValidator";

import Log from "./../../../../components/charts/Log/Log";
import GanttChart from "./../../../charts/Gantt/Gantt";
import DatePanel from "./../../../../components/charts/ChartsPanel/ChartsPanel.jsx";
import { formateDate, formateDatetime } from "./../../../../utils/dateFunction.js";

import sensors from "./../../../../utils/sensorTypes";
import classes from "./../SensorDetail.module.scss";

const oneDayTimestamp = 24 * 60 * 60;
const oneWeekTimestamp = oneDayTimestamp * 7;
const oneMonthTimestamp = oneDayTimestamp * 30;

const labels = {
    name: <FormattedMessage id="sensor.name" defaultMessage="Name" />,
    id: <FormattedMessage id="sensor.id" defaultMessage="ID" />,
    signal: <FormattedMessage id="sensor.signal" defaultMessage="Signal" />,
    pac: <FormattedMessage id="sensor.pac" defaultMessage="PAC" />,
    lastMsg: <FormattedMessage id="sensor.lastMessage" defaultMessage="Last Message" />,
    state: <FormattedMessage id="sensor.state" defaultMessage="State" />,
    stateExpired: <FormattedMessage id="tooltip.state.expired" defaultMessage="Expired" />,
    stateActive: <FormattedMessage id="tooltip.state.active" defaultMessage="Active" />,
    exp: <FormattedMessage id="sensor.expirationDate" defaultMessage="Expiration Date" />,
    type: <FormattedMessage id="sensor.type" defaultMessage="Type" />,
};

class SimpleLeak extends Component {
    constructor(props) {
        super(props);
        const dateFromX = new Date();
        const dateToX = new Date();

        let startPeriod = oneWeekTimestamp;
        let multiplier = 1;

        if (props.startPeriod) {
            switch (props.startPeriod) {
                case "day":
                    startPeriod = oneDayTimestamp;
                    break;
                case "month":
                    startPeriod = oneMonthTimestamp;
                    break;
                default:
                    startPeriod = oneWeekTimestamp;
                    break;
            }
        }

        if (props.multiplier && typeof props.multiplier === "number") {
            multiplier = props.multiplier;
        }

        this.state = {
            dateFrom: new Date(dateFromX.getTime() - startPeriod * multiplier * 1000),
            dateTo: dateToX,
            selectedPeriod: "",
            isFilteringEnabled: true,
        };
    }

    componentDidMount() {
        const id = this.props.id;
        const type = this.props.type;
        const timestampFrom = 0; //this.state.dateFrom.getTime() / 1000;
        const timestampTo = this.state.dateTo.getTime() / 1000;
        if (!this.props.externalLoading) this.props.fetchSensorMeasurements(id, type, timestampFrom, timestampTo);
    }

    getCurrentTimestamp = () => {
        const currentTimestamp = Math.round(new Date().getTime() / 1000);
        return currentTimestamp;
    };

    handlePeriodSelect = (e) => {
        switch (e.value) {
            case "last24hours":
                this.setState({
                    ...this.state,
                    dateFrom: new Date((this.getCurrentTimestamp() - oneDayTimestamp) * 1000),
                    dateTo: new Date(),
                    selectedPeriod: "last24hours",
                    isFilteringEnabled: true,
                });
                break;
            case "last7days":
                this.setState({
                    ...this.state,
                    dateFrom: new Date((this.getCurrentTimestamp() - oneWeekTimestamp) * 1000),
                    dateTo: new Date(),
                    selectedPeriod: "last7days",
                    isFilteringEnabled: true,
                });
                break;
            case "last30days":
                this.setState({
                    ...this.state,
                    dateFrom: new Date((this.getCurrentTimestamp() - oneMonthTimestamp) * 1000),
                    dateTo: new Date(),
                    selectedPeriod: "last30days",
                    isFilteringEnabled: true,
                });
                break;

            default:
                this.setState({ ...this.state, selectedPeriod: "", isFilteringEnabled: false });
                break;
        }
    };

    handleDateFromChange = (date) => {
        if (date) {
            //const roundDay = 1000 * 60 * 60 * 24;
            //date = new Date(Math.floor((date.getTime() / roundDay)) * roundDay);
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);
            let enableFiltering = true;
            if (!date) enableFiltering = false;
            this.setState({ ...this.state, dateFrom: date, selectedPeriod: "", isFilteringEnabled: enableFiltering });
        }
    };

    handleDateToChange = (date) => {
        if (date) {
            //const roundDay = 1000 * 60 * 60 * 24;
            //date = new Date(Math.ceil((date.getTime() / roundDay)) * roundDay);
            date.setHours(23);
            date.setMinutes(59);
            date.setSeconds(59);
            if (date < this.state.dateFrom) {
                date = this.state.dateFrom;
            }
            const actDate = new Date();
            if (date > actDate) {
                date = actDate;
            }
            let enableFiltering = true;
            if (!date) enableFiltering = false;
            this.setState({ ...this.state, dateTo: date, selectedPeriod: "", isFilteringEnabled: enableFiltering });
        }
    };

    handleMeasurementsFilter = () => {
        if (this.state.dateFrom && this.state.dateTo) {
            const { id, type } = this.props;
            let dateFrom = 0; //Math.round(this.state.dateFrom.getTime() / 1000);
            let dateTo = new Date().getTime(); //Math.ceil(this.state.dateTo.getTime() / (1000 * 60 * 60 * 24)) * 60 * 60 * 24;

            if (!this.props.externalLoading) this.props.fetchSensorMeasurements(id, type, dateFrom, dateTo);
        }
    };

    onEditHandler = () => {
        this.props.history.push(`/edit/${this.props.sensor.id}?type=${this.props.sensor.sensor_type}`);
    };

    renderDocument() {}
    render() {
        const { dateFrom, dateTo, selectedPeriod } = this.state;
        let { lang, sensor, sensorMeasurements } = this.props;

        sensorMeasurements = sensorMeasurements[sensor.id] ? sensorMeasurements[sensor.id] : [];

        let graphLoading = this.props.isGraphLoading;

        if (this.props.externalLoading) {
            sensorMeasurements = this.props.externalData;
            graphLoading = this.props.isExternalDataLoading;
        }

        const editButton = <ActionButton type="Edit" click={this.onEditHandler} tooltip={this.props.intl.formatMessage({ id: "tooltip.edit" })} />;

        const sensorOverview = (
            <div id="sensorOverview" className={classes.sensorOV}>
                <Sensor className={classes.sensor} type="overview">
                    <Text className={classes.descriptor} label={labels.name} value={sensor.name} />
                    <Text className={classes.descriptor} label={labels.id} value={sensor.id} />
                    <Signal className={classes.descriptor} label={labels.signal} value={sensor.signal} />
                    <Text className={classes.descriptor} label={labels.pac} value={sensor.pac} />
                    <Text className={classes.descriptor} label={labels.type} value={sensors.simpleLeak.pretty_label} />
                    <Text className={classes.descriptor} label={labels.lastMsg} value={formateDatetime(sensor.last_msg_time, lang)} />
                    <Text className={classes.descriptor} label={labels.state} value={sensor.state === "ACTIVE" ? labels.stateActive : labels.stateExpired} />
                    <Text className={classes.descriptor} label={labels.exp} value={formateDate(sensor.expiration, lang)} />
                </Sensor>
            </div>
        );

        const quickStateView = <QuickStatesView data={sensorMeasurements} isLoading={graphLoading} />;

        const datePanel = (
            <DatePanel
                lang={lang}
                selectedPeriod={selectedPeriod}
                dateFrom={dateFrom}
                dateTo={dateTo}
                onSelect={this.handlePeriodSelect}
                onDateFromChange={this.handleDateFromChange}
                onDateToChange={this.handleDateToChange}
                onFilterButtonClick={this.handleMeasurementsFilter}
                filterButtonDisabled={!this.state.isFilteringEnabled}
            />
        );

        const diagram = (
            <GanttChart
                id="LeakChart"
                label={this.props.intl.formatMessage({ id: "chart.leakageDiagram" })}
                data={sensorMeasurements}
                sensor={sensor}
                commonTimeAxis="timestamp"
                loading={graphLoading}
                startInterval={this.state.dateFrom}
                endInterval={this.state.dateTo}
                mapping={{
                    device_on: {
                        event: "arming",
                        legend: this.props.intl.formatMessage({ id: "chart.gantt.deviceOn" }),
                        color: "limegreen",
                        onValue: 1,
                        offValue: 0,
                    },
                    wrong_possition: {
                        event: "position",
                        legend: this.props.intl.formatMessage({ id: "chart.gantt.wrongPosition" }),
                        color: "rgb(221, 168, 33)",
                        onValue: 1,
                        offValue: 0,
                    },
                    flood: {
                        event: "leakage",
                        legend: this.props.intl.formatMessage({ id: "chart.gantt.leakage" }),
                        color: "rgb(0, 162, 255)",
                        onValue: 1,
                        offValue: 0,
                    },
                }}
            />
        );

        const log = <Log data={sensorMeasurements} lang={lang} />;

        if (!sensor) return null;
        else
            return (
                <div>
                    <span className={classes.title}>
                        <h2>{sensor.name}</h2>
                        {isEnabled(this.props.config, "editButton") ? editButton : null}
                    </span>
                    {isEnabled(this.props.config, "sensorOverview") ? sensorOverview : null}
                    {isEnabled(this.props.config, "quickStateView") ? quickStateView : null}
                    {isEnabled(this.props.config, "datePanel") ? datePanel : null}
                    {isEnabled(this.props.config, "diagram") ? diagram : null}
                    {isEnabled(this.props.config, "log") ? log : null}
                </div>
            );
    }
}

const mapStateToProps = (state, props) => ({
    sensorMeasurements: state.sensors.sensorMeasurements,
    isGraphLoading: state.sensors.isGraphLoading,
    lang: state.locale.lang,
});

export default connect(mapStateToProps, {
    fetchSensorMeasurements,
})(injectIntl(SimpleLeak));
