import { combineReducers } from 'redux';
import sensorReducer from './sensorReducer';
import localeReducer from './localeReducer';
import coinsReducer from './coinsReducer';
import userReducer from './userReducer';
import appReducer from './appReducer';

/*
export default combineReducers({
	sensors: sensorReducer,
	locale: localeReducer,
	coins: coinsReducer,
	users: userReducer
});
*/

const mainReducer = combineReducers({
	sensors: sensorReducer,
	locale: localeReducer,
	coins: coinsReducer,
	users: userReducer,
	app: appReducer
});

const rootReducer = (state, action) => {
	if (action.type === 'APP_LOGOUT') {
		state = undefined;
	}
	return mainReducer(state, action);
};

export default rootReducer;
