import axios from './../../axios/axios';

import { SET_COINS, ADD_COINS, CHANGE_TOPUP_STATE, CHANGE_ACTIVATION_STATE } from './coinsActionTypes';
import { handleRemoteError, activateSensor } from './sensorActions';
import { updateUser, updateUserItem } from './userActions';
import {unmarshall} from './../../utils/awsMarshaller';

export const setCoins = value => {
	return {
		type: SET_COINS,
		value: value
	};
};

export const addCoins = value => {
	return {
		type: ADD_COINS,
		value: value
	};
};

export const fetchCoins = (entity = 'IOT_GSOL') => {
	return dispatch => {
		//const tokens = localStorage.getItem("tokens");
		//if (tokens) {
		//  dispatch(setCoins(tokens));
		//} else {
		axios
			.get('/users/loggedUser', { params: { entity: entity } })
			.then(data => {
				const user = data.data.user.Items.map(item => unmarshall(item));
				let credits = 0;

				user.forEach(set => {
					if (set.info.endsWith('_settings_user')) {
						credits = set.credits;
					}
				});
				dispatch(setCoins(credits));
				dispatch(updateUser(user, data.data.isSubuser));
			})
			.catch(err => {
				console.log('Coins could not be fetched.', err);
			});
		//}
	};
};

export const topupTokens = (token, entity = 'IOT_GSOL') => {
	return dispatch => {
		dispatch(changeTopupState('IN_PROGRESS'));

		axios
			.post('/users/topup/', { token, entity })
			.then(() => {
				dispatch(changeTopupState('SUCCESS'));
				dispatch(fetchCoins());
			})
			.catch(err => {
				if (err.response) {
					switch (err.response.status) {
						case 403:
							switch (err.response.data.error_message) {
								case 'Invalid token':
									dispatch(changeTopupState('FAILED'));
									break;
								default:
									dispatch(handleRemoteError(err, 'topupTokens'));
									break;
							}
							break;
						default:
							dispatch(handleRemoteError(err, 'topupTokens'));
							break;
					}
				} else {
					dispatch(handleRemoteError(err, 'topupTokens'));
				}
			});
	};
};

export const performAction = (sensor_id, body) => {
	return dispatch => {
		dispatch(changeActivationState('IN_PROGRESS'));

		axios
			.post(`/sensors/${sensor_id}/action`, body)
			.then(result => {
				if (result.data.payload) {
					if (result.data.payload.credits) dispatch(setCoins(result.data.payload.credits));
					if (result.data.payload.sms_credits)
						dispatch(updateUserItem('notifications', 'sms_credits', result.data.payload.sms_credits));
				}
				dispatch(changeActivationState('SUCCESS'));
				if(body.action === "0001")dispatch(activateSensor(sensor_id));
			})
			.catch(err => {
				if (err.response && err.response.status === 409) {
					dispatch(changeActivationState('FAILED'));
				} else if(err.response && err.response.status === 429){
					dispatch(handleRemoteError(err, `performAction: (${body.action})`, 'https.activationLimit'));
				} else {
					dispatch(handleRemoteError(err, `performAction: (${body.action})`));
				}
			});
	};
};

export const changeTopupState = newState => {
	return {
		type: CHANGE_TOPUP_STATE,
		state: newState
	};
};

export const changeActivationState = newState => {
	return {
		type: CHANGE_ACTIVATION_STATE,
		state: newState
	};
};
