import React from 'react';

import Spinner from './../../../Spinner/Spinner';

import classes from './State.module.scss';

const state = props => {
	const containerClass = [classes.stateContainer];
	let stateClasses = [classes.stateValue];
	const iconClasses = [props.iconClass];
	let stateVal = props.stateOkVal;

	if (props.alarmRaised) {
		stateVal = props.stateAlarmVal;
		stateClasses.push(classes.offColor);
		iconClasses.push(props.offIconClass);
	} else {
		stateClasses.push(classes.onColor);
		iconClasses.push(props.onIconClass);
	}

	if (props.className) {
		containerClass.push(props.className);
	}

	if (props.disabled) {
		stateVal = '---'; //<Dash style={{width: "30px"}}/>;
	}

	if (props.loading) {
		stateVal = <Spinner type="circle" size="2.5px" component="div" style={{ margin: '5px 0' }} />;
		stateClasses.push(classes.loading);
	} else if (props.secondaryInfo) {
		stateVal = (
			<div className={classes.secondaryInfoContainer}>
				{stateVal + "\n"}
				<i className={classes.secondaryInfoText}>
					{props.secondaryInfo}
				</i>
			</div>
		);
	}


	return (
		<div className={containerClass.join(" ")}>
			<div className={classes.stateLabel}>{props.stateName}</div>
			<div className={stateClasses.join(' ')}>
				{props.loading ? null : <i className={`${iconClasses.join(' ')} ${classes.onIcon}`} />}
				{stateVal}
			</div>
		</div>
	);
};

export default state;
