export const CLEAR_SENSORS = 'CLEAR_SENSORS';
export const FETCH_SENSORS = 'FETCH_SENSORS';
export const FILTER_SENSORS = 'FILTER_SENSORS';
export const FETCH_SENSOR_BY_ID = 'FETCH_SENSOR_BY_ID';
//export const FETCH_SENSOR_GLOBAL = 'FETCH_SENSOR_GLOBAL';
export const POST_SENSOR = 'POST_SENSOR';
export const EDIT_SENSOR = 'EDIT_SENSOR';
export const DELETE_SENSOR = 'DELETE_SENSOR';
export const ACTIVATE_SENSOR = 'ACTIVATE_SENSOR';
export const CHANGE_LOADING_STATUS = 'CHANGE_LOADING_STATUS';
export const CHANGE_SUCCESS_STATUS = 'CHANGE_SUCCESS_STATUS';
export const HANDLE_REMOTE_ERROR = 'HANDLE_REMOTE_ERROR';
export const CLEAR_REMOTE_ERROR = 'CLEAR_REMOTE_ERROR';
export const FETCH_SENSOR_MEASUREMENTS = 'FETCH_SENSOR_MEASUREMENTS';
export const CLEAR_MEASUREMENTS = 'CLEAR_MEASUREMENTS';
export const FETCH_SENSOR_MULTIPLE_DATA = 'FETCH_SENSOR_MULTIPLE_DATA';
export const CHANGE_GRAPH_LOADING_STATUS = 'CHANGE_GRAPH_LOADING_STATUS';
export const CHANGE_CHUNK = 'CHANGE_CHUNK';
export const SET_CHUNK_SIZE = 'SET_CHUNK_SIZE';
export const PROTECT_DEVICE = "PROTECT_DEVICE"