import React from "react";

import classes from "./LabeledInput.module.scss";

const labeledInput = (props) => {
    const baseClasses = [classes.inputField];
    let label = null;
    let units = null;
    let inputType = "text";
    let pattern = null;

    if (props.touched && (props.value !== "" || props.required)) {
        if (props.valid) {
            baseClasses.push(classes.successInput);
        } else {
            baseClasses.push(classes.errorInput);
        }
    }
    if (props.label) {
        label = <label className={classes.placeholder}>{props.label}</label>;
    }
    if (props.units) {
        units = <i className={classes.units}>{props.units}</i>;
    }
    if (props.type) {
        inputType = props.type;
    }
    if (props.className) {
        baseClasses.push(props.className);
    }
    if (props.type && props.type === "number") {
        inputType = "tel"; //change for this ...
        //pattern = "[0-9]*";
    }
    return (
        <span className={classes.container}>
            <input
                className={baseClasses.join(" ")}
                value={props.value}
                type={inputType}
                name={props.name}
                onBlur={props.onBlur}
                onChange={props.inputChange}
                maxLength={props.maxLength}
                id={props.id}
                pattern={pattern}
            />
            {label}
            {units}
        </span>
    );
};

export default labeledInput;
