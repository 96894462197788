import React, { Component } from "react";
import { injectIntl } from "react-intl";

import { FormattedMessage } from "react-intl";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import SensorPicture from "./../../../../../components/overViewItemDesc/Pic/Pic.jsx";
import MeasuredUnit from "./../../../../../components/icons/Unit/Unit";

import sensors from "./../../../../../utils/sensorTypes";
import classes from "./OverViewItem.module.scss";
import "./../../../../../styles/dropDownStyle.scss";

const units = {
    temp: {
        name: "temperature",
        tooltip: "tooltip.temperature",
    },
    hum: {
        name: "humidity",
        tooltip: "tooltip.humidity",
    },
    press: {
        name: "pressure",
        tooltip: "tooltip.pressure",
    },
    iaq: {
        name: "iaq",
        tooltip: "tooltip.iaq",
    },
    flood: {
        name: "flood",
        tooltip: "tooltip.flood",
    },
    binary: {
        name: "binary",
        tooltip: "tooltip.binary",
    },
    gps: {
        name: "gps",
        tooltip: "tooltip.gpsTracking",
    },
    smoke: {
        name: "smoke",
        tooltip: "tooltip.binInput.smoke",
    },
    doorOpen: {
        name: "doorOpen",
        tooltip: "tooltip.binInput.doorOpen",
    },
    motion: {
        name: "motion",
        tooltip: "tooltip.binInput.motion",
    },
};

class OverViewItem extends Component {
    constructor(props) {
        super(props);
        this.selections = [];
        this.sensorTypes = [];
        this.inClass = classes.unitsContainerIn;
        this.outClass = classes.unitsContainerOut;
        this.contentType1 = null;
        this.contentType2 = null;
        this.contentType = 1;
        this.state = {
            selectedIndex: 0,
        };
    }

    selectionChnaged = (e) => {
        const index = this.selections.indexOf(e.target.value);
        this.selectedIndex = index;

        const helpClass = this.outClass;
        this.outClass = this.inClass;
        this.inClass = helpClass;

        this.contentType = this.contentType === 1 ? 2 : 1;
        this.setState({
            ...this.state,
            selectedIndex: index,
        });
    };
    onClickHandler = (e) => {
        this.props.onChange({
            type: this.sensorTypes[this.state.selectedIndex],
        });
    };

    render() {
        let dropDownDisabled = false;
        let defaultValue = "Select Value";
        let label = this.props.type.toUpperCase();

        switch (this.props.type) {
            case sensors.siglink.iot_label:
                this.selections = ["TEMP_HUM"];
                this.sensorTypes = [sensors.siglink.iot_label];
                label = "SIGLINK";
                switch (this.state.selectedIndex) {
                    case 1:
                        defaultValue = this.selections[1];
                        this.contentIn = [units.temp, units.hum, units.iaq];
                        break;
                    case 0:
                        defaultValue = this.selections[0];
                        this.contentIn = [units.temp, units.hum];
                        break;
                    default:
                        this.contentIn = (
                            <p style={{ marginTop: "10px" }}>
                                <FormattedMessage id="sensor.meassure" />
                                {":"}
                            </p>
                        );
                        break;
                }
                break;
            case sensors.wicub.iot_label:
                this.selections = ["N/A"];
                this.sensorTypes = [sensors.wicub.iot_label];
                dropDownDisabled = true;
                label = sensors.wicub.pretty_label;
                switch (this.state.selectedIndex) {
                    case 0:
                        defaultValue = this.selections[0];
                        this.contentIn = [units.temp, units.hum, units.iaq, units.press];
                        break;
                    default:
                        this.contentIn = (
                            <p style={{ marginTop: "10px" }}>
                                <FormattedMessage id="sensor.meassure" />
                                {":"}
                            </p>
                        );
                        break;
                }

                break;
            case sensors.simpleLeak.iot_label:
                this.selections = ["3.0"];
                this.sensorTypes = [sensors.simpleLeak.iot_label];
                dropDownDisabled = false;
                label = sensors.simpleLeak.pretty_label;
                switch (this.state.selectedIndex) {
                    case 0:
                        defaultValue = this.selections[0];
                        this.contentIn = [units.flood];
                        break;
                    default:
                        this.contentIn = (
                            <p style={{ marginTop: "10px" }}>
                                <FormattedMessage id="sensor.meassure" />
                                {":"}
                            </p>
                        );
                        break;
                }

                break;
            case sensors.ste2.iot_label:
                this.selections = ["N/A"];
                this.sensorTypes = [sensors.ste2.iot_label];
                dropDownDisabled = true;
                label = sensors.ste2.pretty_label;
                switch (this.state.selectedIndex) {
                    case 0:
                        defaultValue = this.selections[0];
                        this.contentIn = [units.temp, units.hum, units.binary];
                        break;
                    default:
                        this.contentIn = (
                            <p style={{ marginTop: "10px" }}>
                                <FormattedMessage id="sensor.meassure" />
                                {":"}
                            </p>
                        );
                        break;
                }

                break;
            case sensors.siotech_gps_industrial.iot_label:
                this.selections = ["Industrial"];
                this.sensorTypes = [sensors.siotech_gps_industrial.iot_label];
                dropDownDisabled = false;
                label = sensors.siotech_gps.pretty_label;
                switch (this.state.selectedIndex) {
                    case 0:
                        defaultValue = this.selections[0];
                        this.contentIn = [units.temp, units.gps];
                        break;
                    default:
                        this.contentIn = (
                            <p style={{ marginTop: "10px" }}>
                                <FormattedMessage id="sensor.meassure" />
                                {":"}
                            </p>
                        );
                        break;
                }
                break;
            case sensors.siotech_gps.iot_label:
                this.selections = ["N/A"];
                this.sensorTypes = [sensors.siotech_gps.iot_label];
                dropDownDisabled = true;
                label = sensors.siotech_gps.pretty_label;
                switch (this.state.selectedIndex) {
                    case 0:
                        defaultValue = this.selections[0];
                        this.contentIn = [units.temp, units.gps];
                        break;
                    default:
                        this.contentIn = (
                            <p style={{ marginTop: "10px" }}>
                                <FormattedMessage id="sensor.meassure" />
                                {":"}
                            </p>
                        );
                        break;
                }
                break;
            case sensors.siotech_unior_alarm.iot_label:
                this.selections = ["A"];
                this.sensorTypes = [sensors.siotech_unior_alarm.iot_label];
                dropDownDisabled = false;
                label = "SECURITY";
                switch (this.state.selectedIndex) {
                    case 0:
                        defaultValue = this.selections[0];
                        this.contentIn = [units.motion, units.doorOpen, units.temp, units.hum];
                        break;
                    default:
                        this.contentIn = (
                            <p style={{ marginTop: "10px" }}>
                                <FormattedMessage id="sensor.meassure" />
                                {":"}
                            </p>
                        );
                        break;
                }
                break;
            case sensors.siotech_unior_security.iot_label:
                this.selections = ["B"];
                this.sensorTypes = [sensors.siotech_unior_security.iot_label];
                dropDownDisabled = false;
                label = "SECURITY";
                switch (this.state.selectedIndex) {
                    case 0:
                        defaultValue = this.selections[0];
                        this.contentIn = [units.motion, units.smoke, units.temp, units.hum];
                        break;
                    default:
                        this.contentIn = (
                            <p style={{ marginTop: "10px" }}>
                                <FormattedMessage id="sensor.meassure" />
                                {":"}
                            </p>
                        );
                        break;
                }
                break;
            default:
                //no default
                break;
        }

        this.contentIn = this.contentIn.map((unit) => {
            return <MeasuredUnit type={unit.name} key={unit.name} tooltip={this.props.intl.formatMessage({ id: unit.tooltip })} className={classes.units} />;
        });

        if (this.contentType === 1) {
            this.contentType1 = this.contentIn;
        } else if (this.contentType === 2) {
            this.contentType2 = this.contentIn;
        }

        return (
            <div className={classes.item}>
                <h1 className={classes.label}>{label}</h1>
                <span className={classes.picContainer}>
                    <SensorPicture type={this.props.type} />
                    <span className={classes.unitsMainContainer}>
                        <span className={this.outClass}>{this.contentType2}</span>
                        <span className={this.inClass}>{this.contentType1}</span>
                    </span>
                </span>
                <div className={classes.typeSelection}>
                    <div>
                        <FormattedMessage id="sensor.type" />
                    </div>
                    <DropDownList
                        disabled={dropDownDisabled}
                        data={this.selections}
                        defaultValue={defaultValue}
                        className={classes.dropdownList}
                        onChange={this.selectionChnaged}
                    />
                </div>
                <button className={`btn btn-primary ${classes.btn}`} onClick={this.onClickHandler}>
                    <FormattedMessage id="button.add" />
                </button>
            </div>
        );
    }
}

export default injectIntl(OverViewItem);
