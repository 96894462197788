import React from 'react';

import NotAvailable from './../NotAvailable/NotAvailable.jsx';
import BatteryIcon from './../../../../icons/Battery/Battery.jsx';

import styles from './Battery.module.scss';

const level_classes = [
	`${styles.icon} ${styles.batteryIconRed}`,
	`${styles.icon} ${styles.batteryIconOrange}`,
	`${styles.icon} ${styles.batteryIconGreen}`,
	`${styles.icon} ${styles.batteryIconGreen}`,
	`${styles.icon} ${styles.batteryIconGreen}`,
	`${styles.icon} ${styles.batteryIconGreen}`
];

const battery = props => {
	let battery = <NotAvailable />;
	let value = props.value;

	if (value < 1 || value > 6 || typeof value !== "number") {
		value = null;
	}
	if (value) {
		battery = (
			<BatteryIcon
				value={value}
				className={level_classes[value - 1]}
				tooltip={props.tooltip}
			/>
		);
	}
	let content = null;
	if (!props.hidden) {
		content = (
			<span className={props.className}>
				{props.label ? <p className={styles.label}>{props.label} :</p> : null}
				<div style={{ position: 'relative' }}>
					{battery}
				</div>

			</span>
		);
	}
	return content;
};

export default battery;
