import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import LangPanel from './../../../components/common/LangPanel/LangPanel.jsx';
import Input from './../../../components/UI/Inputs/AuthInputs/SimpleInput/SimpleInput.jsx';
import SubmitButton from './../../../components/UI/Inputs/AuthInputs/SubmitInput/SubmitInput.jsx';
import ConfirmModal from './../../../components/modals/AuthModals/ConfirmModal/ConfirmModal.jsx';
import Auth from '@aws-amplify/auth';
import { EMAIL_PATTERN, PASSWORD_PATTERN } from './../../../utils/InputPatterns.js';
import Spinner from './../../../components/Spinner/Spinner.jsx';

import classes from './../Auth.module.scss';

const texts = {
	signUp: <FormattedMessage id="login.register" defaultMessage="Sign Up" />,
	haveAccount: <FormattedMessage id="login.haveAccount" defaultMessage="I already have an account" />
};

const inputs = [
	{
		key: 'login.email',
		name: 'email',
		type: 'text',
		emptyId: 'login.email',
		tipId: 'login.email.tip'
	},
	{
		key: 'login.password',
		name: 'password',
		type: 'password',
		emptyId: 'login.password',
		tipId: 'login.password.tip'
	},
	{
		key: 'login.passwordAgain',
		name: 'passwordAgain',
		type: 'password',
		tipId: 'login.newPasswordAgain.tip',
		emptyId: 'login.passwordAgain'
	}
];

class SignUp extends Component {
	state = {
		email: {
			value: '',
			pattern: EMAIL_PATTERN,
			isValid: false,
			touched: false
		},
		password: {
			value: '',
			pattern: PASSWORD_PATTERN,
			isValid: false,
			touched: false
		},
		passwordAgain: {
			value: '',
			isValid: false,
			touched: false,
			pattern: '.'
		},
		btnEnabled: false,
		error: null,
		loading: false,
		modalOpen: false
	};

	initState = () => {
		this.setState({
			...this.state,
			email: {
				...this.state.email,
				value: '',
				isValid: false,
				touched: false
			},
			password: {
				...this.state.password,
				value: '',
				isValid: false,
				touched: false
			},
			passwordAgain: {
				...this.state.passwordAgain,
				value: '',
				isValid: false,
				touched: false
			},
			btnEnabled: false,
			error: null,
			loading: false,
			modalOpen: false
		});
	};

	onSubmit = e => {
		e.preventDefault();

		this.setState({ ...this.state, loading: true });

		Auth.signUp(this.state.email.value, this.state.password.value)
			.then(res => {
				this.setState({ ...this.state, modalOpen: true, loading: false });
			})
			.catch(err => {
				this.setState({
					...this.state,
					error: err,
					loading: false
				});
			});
	};

	onInputChange = e => {
		e.persist();

		const name = e.target.name;
		const value = e.target.value;
		const isValid = this.isInputValid(this.state[name].pattern, value);

		let updatedState = {
			...this.state,
			[name]: {
				...this.state[name],
				value: value,
				isValid: isValid,
				touched: true
			},
			error: null
		};
		updatedState.passwordAgain.isValid =
			updatedState.passwordAgain.value === updatedState.password.value && updatedState.passwordAgain.isValid;

		updatedState.btnEnabled =
			updatedState.email.isValid &&
			updatedState.password.isValid &&
			updatedState.password.value === updatedState.passwordAgain.value;

		this.setState(updatedState);
	};

	isInputValid = (pattern, value) => {
		const regex = new RegExp(pattern);
		return regex.test(value);
	};

	onHaveAccountHandler = () => {
		this.initState();
		this.props.onStateChange('signIn', {});
	};

	onModalCloseHandler = () => {
		this.props.onStateChange('confirmSignUp', {
			email: this.state.email.value
		});

		this.initState();
	};

	render() {
		let signUp = null;
		let error = null;
		let modal = null;

		if (this.state.modalOpen) {
			modal = (
				<ConfirmModal
					isOpen={this.state.modalOpen}
					close={this.onModalCloseHandler}
					continue={this.onModalCloseHandler}
					confirmMsg={this.props.intl.formatMessage({
						id: 'login.signUp.success'
					})}
				/>
			);
		}

		let button = (
			<SubmitButton
				value={this.props.intl.formatMessage({ id: 'login.signUp' })}
				isEnabled={this.state.btnEnabled}
				name="button"
				onSubmit={this.onSubmit}
			/>
		);

		if (this.state.loading) {
			button = (
				<Spinner type="dotCircle" size="8px" style={{ margin: '40px 0', marginLeft: '30%', float: 'left' }} />
			);
		}

		if (this.state.error) {
			error = (
				<h4>
					<FormattedMessage
						id={'error.signup.' + this.state.error.code}
						defaultMessage={this.state.error.message}
					/>
				</h4>
			);
		}

		const jsxInputs = inputs.map(input => {
			return (
				<Input
					key={input.key}
					type={input.type}
					name={input.name}
					emptyText={this.props.intl.formatMessage({ id: input.emptyId })}
					tooltip={this.props.intl.formatMessage({ id: input.tipId })}
					isValid={this.state[input.name].isValid}
					touched={this.state[input.name].touched}
					onInputChange={this.onInputChange}
				/>
			);
		});
	

		if (this.props.authState === 'signUp') {
			signUp = (
				<div className={classes.outter}>
					<div className={classes.middle}>
						<div className={classes.container}>
							<section className={classes.content}>
								<span className={classes.topPanel}>
									<img
										className={`img-fluid ${classes.iotIcon}`}
										src={process.env.PUBLIC_URL + '/assets/img/IoTpool_logo.png'}
										alt="iot-logo"
									/>
									<LangPanel className={classes.langPanel} />
								</span>
								<form action="">
									<h1>{texts.signUp}</h1>
									{error}
									{jsxInputs}
									{button}
									<div>
										<p onClick={this.onHaveAccountHandler}>{texts.haveAccount}</p>
									</div>
								</form>
							</section>
						</div>
					</div>
				</div>
			);
		}

		return (
			<div>
				{signUp}
				{modal}
			</div>
		);
	}
}

export default injectIntl(SignUp);
