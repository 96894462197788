import React from 'react';

import { FormattedMessage } from "react-intl";
import GeneralModal from "./../General/General";

import classes from "./AlarmModal.module.scss";

const alarmModal = (props) => {

    const onClose = () => {
        if (props.close) {
            props.close(props.alarm);
        }
    };

    const onShow = () => {
        if (props.onShow) {
            props.onShow(props.alarm);
        }
    };

    return (
        <GeneralModal
            isOpen={props.isOpen}
            className={classes.alarmModal}
            iconClassName={classes.closeIcon}
            close={onClose}
            closeOnEsc
            closeOnOverlayClick
        >
            <div className={classes.alarmHeader}>
                <i className={["fas fa-exclamation-triangle", classes.alarmHeaderIcon].join(" ")}></i>
                <span className={classes.alarmTitleText}>{props.alarm.title}</span>
            </div>
            <div className={classes.alarmBody}>
                <p>{props.alarm.body}</p>
            </div>
            <div className={classes.buttons}>
                <div className={classes.buttonWrapper}>
                    <button className={`orangeBtn ${classes.button}`} onClick={onShow}>
                        <FormattedMessage id="button.show" />
                    </button>
                </div>
                <div className={classes.buttonWrapper}>
                    <button className={`btn btn-secondary ${classes.button}`} onClick={onClose}>
                        <FormattedMessage id="button.close" />
                    </button>
                </div>
                <div className={classes.buttonWrapper}>
                    <button className={`btn btn-secondary ${classes.button}`} onClick={props.closeAll}>
                        <FormattedMessage id="button.closeAll" />
                        {" ("}{props.totalAlarms}{")"}
                    </button>
                </div>
            </div>
        </GeneralModal>
    )
};

export default alarmModal;