import React from "react";
import { injectIntl } from "react-intl";
import Select from "react-select";

const SelectPeriod = ({ onSelect, selectedPeriod, intl }) => {
  const options = [
    {
      value: "last24hours",
      label: intl.formatMessage({ id: "select.24hours" })
    },
    { value: "last7days", label: intl.formatMessage({ id: "select.7days" }) },
    { value: "last30days", label: intl.formatMessage({ id: "select.30days" }) }
  ];

  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      width: "206px",
      borderRadius: "5px",
      color: "#666666",
      boxShadow: "1px 1px 3px 2px rgba(172, 171, 171, 0.527)"
    }),
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      background: "#f9f9f9"
    }),
    placeholder: (provided, state) => ({
      color: "black",
      fontSize: "20px",
      margin: "auto",
      padding: "0 0 0 30px"
    }),
    option: (provided, state) => ({
      ...provided,
      textAlign: "left"
    }),
    input:(provided, state) => ({
      readOnly: 'true',
      userSelect: "none"
    })
  };

  return (
    <Select
      value={options.filter(opt => opt.value === selectedPeriod)}
      options={options}
      styles={customStyles}
      onChange={onSelect}
      placeholder="---"
      isSearchable={false}
    />
  );
};

export default injectIntl(SelectPeriod);
