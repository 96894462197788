import React from 'react';

import * as am4core from '@amcharts/amcharts4/core';

import GeneralModal from './../../../components/modals/General/General';
import ChartDatePanel from './../../../components/charts/ChartsPanel/ChartsPanel';
import LineChart from './../../../containers/charts/Line/Line';

import { FormattedMessage, injectIntl } from 'react-intl';

import { allTypes } from './../../../utils/binaryInputs';

import classes from './ComparatorModal.module.scss';

const comparatorModal = (props) => {
	let dateSelectionOnClass = classes.hideSelection;
	if (props.selectionOn && !props.isClosing) dateSelectionOnClass = classes.showSelection;

	const colorSet = new am4core.ColorSet();
	const mappings = [];
	props.sensors.forEach((dataset) => {
		const mapping = {
			id: dataset.id,
			data: dataset.dataset,
			time: 'timestamp',
			units: dataset.unit,
			legend: dataset.legend,
			color: colorSet.next().toString(),
			pretty_name: (`${dataset.id}_` + dataset.legend).split(' ').join('_'),
		};
		if (dataset.chartType === 'step') {
			mapping.mode = 'step';
			mapping.customTooltipEvaluator = (value) => {
				const strValue = value ? '1' : '0';
				let subType = 'binary';
				if (allTypes.includes(dataset.subType)) subType = dataset.subType;

				let newText = props.intl.formatMessage({ id: `tooltip.binInput.${subType}.${strValue}` });

				return newText;
			};
		}
		mappings.push(mapping);
	});

	return (
		<GeneralModal
			isOpen={props.isOpen}
			className={classes.comparatorModal}
			iconClassName={classes.closeIcon}
			close={props.close}
			closeOnEsc={false}
			closeOnOverlayClick={false}
		>
			{props.selectionOn ? null : (
				<div>
					<button className={`btn btn-light ${classes.selectDateButton}`} onClick={props.onOpenDateSelection}>
						<FormattedMessage id="button.selectDate" />
					</button>
				</div>
			)}
			<GeneralModal
				className={`${classes.dateModal} ${classes.slideContainer} ${dateSelectionOnClass}`}
				isOpen={props.selectionOn}
				close={props.closeDateSelection}
				iconClassName={classes.closeIcon}
			>
				<div className={classes.datePanelContainer}>
					<ChartDatePanel
						lang={props.lang}
						selectedPeriod={props.selectedPeriod}
						dateFrom={props.dateFrom}
						dateTo={props.dateTo}
						onSelect={props.onSelect}
						onDateFromChange={props.onDateFromChange}
						onDateToChange={props.onDateToChange}
						onFilterButtonClick={props.onFilterButtonClick}
						filterButtonDisabled={props.filterDisabled}
						style={{ margin: '0' }}
						closeOnSelect
					/>
				</div>
			</GeneralModal>
			<div className={`${classes.chartContainer}`}>
				<LineChart
					id="comparatorChart"
					label={props.intl.formatMessage({ id: 'chart.temperature' })}
					data={props.data}
					containsMoreDatasets
					sensor={props.sensors.length === 1 ? props.sensors[0] : null}
					exportName="Comparison"
					mapping={mappings}
					commonTimeAxis="timestamp"
					loading={props.graphLoading}
					from={props.dateFrom}
					to={props.dateTo}
					containerStyle={{ height: '100%', width: '100%', margin: '0px 0px 0px 0px' }}
					//loaderStyle={{ top: '400px' }}
					labelDisabled
					rightPanelDisabled
					lockXAxis={false}
				/>
			</div>
		</GeneralModal>
	);
};

export default injectIntl(comparatorModal);
