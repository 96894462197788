import React, { Component } from 'react';
import { connect } from 'react-redux';

import RemoteErrorModal from "./../../../components/modals/RemoteError/RemoteError.jsx";
import { clearRemoteError } from "./../../../store/actions/sensorActions.js";

class NetworkError extends Component {

    onModalClose = () => {
        this.props.clearError();
    };

    render() {
        const isModalOpen = this.props.error ? true : false;
        return (
            <RemoteErrorModal 
             isOpen={isModalOpen}
             close={this.onModalClose}
             continue={this.onModalClose}
             error={this.props.error}
             />
        );
    };
};

const mapStateToProps = state => {
    return {
        error: state.sensors.network_error
    };
};

const mapDispatchToProps = dispatch => {
    return {
        clearError: () => dispatch(clearRemoteError())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NetworkError);