import React from 'react';

import Unit from './../../../../icons/Unit/Unit';
import CheckBox from '../../../../UI/checkBoxes/Type2/Type2';

import classes from './ComparatorItem.module.scss';
import { isNumber } from 'lodash';

const onClickHandler = (e, measurement) => {
	e.stopPropagation();
	e.preventDefault();
	if (!measurement.disabled && measurement.onInputChange) {
		measurement.onInputChange(measurement.id, !measurement.checked);
	}
};

function ComparatorItem(props) {
	const { header, measurements } = props;

	return (
		<table className={classes.SensorDetailTable}>
			<tbody>
				<tr>
					<th className={classes.header} colSpan={4}>
						{header}
					</th>
				</tr>
				{measurements.map((meas) => {
					const rowClasses = [classes.rowRecord];
					if (meas.checked) rowClasses.push(classes.toggled);
					if (meas.disabled) rowClasses.push(classes.notAllowed);
					return (
						<tr key={meas.key} onClick={(e) => onClickHandler(e, meas)} className={rowClasses.join(' ')}>
							<td className={classes.iconColumn}>
								<Unit type={meas.iconType} className={classes.icon} />
							</td>
							<td className={classes.nameColumn}>
								<span className={classes.toggleContainer}>
									<CheckBox
										id={meas.id}
										type="checkbox"
										checked={meas.checked}
										readOnly={meas.disabled}
										inputChange={() => {}} // this is controled id onMouseUp event above
										toggleClass={classes.toggleButton}
									/>
									{meas.name}
								</span>
							</td>

							<td className={classes.valueColumn}>{meas.value ? `${isNumber(meas.value) ? meas.value.toFixed(2) : meas.value}` : `--.--`}</td>
							<td className={classes.unitColumn}>{meas.units}</td>
						</tr>
					);
				})}
			</tbody>
		</table>
	);
}

export default ComparatorItem;
