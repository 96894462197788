import React from 'react';

import Tooltip from './../../../Tooltip/Tooltip';

const smoke = (props) => {
	const disabledColor = "lightgray";
	let smokeState = (
		<div {...props}>
			<Tooltip tooltip={props.tooltip}>
				<svg width="100%" viewBox="0 0 45 45">
					<g>
						<path
							fill={props.disabled ? disabledColor :"#666"}
							d="M4.759 4.672v7.672c0 1.87 1.34 3.427 3.111 3.766.496 3.439 1.838 8.06 5.484 8.06h18.291c3.645 0 4.988-4.62 5.484-8.06a3.837 3.837 0 003.111-3.766V4.672H4.759zm8.595 17.248c-1.744 0-2.725-2.911-3.189-5.739h3.459c.268 1.5.656 3.63 1.066 5.739h-1.336zm8.022 0h-4.393a357.154 357.154 0 01-1.074-5.739h5.467v5.739zm6.641 0h-4.393v-5.739h5.467c-.281 1.567-.67 3.689-1.074 5.739zm3.629 0h-1.338c.412-2.109.801-4.239 1.068-5.739h3.461c-.463 2.828-1.443 5.739-3.191 5.739zm3.531-10.36a1.687 1.687 0 11-.001-3.375 1.687 1.687 0 01.001 3.375z"
						/>
						<path
							fill={props.disabled ? disabledColor :"#bf0000"}
							d="M12.845 27.396s-2.537 1.61-1.586 4.235c.951 2.624 2.816 4.015.387 6.712 4.412-2.103 2.27-5.474 1.277-7.259-.996-1.784-.078-3.688-.078-3.688zM32.431 27.396s-2.539 1.61-1.588 4.235c.953 2.624 2.816 4.015.387 6.712 4.412-2.103 2.271-5.474 1.277-7.259-.994-1.784-.076-3.688-.076-3.688zM22.74 27.396s-2.998 1.902-1.875 5.003c1.125 3.101 3.328 4.742.457 7.929 5.213-2.483 2.684-6.466 1.508-8.574-1.174-2.109-.09-4.358-.09-4.358z"
						/>
					</g>
				</svg>
			</Tooltip>
		</div>
	);

	if (props.closed) {
		smokeState = (
			<div {...props}>
				<Tooltip tooltip={props.tooltip}>
					<svg width="100%" viewBox="0 0 45 45">
						<g>
							<path
								fill={props.disabled ? disabledColor :"#666"}
								d="M4.759 4.672v7.672c0 1.87 1.34 3.427 3.111 3.766.496 3.439 1.838 8.06 5.484 8.06h18.291c3.645 0 4.988-4.62 5.484-8.06a3.837 3.837 0 003.111-3.766V4.672H4.759zm8.595 17.248c-1.744 0-2.725-2.911-3.189-5.739h3.459c.268 1.5.656 3.63 1.066 5.739h-1.336zm8.022 0h-4.393a357.154 357.154 0 01-1.074-5.739h5.467v5.739zm6.641 0h-4.393v-5.739h5.467c-.281 1.567-.67 3.689-1.074 5.739zm3.629 0h-1.338c.412-2.109.801-4.239 1.068-5.739h3.461c-.463 2.828-1.443 5.739-3.191 5.739zm3.531-10.36a1.687 1.687 0 11-.001-3.375 1.687 1.687 0 01.001 3.375z"
							/>
						</g>
					</svg>
				</Tooltip>
			</div>
		);
	}

	return smokeState;
};

export default smoke;
