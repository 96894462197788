import React from 'react';
import Tooltip from "./../../../Tooltip/Tooltip";

const pressure = props => {
    return (
        <div {...props}>
            <Tooltip tooltip={props.tooltip}>
                <svg width="100%" viewBox="0 0 64 64">
                    <style>
                        {
                            ".pressure_prefix__st5{fill:#6e83b7}.pressure_prefix__st7{fill:#80d6fb}.pressure_prefix__st8{fill:#edeff1}"
                        }
                    </style>
                    <circle className="pressure_prefix__st5" cx={32} cy={26} r={24} />
                    <circle className="pressure_prefix__st8" cx={32} cy={26} r={20} />
                    <path
                        d="M33 35.184V14h-2v21.184A2.99 2.99 0 0029 38a3 3 0 106 0 2.99 2.99 0 00-2-2.816z"
                        fill="#d3d3d3"
                    />
                    <circle className="pressure_prefix__st5" cx={32} cy={26} r={2} />
                    <path className="pressure_prefix__st5" d="M31 7h2v3h-2z" />
                    <path
                        className="pressure_prefix__st5"
                        transform="rotate(60 23.25 10.845)"
                        d="M21.75 9.844h3v2h-3z"
                    />
                    <path
                        className="pressure_prefix__st5"
                        transform="rotate(30.006 16.844 17.248)"
                        d="M15.345 16.25h3v2h-3z"
                    />
                    <path className="pressure_prefix__st5" d="M13 25h3v2h-3z" />
                    <path
                        className="pressure_prefix__st5"
                        transform="rotate(60.007 16.844 34.749)"
                        d="M15.845 33.25h2v3h-2z"
                    />
                    <path
                        className="pressure_prefix__st5"
                        transform="rotate(30.006 47.152 34.746)"
                        d="M45.655 33.75h3v2h-3z"
                    />
                    <path className="pressure_prefix__st5" d="M48 25h3v2h-3z" />
                    <path
                        className="pressure_prefix__st5"
                        transform="rotate(60.007 47.154 17.25)"
                        d="M46.155 15.75h2v3h-2z"
                    />
                    <path
                        className="pressure_prefix__st5"
                        transform="rotate(29.991 40.752 10.847)"
                        d="M39.75 9.344h2v3h-2z"
                    />
                    <path
                        className="pressure_prefix__st8"
                        d="M45.243 52a3.24 3.24 0 01-.515-6.441 5.943 5.943 0 015.921-5.45 5.936 5.936 0 015.004 2.743c.132-.02.264-.04.401-.04 1.114 0 2.07.675 2.483 1.638A3.78 3.78 0 0162 48.216 3.784 3.784 0 0158.216 52H45.243z"
                    />
                    <path
                        className="pressure_prefix__st7"
                        d="M48 56a1 1 0 01-2 0c0-.552 1-2 1-2s1 1.448 1 2zM52 56a1 1 0 01-2 0c0-.552 1-2 1-2s1 1.448 1 2zM50 59a1 1 0 01-2 0c0-.552 1-2 1-2s1 1.448 1 2zM54 59a1 1 0 01-2 0c0-.552 1-2 1-2s1 1.448 1 2zM58 59a1 1 0 01-2 0c0-.552 1-2 1-2s1 1.448 1 2zM56 56a1 1 0 01-2 0c0-.552 1-2 1-2s1 1.448 1 2zM60 56a1 1 0 01-2 0c0-.552 1-2 1-2s1 1.448 1 2z"
                    />
                    <g>
                        <path
                            d="M19.091 51.454L22 50l-2.909-1.454a.334.334 0 01-.103-.517l1.25-1.442-1.904-.135a.334.334 0 01-.293-.438l1.028-3.085-3.085 1.028a.333.333 0 01-.438-.293l-.135-1.904-1.442 1.25a.334.334 0 01-.517-.103L12 40l-1.454 2.909a.334.334 0 01-.517.103l-1.442-1.25-.135 1.904a.334.334 0 01-.438.293l-3.085-1.028 1.028 3.085a.333.333 0 01-.293.438l-1.904.135 1.25 1.442a.334.334 0 01-.103.517L2 50l2.909 1.454a.334.334 0 01.103.517l-1.25 1.442 1.904.135a.334.334 0 01.293.438l-1.028 3.085 3.085-1.028a.333.333 0 01.438.293l.135 1.904 1.442-1.25a.334.334 0 01.517.103L12 60l1.454-2.909a.334.334 0 01.517-.103l1.442 1.25.135-1.904a.334.334 0 01.438-.293l3.085 1.028-1.028-3.085a.333.333 0 01.293-.438l1.904-.135-1.25-1.442a.331.331 0 01.101-.515z"
                            fill="#ffe352"
                        />
                        <circle cx={12} cy={50} r={6} fill="#ffb236" />
                    </g>
                </svg>
            </Tooltip>
        </div>
    );
};

export default pressure;
