import React, { Component } from 'react';
import { connect } from 'react-redux';

import axios from 'axios';

import Siglink from './../../pages/SensorDetail/Siglink/Siglink';
import SimpleLeak from './../../pages/SensorDetail/SimpleLeak/SimpleLeak';
import Wicub from './../../pages/SensorDetail/Wicub2/Wicub2';
import Ste2 from './../../pages/SensorDetail/STE2/STE2';
import SiotechGPS from './../../pages/SensorDetail/siotech/SiotechGPS/SiotechGPS';
import SiotechUAlarm from './../../pages/SensorDetail/siotech/unior/SiotechUniorAlarm/SiotechUniorAlarm';
import SiotechUSecurity from './../../pages/SensorDetail/siotech/unior/SiotechUniorSecurity/SiotechUniorSecurity';

import Spinner from './../../../components/Spinner/Spinner.jsx';

import { setLocale } from './../../../store/actions/localeActions';
import sensors from './../../../utils/sensorTypes';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import classes from './QuickGraph.module.scss';

class QuickGraph extends Component {
	state = {
		sensor: null,
		measurements: [],
		config: null,
		isLoading: false,
		dateFrom: new Date().getTime() / 1000 - 24 * 60 * 60,
		dateTo: new Date().getTime() / 1000,
	};

	componentDidMount() {
		document.getElementById('root-loader').classList.add('root_loader_hide');
		const sensorIds = this.props.match.params.identifier.split('-');
		const lang = this.props.match.params.lang;

		switch (lang) {
			case 'cs':
				this.props.changeLang('cs');
				break;
			default:
				this.props.changeLang('en');
				break;
		}

		if (sensorIds.length === 2) {
			this.setState({ ...this.state, isLoading: true });

			this.fetchData(this.props.match.params.identifier, this.state.dateFrom, this.state.dateTo).then((sensor) => {
				if (sensor) {
					this.setState({
						...this.state,
						sensor: sensor.data.sensor,
						measurements: sensor.data.data,
						isLoading: false,
						config: sensor.data.config,
					});
				} else {
					this.props.history.push('/');
				}
			});
		} else {
			this.props.history.push('/');
		}
	}

	fetchData = (id, from, to) => {
		const requestParams = {
			params: {
				from: from,
				to: to,
			},
			headers: {
				'X-iot-gsol-secret': id,
			},
		};

		return axios.get(`https://backend.iotpool.io/show`, requestParams).catch((err) => {
			this.props.history.push('/');
		});
	};

	render() {
		let content = null;

		let startPeriod = 'day';
		let multiplier = 1;
		let config = null;
		let QuickGraphComponent = null;

		if (!this.state.sensor && this.state.isLoading) {
			content = <Spinner type="rectangles" size="11px" style={{ marginTop: '100px' }} />;
		} else if (!this.state.sensor) {
			content = null;
		} else {
			switch (this.state.sensor.sensor_type) {
				case sensors.siglink.iot_label:
					QuickGraphComponent = Siglink;
					config = {
						editButton: false,
						sensorOverview: true,
						datePanel: false,
						temperature: this.state.config.charts.includes('temperature'),
						humidity: this.state.config.charts.includes('humidity'),
					};
					break;
				case sensors.simpleLeak.iot_label:
					QuickGraphComponent = SimpleLeak;
					startPeriod = 'month';
					config = {
						editButton: false,
						sensorOverview: true,
						quickStateView: true,
						datePanel: false,
						diagram: false, //this.state.config.charts.length > 0,
						log: false,
					};
					break;
				case sensors.wicub.iot_label:
					QuickGraphComponent = Wicub;
					config = {
						editButton: false,
						sensorOverview: true,
						datePanel: false,
						temperature: this.state.config.charts.includes('temperature'),
						humidity: this.state.config.charts.includes('humidity'),
						pressure: this.state.config.charts.includes('pressure'),
						iaq: this.state.config.charts.includes('iaq'),
					};
					break;
				case sensors.ste2.iot_label:
					QuickGraphComponent = Ste2;

					const customChartsPerm = {};
					this.state.config.charts.forEach((customChart) => {
						customChartsPerm[customChart] = true;
					});

					config = {
						editButton: false,
						sensorOverview: true,
						datePanel: false,
						...customChartsPerm,
					};
					break;
				case sensors.siotech_gps.iot_label:
					QuickGraphComponent = SiotechGPS;
					config = {
						editButton: false,
						sensorOverview: true,
						datePanel: false,
						map: this.state.config.charts.includes('gps_coordinates'),
						log: true,
					};
					break;
				case sensors.siotech_unior_alarm.iot_label:
					QuickGraphComponent = SiotechUAlarm;
					config = {
						editButton: false,
						sensorOverview: true,
						datePanel: false,
						temperature_inner: this.state.config.charts.includes('temperature_inner'),
						temperature_ext: this.state.config.charts.includes('temperature_iext'),
						humidity: this.state.config.charts.includes('humidity'),
						pir: this.state.config.charts.includes('pir'),
						door: this.state.config.charts.includes('door'),
					};
					break;
				case sensors.siotech_unior_security.iot_label:
					QuickGraphComponent = SiotechUSecurity;
					config = {
						editButton: false,
						sensorOverview: true,
						datePanel: false,
						temperature_inner: this.state.config.charts.includes('temperature_inner'),
						temperature_ext: this.state.config.charts.includes('temperature_ext'),
						humidity: this.state.config.charts.includes('humidity'),
						pir: this.state.config.charts.includes('pir'),
						smoke: this.state.config.charts.includes('smoke'),
					};
					break;
				default:
					content = null;
					break;
			}
		}

		if (QuickGraphComponent) {
			content = (
				<QuickGraphComponent
					id={this.state.sensor.id}
					type={this.state.sensor.sensor_type}
					history={this.props.history}
					sensor={this.state.sensor}
					startPeriod={startPeriod}
					multiplier={multiplier}
					from={this.state.dateFrom}
					to={this.state.dateTo}
					externalData={this.state.measurements}
					externalLoading
					isExternalDataLoading={false}
					config={config}
				/>
			);
		}

		return (
			<div className={'centerContainer'} style={{ marginTop: '15px' }}>
				<div className={classes.backButtonWrapper}>
					<Link to="/">
						<button className="btn btn-md btn-secondary">
							<FormattedMessage id="button.home" defaultMessage="Back" />
						</button>
					</Link>
				</div>
				{content}
			</div>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		changeLang: (lang) => dispatch(setLocale(lang)),
	};
};

export default connect(null, mapDispatchToProps)(QuickGraph);
