
export const formateDate = (timestamp, lang = "cs") => {
  if (!timestamp)
    return timestamp;

  const date = new Date(timestamp * 1000);
  switch (lang) {
    case "cs":
      //return `${date.getDate()}.${date.getMonth()}.${date.getFullYear()} - ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
      return date.toLocaleDateString("cs-CZ");
    default:
      return date.toLocaleDateString("en-US");
  }
};

export const formateDatetime = (timestamp, lang = "cs") => {
  if (!timestamp)
    return timestamp;

  const date = new Date(timestamp * 1000);

  switch (lang) {
    case "cs":
      //return `${date.getDate()}.${date.getMonth()}.${date.getFullYear()} - ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
      return date.toLocaleString("cs-CZ");
    default:
      return date.toLocaleString("en-US");
  }
};

