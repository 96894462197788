import am4lang_cs_CZ from "@amcharts/amcharts4/lang/cs_CZ";
import am4lang_en_US from "@amcharts/amcharts4/lang/en_US";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as chartBuilder from "./chartBuilder.js";

const regimes = chartBuilder.regimes;

export const addBullets = (chart) => {
    if (chart.series) {
        chart.series.values.forEach((serie) => {
            chartBuilder.addBullets(serie);
        });
    }
};

export const hideBullets = (regime) => {
    regime.series.forEach((item) => {
        item.serie.bullets.each((bullet) => {
            bullet.fillOpacity = 0;
            bullet.strokeOpacity = 0;
            bullet.disabled = true;
        });
    });
};

export const showBullets = (regime) => {
    regime.series.forEach((item) => {
        item.serie.bullets.each((bullet) => {
            bullet.disabled = false;
            //setTimeout(() => {
            bullet.fillOpacity = 1;
            bullet.strokeOpacity = 1;
            //}, 500);
        });
    });
};

export const addScrollBarX = (chart) => {
    if (chart.series) {
        chart.series.values.forEach((serie) => {
            chartBuilder.addScrollbarX(chart, serie);
        });
    }
};

export const getYAxesRanges = (chart) => {
    let ranges = [];
    chart.yAxes.values.forEach((axis) => {
        const range = [axis.minZoomed, axis.maxZoomed];
        ranges.push(range);
    });

    return ranges;
};

export const getXAxesRanges = (chart) => {
    // Do not support multiple axes !!
    let ranges = [];
    chart.xAxes.values.forEach((axis) => {
        ranges = [axis.minZoomed, axis.maxZoomed];
    });

    return ranges;
};

export const zoomToRanges = (chart, ranges, multiAxes = false) => {
    if (multiAxes) {
        /*
		for (let i = 0; i < ranges.length; i++) {
			const serie = chart.series.values[i];
			if (serie && (serie.isHiding || serie.isHidden)) continue;
			const range = ranges[i];
			chart.yAxes.values[i].zoomToValues(range[0], range[1], true, false);
		}
		*/
    } else {
        const maxes = [];
        const mins = [];
        for (let i = 0; i < ranges.length; i++) {
            const serie = chart.series.values[i];

            if (serie && (serie.isHiding || serie.isHidden)) continue;
            const range = ranges[i];
            mins.push(range[0]);
            maxes.push(range[1]);
        }
        chart.yAxes.values[0].zoomToValues(Math.min(...mins), Math.max(...maxes), true, false);
    }
};

export const zoomToDates = (chart, from, to) => {
    for (let i = 0; i < chart.xAxes.values.length; i++) {
        chart.xAxes.values[i].zoomToDates(from, to, true, false);
    }
};

export const hideAllLimits = (chart) => {
    chart.yAxes.values.forEach((axis) => {
        axis.axisRanges.values.forEach((range) => {
            if (!range.name || !range.name.startsWith("aggr_line")) {
                range.axisFill.hide();
                range.grid.hide();
                range.label.hide();
            }
        });
    });
};

export const showAllLimits = (chart) => {
    chart.yAxes.values.forEach((axis) => {
        axis.axisRanges.values.forEach((range) => {
            if (!range.name || !range.name.startsWith("aggr_line")) {
                range.axisFill.show();
                range.grid.show();
                range.label.show();
            }
        });
    });
};

export const turnOffAnimations = (chart) => {
    chart.series.values.forEach((serie) => {
        serie.showOnInit = false;
        serie.defaultState.transitionDuration = 0;
        serie.hiddenState.transitionDuration = 0;
        serie.interpolationDuration = 0;
    });
};

export const turnOnAnimations = (chart) => {
    chart.series.values.forEach((serie) => {
        serie.showOnInit = true;
        serie.defaultState.transitionDuration = 700;
        serie.hiddenState.transitionDuration = 700;
        serie.interpolationDuration = 1000;
    });
};

export const simplifyAnimations = (chart) => {
    chart.series.values.forEach((serie) => {
        serie.showOnInit = true;
        serie.interpolationDuration = 0;
    });
};

export const getXAxesLabelWidth = (chart, constant = 0) => {
    let margin = 0;
    chart.yAxes.values.forEach((axis) => {
        if (!axis.disabled) {
            margin += axis.measuredWidth;
            margin -= constant;
            //margin += axis.paddingLeft + axis.paddingRight;
        }
    });

    return margin + 15;
};

export const turnOnMobileRegime = (chart, isGantt = false) => {
    chart.paddingBottom = 35;
    chart.xAxes.each((xAxis) => (xAxis.renderer.minGridDistance = 60));
    chart.yAxes.values.forEach((axis) => {
        axis.renderer.inside = true;
        axis.renderer.labels.template.align = "left";
        axis.title.dx = 10;
        axis.axisRanges.each(function (range) {
            if (range.name && range.name.startsWith("aggr_line")) {
                range.label.inside = true;
                range.label.background.fill = am4core.color("#fff");
            }
        });
    });
    if (isGantt) {
        chart.series.values.forEach((serie) => {
            if (serie.className === "ColumnSeries") {
                serie.columns.template.tooltipText = "[bold]{openDateX}[/]\n-\n[bold]{dateX}[/]";
                serie.tooltip.label.textAlign = "middle";
            }
        });
    }
};

export const turnOffMobileRegime = (chart, mainYaxis, isGantt = false) => {
    chart.paddingBottom = 0;
    chart.xAxes.each((xAxis) => (xAxis.renderer.minGridDistance = 120));
    chart.yAxes.values.forEach((axis) => {
        axis.renderer.inside = false;
        axis.renderer.labels.template.align = "center";
        axis.title.dx = 0;
        if (axis !== mainYaxis && !isGantt) {
            //* We need to define axis title setting again because the new container is defined
            //* when device leave the mobil regime
            //* This is multiAxis - we need to redefine
            //* it is the same definition like in "chartBuilder" in "addValueAxis"
            axis.title.rotation = 0;
            axis.title.align = "center";
            axis.title.valign = "top";
            axis.title.dy = -30;
            axis.title.paddingLeft = 0;
            axis.title.paddingRight = 0;
            axis.layout = "absolute";
            axis.paddingLeft = -5;
            axis.paddingRight = -5;
        }

        axis.axisRanges.each(function (range) {
            if (range.name && range.name.startsWith("aggr_line")) {
                range.label.inside = false;
                range.label.background.fill = am4core.color("#eee");
            }
        });
    });
    if (isGantt) {
        chart.series.values.forEach((serie) => {
            if (serie.className === "ColumnSeries") {
                serie.columns.template.tooltipText = "[bold]{openDateX}[/] - [bold]{dateX}[/]";
                serie.tooltip.label.textAlign = "start";
            }
        });
    }
};

export const setChartLocale = (chart, lang) => {
    const locale = lang === "cs" ? am4lang_cs_CZ : am4lang_en_US;
    chart.language.locale = locale;
};

export const changeLocale = (chart, regimes, mapping, lang) => {
    const locale = lang === "cs" ? am4lang_cs_CZ : am4lang_en_US;
    chart.language.locale = locale;

    for (let regimeName in regimes) {
        regimes[regimeName].series.forEach((item) => {
            for (let i = 0; i < mapping.length; i++) {
                const actMap = mapping[i];
                if (actMap.id === item.config.id && actMap.data === item.config.data) {
                    item.serie.legendSettings.labelText = actMap.legend;
                    break;
                }
            }
            if (regimeName === "ColumnSeries") {
                switch (lang) {
                    case "cs":
                        item.serie.columns.template.tooltipText = "start: [bold]{dateX}[/]\nkonec: [bold]{dateXEnd}[/]\nhodnota: [bold]{valueY}[/]";
                        break;
                    default:
                        item.serie.columns.template.tooltipText = "start: [bold]{dateX}[/]\nend: [bold]{dateXEnd}[/]\nvalue: [bold]{valueY}[/]";
                        break;
                }
            }
        });
    }
};

export const turnOffMultipleAxes = (regime, mainX, mainY) => {
    if (!regime) return;

    const dateAxis = mainX;
    const valueAxis = mainY;

    valueAxis.title.hide();
    valueAxis.renderer.labels.template.fill = am4core.color("black");
    valueAxis.disabled = false;

    if (dateAxis && valueAxis) {
        regime.series.forEach((line) => {
            const serie = line.serie;
            serie.yAxis = valueAxis;
            serie.xAxis = dateAxis;
            line.y.disabled = true;
        });
    }
};

export const turnOnMultipleAxes = (chart, regime, mainY) => {
    if (!regime || !chart) return;

    mainY.disabled = true;

    regime.series.forEach((line) => {
        const valueAxis = line.y;
        const serie = line.serie;
        let anyData = false;

        if (serie.data.length > 0) anyData = true;

        if (serie.yAxis !== valueAxis) {
            valueAxis.disabled = false;
            valueAxis.title.show();
            valueAxis.renderer.labels.template.fill = serie.stroke;
            serie.yAxis = valueAxis;
        }

        if (serie.isHiding || serie.isHidden || !anyData) {
            valueAxis.disabled = true;
        }
    });
};

export const chnageLineValue = (chart, value, axisNumber, setname) => {
    let axis = chart.yAxes.values[axisNumber];

    if (axis) {
        axis.axisRanges.each(function (range) {
            if (range.name && range.name.startsWith("aggr_line")) {
                range.show();
                range.grid.disabled = false;
                range.value = value;
                range.label.text = (Math.round(value * 100) / 100).toFixed(2);
            }
        });
    }
};

export const chnageAggrLineValue = (chart, direction, value) => {
    let axis = chart.yAxes.values[0];
    if (direction === "x") {
        axis = chart.xAxes.values[0];
    }

    if (axis) {
        axis.axisRanges.each(function (range) {
            if (range.name && range.name.startsWith("aggr_line")) {
                range.show();
                range.grid.disabled = false;
                if (direction === "x") {
                    range.date = value;
                } else {
                    range.value = value;
                    range.label.text = (Math.round(value * 100) / 100).toFixed(2);
                }
            }
        });
    }
};

export const deleteAggrLines = (chart) => {
    let lineRanges = [];

    chart.xAxes.values.forEach((xAxis) => {
        xAxis.axisRanges.each(function (range) {
            if (range.name && range.name.startsWith("aggr_line")) {
                //range.hide();
                lineRanges.push(range);
            }
        });
        lineRanges.forEach((range) => {
            xAxis.axisRanges.removeValue(range);
        });
        lineRanges = [];
    });

    chart.yAxes.values.forEach((yAxis) => {
        yAxis.axisRanges.each(function (range) {
            if (range.name && range.name.startsWith("aggr_line")) {
                //range.hide();
                lineRanges.push(range);
            }
        });
        lineRanges.forEach((range) => {
            yAxis.axisRanges.removeValue(range);
        });
    });
};

export const injectDataToIndividualSeries = (chart, regime, data, invalidate = false) => {
    regime.series.forEach((item) => {
        item.serie.isEmpty = false;

        if (data.hasOwnProperty(item.serie.name)) {
            item.serie.data = data[item.serie.name];

            if (data[item.serie.name].length === 0) {
                item.serie.data = regime.emptySet;
                item.serie.isEmpty = true;
            }
        } else {
            item.serie.data = regime.emptySet;
            item.serie.isEmpty = true;
        }
    });

    if (invalidate) chart.invalidateData();
    //chart.invalidateData();
};

/*
export const injectDataToIndividualSeries = (chart, regime, data) => {
	//chart.scrollbarX.series.clear();
	//let invalidate = false;

	regime.series.forEach(item => {
		if (item.serie && data[item.serie.name]) {
			let injectData = true;
			let serieIndex = chart.series.indexOf(item.serie);

			if (data[item.serie.name].length > 0 && serieIndex === -1) {
				//item.serie.disabled = false;
				//chart.series.push(item.serie);
			} else if (data[item.serie.name].length === 0 && serieIndex !== -1) {
				//item.serie.disabled = true;
				injectData = false;
				//invalidate = true;
				//chart.series.moveValue(item.serie);
				//count++;
			}

			if (injectData) {
				if (!item.serie.isDisposed()) item.serie.data = data[item.serie.name];
				//if (item.serie.disabled) item.serie.disabled = false;
				//if(chart.scrollbarX.series.length === 0) chart.scrollbarX.series.push(item.serie);
			}
		} /*else if (item.serie && item.serie.data.length > 0 && data.length === 0) {
			console.log('jsem tady');
			item.serie.data = [];
			chart.invalidateData();
			item.serie.disabled = true;
		
	});

	//chart.invalidateData();
};
*/

export const smartYZoom = (chart, multiAxes) => {
    const ranges = [];

    chart.series.each((serie) => {
        if (serie.data.length > 0) {
            ranges.push(extractExtremes(serie.data, serie.name));
        } else {
            ranges.push(extractExtremes(chart.data, serie.name));
        }
    });

    zoomToRanges(chart, ranges, multiAxes);
};

const extractExtremes = (data, name) => {
    const result = [undefined, undefined];

    if (data.length > 0) {
        const vals = [];
        data.forEach((item) => vals.push(item[name]));

        result[0] = Math.floor(Math.min(...vals));
        result[1] = Math.ceil(Math.max(...vals));
    }

    return result;
};

export const moveLegends = (chart, position) => {
    if (position === "out") {
        chart.legend.parent = chart.chartContainer;
        chart.legend.scrollable = true;
        chart.legend.maxHeight = 85;
    } else {
        //* We have to dispose old legend container which was out of plotcontainer
        //* Then we need to create new Legend container inside the plot container again
        //* Needs to be new container or the labels are badly positioned
        chart.legend.dispose();
        chart.legend = new am4charts.Legend();
        chart.legend.useDefaultMarker = false;
        chart.legend.parent = chart.plotContainer;
        chart.legend.zIndex = 100;
        chart.legend.itemContainers.template.togglable = true;
    }
};

export const yMultiAxesPrettierOn = (regime) => {
    const half = Math.floor(regime.series.length / 2);
    let i = half;
    let constant = 10;
    let addConst = 0;
    if (regime.series.length === 2) constant = 6;
    if (regime.series.length % 2 === 0 && regime.series.length > 3) addConst = -4;

    regime.series.forEach((item) => {
        const axis = item.y;
        if (regime.series.length > 3) axis.renderer.minGridDistance = 60;
        axis.renderer.labels.template.dy = i * constant + addConst;
        axis.title.disabled = true;

        i--;

        if (i === 0) {
            //constant = -1;
            if (regime.series.length % 2 === 0) i = -1;
        }
        if (regime.series.length % 2 === 0 && (i === 1 || i === -1)) {
            constant = 6;
            addConst = 0;
        } else {
            if (regime.series.length % 2 === 0 && regime.series.length > 3) addConst = -4;
            constant = 10;
        }
        if (i < 0) addConst = -1 * addConst;
    });
    /*
	chart.yAxes.each(axis => {
		//axis.renderer.labels.template.disabled = true;
		console.log( axis.renderer.minGridDistance, axis.renderer.labels.template.dy);
		if(chart.yAxes.length > 4) axis.renderer.minGridDistance = 60;
		axis.renderer.labels.template.dy = i * 10;
		console.log(axis.renderer.labels.template.dy);
		axis.title.disabled = true;
		i++;
	});
	*/
};
export const yMultiAxesPrettierOff = (regime) => {
    regime.series.forEach((item) => {
        const axis = item.y;
        axis.renderer.minGridDistance = 40;
        axis.renderer.labels.template.dy = 0;
        axis.title.disabled = false;
    });
};

export const disableAllRegimesExceptOne = (chartRegimes, selectedRegime, mainX, mainY) => {
    for (let regimeName in chartRegimes) {
        if (regimeName === selectedRegime) {
            chartRegimes[regimeName].series.forEach((item) => {
                //if (item.serie.xAxis !== mainX) item.serie.xAxis.disabled = false;
                if (item.serie.yAxis !== mainY) item.serie.yAxis.disabled = false;
                //item.serie.disabled = false;
                //item.serie.show();
                //item.serie.hiddenInLegend = false;
                if (regimeName === regimes.ColumnSeries) showBullets(chartRegimes[regimeName]);
            });
        } else {
            chartRegimes[regimeName].series.forEach((item) => {
                //if (item.serie.xAxis !== mainX) item.serie.xAxis.disabled = true;
                if (item.serie.yAxis !== mainY) item.serie.yAxis.disabled = true;
                //item.serie.hide();
                //item.serie.disabled = true;
                //item.serie.hiddenInLegend = true;
                if (regimeName === regimes.ColumnSeries) hideBullets(chartRegimes[regimeName]);
            });
        }
    }
};

export const changeAxisProps = (axis, props) => {
    for (let propName in props) {
        axis[propName] = props[propName];
    }
};

export const showWeekends = (axis) => {
    if (axis.axisRanges) axis.axisRanges.clear();

    const fiveMonthsInMs = 5 * 30.5 * 24 * 60 * 60 * 1000;
    if (axis.maxZoomed - axis.minZoomed > fiveMonthsInMs) return;

    let start = axis.positionToDate(0);
    let end = axis.positionToDate(1);

    // Get weekends
    var current = new Date(start);
    while (current < end) {
        // Get weekend start and end dates
        let weekendStart = getWeekend(current);
        let weekendEnd = new Date(weekendStart);
        weekendEnd.setDate(weekendEnd.getDate() + 2);

        // Create a range
        let range = axis.axisRanges.create();
        range.date = weekendStart;
        range.endDate = weekendEnd;
        range.axisFill.fill = am4core.color("#396478");
        range.axisFill.fillOpacity = 0.2;
        range.grid.strokeOpacity = 0;

        // Iterate
        current.setDate(current.getDate() + 7);
    }

    function getWeekend(date) {
        let lastday = date.getDate() - (date.getDay() || 7) + 6;
        let lastdate = new Date(date);
        lastdate.setDate(lastday);
        lastdate.setHours(0);
        lastdate.setMinutes(0);
        lastdate.setSeconds(0);
        lastdate.setMilliseconds(0);
        return lastdate;
    }
};

export const lockAxis = (axis, from, to) => {
    //return;
    axis.strictMinMax = true;
    axis.min = from.getTime();
    axis.max = to.getTime();
};

export const unlockAxis = (axis) => {
    //return;
    axis.strictMinMax = false;
    axis.min = undefined;
    axis.max = undefined;
};

export const setZoomedStyle = (chart) => {
    window.scrollTo(0, 0);
    document.body.style.overflow = "hidden";
    document.documentElement.style.overflow = "hidden";
    chart.paddingBottom = 50;
};
export const disableZoomedStyle = (chart) => {
    document.body.style.overflow = "visible";
    document.documentElement.style.overflow = "visible";
    chart.paddingBottom = 0;
};

/*
export const addLine = (chart, value) => {

	const dateAxis = chart.xAxes.values[0];

	dateAxis.axisRanges.each(function (range) {
		range.show();
		range.grid.disabled = false;
		range.date = new Date(2020, 0, 23,6,0,0);
	})


};

*/
