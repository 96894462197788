import React from 'react';
import Tooltip from "./../../../Tooltip/Tooltip";

import classes from './ActionButton.module.scss';

const actionButton = props => {
  let classesArr = [classes.icon];

  switch (props.type) {
    case 'Edit':
      classesArr.push('fas fa-user-edit');
      classesArr.push(classes.editLink);
      break;
    case 'Delete':
      classesArr.push('fas fa-minus-circle');
      classesArr.push(classes.deleteLink);
      break;
    case 'Activate':
      classesArr.push('fas fas fa-key');
      classesArr.push(classes.activateLink);
      break;
    case 'Protect':
      classesArr.push('fas fa-shield-alt');
      classesArr.push(classes.protectLink);
      break;
    default:
      //no action here
      break;
  }
  if (props.clearDefaultClasses) classesArr = [classesArr[1]];

  if (props.className) {
    classesArr.push(props.className);
  }

  let content = null;
  if (!props.hidden) {
    content = (
      <div >
        <Tooltip tooltip={props.tooltip}>
          <i className={classesArr.join(' ')}
            onClick={props.click}
          />
        </Tooltip>
      </div>
    );
  }

  return content;
};

export default actionButton;
