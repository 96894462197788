exports.min = function (data, dataset_name, omitDisabled = true, omitNan = true) {
	let min = Number.MAX_VALUE;
	let count = 0;


	for (let i = 0; i < data.length; i++) {
		const value = data[i];
		if (value.hasOwnProperty(dataset_name)) {
			if (omitDisabled && value.disabled) continue;
			if (omitNan && (isNaN(value[dataset_name]) || value[dataset_name] === 'undefined')) continue;

			min = Math.min(min, value[dataset_name]);
			count++;
		}
	}



	if (count === 0) return null;
	else return min;
};
