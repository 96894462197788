import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import TokenInput from './../../../UI/Inputs/TokenInput/TokenInput.jsx';
//import Coin from "./../../../icons/Coin/Coin";

import classes from "./InsertToken.module.scss";

const insertToken = props => {

	const onClickHandler = () => {
		switch (props.lang) {
			case "cs":
				window.open("https://www.iotpool.io/shop/kredit/");
				break;
			default:
				window.open("https://www.iotpool.io/en/shop/credit/");
				break;
		}
	};

	return (
		<div>
			<div>
				<h6 className="modalMessage" style={{ margin: "0px 0 0px 0" }}>
					<FormattedMessage id="modal.insertTopupCode" defaultMessage="Insert your top-up code" />
				</h6>
				<button className={`btn btn-outline-info ${classes.buyCreditBtn}`} onClick={onClickHandler}>
					<FormattedMessage id="button.buyCredit" defaultMessage="Buy credit" />
					{/*<Coin className={classes.coin}/>*/}
				</button>
				<div style={{ margin: '30px 0' }}>
					<TokenInput
						key="sensor.token"
						value={props.value}
						name="token"
						inputChange={props.onValueChanged}
					/>
				</div>
			</div>

			<button
				className="Btn_range"
				onClick={props.continue}
				disabled={props.isDisabled}
				style={{ marginRight: '10px' }}
			>
				<FormattedMessage id="button.topup" defaultMessage="Topup" />
			</button>
			<button className="btn btn-md btn-secondary" onClick={props.close}>
				<FormattedMessage id="button.cancel" defaultMessage="Cancel" />
			</button>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		lang: state.locale.lang,
	};
};


export default connect(mapStateToProps, null)(insertToken);;
