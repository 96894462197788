import Auth from '@aws-amplify/auth';
import axios from './../axios/axios.js';

let timer = null;

const startAutoSessionRenewal = () => {
	checkCurrentUser();
};
export default startAutoSessionRenewal;

export const checkCurrentUser = () => {
	Auth.currentAuthenticatedUser()
		.then(user => {
			const expTime = user.signInUserSession.idToken.payload.exp;
			setTimer(expTime);
		})
		.catch(err => {
			logOut();
		});
};
/*/
export const checkCurrentUser = () => {
	Auth.currentAuthenticatedUser()
		.then(user => {
			const expTime = new Date().getTime() + 1 * 60 * 1000;
			setTimer(expTime);
		})
		.catch(err => {
			logOut();
		});
};
*/
/*
export const setTimer = expTime => {
	//const exp = expTime * 1000 - new Date().getTime() - 10 * 60 * 1000; // 10 minutes before expiration
	const exp = 5000;
	//const exp = 70000;
	timer = setTimeout(function resetSession() {
		refreshCurrentSession()
			.then(actSession => {
				let nextExpTime = 5000;
				console.log("timer refresuje", timer);
				localStorage.setItem("timer", timer);
				timer = setTimeout(resetSession, nextExpTime); //nextExpTime
				console.log("new timer ", timer);
			})
			.catch(err => {
				logOut();
			});
	}, exp);
};
*/

export const setTimer = expTime => {
	const exp = expTime * 1000 - new Date().getTime() - 10 * 60 * 1000; // 10 minutes before expiration
	//const exp = 70000;
	timer = setTimeout(function resetSession() {
		refreshCurrentSession()
			.then(actSession => {
				let nextExpTime = actSession.idToken.payload.exp;
				nextExpTime = nextExpTime * 1000 - new Date().getTime();
				axios.defaults.headers.common['Iot-Id-Token'] = actSession.idToken.jwtToken;
				timer = setTimeout(resetSession, nextExpTime); //nextExpTime
			})
			.catch(err => {
				logOut();
			});
	}, exp);
};


export const logOut = () => {
	Auth.signOut();
	if (timer) {
		clearInterval(timer);
	}
};

const refreshSessionPromise = refreshToken => {
	return new Promise(async (resolve, reject) => {
		const user = await Auth.currentAuthenticatedUser();
		return user.refreshSession(refreshToken, async (err, data) => {
			if (err) reject(err);
			else {
				resolve(data); // THIS IS YOUR REFRESHED ATTRIBUTES/GROUPS
			}
		});
	});
};

export const refreshCurrentSession = () => {
	return Auth.currentSession().then(session => {
		return refreshSessionPromise(session.getRefreshToken());
	});
};

export const stopWatching = () =>{
	if(timer || timer === 0){
		clearInterval(timer);
	}
};

/*
Promise.all([Auth.currentAuthenticatedUser(), Auth.currentSession()]).then(
      ([user, session]) => {
        console.log(user, session);
        user.refreshSession(session.refreshToken, (err, session) => {
          console.log("New session", session);
          Auth.currentSession().then(actSession => {
            console.log("NEW NEW ses", actSession);
          });
        });
      }
    );
    */
