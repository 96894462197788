import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";

import PhoneInput from "react-phone-input-2";
//import CheckBox from './../../checkBoxes/Rectangle/Rectangle.jsx';
import CheckBox from "./../../checkBoxes/Type2/Type2";

import "react-phone-input-2/lib/style.css";
import classes from "./PhoneInput.module.scss";
//import cz from 'lang/cz.json';
//import en from 'lang/en.json';

const phoneInput = (props) => {
    let enableBtn = null;
    let disabled = props.disabled;
    const phoneInputClasses = [classes.phoneInput];
    const phoneInputContainerClasses = [classes.phoneNumberContainer];
    let buttonStyle = {
        border: "1px solid black",
    };

    if (props.readOnly) {
        buttonStyle.cursor = "not-allowed";
        buttonStyle.pointerEvents = "none";
    }

    if (props.notFocusable) {
        phoneInputContainerClasses.push(classes.notFocusable);
    }

    if (props.showEnableBtn) {
        disabled = props.disabled || !props.btnIsEnabled;
    }
    if (props.showEnableBtn) {
        enableBtn = (
            <div className={classes.remainsInfo}>
                <CheckBox
                    text={
                        <div>
                            <FormattedMessage id="button.enable" />
                            {props.btnText ? ` ${props.btnText}` : ""}
                        </div>
                    }
                    type="checkbox"
                    inputChange={props.enableChange}
                    checked={props.btnIsEnabled}
                    readOnly={props.readOnly}
                />
            </div>
        );
    }

    if (disabled) {
        phoneInputClasses.push(classes.disabled);
    }

    const onChange = (value, e) => {
        let val = value;

        if (!value.startsWith("+" + e.dialCode)) {
            val = "+" + val;
        }

        const obj = {
            target: {
                value: val.replace(/ /g, ""),
                name: props.name,
            },
            info: {
                ...e,
            },
        };

        props.inputChange(obj);
    };

    return (
        <div className={`formItem`}>
            <label className={`inputTitle`}>{props.label}</label>
            <div className={phoneInputContainerClasses.join(" ")}>
                <div className={[classes.inputContainer].join(" ")}>
                    <PhoneInput
                        //defaultCountry="cz"
                        //regions={'europe'}
                        inputClass={phoneInputClasses.join(" ")}
                        preferredCountries={["cz", "de", "fr"]}
                        buttonStyle={buttonStyle}
                        dropdownClass={classes.dropDown}
                        searchClass={classes.search}
                        enableSearch
                        searchPlaceholder={props.intl.formatMessage({ id: "select.sensor" })}
                        onChange={onChange}
                        countryCodeEditable={false}
                        //disabled={disabled}
                        value={props.value}
                        disabled={props.readOnly}
                        id={props.id}
                    />
                </div>
                {enableBtn}
            </div>
        </div>
    );
};
export default injectIntl(phoneInput);
