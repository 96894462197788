import React from 'react';

import Tooltip from "./../../../Tooltip/Tooltip";

const humidity = props => {
    return (
        <div {...props}>
            <Tooltip tooltip={props.tooltip}>
                <svg width="100%" viewBox="0 0 650 650">
                    <path
                        d="M320.771 634.537c-92.873 0-183.064-52.799-202.283-150.458-27.122-137.854 92.759-235.51 151.96-342.483 22.128-39.984 42.21-82.557 52.056-128.596h7.958c9.846 46.039 29.927 88.611 52.056 128.596 59.201 106.974 179.081 204.457 151.959 342.309-19.219 97.661-110.195 150.544-203.067 150.544l-10.639.088z"
                        fill="#F2F2F2"
                    />
                    <path
                        d="M203.18 241.121s8.611 21.801 40.172 21.801c32.074 0 42.595-22.067 42.595-22.067s9.237 21.041 40.798 21.041c30.791 0 41.568-21.041 41.568-21.041s9.751 21.041 41.055 21.041c26.562 0 39.833-8.526 42.851-13.001 52.304 72.194 100.729 141.113 82.254 235.005-19.214 97.67-110.192 150.549-203.068 150.549l-10.633.093c-92.877 0-183.064-52.808-202.288-150.467-18.609-94.571 31.981-170.224 84.696-242.954z"
                        fill="#5EC4F7"
                    />
                    <path
                        d="M491.878 302.827c-12.563-20.497-24.521-29.129-35.297-15.016-3.018 4.474-14.113-5.132-36.95-14.113-3.429-1.348-17.335-12.091-20.435-12.614C331.6 139.644 330.46 21.23 330.46 13.411c.308 39.401 55.394 146.329 112.737 217.231 19.091 23.617 35.307 48.26 48.681 72.185z"
                        fill="#E6E6E6"
                    />
                    <path
                        d="M538.414 424.217c1.365 9.072 1.499 24.058-1.221 41.958 2.196-20.056 1.324-39.259-1.93-57.785 1.385 5.737 2.432 11.034 3.151 15.827z"
                        fill="#3DB9EA"
                    />
                    <path
                        d="M535.263 408.39c3.253 18.526 4.126 37.729 1.93 57.785a.74.74 0 00-.021.153c-9.833 64.692-56.728 167.391-216.4 168.213-55.065 0-109.196-18.567-148.137-54.039-55.414-56.645-55.558-137.647-55.558-138.488 16.75 53.022 66.283 129.045 198.809 134.619 132.535 5.583 172.297-168.109 92.774-299.25a492.515 492.515 0 01-9.463-16.299c3.1.523 6.487.812 10.171.812 26.562 0 38.53-14.78 41.547-19.255 14.298 19.736 28.4 39.689 40.963 60.187 22.324 39.925 36.724 77.829 43.385 105.562z"
                        fill="#3DB9EA"
                    />
                    <path
                        d="M535.263 408.39c-6.661-27.732-21.061-65.637-43.385-105.562 20.425 33.295 36.795 68.027 43.385 105.562zM320.771 634.541c159.672-.822 206.567-103.521 216.4-168.213a209.037 209.037 0 01-2.699 17.571c-19.214 97.67-110.192 150.549-203.068 150.549l-10.633.093z"
                        fill="#5EC4F7"
                    />
                    <path
                        d="M320.771 634.541c-71.6.358-118.033-23.238-148.137-54.039 38.941 35.472 93.072 54.039 148.137 54.039z"
                        fill="#3DB9EA"
                    />
                    <path
                        d="M117.077 442.014c0 .841.144 81.844 55.558 138.488-26.737-24.346-46.331-56.676-54.151-96.428-2.843-14.451-4.075-28.451-3.911-42.082l2.504.022z"
                        fill="#5EC4F7"
                    />
                    <g>
                        <path
                            d="M146.947 388.544a15.184 15.184 0 01-3.542-.604c-7.982-2.36-12.541-10.745-10.181-18.727 11.83-40.019 27.112-63.071 44.413-86.987 4.879-6.746 14.302-8.257 21.046-3.378 6.745 4.878 8.257 14.302 3.378 21.046-16.746 23.149-29.412 42.284-39.929 77.864-2.012 6.804-8.401 11.12-15.185 10.786z"
                            fill="#FFF"
                        />
                        <circle cx={137.61} cy={413.989} fill="#FFF" r={18.21} />
                    </g>
                    <g>
                        <path
                            d="M384.136 572.374c-30.373 0-55.082-24.71-55.082-55.082s24.709-55.083 55.082-55.083 55.083 24.711 55.083 55.083-24.71 55.082-55.083 55.082zm0-79.373c-13.395 0-24.291 10.896-24.291 24.291 0 13.394 10.896 24.291 24.291 24.291s24.291-10.897 24.291-24.291c0-13.395-10.897-24.291-24.291-24.291zM268.156 456.395c-30.373 0-55.083-24.711-55.083-55.083s24.71-55.082 55.083-55.082 55.083 24.71 55.083 55.082-24.711 55.083-55.083 55.083zm0-79.374c-13.395 0-24.292 10.897-24.292 24.291 0 13.395 10.897 24.291 24.292 24.291s24.291-10.896 24.291-24.291c0-13.394-10.897-24.291-24.291-24.291zM415.852 344.573l23.635 23.635L237.445 570.25l-23.634-23.635z"
                            fill="#3DB9EA"
                        />
                    </g>
                    <g>
                        <path
                            d="M384.136 549.794c-30.373 0-55.082-24.71-55.082-55.082s24.709-55.083 55.082-55.083 55.083 24.711 55.083 55.083-24.71 55.082-55.083 55.082zm0-79.374c-13.395 0-24.291 10.897-24.291 24.292 0 13.394 10.896 24.291 24.291 24.291s24.291-10.897 24.291-24.291c0-13.395-10.897-24.292-24.291-24.292zM268.156 433.813c-30.373 0-55.083-24.71-55.083-55.082s24.71-55.082 55.083-55.082 55.083 24.71 55.083 55.082-24.711 55.082-55.083 55.082zm0-79.373c-13.395 0-24.292 10.897-24.292 24.291s10.897 24.291 24.292 24.291 24.291-10.897 24.291-24.291-10.897-24.291-24.291-24.291zM415.863 321.995l23.635 23.635-202.041 202.042-23.636-23.636z"
                            fill="#B3E4FF"
                        />
                    </g>
                    <g>
                        <path
                            d="M237.358 238.117c-3.593 0-7.201-1.25-10.12-3.799-6.404-5.593-7.062-15.318-1.47-21.723 40.233-46.075 66.832-111.938 67.095-112.599 3.157-7.895 12.115-11.738 20.012-8.576 7.895 3.157 11.735 12.117 8.577 20.013-1.157 2.893-28.846 71.432-72.492 121.415a15.353 15.353 0 01-11.602 5.269z"
                            fill="#FFF"
                        />
                    </g>
                    <g>
                        <circle cx={318.259} cy={68.217} fill="#FFF" r={15.52} />
                    </g>
                    <g>
                        <path
                            d="M320.771 642c-48.624 0-95.771-14.387-132.756-40.09-40.559-28.187-66.976-68.5-76.395-116.363-20.205-102.697 38.09-182.833 94.466-260.275 21.63-29.713 42.061-57.807 58.237-87.037 18.397-33.242 40.859-77.696 51.335-126.684.691-3.229 3.544-5.551 6.845-5.551h7.958a7 7 0 016.845 5.536c10.478 48.99 32.938 93.429 51.335 126.67 16.189 29.253 36.638 57.324 58.287 87.043 56.349 77.354 114.615 157.341 94.416 260.007-9.414 47.838-35.971 88.054-76.799 116.3-37.185 25.726-84.466 40.124-133.136 40.124l-10.58.32h-.058zm5.711-615.317c-11.697 45.878-32.57 86.97-49.911 118.303-16.597 29.989-38.239 59.719-59.168 88.469-54.607 75.012-111.073 152.577-92.048 249.273 8.704 44.227 33.134 81.384 70.648 107.454 34.654 24.083 78.951 37.349 124.734 37.355l10.613-.089c45.91 0 90.363-13.284 125.229-37.405 37.771-26.131 62.332-63.301 71.028-107.49 19.018-96.663-37.418-174.137-91.996-249.061-20.948-28.757-42.61-58.493-59.22-88.507-17.338-31.331-38.211-72.423-49.909-118.302z"
                            fill="#3F456B"
                        />
                    </g>
                </svg>
            </Tooltip>
        </div>
    );
};

export default humidity;
