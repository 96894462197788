import {Marshaller} from '@aws/dynamodb-auto-marshaller';

const marshaller = new Marshaller({unwrapNumbers: true});

export const unmarshall = (item) => {
    return marshaller.unmarshallItem(item);
};

export const marshall = (item) => {
    return marshaller.marshallItem(item);
};