import React from 'react';

import Tooltip from "./../../Tooltip/Tooltip";

const BatteryIcon = props => {
	let content = null;
	let viewBox = '0 0 512 262';
	switch (props.value) {
		case 6: // charging - only for wicub - same as the lvl 5 = full battery
		case 5:
			content = (
				<g>
					<path d="M416.564 58.209v145c0 4.141-3.359 7.5-7.5 7.5h-353a7.501 7.501 0 01-7.5-7.5v-145c0-4.141 3.36-7.5 7.5-7.5h353c4.141 0 7.5 3.359 7.5 7.5zm96 48.5v48c0 13.25-10.75 24-24 24h-24v32c0 26.516-21.5 48-48 48h-368c-26.5 0-48-21.484-48-48v-160c0-26.516 21.5-48 48-48h368c26.5 0 48 21.484 48 48v32h24c13.25 0 24 10.75 24 24zm-80-56c0-8.828-7.188-16-16-16h-368c-8.813 0-16 7.172-16 16v160c0 8.828 7.188 16 16 16h368c8.813 0 16-7.172 16-16v-160z" />
				</g>
			);
			break;
		case 4:
			content = (
				<g>
					<path d="M288.564 51.273l96 160h-328.5a7.501 7.501 0 01-7.5-7.5v-145c0-4.14 3.36-7.5 7.5-7.5h232.5zm224 56v48c0 13.25-10.75 24-24 24h-24v32c0 26.516-21.5 48-48 48h-368c-26.5 0-48-21.484-48-48v-160c0-26.516 21.5-48 48-48h368c26.5 0 48 21.484 48 48v32h24c13.25 0 24 10.75 24 24zm-80-56c0-8.828-7.188-16-16-16h-368c-8.813 0-16 7.172-16 16v160c0 8.828 7.188 16 16 16h368c8.813 0 16-7.172 16-16v-160z" />
				</g>
			);
			break;
		case 3:
			content = (
				<g>
					<path d="M176.564 51.838l96 160h-216.5a7.501 7.501 0 01-7.5-7.5v-145c0-4.141 3.36-7.5 7.5-7.5h120.5zm336 56v48c0 13.25-10.75 24-24 24h-24v32c0 26.516-21.5 48-48 48h-368c-26.5 0-48-21.484-48-48v-160c0-26.516 21.5-48 48-48h368c26.5 0 48 21.484 48 48v32h24c13.25 0 24 10.75 24 24zm-80-56c0-8.828-7.188-16-16-16h-368c-8.813 0-16 7.172-16 16v160c0 8.828 7.188 16 16 16h368c8.813 0 16-7.172 16-16v-160z" />
				</g>
			);
			break;
		case 2:
			content = (
				<g>
					<path d="M80.564 50.709l96 160h-120.5a7.501 7.501 0 01-7.5-7.5v-145c0-4.141 3.36-7.5 7.5-7.5h24.5zm432 56v48c0 13.25-10.75 24-24 24h-24v32c0 26.516-21.5 48-48 48h-368c-26.5 0-48-21.484-48-48v-160c0-26.516 21.5-48 48-48h368c26.5 0 48 21.484 48 48v32h24c13.25 0 24 10.75 24 24zm-80-56c0-8.828-7.188-16-16-16h-368c-8.813 0-16 7.172-16 16v160c0 8.828 7.188 16 16 16h368c8.813 0 16-7.172 16-16v-160z" />
				</g>
			);
			break;
		default:
			content = (
				<g>
					<path d="M509.28 185.751v48c0 13.25-10.75 24-24 24h-24v32c0 26.516-21.5 48-48 48h-60.392l-20.906-32h81.297c8.813 0 16-7.172 16-16v-160c0-8.828-7.188-16-16-16H206.53l-20.906-32H413.28c26.5 0 48 21.484 48 48v32h24c13.25 0 24 10.75 24 24zm-464 120c-8.814 0-16-7.172-16-16v-160c0-8.828 7.187-16 16-16h65.296l-20.906-32H45.28c-26.5 0-48 21.484-48 48v160c0 26.516 21.5 48 48 48h211.655l-20.905-32H45.28zM110.872 5.345a8.079 8.079 0 00-5.125-3.438 7.902 7.902 0 00-1.594-.156 7.968 7.968 0 00-4.469 1.36L80.873 15.656a8.045 8.045 0 00-2.234 11.188l253.047 387.313a8.08 8.08 0 005.125 3.438c.53.11 1.063.156 1.594.156a7.968 7.968 0 004.47-1.359l18.812-12.547a8.044 8.044 0 002.233-11.188L110.873 5.345z" />
				</g>
			);
			viewBox = '0 0 512 422';
			break;
	}
	return (
		<div {...props}>
			<Tooltip tooltip={props.tooltip}>
				<svg width="100%" viewBox={viewBox}>
					{content}
				</svg>
			</Tooltip>
		</div>
	);
};

export default BatteryIcon;
