import React, { Component } from 'react';
import { connect } from 'react-redux';

import { FormattedMessage } from 'react-intl';
import { Push } from '@progress/kendo-react-animation';
import { fetchUser } from './../../../../store/actions/userActions';

import MyAccount from './MyAccount/MyAccount';
import Subusers from './Subusers/Subusers';
import ChangePassword from './ChangePassword/ChangePassword';
import DeleteAccount from './DeleteAccout/DeleteAccount';

import classes from './Users.module.scss';
import './UsersAnimations.scss';

class Users extends Component {
	constructor(props) {
		super(props);
		this.state = {
			subpage: 1,
			isModalOpen: false
		};
	}

	componentDidMount() {
		this.props.fetchUser('IOT_GSOL');
	}

	subpageSelectedHandler = e => {
		this.setState({
			...this.state,
			subpage: e
		});
	};

	render() {
		const activeClass = classes.active;
		const pointer = <i className={`fas fa-hand-point-right ${classes.activeLink}`} />;

		let myAccount = null;
		let subUsers = null;
		let changePassword = null;
		let deleteAccount = null;

		let classPg1 = "";
		let classPg2 = '';
		let classPg3 = "";
		let classPg4 = "";

		let p1 = null;
		let p2 = null;
		let p3 = null;
		let p4 = null;

		switch (this.state.subpage) {
			case 1:
				myAccount = <MyAccount />;
				classPg1 = activeClass;
				p1 = pointer;
				break;
			case 2:
				subUsers = <Subusers />;
				classPg2 = activeClass;
				p2 = pointer;
				break;
			case 3:
				changePassword = <ChangePassword />;
				classPg3 = activeClass;
				p3 = pointer;
				break;
			case 4:
				deleteAccount = <DeleteAccount />;
				classPg4 = activeClass;
				p4 = pointer;
				break;
			default:
				myAccount = <MyAccount />;
				classPg1 = activeClass;
				p1 = pointer;
				break;
		}

		const menuStyle = this.props.isLoading ? { display: "none" } : null;

		const content = (
			<div className={`centerContainer`}>
				<div className={classes.userLabel}>
					<h2>
						<FormattedMessage id="title.user" defaultMessage="User" />
					</h2>
				</div>
				<div className={classes.userMenu} style={menuStyle}>
					<p className={[classes.menuLink, classPg1].join(" ")} onClick={() => this.subpageSelectedHandler(1)}>
						{p1}
						<FormattedMessage id="user.submenu.info" />
					</p>
					{this.props.user.permissions &&
						this.props.user.permissions.permissions.role !== 'GLOBAL_ADMIN' ? null : (
							<p className={[classes.menuLink, classPg2].join(" ")}  onClick={() => this.subpageSelectedHandler(2)}>
								{p2}
								<FormattedMessage id="user.submenu.subusers" />
							</p>
						)}
					<p className={[classes.menuLink, classPg3].join(" ")}  onClick={() => this.subpageSelectedHandler(3)}>
						{p3}
						<FormattedMessage id="user.submenu.changePassword" />
					</p>
					<p className={[classes.menuLink, classPg4].join(" ")} onClick={() => this.subpageSelectedHandler(4)}>
						{p4}
						<FormattedMessage id="user.submenu.deleteAccount" />
					</p>
				</div>
				<div className={classes.pageContainer}>
					<Push
						className={classes.page}
						transitionName="user-slow-disapear"
						direction="left"
						transitionEnterDuration={500}
						transitionExitDuration={500}
					>
						{myAccount}
					</Push>
					<Push
						className={classes.page}
						transitionName="user-slow-disapear"
						direction="left"
						transitionEnterDuration={500}
						transitionExitDuration={500}
					>
						{subUsers}
					</Push>
					<Push
						className={classes.page}
						transitionName="user-slow-disapear"
						direction="left"
						transitionEnterDuration={500}
						transitionExitDuration={500}
					>
						{changePassword}
					</Push>
					<Push
						className={classes.page}
						transitionName="user-slow-disapear"
						direction="left"
						transitionEnterDuration={500}
						transitionExitDuration={500}
					>
						{deleteAccount}
					</Push>
				</div>
			</div>
		);

		return content;
	}
}

const mapStateToProps = state => {
	return {
		user: state.users.logged_user,
		isLoading: state.users.isLoading
	};
};
const mapDispatchToProps = dispatch => {
	return {
		fetchUser: entity => dispatch(fetchUser(entity))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
