import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";


import * as chartBuilder from "./../../../utils/chartBuilder.js";
import * as chartExports from "./../../../utils/chartExports.js";
import * as chartInteractions from "./../../../utils/chartInteractions.js";

import ChartToolbar from "./../../../components/charts/Toolbar/Toolbar";
import Exporter from "./../../../components/charts/Export/Exporter";
import Spinner from "./../../../components/Spinner/Spinner.jsx";
import Label from "./../../../components/charts/Label/Label";

//import am4themes_animated from '@amcharts/amcharts4/themes/animated';

import classes from "./Gantt.module.scss";

//am4core.useTheme(am4themes_animated);
//am4core.useTheme(am4themes_kelly);

//am4core.options.minPolylineStep = 10;
//am4core.options.queue = true;
//am4core.options.onlyShowOnViewport = true;

class Line extends Component {
    constructor(props) {
        super(props);

        this.chart = null;
        this.borders = { min: null, max: null };
        this.scroll = { top: 0, left: 0, behavior: "smooth" };
        if (!this.exportName && this.props.sensor) this.exportName = `${this.props.sensor.name}_Water_Leak_Diagram`;
        else this.exportName = "Export";

        this.state = {
            zoomed: false,
            leftMargin: 0,
            chartReady: true,
            exporting: false,
            exportFailed: false,
            exportModalOpen: {
                xlsx: false,
                csv: false,
            },
            export: {
                defaultName: this.exportName,
                name: this.exportName,
                delimiter: ";",
                decimalSeparator: ".",
                decimalPlaces: "",
            },
        };
    }

    componentDidUpdate(prevProps) {
        if (this.chart && prevProps.lang !== this.props.lang) {
            this.fillChart();
        }
        if (prevProps.data !== this.props.data) {
            this.fillChart();
        }
    }
    componentDidMount() {
        this.chart = chartBuilder.createXYChart(this.props.id, false, { top: -5, left: 15, right: 15, bottom: 0 }, false);
        this.chart.hiddenState.properties.opacity = 0;
        this.chart.events.on("sizechanged", this.resizeHandler);
        this.chart.events.on("doublehit", this.fullZoomOut);

        chartInteractions.setChartLocale(this.chart, this.props.lang);
        chartBuilder.makePreloaderTransparent(this.chart);

        const category = chartBuilder.addCategoryAxis(this.chart);
        category.events.on("datarangechanged", this.rangeHandler);
        chartBuilder.addDateAxis(this.chart);
        const series = chartBuilder.addColumnSeries(this.chart);
        series.events.on("shown", this.chartReadyHandler);

        chartBuilder.addScrollbarX(this.chart);

        //chartInteractions.turnOnMobileRegime(this.chart);
        this.fillChart();
        this.chart.exporting.filePrefix = `${this.props.sensor.name}_Water_Leak_Diagram`;
    }

    chartReadyHandler = () => {
        let updatedState = {
            ...this.state,
            chartReady: true,
        };

        this.setState(updatedState);
    };

    resizeHandler = () => {
        if (this.chart.contentWidth < 767) {
            chartInteractions.turnOnMobileRegime(this.chart,null, true);
        } else {
            chartInteractions.turnOffMobileRegime(this.chart,null, true);
        }
    };

    fullZoomOut = () => {
        //console.log(new Date(this.mainXAxis.min), new Date(this.mainXAxis.max));
        //if(this.mainXAxis.zoomFactor > 1)
        this.chart.xAxes.values[0].zoomToDates(this.chart.xAxes.values[0].min, this.chart.xAxes.values[0].max, false, false);
    };

    rangeHandler = () => {
        const max = this.chart.yAxes.values[0].max;
        const min = this.chart.yAxes.values[0].min;
        if (this.borders.min !== min || this.borders.max !== max) {
            this.borders.min = min;
            this.borders.max = max;
        }

        const leftMargin = chartInteractions.getXAxesLabelWidth(this.chart);

        if (leftMargin !== this.state.leftMargin) {
            this.setState({ ...this.state, leftMargin: leftMargin });
        }
    };

    componentWillUnmount() {
        if (this.chart) {
            this.chart.data = null;
            this.chart.dispose();
        }
    }

    fillChart = () => {
        if (this.chart) {
            const categoryNames = Object.keys(this.props.mapping);
            const data = [];

            let index = -1;

            const sortedData = {};
            categoryNames.forEach((categoryName) => {
                sortedData[categoryName] = this.props.data.filter((item) => item.hasOwnProperty(this.props.mapping[categoryName].event));
                data.push({ category: this.props.mapping[categoryName].legend });
                index++;
            });

            for (const category in sortedData) {
                let flip = false;
                let value = null;
                let lastValue = null;
                let checkItem = false;
                const map = this.props.mapping[category];

                const record = {
                    category: map.legend, //categoryName,
                    start: this.props.startInterval,
                    end: this.props.endInterval,
                    color: map.color,
                    //task: map.label
                };

                const categoryData = sortedData[category];

                for (let i = 0; i < categoryData.length; i++) {
                    const item = categoryData[i];
                    value = item[map.event];

                    if (!flip && value !== lastValue) {
                        data.push({ ...record });
                        index++;
                    }

                    if (i === 0 || i === categoryData.length - 1) {
                        flip = value === map.onValue ? false : true;
                    }

                    if (!flip && value !== lastValue) {
                        data[index].start = new Date(item.timestamp * 1000);
                        flip = true;
                    } else if (flip && value !== lastValue) {
                        data[index].end = new Date(item.timestamp * 1000);
                        flip = false;
                        checkItem = true;
                    }
                    if (checkItem || i === categoryData.length - 1) {
                        if (
                            (data[index].start < this.props.startInterval && data[index].end < this.props.startInterval) ||
                            (data[index].start > this.props.endInterval && data[index].end > this.props.endInterval)
                        ) {
                            data.pop();
                            index--;
                        } else {
                            if (data[index].start < this.props.startInterval) data[index].start = this.props.startInterval;
                            if (data[index].end > this.props.endInterval) data[index].end = this.props.endInterval;
                        }

                        checkItem = false;
                    }

                    lastValue = value;
                }
            }

            /*
			let k = 1;
			if (data.length > 0) {
				if (this.props.startInterval < data[0].start) {
					const startObj = { ...record };
					startObj.end = data[0].start;
					startObj.color = 'white';
					data.push(startObj);
					k++;
					index++;
				}
	
				if (this.props.endInterval > data[data.length - k].end) {
					const endObj = { ...record };
					endObj.start = data[data.length - k].end;
					endObj.color = 'white';
					data.push(endObj);
					index++;
				}
			}
			*/
            this.chart.data = data;
        }
    };

    onZoom = () => {
        this.ranges = chartInteractions.getYAxesRanges(this.chart);
        const value = !this.state.zoomed;
        this.setState({ zoomed: value });
        if (value) {
            const doc = document.documentElement;
            this.scroll.left = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0);
            this.scroll.top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

            window.scrollTo(0, 0);
            document.body.style.overflow = "hidden";
            document.documentElement.style.overflow = "hidden";
            this.chart.paddingBottom = 50;
        } else {
            document.body.style.overflow = "auto";
            document.documentElement.style.overflow = "auto";
            this.chart.paddingBottom = 0;
            setTimeout(() => {
                window.scrollTo(this.scroll);
            }, 200);
        }
    };

    savePDF = () => {
        this.setState({ ...this.state, exporting: true, exportFailed: false, exportModalOpen: this.closeAllExportModals() });
        chartExports
            .savePDF(this.chart, this.exportName, this.state.zoomed)
            .then(() => this.setState({ ...this.state, exporting: false }))
            .catch((err) => {
                this.setState({
                    ...this.state,
                    exportFailed: true,
                    exporting: false,
                });
            });
    };
    saveCSV = () => {
        this.setState({ ...this.state, exporting: true, exportFailed: false, exportModalOpen: this.closeAllExportModals() });

        const config = {
            ...this.state.export,
            mergeData: false,
        };

        if (!config.name) config.name = config.defaultName;
        if (!config.delimiter) config.delimiter = ";";
        if (!config.decimalSeparator) config.decimalSeparator = ".";
        if (config.decimalPlaces && isNaN(+config.decimalPlaces)) config.decimalPlaces = undefined;

        const exportData = this.chart.data.slice(3, this.chart.data.length).map(rec =>{
            return{
                category: rec.category,
                start: rec.start,
                end: rec.end
            };
        }); // first 3 records are artifical - invalid (empty)
        setTimeout(() => {
            try {
                chartExports.saveCsv({data: exportData}, config);
                this.setState({ ...this.state, exporting: false });
            } catch (err) {
                this.setState({
                    ...this.state,
                    exportFailed: true,
                    exporting: false,
                });
            }
        }, 100);
    };
    saveXLSX = () => {
        this.setState({ ...this.state, exporting: true, exportFailed: false, exportModalOpen: this.closeAllExportModals() });

        const config = {
            name: this.state.export.name,
            decimalPlaces: this.state.export.decimalPlaces,
            mergeData: false,
        };

        if (!config.name) config.name = config.defaultName;
        if (config.decimalPlaces && isNaN(+config.decimalPlaces)) config.decimalPlaces = undefined;

        const exportData = this.chart.data.slice(3, this.chart.data.length).map(rec =>{
            return{
                category: rec.category,
                start: rec.start,
                end: rec.end
            };
        }); // first 3 records are artifical - invalid (empty)
        setTimeout(() => {
            try {
                chartExports.saveXlsx({data: exportData}, config);
                this.setState({ ...this.state, exporting: false });
            } catch (err) {
                this.setState({
                    ...this.state,
                    exportFailed: true,
                    exporting: false,
                });
            }
        }, 100);
    };
    exportFailedHandler = () => {
        this.setState({
            ...this.state,
            exportFailed: false,
        });
    };

    onExportModalCloseHandler = () => {
        this.setState({
            ...this.state,
            exportModalOpen: this.closeAllExportModals(),
        });
    };

    closeAllExportModals = () => {
        const exportModals = { ...this.state.exportModalOpen };
        for (let modalName in exportModals) exportModals[modalName] = false;

        return exportModals;
    };

    onExportSettingHandler = (e) => {
        const name = e.target.name;
        let value = e.target.value;

        this.setState({
            ...this.state,
            export: {
                ...this.state.export,
                [name]: value,
            },
        });
    };

    openExportModal = (type) => {
        this.setState({
            ...this.state,
            exportModalOpen: {
                ...this.closeAllExportModals(),
                [type]: true,
            },
            export: {
                ...this.state.export,
                name: this.exportName,
                delimiter: ";",
                decimalSeparator: ".",
                decimalPlaces: "",
            },
        });
    };

    onExportClikHandler = () => {
        let type = null;
        for (const modalType in this.state.exportModalOpen) {
            if (this.state.exportModalOpen[modalType]) {
                type = modalType;
                break;
            }
        }
        switch (type) {
            case "xlsx":
                this.saveXLSX();
                break;
            case "csv":
                this.saveCSV();
                break;
            default:
                break;
        }
    };

    render() {
        let alarmRaised = false;
        let h1Style = null;

        const resizeIcon = this.state.zoomed ? "minimize.svg" : "maximize.svg";
        const resizeTextId = this.state.zoomed ? "chart.menu.zoomout" : "chart.menu.zoomin";

        let exportModal = null;
        let visibility = "visible";
        let spinner = null;
        let chartC = [classes.chart, classes.loaded].join(" ");

        const chartClass = this.state.zoomed ? classes.zoomedChart : classes.chartsContainer;

        if (!this.state.chartReady || this.props.loading /*||this.state.leftMargin < 1*/) {
            visibility = "hidden";
            chartC = classes.chart;
            spinner = (
                <div className={classes.loaderContainer}>
                    <p>
                        <FormattedMessage id="chart.loading" defaultMessage="Loading..." />
                    </p>
                    <Spinner type="circle" />
                </div>
            );
        } else if (this.state.exporting) {
            chartC = classes.chart;
            spinner = (
                <div className={classes.loaderContainer}>
                    <p>
                        <FormattedMessage id="chart.exporting" defaultMessage="Exporting..." />
                    </p>
                    <Spinner type="circle" />
                </div>
            );
        } else if (this.state.exportFailed) {
            chartC = classes.chart;
            spinner = (
                <div className={classes.exportFailed}>
                    <div style={{ color: "red" }}>
                        <FormattedMessage id="chart.export.failed" defaultMessage="Export failed" />
                        {/*<p>{this.state.exportFailedMsg}</p>*/}
                        <div className={classes.exportFailedButton}>
                            <button className="btn btn-danger" onClick={this.exportFailedHandler}>
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            );
        }

        if (this.state.exportModalOpen.xlsx) {
            chartC = classes.chart;
            exportModal = (
                <Exporter
                    type="xlsx"
                    containerClassName={classes.exportContainer}
                    nameValue={this.state.export.name}
                    decimalPlacesValue={this.state.export.decimalPlaces}
                    onChange={this.onExportSettingHandler}
                    onClose={this.onExportModalCloseHandler}
                    onExportClick={this.onExportClikHandler}
                />
            );
        } else if (this.state.exportModalOpen.csv) {
            chartC = classes.chart;
            exportModal = (
                <Exporter
                    type="csv"
                    containerClassName={classes.exportContainer}
                    nameValue={this.state.export.name}
                    delimiterValue={this.state.export.delimiter}
                    decimalSeparatorValue={this.state.export.decimalSeparator}
                    decimalPlacesValue={this.state.export.decimalPlaces}
                    onChange={this.onExportSettingHandler}
                    onClose={this.onExportModalCloseHandler}
                    onExportClick={this.onExportClikHandler}
                />
            );
        }

        const toolbarConfig = [
            {
                id: this.props.id,
                type: "exportPdf",
                tip: this.props.intl.formatMessage({ id: "chart.menu.pdf" }),
                toggable: false,
                icon: "pdf",
                handler: this.savePDF,
                disabled: false,
                hidden: false,
            },
            {
                id: this.props.id,
                type: "exportCsv",
                tip: this.props.intl.formatMessage({ id: "chart.menu.csv" }),
                toggable: false,
                icon: "csv",
                handler: () => this.openExportModal("csv"),
                disabled: false,
                hidden: false,
            },
            {
                id: this.props.id,
                type: "exportXlsx",
                tip: this.props.intl.formatMessage({ id: "chart.menu.xlsx" }),
                toggable: false,
                icon: "xls",
                handler: () => this.openExportModal("xlsx"),
                disabled: false,
                hidden: false,
            },
        ];

        return (
            <div className={chartClass}>
                {this.props.labelDisabled || this.state.zoomed ? null : (
                    <Label
                        showAlarmIcon
                        alarmRaised={alarmRaised}
                        style={h1Style}
                        text={this.props.label}
                        //alarmClick={this.zoomToAlarmHandler}
                    />
                )}
                <div className={classes.panelContainer} style={{ visibility: visibility }}>
                    <div className={classes.panelMenu} style={{ marginLeft: `${this.state.leftMargin}px` }}>
                        <ChartToolbar buttons={toolbarConfig} />
                    </div>
                    <div className={classes.panelZoom}>
                        <ChartToolbar
                            buttons={[
                                {
                                    id: this.props.id,
                                    type: "resize",
                                    tip: this.props.intl.formatMessage({ id: resizeTextId }),
                                    toggable: false,
                                    imageUrl: process.env.PUBLIC_URL + `/assets/img/${resizeIcon}`,
                                    handler: this.onZoom,
                                    disabled: false,
                                    hidden: false,
                                },
                            ]}
                        />
                    </div>
                </div>
                <div className={chartC} id={this.props.id} />
                {spinner}
                {exportModal}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    lang: state.locale.lang,
});

export default connect(mapStateToProps)(injectIntl(Line));
