// @flow
import React, { Component } from "react";
import Sensor from "./../../../../components/sensorsList/sensor/Sensor.jsx";

import Text from "./../../../../components/sensorsList/sensor/sensorDescriptors/text/Text.jsx";
import ActionButton from "./../../../../components/sensorsList/sensor/sensorActionButton/ActionButton.jsx";
import InputState from "./../../../../components/charts/QuickStatesView/State/State";

import { connect } from "react-redux";
import { fetchSensorMeasurements } from "../../../../store/actions/sensorActions";
import { FormattedMessage, injectIntl } from "react-intl";
import { isEnabled } from "./../../../../utils/inputsValidator";

import LineChart from "./../../../charts/Line/Line.jsx";
import DatePanel from "./../../../../components/charts/ChartsPanel/ChartsPanel.jsx";
import { formateDate, formateDatetime } from "./../../../../utils/dateFunction.js";

import { allTypes } from "./../../../../utils/binaryInputs";

import sensors from "./../../../../utils/sensorTypes";
import classes from "./../SensorDetail.module.scss";
import steClasses from "./STE2.module.scss";

const oneDayTimestamp = 24 * 60 * 60;
const oneWeekTimestamp = oneDayTimestamp * 7;
const oneMonthTimestamp = oneDayTimestamp * 30;

const labels = {
    name: <FormattedMessage id="sensor.name" defaultMessage="Name" />,
    serNumber: <FormattedMessage id="sensor.serialNumber" defaultMessage="Serial Number" />,
    mac: <FormattedMessage id="sensor.mac" defaultMessage="MAC" />,
    numOfSensors: <FormattedMessage id="sensor.connectedSensors" defaultMessage="Connected Sensors" />,
    lastMsg: <FormattedMessage id="sensor.lastMessage" defaultMessage="Last Message" />,
    state: <FormattedMessage id="sensor.state" defaultMessage="State" />,
    stateExpired: <FormattedMessage id="tooltip.state.expired" defaultMessage="Expired" />,
    stateActive: <FormattedMessage id="tooltip.state.active" defaultMessage="Active" />,
    exp: <FormattedMessage id="sensor.expirationDate" defaultMessage="Expiration Date" />,
    type: <FormattedMessage id="sensor.type" defaultMessage="Type" />,
};

class Ste2 extends Component {
    constructor(props) {
        super(props);
        const dateFromX = new Date();
        const dateToX = new Date();
        let startPeriod = oneWeekTimestamp;
        let multiplier = 1;

        this.lockAxis = false;

        if (props.startPeriod) {
            switch (props.startPeriod) {
                case "day":
                    startPeriod = oneDayTimestamp;
                    break;
                case "month":
                    startPeriod = oneMonthTimestamp;
                    break;
                default:
                    startPeriod = oneWeekTimestamp;
                    break;
            }
        }

        if (props.multiplier && typeof props.multiplier === "number") {
            multiplier = props.multiplier;
        }
        this.state = {
            dateFrom: new Date(dateFromX.getTime() - startPeriod * multiplier * 1000),
            dateTo: dateToX,
            selectedPeriod: "",
            isFilteringEnabled: true,
        };

        if (this.props.externalLoading) {
            this.state.dateFrom = new Date(this.props.from * 1000);
            this.state.dateTo = new Date(this.props.to * 1000);
            this.lockAxis = this.props.lockXAxis;
        }
    }

    componentDidMount() {
        const id = this.props.id;
        const type = this.props.type;
        const timestampFrom = this.state.dateFrom.getTime() / 1000;
        const timestampTo = this.state.dateTo.getTime() / 1000;

        if (!this.props.externalLoading) this.props.fetchSensorMeasurements(id, type, timestampFrom, timestampTo);
    }

    getCurrentTimestamp = () => {
        const currentTimestamp = Math.round(new Date().getTime() / 1000);
        return currentTimestamp;
    };

    handlePeriodSelect = (e) => {
        switch (e.value) {
            case "last24hours":
                this.setState({
                    ...this.state,
                    dateFrom: new Date((this.getCurrentTimestamp() - oneDayTimestamp) * 1000),
                    dateTo: new Date(),
                    selectedPeriod: "last24hours",
                    isFilteringEnabled: true,
                });
                break;
            case "last7days":
                this.setState({
                    ...this.state,
                    dateFrom: new Date((this.getCurrentTimestamp() - oneWeekTimestamp) * 1000),
                    dateTo: new Date(),
                    selectedPeriod: "last7days",
                    isFilteringEnabled: true,
                });
                break;
            case "last30days":
                this.setState({
                    ...this.state,
                    dateFrom: new Date((this.getCurrentTimestamp() - oneMonthTimestamp) * 1000),
                    dateTo: new Date(),
                    selectedPeriod: "last30days",
                    isFilteringEnabled: true,
                });
                break;

            default:
                this.setState({ ...this.state, selectedPeriod: "", isFilteringEnabled: false });
                break;
        }
    };

    handleDateFromChange = (date) => {
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);

        let enableFiltering = true;
        if (!date) enableFiltering = false;
        this.setState({ ...this.state, dateFrom: date, selectedPeriod: "", isFilteringEnabled: enableFiltering });
    };

    handleDateToChange = (date) => {
        date.setHours(23);
        date.setMinutes(59);
        date.setSeconds(59);

        if (date < this.state.dateFrom) {
            date = this.state.dateFrom;
        }
        let enableFiltering = true;
        if (!date) enableFiltering = false;
        this.setState({ ...this.state, dateTo: date, selectedPeriod: "", isFilteringEnabled: enableFiltering });
    };

    handleMeasurementsFilter = () => {
        this.lockAxis = true;

        if (this.state.dateFrom && this.state.dateTo) {
            const { id, type } = this.props;
            const dateFrom = Math.round(this.state.dateFrom.getTime() / 1000);
            const dateTo = Math.ceil(this.state.dateTo.getTime() / (1000 * 60 * 60 * 24)) * 60 * 60 * 24;

            if (!this.props.externalLoading) {
                this.props.fetchSensorMeasurements(id, type, dateFrom, dateTo);
            }
        }
    };

    onEditHandler = () => {
        this.props.history.push(`/edit/${this.props.sensor.id}?type=${this.props.sensor.sensor_type}`);
    };

    renderDocument() { }
    render() {
        const { dateFrom, dateTo, selectedPeriod } = this.state;
        let { lang, sensor, sensorMeasurements } = this.props;

        sensorMeasurements = sensorMeasurements[sensor.id] ? sensorMeasurements[sensor.id] : [];

        let graphLoading = this.props.isGraphLoading;

        if (this.props.externalLoading) {
            sensorMeasurements = this.props.externalData;
            graphLoading = this.props.isExternalDataLoading;
        }

        const editButton = <ActionButton type="Edit" click={this.onEditHandler} tooltip={this.props.intl.formatMessage({ id: "tooltip.edit" })} />;

        const sensorOverView = (
            <div id="sensorOverview" className={classes.sensorOV}>
                <Sensor className={classes.sensor} type="overview">
                    <Text className={classes.descriptor} label={labels.name} value={sensor.name} />
                    <Text className={classes.descriptor} label={labels.serNumber} value={sensor.id} />
                    <Text className={classes.descriptor} label={labels.mac} value={sensor.mac} />
                    <Text
                        className={classes.descriptor}
                        label={labels.numOfSensors}
                        value={sensor.registered_sensors.filter((rs) => rs.type !== "binary_switch").length}
                    />
                    <Text className={classes.descriptor} label={labels.type} value={sensors.ste2.pretty_label} />
                    <Text className={classes.descriptor} label={labels.state} value={sensor.state === "ACTIVE" ? labels.stateActive : labels.stateExpired} />
                    <Text className={classes.descriptor} label={labels.exp} value={formateDate(sensor.expiration, lang)} />
                    <Text className={classes.descriptor} label={labels.lastMsg} value={formateDatetime(sensor.last_msg_time, lang)} />
                </Sensor>
            </div>
        );
        const inputStateView =
            sensor.registered_sensors.filter((rg) => rg.type === "binary_switch" && isEnabled(this.props.config, `custom:[bin]:${rg.id}`)).length > 0 ? (
                <div className={steClasses.stateView}>
                    {sensor.registered_sensors
                        .filter((rg) => rg.type === "binary_switch" && isEnabled(this.props.config, `custom:[bin]:${rg.id}`))
                        .map((bsw) => {
                            const isOn =
                                sensorMeasurements.length > 1 ? Boolean(+sensorMeasurements[sensorMeasurements.length - 1][`custom:[bin]:${bsw.id}`]) : false;
                            let inputClass = null;
                            let subTypeInfo = "";
                            const isOnStr = isOn ? "1" : "0";
                            if (sensor.alarms && sensor.alarms.length > 0) {
                                const alrm = sensor.alarms.find((a) => a.dataset === `custom:[bin]:${bsw.id}`);
                                if (alrm && alrm.alarm_raised) {
                                    inputClass = steClasses.alarm;
                                }
                            }
                            if (allTypes.includes(bsw.subType)) {
                                subTypeInfo = "(" + this.props.intl.formatMessage({ id: `tooltip.binInput.${bsw.subType}.${isOnStr}` }) + ")";
                            }
                            return (
                                <InputState
                                    key={bsw.id}
                                    className={inputClass}
                                    stateName={
                                        <span>
                                            <FormattedMessage id="sensor.binaryInput" />
                                            {" " + bsw.id}
                                        </span>
                                    }
                                    stateOkVal={this.props.intl.formatMessage({ id: "tooltip.binInput.binary.0" })}
                                    stateAlarmVal={this.props.intl.formatMessage({ id: "tooltip.binInput.binary.1" })}
                                    secondaryInfo={subTypeInfo}
                                    alarmRaised={isOn}
                                    iconClass={isOn ? "fas fa-toggle-on" : "fas fa-toggle-off"}
                                    onIconClass={steClasses.offDevice}
                                    offIconClass={steClasses.onDevice}
                                    loading={graphLoading}
                                />
                            );
                        })}
                </div>
            ) : null;

        const datePanel = (
            <DatePanel
                lang={lang}
                selectedPeriod={selectedPeriod}
                dateFrom={dateFrom}
                dateTo={dateTo}
                onSelect={this.handlePeriodSelect}
                onDateFromChange={this.handleDateFromChange}
                onDateToChange={this.handleDateToChange}
                onFilterButtonClick={this.handleMeasurementsFilter}
                filterButtonDisabled={!this.state.isFilteringEnabled}
            />
        );

        const charts = sensor.registered_sensors
            .filter((rs) => {
                let dataset = `custom:[bin]:${rs.id}`;
                if (rs.type !== "binary_switch") dataset = `custom:[${rs.units}]:${rs.id}`;
                return isEnabled(this.props.config, dataset);
            })
            .map((s) => {
                let dataset = `custom:[bin]:${s.id}`;
                let customName = null;
                let paddingTop = "0";
                if (s.subType) {
                    paddingTop = "15px";
                    customName = <i style={{ fontSize: "15px" }}>{"\n(" + this.props.intl.formatMessage({ id: `tooltip.binInput.${s.subType}` }) + ")"}</i>;
                }
                let label = (
                    <div style={{ whiteSpace: "pre-wrap", lineHeight: "12px", paddingTop: paddingTop }}>
                        {this.props.intl.formatMessage({ id: "sensor.binaryInput" }) + ":" + s.id}
                        {customName}
                    </div>
                );
                let actMapping = {
                    id: s.id,
                    pretty_name: (`${sensor.id}_` + this.props.intl.formatMessage({ id: `sensor.binaryInput`, defaultMessage: "Sensor" }) + `_${s.id}`).replace(
                        " ",
                        "_"
                    ),
                    mode: "step",
                    data: dataset,
                    time: "timestamp",
                    units: "",
                    legend: this.props.intl.formatMessage({ id: "sensor.binaryInput" }) + ":" + s.id,
                    color: "rgb(163, 45, 45)",
                    customTooltipEvaluator: (value) => {
                        const strValue = value ? "1" : "0";
                        let subType = "binary";
                        if (allTypes.includes(s.subType)) subType = s.subType;

                        let newText = this.props.intl.formatMessage({ id: `tooltip.binInput.${subType}.${strValue}` });

                        return newText;
                    },
                };
                if (s.type !== "binary_switch") {
                    label = this.props.intl.formatMessage({ id: "sensor.sensor" }) + ":" + s.id;
                    if (s.name) label = s.name;
                    actMapping.pretty_name = `${s.id}_` + this.props.intl.formatMessage({ id: `chart.${s.type}`, defaultMessage: "Sensor" });
                    actMapping.data = `custom:[${s.units}]:${s.id}`;
                    actMapping.mode = "default";
                    actMapping.units = s.units;
                    actMapping.legend = label;
                    actMapping.color = s.type === "temperature" ? "rgb(255, 115, 0)" : "rgb(0, 162, 255)";
                    actMapping.customTooltipEvaluator = null;
                }

                //for Ste2 has to be filtered only data which contains column named as "dataset" variable;
                const sensorData = sensorMeasurements.filter((sm) => sm.hasOwnProperty(actMapping.data));

                return (
                    <LineChart
                        key={s.id}
                        id={s.id}
                        label={label}
                        data={sensorData}
                        sensor={sensor}
                        from={dateFrom}
                        to={dateTo}
                        activeOnTap
                        commonTimeAxis="timestamp"
                        loading={graphLoading}
                        lockXAxis={false}
                        mapping={[actMapping]}
                    />
                );
            });
        if (!sensor) return null;
        else
            return (
                <div>
                    <span className={classes.title}>
                        <h2>{sensor.name}</h2>
                        {isEnabled(this.props.config, "editButton") ? editButton : null}
                    </span>
                    {isEnabled(this.props.config, "sensorOverview") ? sensorOverView : null}
                    {inputStateView}
                    {isEnabled(this.props.config, "datePanel") ? datePanel : null}
                    {charts}
                </div>
            );
    }
}

const mapStateToProps = (state, props) => ({
    sensorMeasurements: state.sensors.sensorMeasurements,
    isGraphLoading: state.sensors.isGraphLoading,
    lang: state.locale.lang,
});

export default connect(mapStateToProps, {
    fetchSensorMeasurements,
})(injectIntl(Ste2));
