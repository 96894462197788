import React from 'react';

import { FormattedMessage } from 'react-intl';
import Spinner from './../../Spinner/Spinner.jsx';
import GeneralModal from './../General/General.jsx';
import Checkbox from './../../UI/checkBoxes/Type2/Type2';

import classes from './ModalDelete.module.scss';

const modalDelete = props => {
	let cancelBtn = null;
	let deleteBtn = null;
	let content = (
		<div>
			<FormattedMessage id="sensor.deleting" defaultMessage="Deleting" />
			<Spinner type="dotCircle" size="10px" style={{ margin: '50px auto' }} />
		</div>
	);

	if (!props.loading) {
		content = (
			<div>
				{/*<h6 className="modalMessage">
					<FormattedMessage
						id="modal.delete1"
						defaultMessage="If you delete the sensor, the related activation token will be permanently lost."
					/>
				</h6>*/}
				<h6 className="modalMessage">
					<FormattedMessage id="modal.delete2" defaultMessage="Are you sure you want to delete the sensor?" />
				</h6>
				<div className={`modalMessage ${classes.deleteDataCheckbox}`}>
					<Checkbox
						text={<FormattedMessage id="modal.deleteData" />}
						inputChange={props.onDeletedataChange}
					/>
				</div>
				<div className="modalSensorInfoDiv">
					<p className="modalSensorInfo">
						<FormattedMessage id="sensor.name" defaultMessage="Name" />: {props.sensorName}
					</p>
					<p className="modalSensorInfo">
						<FormattedMessage id="sensor.id" defaultMessage="ID" />: {props.sensorID}
					</p>
				</div>
			</div>
		);

		deleteBtn = {
			className: 'btn btn-md btn-danger mr-3',
			action: props.continue,
			isDisabled: false,
			text: <FormattedMessage id="button.delete" defaultMessage="Delete" />
		};

		cancelBtn = {
			className: 'btn btn-md btn-secondary',
			action: props.close,
			isDisabled: false,
			text: <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
		};
	}

	return (
		<GeneralModal actionBtn={deleteBtn} cancelBtn={cancelBtn} isOpen={props.isOpen} close={props.close}>
			{content}
		</GeneralModal>
	);
};

export default modalDelete;
