import React from 'react';

import Tooltip from "./../../Tooltip/Tooltip";

const ToTopIcon = props => {
    return (
        <div {...props}>
            <Tooltip tooltip={props.tooltip}>
                <svg width="100%" viewBox="0 0 512 512">
                    <path d="M361.089 76.741a6.25 6.25 0 11-10.78-6.322l13.295-22.674a6.248 6.248 0 018.551-2.229 6.246 6.246 0 012.229 8.551l-13.295 22.674zM133.868 464.247a6.25 6.25 0 11-10.781-6.323l13.295-22.674a6.248 6.248 0 018.551-2.229 6.248 6.248 0 012.23 8.551l-13.295 22.675zM436.198 157.949a6.249 6.249 0 11-6.115-10.898l22.92-12.865a6.247 6.247 0 018.507 2.39 6.246 6.246 0 01-2.391 8.507l-22.921 12.866zM44.468 377.807a6.247 6.247 0 11-6.115-10.898l22.92-12.866a6.248 6.248 0 018.506 2.393 6.247 6.247 0 01-2.391 8.507l-22.92 12.864zM260.777 266.347a6.248 6.248 0 11-6.304-10.79l133.591-80.702a6.248 6.248 0 018.547 2.242 6.25 6.25 0 01-2.242 8.549l-133.592 80.701zM136.381 76.741a6.249 6.249 0 1010.78-6.322l-13.295-22.674a6.247 6.247 0 00-8.55-2.229 6.246 6.246 0 00-2.229 8.551l13.294 22.674zM363.603 464.247a6.25 6.25 0 0010.781-6.323l-13.295-22.674a6.249 6.249 0 00-8.552-2.229 6.246 6.246 0 00-2.229 8.551l13.295 22.675zM61.271 157.949a6.248 6.248 0 106.116-10.898l-22.92-12.865a6.246 6.246 0 00-8.506 2.39 6.245 6.245 0 002.391 8.507l22.919 12.866zM453.002 377.807a6.249 6.249 0 006.116-10.898l-22.922-12.866a6.248 6.248 0 00-8.506 2.393 6.248 6.248 0 002.391 8.507l22.921 12.864z" />
                    <g>
                        <path d="M261.265 12h-12.529a6.25 6.25 0 00-6.249 6.248V44.54a6.248 6.248 0 006.248 6.249h12.529a6.25 6.25 0 006.25-6.249V18.249A6.25 6.25 0 00261.265 12zM261.265 461.21h-12.529a6.25 6.25 0 00-6.249 6.249v26.293a6.248 6.248 0 006.248 6.248h12.529a6.25 6.25 0 006.25-6.248v-26.293a6.25 6.25 0 00-6.249-6.249z" />
                    </g>
                    <path d="M188.066 209.462l-7.592 9.965a6.247 6.247 0 001.184 8.757l49.765 37.917.996.758 10.954 8.348a6.246 6.246 0 008.756-1.184l7.594-9.965a6.25 6.25 0 00-1.183-8.759l-10.956-8.347-.995-.757-49.766-37.916a6.248 6.248 0 00-8.757 1.183z" />
                    <g>
                        <path d="M499 262.265v-12.528a6.248 6.248 0 00-6.248-6.249h-26.293a6.25 6.25 0 00-6.249 6.248v12.528a6.25 6.25 0 006.249 6.25h26.293a6.248 6.248 0 006.248-6.249zM49.79 262.265v-12.529a6.25 6.25 0 00-6.25-6.249H17.248A6.248 6.248 0 0011 249.735v12.529a6.25 6.25 0 006.248 6.25h26.293a6.25 6.25 0 006.249-6.249z" />
                    </g>
                    <circle cx={250.123} cy={263.568} r={18.77} />
                </svg>
            </Tooltip>
        </div>
    );
};

export default ToTopIcon;
