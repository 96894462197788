import React from 'react';

import { injectIntl } from 'react-intl';


import Signal from "./../../signal/Signal";
import Id from "./../../../../../icons/Id/Id";
import Time from "./../../Time/Time";
import NotAvailable from "./../../NotAvailable/NotAvailable";

import TextIcon from "./../../TextIcon/TextIcon";

import * as infos from "./../infos";

const simpleLeak = (props) => {

    let icon = null;
    let iconClass = null;
    let tooltip = null;
    let value = props.value;
    let style = null;

    let chState = null;
    switch (props.type) {
        case infos.signal_lvl:
            return (
                <Signal
                    value={props.value}
                    tooltip={props.intl.formatMessage({ id: 'tooltip.signal' }) + ` [1-5]  :  ${props.value}`}
                />
            );
        case infos.id:
            icon = <Id tooltip={props.intl.formatMessage({ id: 'tooltip.info.id' })}/>;
            break;
        case infos.last_msg_time:
            return (
                <Time
                    lang={props.lang}
                    time={props.value}
                    tooltip={props.intl.formatMessage({ id: 'tooltip.info.lastMsgTime' })}
                />
            );
        case infos.device_power:
            iconClass = "fas fa-power-off";
            value = props.value === 0 ? false : true;
            chState = value ? "tooltip.info.devicePowerON" : "tooltip.info.devicePowerOFF";
            style = value ? { color: "limegreen" } : { color: "red" };
            tooltip = props.intl.formatMessage({ id: chState });
            break;
        case infos.flood:
            iconClass = "fas fa-water";
            value = props.value === 0 ? false : true;
            chState = value ? "tooltip.info.leakageON" : "tooltip.info.leakageOFF";
            style = value ? { color: "rgb(0, 162, 255)" } : null;
            tooltip = props.intl.formatMessage({ id: chState });
            break;
        case infos.position:
            iconClass = "fas fa-eraser";
            value = props.value === 0 ? false : true;
            chState = value ? "tooltip.info.positionKO" : "tooltip.info.positionOK";
            style = value ? { color: "rgb(221, 168, 33)" } : null;
            tooltip = props.intl.formatMessage({ id: chState });
            break;
        default:
            return <NotAvailable />;
    }

    return (
        <TextIcon
            iconComponent={icon}
            iconClass={iconClass}
            text={value}
            tooltip={tooltip}
            style={style}
        />);
};


export default injectIntl(simpleLeak);