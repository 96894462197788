import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";

import classes from "./ConfimationModal.module.scss";
import Coin from "./../../../common/CoinPanel/Coin/Coin";

const confimrationModal = props => {
  const content = (
    <div>
      <h6 className="modalMessage">
        <FormattedMessage id="modal.activation.summary" defaultMessage="Summary" />
      </h6>
      <div className={classes.summary}>
        <span className={classes.summaryRow}>
          <Coin
            label={props.intl.formatMessage({ id: "modal.activation.summary.price" }) + ": "}
            value={`${props.selection.price}`}
            coinClassName={classes.coin}
            coinTextClassName={classes.coinText}
          />
        </span>
        <span className={classes.summaryRow}>
          <h5 style={{color: "black"}}>
          <FormattedMessage id="modal.activation.summary.period" defaultMessage="Summary" />
          {": "}
          <FormattedMessage id={props.selection.period} defaultMessage="Summary" />
          </h5>
        </span>
        <p className={classes.summaryRow}>

        </p>
      </div>
      <h6 className="modalMessage">
        <FormattedMessage id="modal.activation.continue" defaultMessage="Summary" />
      </h6>
    </div>
  );



  return content;
};

export default injectIntl(confimrationModal);
