import React from "react";
import { injectIntl } from "react-intl";

import LabeledInput from "./../LabeledInput/LabeledInput.jsx";

import classes from "./LimitsInput.module.scss";

const limitsInput = (props) => {
    let errMessage = null;
    let errContainerStyle = null;

    let limits = null;
    let numOfInputs = props.inputsCount > 0 && props.inputsCount <= 2 ? props.inputsCount : 2;

    const transformReturnValue = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        if (value === "" && !props.required) value = "N/A";

        let newValue = { ...props.value };

        if (name.endsWith("_low")) {
            newValue.low = value;
        } else {
            newValue.high = value;
        }
        return {
            name: props.name,
            value: newValue,
        };
    };

    const value_low = props.value.low !== "N/A" ? props.value.low : "";
    const value_high = props.value.high !== "N/A" ? props.value.high : "";

    if (props.touched && (value_low !== "" || value_high !== "" || props.required)) {
        if (props.valid.every((x) => x)) {
        } else {
            errMessage = (
                <div className={`formError ${classes.errText}`}>
                    <p className="formErrorMessage">{props.errorMessage}</p>
                </div>
            );
            errContainerStyle = { paddingBottom: "20px" };
        }
    }

    if (props.limits) {
        limits = (
            <p className={classes.periodDescription}>
                <small className={classes.periodDescriptionText}>Min: {`${props.limits.low} ${props.units}`}</small>
                <small className={classes.periodDescriptionText}>Max: {`${props.limits.high} ${props.units}`}</small>
            </p>
        );
    }
    return (
        <React.Fragment>
            <div className={`formItem `} style={errContainerStyle}>
                <label className={`inputTitle`}>{props.label}</label>
                <span className={`UI-input ${classes.inputsContainer}`}>
                    <span>
                        <span className={classes.input}>
                            <LabeledInput
                                className={props.className}
                                value={value_low}
                                name={`${props.name}_low`}
                                onBlur={props.onBlur}
                                inputChange={(e) => props.inputChange(e, transformReturnValue(e))}
                                maxLength={props.maxLength}
                                units={props.units}
                                label={props.labelsDisabled ? null : props.intl.formatMessage({ id: "sensor.lowLimit" })}
                                touched={props.touched}
                                valid={props.valid[0]}
                                required={props.required}
                                id={props.lowId}
                                type="number"
                            />
                        </span>
                        {numOfInputs > 1 ? (
                            <span className={classes.input}>
                                <LabeledInput
                                    className={props.className}
                                    value={value_high}
                                    name={`${props.name}_high`}
                                    onBlur={props.onBlur}
                                    inputChange={(e) => props.inputChange(e, transformReturnValue(e))}
                                    maxLength={props.maxLength}
                                    units={props.units}
                                    label={props.labelsDisabled ? null : props.intl.formatMessage({ id: "sensor.highLimit" })}
                                    touched={props.touched}
                                    valid={props.valid[1]}
                                    required={props.required}
                                    id={props.highId}
                                    type="number"
                                />
                            </span>
                        ) : null}
                    </span>
                    {limits}
                    {errMessage}
                </span>
            </div>
            {/*<div className={`row ${classes.errContainer}`}>
				<div className={`col-md-3 ml-md-auto ${classes.errText}`}>{errMessage}</div>
			</div>*/}
        </React.Fragment>
    );
};

export default injectIntl(limitsInput);
