import React, { Component } from 'react';
import { connect } from 'react-redux';

import Input from './../../../../../components/UI/Inputs/Input/Input';
import FormNavigation from './../../../../../components/UI/FormNavigation/FormNavigation2';
import PageSpinner from './../../../../../components/Spinner/Spinner';

import { FormattedMessage } from 'react-intl';
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import { handleRemoteError } from './../../../../../store/actions/sensorActions';

import classes from './ChangePassword.module.scss';
import * as patterns from './../../../../../utils/InputPatterns';
import * as validations from './../../../../../utils/inputsValidator';

const fieldNames = [
	{
		id: 'user.actualPassword',
		name: 'actual_password',
		success: <FormattedMessage id="login.actualPassword" defaultMessage="Actual Password" />,
		error: <FormattedMessage id="login.actualPassword.tip" defaultMessage="Invalid input" />,
	},
	{
		id: 'user.newPassword',
		name: 'password',
		success: <FormattedMessage id="login.newPassword" defaultMessage="Password" />,
		error: <FormattedMessage id="login.password.tip" defaultMessage="Invalid input" />,
	},
	{
		id: 'user.newPasswordAgain',
		name: 'password_again',
		success: <FormattedMessage id="login.newPasswordAgain" defaultMessage="Password again" />,
		error: <FormattedMessage id="login.newPasswordAgain.tip" defaultMessage="Invalid input" />,
	},
];

class ChangePassword extends Component {
	state = {
		actual_password: {
			required: true,
			valid: false,
			value: '',
			type: 'basic',
			clicked: false,
			active: false,
			validationRules: {
				pattern: '.{6,}',
				maxLength: 64,
			},
		},
		password: {
			required: true,
			valid: false,
			value: '',
			type: 'basic',
			clicked: false,
			active: false,
			validationRules: {
				pattern: patterns.PASSWORD_PATTERN,
				maxLength: 64,
			},
		},
		password_again: {
			required: true,
			valid: false,
			value: '',
			type: 'basic',
			clicked: false,
			active: false,
			validationRules: {
				pattern: '.',
				maxLength: 64,
			},
		},
		isLoading: false,
	};

	getInitiatedState = () => {
		const initObj = {
			required: true,
			valid: false,
			value: '',
			type: 'basic',
			clicked: false,
			active: false,
		};
		return {
			...this.state,
			actual_password: {
				...this.state.actual_password,
				...initObj,
			},
			password: {
				...this.state.password,
				...initObj,
			},
			password_again: {
				...this.state.password_again,
				...initObj,
			},
			isLoading: false,
		};
	};

	onInputChangedHandler = (e) => {
		const stateName = e.target.name;
		const value = e.target.value;
		const updatedState = validations.recomputeState(this.state[stateName], value);
		const completeState = {
			...this.state,
			[stateName]: updatedState,
		};
		completeState.password_again.valid = completeState.password.value === completeState.password_again.value && completeState.password.valid;
		this.setState(completeState);
	};

	onSubmitHandler = (e) => {
		e.preventDefault();
		this.setState({
			...this.state,
			isLoading: true,
		});

		if (this.props.user.user.role === 'DEMO') {
			const resp = {
				response: {
					status: 401,
					data: {
						message: "Your don't have permissions for this operation",
					},
				},
			};
			this.props.handleRemoteError(resp, 'changePasswordDemo');
			this.setState(this.getInitiatedState());
		} else {
			Auth.currentAuthenticatedUser()
				.then((user) => {
					return Auth.changePassword(user, this.state.actual_password.value, this.state.password.value);
				})
				.then(() => {
					toast.success(<FormattedMessage id="login.code.updated" defaultMessage="Password updated successfully." />, {
						position: toast.POSITION.BOTTOM_RIGHT,
					});
				})
				.catch((err) => {
					let code = 'https.unknown';
					if (err.code === 'NotAuthorizedException') code = 'https.invalidPassword';
					else if (err.code === 'LimitExceededException') code = 'https.limitExceeded';

					const resp = {
						response: {
							status: 400,
							data: {
								message: JSON.stringify(err),
							},
						},
					};
					this.props.handleRemoteError(resp, 'changePasswordApp', code);
				})
				.finally(() => {
					this.setState(this.getInitiatedState());
				});
		}
	};

	render() {
		const inputs = [];
		fieldNames.forEach((field) => {
			const stateName = field.name;
			inputs.push(
				<Input
					key={field.id}
					label={field.success}
					name={stateName}
					inputChange={this.onInputChangedHandler}
					touched={this.state[stateName].clicked}
					valid={this.state[stateName].valid}
					required={this.state[stateName].required}
					value={this.state[stateName].value}
					errorMessage={field.error}
					maxLength={this.state[stateName].validationRules.maxLength}
					type="password"
				/>
			);
		});
		const navigationButtons = (
			<FormNavigation
				wraperStyle={{ marginRight: '20%' }}
				confirmBtnDisabled={!validations.checkFormValidity(this.state)}
				backButtonHidden
				homeButtonHidden
				animation
				confirm={this.onSubmitHandler}
				confirmText={<FormattedMessage id={'button.update'} defaultMessage="Update" />}
			/>
		);
		let content = <PageSpinner type="rectangles" size="11px" style={{ marginTop: '100px' }} />;
		if (!this.state.isLoading) {
			content = (
				<form action="" className={classes.form}>
					{inputs}
					{navigationButtons}
				</form>
			);
		}
		return content;
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.users.logged_user,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		handleRemoteError: (err, id = null, eventId = 'https.unknown') => dispatch(handleRemoteError(err, id, eventId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
