import React from 'react';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';

export function sensorUpdated() {
	toast.success(<FormattedMessage id="modal.editSuccess" defaultMessage="Sensor was successfully edited" />, {
		position: toast.POSITION.BOTTOM_RIGHT,
	});
}

export function sensorDeleted() {
	toast.success(<FormattedMessage id="modal.deleteSuccess" defaultMessage="Sensor was successfully deleted" />, {
		position: toast.POSITION.BOTTOM_RIGHT,
	});
}

export function sensorAdded() {
	toast.success(<FormattedMessage id="modal.postSuccess" defaultMessage="Sensor was successfully created" />, {
		position: toast.POSITION.BOTTOM_RIGHT,
	});
}

export function sensorEncoded() {
	toast.success(<FormattedMessage id="modal.encodedSuccessfully" defaultMessage="Successfully encoded" />, {
		position: toast.POSITION.BOTTOM_RIGHT,
	});
}
export function sensorDecoded() {
	toast.success(<FormattedMessage id="modal.decodedSuccessfully" defaultMessage="Successfully decoded" />, {
		position: toast.POSITION.BOTTOM_RIGHT,
	});
}

export function sensorLocked(isProtected = false) {
	const keyEnd = isProtected ? "protected" : "unprotected";
	toast.success(<FormattedMessage id={`modal.deviceIsLocked.${keyEnd}`} defaultMessage="Device is locked" />, {
		position: toast.POSITION.BOTTOM_RIGHT,
	});
}

export default toast;
