exports.diff = function (data, dataset_name, omitDisabled = true, omitNan = true) {
	let count = 0;
	let diff = 0;

	for (let i = 0; i < data.length; i++) {
		const value = data[i];
		if (value.hasOwnProperty(dataset_name)) {
			if (omitDisabled && value.disabled) continue;
			if (omitNan && (isNaN(value[dataset_name]) || value[dataset_name] === 'undefined')) continue;

			diff -= value[dataset_name];
			count++;
		}
	}



	if (count === 0) return null;
	else return diff;
};
