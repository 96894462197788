import React from 'react';

import NotAvailable from "./../NotAvailable/NotAvailable.jsx";

import Tooltip from './../../../../Tooltip/Tooltip';
import classes from "./TextIcon.module.scss";


const textIcon = (props) => {

    let content = null;
    let icon = props.iconComponent;
    let tooltip = props.tooltip;

    if (props.iconClass) {
        icon = <i className={`${props.iconClass} ${classes.fontIcon}`} />;
    }
    if (!props.text && props.hideNA) {
        icon = <NotAvailable />;
        tooltip = null;
    }

    // round numbers
    let text = props.text;
    //if(!isNaN(+text)) text = (+text).toFixed(1);

    if (!props.hidden) {
        content = (
            <div className={props.className}>
                {/*<i className={stateClasses.join(' ')} data-tip={props.tooltip} data-for="descriptorState" />*/}
                <span
                    className={classes.container}>
                    <span className={classes.iconState} style={props.style}>
                        <Tooltip tooltip={tooltip}>
                            {icon}
                        </Tooltip>
                    </span>
                    {props.text ? <p className={classes.label}>{text}</p> : null}
                </span>
            </div>
        );
    }

    return content;

};

export default textIcon;