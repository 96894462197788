import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

const chartConfig = {
    bullets: false,
    pdf: false,
    csv: false,
    xlsx: false,
    limits: false,
    multiAxes: false,
    avg: false,
    min: false,
    max: false,
    type: false,
    aggregation: false,
    period: false,
};

export const regimes = {
    LineSeries: "LineSeries",
    ColumnSeries: "ColumnSeries",
};

export const createXYChart = (id, activeOnTap = false, margins = { top: -5, left: 15, right: 15, bottom: 0 }, legend = true) => {
    let chart = am4core.create(id, am4charts.XYChart);
    chart.width = am4core.percent(100); //set dimensions and layout
    chart.height = am4core.percent(100);
    //let chart = container.createChild(am4charts.XYChart);
    //chart.horizontalCenter = "left";
    //chart.verticalCenter = "top";
    chart.paddingRight = margins.right;
    chart.paddingTop = margins.top;
    chart.paddingLeft = margins.left;
    chart.paddingBottom = margins.bottom;

    if (legend) {
        chart.legend = new am4charts.Legend();
        chart.legend.useDefaultMarker = false;
        chart.legend.parent = chart.plotContainer;
        chart.legend.zIndex = 100;
        chart.legend.itemContainers.template.togglable = true;

        chart.legend.itemContainers.template.events.on("over", function (event) {
            processOver(chart, event.target.dataItem.dataContext);
        });

        chart.legend.itemContainers.template.events.on("out", function (event) {
            processOut(chart);
        });
    }

    chart.zoomOutButton.parent = chart.tooltipContainer;
    chart.zoomOutButton.disabled = true;

    chart.tapToActivate = activeOnTap;

    //chart.maskBullets = true;

    return chart;
};

export const addDateAxis = (chart, period = "second", groupData = false, groupCount = 2000) => {
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());

    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.axisFills.template.disabled = false;
    dateAxis.renderer.labels.template.textAlign = "middle";
    dateAxis.renderer.labels.template.fontSize = 12;
    dateAxis.renderer.labels.template.location = 0.00001;

    //dateAxis.renderer.axisFills.template.fillOpacity = 1; //0.05;

    dateAxis.dateFormatter.dateFormat = "MM-dd-yyyy H:mm";


    /******************* WARNING ************************
	Time format has to be 2-lines or single line. If there both types then the bar chart is buggy.
	It cause bad rendering of bar lines becouse single line time formats comes to double line format
	and the space for chart is the smaller and it cause bad re-rendering.
    /************************************************** */

    dateAxis.dateFormats.setKey("month", "MM-dd-yyyy\nH:mm");
    dateAxis.dateFormats.setKey("day", "MM-dd-yyyy\nH:mm");
    dateAxis.dateFormats.setKey("minute", "MM-dd-yyyy\nH:mm");
    dateAxis.dateFormats.setKey("hour", "MM-dd-yyyy\nH:mm");
    dateAxis.dateFormats.setKey("year", "MM-dd-yyyy\nH:mm");
    dateAxis.dateFormats.setKey("week", "MM-dd-yyyy\nH:mm");
    dateAxis.periodChangeDateFormats.setKey("month", "MM-dd-yyyy\nH:mm");
    dateAxis.periodChangeDateFormats.setKey("day", "MM-dd-yyyy\nH:mm");
    dateAxis.periodChangeDateFormats.setKey("minute", "MM-dd-yyyy\nH:mm");
    dateAxis.periodChangeDateFormats.setKey("hour", "MM-dd-yyyy\nH:mm");
    dateAxis.periodChangeDateFormats.setKey("year", "MM-dd-yyyy\nH:mm");
    dateAxis.periodChangeDateFormats.setKey("week", "MM-dd-yyyy\nH:mm");
    dateAxis.tooltipDateFormat = "dd-MMM-YYYY\nHH:mm:ss";
    /****************************************************************** */

    dateAxis.groupData = groupData;
    dateAxis.groupCount = groupCount;
    dateAxis.keepSelection = true;

    dateAxis.baseInterval = {
        timeUnit: period,
        count: 1,
    };

    dateAxis.groupIntervals.setAll([
        { timeUnit: "millisecond", count: 1 },
        { timeUnit: "millisecond", count: 10 },
        { timeUnit: "millisecond", count: 100 },
        { timeUnit: "second", count: 1 },
        //{ timeUnit: "second", count: 10 },
        { timeUnit: "minute", count: 1 },
        //{ timeUnit: "minute", count: 10 },
        { timeUnit: "hour", count: 1 },
        //{ timeUnit: "hour", count: 10 },
        { timeUnit: "day", count: 1 },
        //{ timeUnit: "day", count: 3 },
        { timeUnit: "week", count: 1 },
        // { timeUnit: "week", count: 2 },
        { timeUnit: "month", count: 1 },
        //{ timeUnit: "month", count: 3 },
        //{ timeUnit: "month", count: 6 },
        { timeUnit: "year", count: 1 },
    ]);

    dateAxis.renderer.labels.template.rotation = 0;
    dateAxis.renderer.cellStartLocation = 0.1;
    dateAxis.renderer.cellEndLocation = 0.9;

    return dateAxis;
};

export const addValueAxis = (chart, regime, multiAxisOn, isMainAxis = false, units = null, sync = true, syncAxis = null, disabled = false) => {
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    if (sync) {
        //if (chart.yAxes.indexOf(valueAxis) !== 0) {
        valueAxis.syncWithAxis = syncAxis; //chart.yAxes.getIndex(0);
        //valueAxis.renderer.grid.template.disabled = true;
        //}
    }
    valueAxis.disabled = disabled;
    valueAxis.tooltip.disabled = true;
    //valueAxis.renderer.minWidth = 35;
    //valueAxis.renderer.minGridDistance = 40;
    //valueAxis.renderer.inside = true;
    valueAxis.numberFormatter = new am4core.NumberFormatter();
    valueAxis.numberFormatter.numberFormat = "#.00";
    //valueAxis.adjustLabelPrecision = false;

    valueAxis.title.text = "[bold]" + units;
    //if(disabled) valueAxis.title.hide();

    valueAxis.renderer.labels.template.fontSize = 12;
    valueAxis.renderer.labels.template.fill = am4core.color("black");
    valueAxis.renderer.labels.template.align = "center";


    valueAxis.paddingLeft = -15;
    valueAxis.paddingRight = -5;

    if (multiAxisOn) {
        valueAxis.title.rotation = 0;
        valueAxis.title.align = "center";
        valueAxis.title.valign = "top";
        valueAxis.title.dy = -30;
        valueAxis.title.paddingLeft = 0;
        valueAxis.title.paddingRight = 0;
        valueAxis.layout = "absolute";
        valueAxis.paddingLeft = -5;
        valueAxis.paddingRight = -5;
        //valueAxis.width = 50;
    }
    

    if (isMainAxis) valueAxis.name = `${regime}_main_axis`;
    else valueAxis.name = `${regime}_sec_axis`;

    /*
	valueAxis.renderer.opposite = true;
	valueAxis.renderer.grid.template.disabled = true;
	valueAxis.renderer.labels.template.disabled = true;
	valueAxis.renderer.tooltip.disabled = true;
	*/
    //valueAxis.title.dx = 300;

    //if (chart.yAxes.length > 1) valueAxis.renderer.opposite = true;

    return valueAxis;
};

//*************  BAR CHART FUNCTIONS *********************** */


export const addCategorySeriesBAR = (chart, xAxis, yAxis, id, xVarName, yVarName, pretty_name, legend = "", color = null, lang = "cs") => {
    //let series = chart.series.push(new am4charts.ColumnSeries());
    let series = new am4charts.ColumnSeries();
    series.groupFields.valueY = "average";
    series.dataFields.valueY = id + "_" + yVarName;
    series.dataFields.dateX = xVarName;
    series.dataFields.dateXEnd = "end";
    series.name = id + "_" + yVarName;
    series.pretty_name = pretty_name;
    switch (lang) {
        case "cs":
            series.columns.template.tooltipText = "start: [bold]{dateX}[/]\nkonec: [bold]{dateXEnd}[/]\nhodnota: [bold]{valueY}[/]";
            break;
        default:
            series.columns.template.tooltipText = "start: [bold]{dateX}[/]\nend: [bold]{dateXEnd}[/]\nvalue: [bold]{valueY}[/]";
            break;
    }
    series.columns.template.showTooltipOn = "hit";
    //series.columns.template.propertyFields.showTooltipOn = 'hover';
    series.columns.template.width = am4core.percent(90);
    series.yAxis = yAxis;
    series.xAxis = xAxis;
    //series.showOnInit = false;
    //series.autoDispose = false;
    series.simplifiedProcessing = true;
    //series.excludeFromTotal = true;
    //series.clustered = true;

    if (color) {
        series.columns.template.stroke = am4core.color(color); // red outline
        series.columns.template.fill = am4core.color(color);
    }

    /*
	series.events.on('dataitemsvalidated', (ev) => {
		let serie = ev.target;
		var cellWidth = serie.pixelWidth / (serie.endIndex - serie.startIndex);
		console.log(cellWidth, serie.maxWidth);
	});
	series.events.on('selectionextremeschanged', (ev) => {
		let serie = ev.target;
		var cellWidth = serie.pixelWidth / (serie.endIndex - serie.startIndex);
		console.log(cellWidth, serie.maxWidth);
	});
	*/

    /*
	let labelBullet = series.columns.template.createChild(am4core.Label);
	
	labelBullet.text = '{valueY.formatNumber("#.00")}';
	labelBullet.stroke = am4core.color('#ccc');
	labelBullet.fontSize = 12;
	*/
    /*
	let labelBullet = series.bullets.push(new am4charts.LabelBullet());
	labelBullet.label.text = '{valueY.formatNumber("#.00")}';
	labelBullet.label.fill = am4core.color('#fff');
	//labelBullet.locationY = 0.1;
	labelBullet.dy = 10;
	labelBullet.label.truncate = false;
	labelBullet.label.hideOversized = true;
	labelBullet.disabled = true;
	labelBullet.label.fontSize = 12;
	
	//labelBullet.disabled = true;
	//labelBullet.events.on('ready', ev => {
	//	ev.target.disabled = true;
	//});
	/*
	series.events.on('sizechanged', function(ev) {
		let serie = ev.target;
		var cellWidth = serie.pixelWidth / (serie.endIndex - serie.startIndex);
		console.log(cellWidth, serie.maxWidth);
		if (cellWidth < serie.maxWidth) {
			labelBullet.rotation = -90;
		} else {
			labelBullet.rotation = 0;
		}
	});
*/
    if (legend) {
        series.legendSettings.labelText = legend;
    }

    /*
	let hoverState = series.columns.template.states.create('hover');
	hoverState.properties.strokeWidth = 3;
	//hoverState.properties.stroke = am4core.color('red');
	
	let dimmed = series.columns.template.states.create('dimmed');
	dimmed.properties.fill = am4core.color('#dadada');
	dimmed.properties.stroke = am4core.color('#dadada');
	*/

    return series;
};


//******************************************************** */

export const addCategoryAxis = (chart) => {
    var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.inversed = true;

    return categoryAxis;
};

export const addColumnSeries = (chart) => {
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.columns.template.height = am4core.percent(70);
    //series.columns.template.tooltipText = '{task}: [bold]{openDateX}[/] - [bold]{dateX}[/]';
    series.columns.template.tooltipText = "[bold]{openDateX}[/]\n - [bold]{dateX}[/]";

    series.dataFields.openDateX = "start";
    series.dataFields.dateX = "end";
    series.dataFields.categoryY = "category";
    series.columns.template.propertyFields.fill = "color"; // get color from data
    series.columns.template.propertyFields.stroke = "color";
    series.columns.template.strokeOpacity = 1;

    return series;
};


export const addSeries = (chart, dateAxis, valAxis, mode, id, xVarName, yVarName, pretty_name, color, multiAxisOn, legend = "") => {


    //let series = chart.series.push(new am4charts.LineSeries());
    let series = null;
    switch (mode) {
        case "step":
            series = new am4charts.StepLineSeries();
            break;
        default: //line
            series = new am4charts.LineSeries();
            break;
    }

    //const defColor = series.stroke;

    series.dataFields.dateX = xVarName; //series.dataFields.dateX = "date";
    series.dataFields.valueY = id + "_" + yVarName; //series.dataFields.valueY = "value";
    if (multiAxisOn) {
        series.yAxis = valAxis;
        series.xAxis = dateAxis;
    }

    series.strokeWidth = 1.5;
    series.stroke = am4core.color(color); // red
    //! If both tensions were equal to 1 the the bullets were  missaligned. This seting close to 1 works.
    series.tensionX = 0.9999999;
    series.tensionY = 0.9999999;
    series.minBulletDistance = 1;
    //series.showOnInit = true;
    series.name = id + "_" + yVarName;
    series.pretty_name = pretty_name;
    //series.autoDispose = false;
    series.simplifiedProcessing = true;

    //let segment = series.segments.template;
    //segment.interactionsEnabled = true;

    let hoverState = series.states.create("hover");
    hoverState.properties.strokeWidth = 3;

    let dimmed = series.states.create("dimmed");
    dimmed.properties.stroke = am4core.color("#dadada");

    //Setting ValueAxis Color
    /*
	if (chart.yAxes.length > 0) {
		//valAxis.renderer.line.strokeOpacity = 1;
		//valAxis.renderer.line.strokeWidth = 2;
		//valAxis.renderer.line.stroke = series.stroke;
		valAxis.renderer.labels.template.fill = series.stroke;
		//valAxis.renderer.labels.template.fontSize = 12;
		//valAxis.renderer.labels.template.rotation = 90;
	}
	*/

    // series.stroke = am4core.color("red");

    if (legend) {
        series.legendSettings.labelText = legend;
    }

    return series;
};

export const addBulletAllarm = (series) => {
    let bullet2 = series.bullets.push(new am4charts.Bullet());
    let image = bullet2.createChild(am4core.Image);

    image.propertyFields.href = "bullet";
    image.width = 15;
    image.height = 15;
    image.horizontalCenter = "middle";
    image.verticalCenter = "middle";

    return bullet2;
};

export const addBullets = (series) => {
    let bullet = series.bullets.push(new am4charts.CircleBullet()); //new am4charts.CircleBullet()


    bullet.circle.strokeWidth = 1;
    bullet.circle.radius = 1.5;
    bullet.circle.fillOpacity = 1;
    //bullet.circle.fill = am4core.color("#fff");
    bullet.stroke = series.stroke; //'color';
    bullet.circle.fill = am4core.color("white");
    bullet.propertyFields.disabled = "disabled";
    bullet.disabled = true;

    let dimmed = bullet.states.create("dimmed");
    dimmed.properties.stroke = am4core.color("#dadada");


    //bullet.events.on('ready', ev => {
    //	ev.target.disabled = true;
    //});
    //bullet.disabled = true;
    //bullet.hide();
    //image.hide();

    return bullet;
};

export const addScrollbarX = (chart, series = null) => {
    //if (chart.scrollbarX && chart.scrollbarX.series.length > 0) {
    //	chart.scrollbarX.series.push(series);
    //} else {
    let scrollbarX = new am4core.Scrollbar(); //new am4charts.XYChartScrollbar();//new am4core.Scrollbar();
    //scrollbarX.series.push(series);
    //scrollbarX.series.stroke = am4core.color("red");
    chart.scrollbarX = scrollbarX;
    //chart.scrollbarX.unselectedOverlay.fill = am4core.color("#fff");
    //chart.scrollbarX.unselectedOverlay.fillOpacity = 0.9;

    chart.scrollbarX.height = 22;
    chart.scrollbarX.paddingLeft = 5;
    chart.scrollbarX.thumb.background.fill = am4core.color("rgba(63, 60, 60, 0.15)");

    chart.scrollbarX.background.fill = am4core.color("rgba(63, 60, 60, 0.1)");
    //chart.scrollbarX.animationDuration = 0;

    //}

    /*
    this.chart.scrollbarX.startGrip.background.fill = am4core.color("black");
    this.chart.scrollbarX.endGrip.background.fill = am4core.color("black");
    this.chart.scrollbarX.stroke = am4core.color("grey");
    */
    return chart.scrollbarX;
};

export const setValueTooltip = (series, units, color, evaluateText = null) => {
    series.tooltipText = '{valueY.formatNumber("#.00")} ' + units;
    series.tooltip.getFillFromObject = false;
    series.tooltip.background.fill = am4core.color(color);

    if (evaluateText && typeof evaluateText === "function") {
        series.adapter.add("tooltipText", function (text, target) {
            return evaluateText(target.tooltipDataItem.values.valueY.value);
        });
    }
};

export const setCursorMode = (chart, type) => {
    chart.cursor = new am4charts.XYCursor();
    switch (type) {
        case "x":
            chart.cursor.lineY.disabled = true;
            break;
        case "y":
            chart.cursor.lineX.disabled = true;
            break;
        default:
            chart.cursor.lineX.disabled = false;
            chart.cursor.lineY.disabled = false;
            break;
    }
};

export const showLimit = (yAxis, series, startValue, endValue, color, text, position) => {
    let range = yAxis.createSeriesRange(series);
    range.value = startValue;
    range.endValue = endValue;
    //range.contents.stroke = am4core.color(color);
    //range.contents.fill = am4core.color(color);
    //range.contents.strokeWidth = 1.5;

    range.axisFill.fill = am4core.color(color);
    range.axisFill.fillOpacity = 0.05;

    let rangeLine = yAxis.axisRanges.create();
    rangeLine.value = startValue;

    rangeLine.grid.stroke = am4core.color(color);
    rangeLine.grid.strokeWidth = 0.5;
    rangeLine.grid.strokeOpacity = 0.8;
    rangeLine.label.inside = true;
    rangeLine.label.text = text;
    rangeLine.label.fill = rangeLine.grid.stroke;
    rangeLine.label.verticalCenter = position;
    rangeLine.label.dy = startValue < endValue ? 10 : -10;
    rangeLine.label.dx = -15;

    return range;
};

export const makePreloaderTransparent = (chart) => {
    chart.preloader.background.fillOpacity = 0;
    /*
  chart.preloader.background.fill = am4core.color("#fff");
  chart.preloader.width = am4core.percent(100);
  chart.preloader.height = am4core.percent(100);
  chart.preloader.events.on("shown", () => { console.log("Preloader shown") });
  chart.preloader.events.on("hidden", () => { console.log("Preloader hidden") });
  */
};

export const addLabel = (chart, text, fontSize) => {
    let label = chart.createChild(am4core.Label);
    label.text = text;
    label.fontSize = fontSize;
    label.isMeasured = false;
    label.x = am4core.percent(35);
    label.y = am4core.percent(40);

    return label;
};

export const initLine = (custSerie, value, mobileRegime, type = "dashed") => {
    const serie = custSerie.serie;
    const axis = custSerie.serie.yAxis;

    const show = !serie.isHiding && !serie.isHidden;

    if (axis) {
        let range = axis.axisRanges.create();
        range.name = "aggr_line_";

        range.value = value;
        range.grid.stroke = am4core.color(custSerie.config.color);
        range.grid.strokeWidth = 1.5;
        range.grid.strokeOpacity = 1;
        range.label.inside = mobileRegime;
        range.label.text = (Math.round(value * 100) / 100).toFixed(2);
        range.label.fill = range.grid.stroke;
        range.label.fontWeight = 900;
        range.label.background.fill = am4core.color("#eee");
        range.label.background.strokeOpacity = 1;
        range.label.verticalCenter = "middle";
        range.label.paddingLeft = 5;
        range.label.paddingRight = 5;

        if (show) range.show();
        else range.hide();

        if (type === "dashed") range.grid.strokeDasharray = 3.3;
    }
};

export const processOver = (chart, hoveredSeries) => {
    hoveredSeries.toFront();

    hoveredSeries.setState("hover");
    /*
	if (hoveredSeries.segments) {
		hoveredSeries.segments.each(function(segment) {
			console.log("segment", segment);
			segment.setState('hover');
		});
	}
	*/

    chart.series.each(function (series) {
        if (series !== hoveredSeries) {
            series.setState("dimmed");
            /*
			if (series.segments && series.segments.length > 0) {
				series.segments.each(function(segment) {
					segment.setState('dimmed');
				});
			}
			*/
            if (series.bullets && series.bullets.length > 0) {
                series.bullets.each((bullet) => {
                    bullet.setState("dimmed");
                });
            }
            if (series.columns && series.columns.length > 0) {
                series.columns.each((column) => {
                    column.setState("dimmed");
                });
            }

            //series.bulletsContainer.setState('dimmed');
        }
    });
};

export const processOut = (chart) => {
    chart.series.each(function (series) {
        series.setState("default");
        /*
		if (series.segments && series.segments.length > 0) {
			series.each(function(segment) {
				console.log("default");
				segment.setState('default');
			});
		}
		*/
        if (series.bullets && series.bullets.length > 0) {
            series.bullets.each((bullet) => {
                bullet.setState("default");
            });
        }
        if (series.columns && series.columns.length > 0) {
            series.columns.each((column) => {
                column.setState("default");
            });
        }
        //series.bulletsContainer.setState('default');
    });
};

export const loadConfig = (type) => {
    const config = { ...chartConfig };

    switch (type) {
        case regimes.ColumnSeries:
            config.limits = true;
            config.multiAxes = true;
            config.bullets = true;
            config.min = true;
            config.max = true;
            config.avg = true;
            break;
        default:
            config.aggregation = true;
            config.period = true;
            break;
    }

    return config;
};
