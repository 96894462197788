import React from 'react';
import { injectIntl } from 'react-intl';
import withSensorState from '../../hoc/withState';

import Text from './sensorDescriptors/text/Text.jsx';
import StateDescriptor from './sensorDescriptors/State/State.jsx';
import Info from './sensorDescriptors/Info/Info';

import Menu from './../../Menu/Menu';
import Spinner from './../../Spinner/Spinner.jsx';
import Tooltip from './../../Tooltip/Tooltip';
//import Semaphore from "./sensorDescriptors/Semaphore/Semaphore.jsx";

import sensorTypes from './../../../utils/sensorTypes';
import ActionButton from './sensorActionButton/ActionButton.jsx';
import classes from './Sensor.module.scss';

const sensor = (props) => {
	let sen = null;

	let wrapperClass = [classes.SensorWrapper];
	let alarmClass = classes.alarmOff;
	let alarmTooltip = props.intl.formatMessage({ id: 'sensor.alarmOK' });
	let actionMenuStyle = null;

	const getState = () => {
		if (props.sensor.hasOwnProperty('protected')) {
			actionMenuStyle = { minWidth: '160px' };
			if (props.sensor.state === 'ACTIVE') {
				// if (props.sensor.force_locked) {
				// 	return 'locked';
				// } else if(props.sensor.protected)
				if (props.sensor.protected) {
					return 'protected';
				} else {
					return 'unprotected';
				}
			}
		}

		return props.sensor.state;
	};

	if (props.sensor) {
		if (props.sensor.alarm_raised) {
			alarmClass = classes.alarmOn;
			alarmTooltip = props.intl.formatMessage({ id: 'sensor.alarmRaised' });
			wrapperClass.push(classes.alarmActive);
		} else if (props.mobileRegimeOn && props.sensor.state !== 'ACTIVE') {
			alarmClass = classes.alarmOn;
			alarmTooltip = props.intl.formatMessage({ id: 'sensor.notActivated' });
			wrapperClass.push(classes.notActivatedAlarm);
		}
	}

	const handleClick = (e) => {
		if (!e.target.id.startsWith('menu-open')) {
			props.click(e);
		}
	};

	const silentClick = (e) => {
		e.stopPropagation();
	};

	if (props.type === 'Table_Row') {
		let actRegime = 'width';
		let data = null;
		if (props.mobileRegimeOn) {
			actRegime = 'mobWidth';
		} else if (props.tabletRegimeOn) {
			actRegime = 'tabWidth';
		}
		if (props.sensor.registered_sensors) {
			data = props.sensor.registered_sensors;
		} else if (props.sensor.hasOwnProperty('protected')) {
			data = !props.sensor.protected || props.sensor.force_locked;
		}
		sen = (
			<tr className={wrapperClass.join(' ')} onClick={handleClick}>
				{props.sensor.force_locked && props.mobileRegimeOn && (
					<td className={classes.sensorLocked}>
						<i className={'fas fa-lock'} />
					</td>
				)}
				<td className={alarmClass}>
					<Tooltip tooltip={alarmTooltip}>
						<i className={`fas fa-exclamation-triangle ${classes.triangle}`} onClick={silentClick} />
						<i className={`fas fa-exclamation ${classes.basic}`} onClick={silentClick} />
					</Tooltip>
				</td>
				<td className={classes.tableCol} style={{ minWidth: props.columns[0].props[actRegime] }}>
					<Text value={props.sensor.name} />
				</td>
				{props.mobileRegimeOn ? null : (
					<td className={classes.tableCol} style={{ minWidth: props.columns[1].props[actRegime] }}>
						<StateDescriptor
							disabled={props.sensor.force_locked}
							hidden={props.mobileRegimeOn}
							state={getState()}
							tooltip={props.intl.formatMessage({ id: 'tooltip.state' }) + ': ' + props.intl.formatMessage({ id: props.labelId }).toUpperCase()}
						/>
					</td>
				)}
				{props.mobileRegimeOn ? null : (
					<td className={classes.tableCol} style={{ minWidth: props.columns[2].props[actRegime] }}>
						<Text value={getPrettyLabel(props.sensor.sensor_type)} hidden={props.mobileRegimeOn} />
					</td>
				)}
				<td className={classes.tableCol} style={{ minWidth: props.columns[3].props[actRegime] }}>
					<Info type={props.sensor.sensor_type} infoType={props.sensor.info_1_template} value={props.sensor.info_1} data={data} />
				</td>
				{props.mobileRegimeOn || props.tabletRegimeOn ? null : (
					<td className={classes.tableCol} style={{ minWidth: props.columns[4].props[actRegime] }}>
						<Info type={props.sensor.sensor_type} infoType={props.sensor.info_2_template} value={props.sensor.info_2} data={data} />
					</td>
				)}
				<td className={classes.tableCol} style={{ minWidth: props.columns[5].props[actRegime] }}>
					{props.mobileRegimeOn || props.tabletRegimeOn ? (
						<div className={classes.menuContainer}>
							<Menu
								id={`${Math.random() * 100}`}
								item1={
									<ActionButton
										type="Edit"
										click={props.onEditClick}
										tooltip={props.intl.formatMessage({ id: 'tooltip.edit' })}
										clearDefaultClasses
										className={classes.edit}
									/>
								}
								item1Class={classes.editLink}
								item2={
									<ActionButton
										type="Delete"
										click={props.onDeleteClick}
										tooltip={props.intl.formatMessage({ id: 'tooltip.deleteSensor' })}
										clearDefaultClasses
										className={classes.delete}
									/>
								}
								item2Class={classes.deleteLink}
								item3={
									<ActionButton
										type="Activate"
										click={props.onActivateClick}
										tooltip={props.intl.formatMessage({ id: 'tooltip.activateSensor' })}
										clearDefaultClasses
									/>
								}
								item3Class={classes.activateLink}
								item4={
									props.sensor.hasOwnProperty('protected') ? (
										<ActionButton
											type="Protect"
											click={props.onProtectClick}
											tooltip={props.intl.formatMessage({ id: 'tooltip.protectSensor' })}
											clearDefaultClasses
										/>
									) : null
								}
								item4Class={classes.protectLink}
							/>
						</div>
					) : (
							<div className={classes.actionPanel} style={actionMenuStyle}>
								{props.sensor.hasOwnProperty('protected') ? (
									<ActionButton type="Protect" click={props.onProtectClick} tooltip={props.intl.formatMessage({ id: 'tooltip.protectSensor' })} />
								) : null}
								<ActionButton type="Edit" click={props.onEditClick} tooltip={props.intl.formatMessage({ id: 'tooltip.edit' })} />
								<ActionButton type="Delete" click={props.onDeleteClick} tooltip={props.intl.formatMessage({ id: 'tooltip.deleteSensor' })} />
								<ActionButton type="Activate" click={props.onActivateClick} tooltip={props.intl.formatMessage({ id: 'tooltip.activateSensor' })} />
							</div>
						)}
				</td>
			</tr>
		);
	} else if (props.type === 'loading_sensor') {
		sen = (
			<tr className={classes.SensorWrapper}>
				<Spinner type="circle" size="4px" component="td" />
			</tr>
		);
	} else {
		sen = (
			<div id={props.id} className={props.className}>
				{props.children}
			</div>
		);
	}

	return sen;
};

const getPrettyLabel = (type) => {
	for (let sensorType in sensorTypes) {
		if (sensorTypes[sensorType].iot_label === type) {
			return sensorTypes[sensorType].pretty_label;
		}
	}
};

export default injectIntl(withSensorState(sensor));
