import React, { Component } from 'react';

import Auth from '@aws-amplify/auth';
import { FormattedMessage, injectIntl } from 'react-intl';
import LangPanel from './../../../components/common/LangPanel/LangPanel.jsx';
import Input from './../../../components/UI/Inputs/AuthInputs/SimpleInput/SimpleInput.jsx';
import SubmitButton from './../../../components/UI/Inputs/AuthInputs/SubmitInput/SubmitInput.jsx';
import { CODE_PATTERN } from './../../../utils/InputPatterns.js';
import ConfirmModal from './../../../components/modals/AuthModals/ConfirmModal/ConfirmModal.jsx';
import Spinner from './../../../components/Spinner/Spinner.jsx';

import classes from './../Auth.module.scss';
import classes3 from './ConfirmCode.module.scss';

const texts = {
	verification: <FormattedMessage id="login.verification" defaulMessage="Verification" />,
	verify: <FormattedMessage id="login.verify" defaulMessage="Verify" />,
	back: <FormattedMessage id="login.back" defaulMessage="Back to sign in" />,
	newCode: <FormattedMessage id="login.newCode" defaulMessage="I need a new code" />
};
const inputs = [
	{
		key: 'login.code',
		name: 'code',
		type: 'text',
		emptyId: 'login.code',
		tipId: 'login.code.tip'
	}
];

class ConfirmCode extends Component {
	state = {
		code: '',
		pattern: CODE_PATTERN,
		isValid: false,
		error: null,
		loading: false,
		touched: false,
		resend: 'None',
		verifyModalOpen: false,
		codeModalOpen: false
	};

	initState = () => {
		this.setState({
			...this.state,
			code: '',
			loading: false,
			isValid: false,
			error: null,
			touched: false,
			resend: 'None',
			verifyModalOpen: false,
			codeModalOpen: false
		});
	};

	onSubmit = e => {
		e.preventDefault();

		this.setState({ ...this.state, loading: true });

		Auth.confirmSignUp(this.props.authData.email, this.state.code)
			.then(result => {
				this.setState({ ...this.state, verifyModalOpen: true, loading: false });
			})
			.catch(err => {
				this.setState({
					...this.state,
					error: err,
					loading: false
				});
			});
	};

	onInputChange = e => {
		const value = e.target.value;
		const regex = new RegExp(this.state.pattern);
		const isValid = regex.test(value);

		this.setState({
			...this.state,
			code: value,
			isValid: isValid,
			error: null,
			resend: 'None',
			touched: true
		});
	};

	onBackClickHandler = () => {
		this.initState();
		this.props.onStateChange('signIn', {});
	};

	onNewCodeClickHandler = () => {
		this.setState({ ...this.state, loading: true, error: null });

		Auth.resendSignUp(this.props.authData.email)
			.then(result => {
				this.setState({ ...this.state, codeModalOpen: true, loading: false });
			})
			.catch(err => {
				console.log(err);
				if (err.name === 'InvalidParameterException' && err.message === 'User is already confirmed.') {
					Auth.forgotPassword(this.props.authData.email).then(() => {
						this.setState({ ...this.state, codeModalOpen: true, loading: false });
					});
				} else {
					this.setState({
						...this.state,
						error: err,
						loading: false
					});
				}
			});
	};
	onVerifyModalCloseHandler = () => {
		this.props.onStateChange('signIn', {});
		this.initState();
	};
	onCodeModalCloseHandler = () => {
		this.initState();
	};

	render() {
		let button = (
			<Spinner type="dotCircle" size="8px" style={{ margin: '40px 0', marginLeft: '30%', float: 'left' }} />
		);
		let modalVerify = null;
		let modalCode = null;

		if (this.state.verifyModalOpen) {
			modalVerify = (
				<ConfirmModal
					isOpen={this.state.verifyModalOpen}
					close={this.onVerifyModalCloseHandler}
					continue={this.onVerifyModalCloseHandler}
					confirmMsg={this.props.intl.formatMessage({
						id: 'login.verify.success'
					})}
				/>
			);
		}

		if (this.state.codeModalOpen) {
			modalCode = (
				<ConfirmModal
					isOpen={this.state.codeModalOpen}
					close={this.onCodeModalCloseHandler}
					continue={this.onCodeModalCloseHandler}
					confirmMsg={this.props.intl.formatMessage({
						id: 'login.email.sent'
					})}
					envelopeAnimation
				/>
			);
		}

		let input = (
			<i
				className={`fas fa-envelope ${classes3.message}`}
				/*
	onAnimationEnd={() => {
	  this.setState({ ...this.state, resend: "Done" });
	}}
	*/
			/>
		);

		let confirmCode = null;
		let error = null;

		if (!this.state.loading) {
			button = (
				<SubmitButton
					value={this.props.intl.formatMessage({ id: 'login.verify' })}
					isEnabled={this.state.isValid}
					name="button"
					onSubmit={this.onSubmit}
				/>
			);
		}
		if (this.state.resend !== 'Init') {
			input = inputs.map(input => {
				return (
					<Input
						key={input.key}
						type={input.type}
						name={input.name}
						emptyText={this.props.intl.formatMessage({ id: input.emptyId })}
						tooltip={this.props.intl.formatMessage({ id: input.tipId })}
						isValid={this.state.isValid}
						touched={this.state.touched}
						onInputChange={this.onInputChange}
					/>
				);
			});
		}

		if (this.state.error) {
			error = (
				<h4>
					<FormattedMessage
						id={'error.code.' + this.state.error.code}
						defaultMessage={this.state.error.message}
					/>
				</h4>
			);
		}

		if (this.props.authState === 'confirmSignUp') {
			confirmCode = (
				<div className={classes.outter}>
					<div className={classes.middle}>
						<div className={classes.container}>
							<section className={classes.content}>
								<span className={classes.topPanel}>
									<img
										className={`img-fluid ${classes.iotIcon}`}
										src={process.env.PUBLIC_URL + '/assets/img/IoTpool_logo.png'}
										alt="iot-logo"
									/>
									<LangPanel className={classes.langPanel} />
								</span>
								<form action="">
									<h1>{texts.verification}</h1>
									{error}
									{input}
									{button}
									<div>
										<p onClick={this.onNewCodeClickHandler}>{texts.newCode}</p>
										<p onClick={this.onBackClickHandler}>{texts.back}</p>
									</div>
								</form>
							</section>
						</div>
					</div>
				</div>
			);
		}

		return (
			<div>
				{confirmCode}
				{modalVerify}
				{modalCode}
			</div>
		);
	}
}

export default injectIntl(ConfirmCode);
