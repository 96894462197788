// @flow
import React from "react";
import styles from "./LangPanel.module.scss";
import { connect } from "react-redux";
import { setLocale } from "../../../store/actions/localeActions";

const LangPanel = props => {
  return (
    <div className={props.className ? props.className : styles.langPanel}>
      <button
        className={
          props.lang === "en" ? styles.langButtonActive : styles.langButton
        }
        style={props.style}
        onClick={() => props.setLocale("en")}
      >
        EN
      </button>
      <i style={props.style}>|</i>
      <button
        className={
          props.lang === "cs" ? styles.langButtonActive : styles.langButton
        }
        style={props.style}
        onClick={() => props.setLocale("cs")}
      >
        CZ
      </button>
    </div>
  );
};

const mapStateToProps = state => ({
  lang: state.locale.lang
});

export default connect(
  mapStateToProps,
  {
    setLocale
  }
)(LangPanel);
