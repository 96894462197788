import React, { Component } from 'react';
import { connect } from 'react-redux';

import Input from './../../../../../components/UI/Inputs/Input/Input';
import PhoneInput from './../../../../../components/UI/Inputs/PhoneInput/PhoneInput';
import FormNavigation from './../../../../../components/UI/FormNavigation/FormNavigation2';
import PageSpinner from './../../../../../components/Spinner/Spinner';
import {AdminMenu} from "../../../../../components/admin/AdminMenu/AdminMenu"

import { putUser } from './../../../../../store/actions/userActions';
import { toast } from 'react-toastify';

import { FormattedMessage, injectIntl } from 'react-intl';
import * as patterns from './../../../../../utils/InputPatterns';
import * as validations from './../../../../../utils/inputsValidator';
import classes from './MyAccount.module.scss';

const fieldNames = [
	{
		id: 'user.name',
		name: 'name',
		success: <FormattedMessage id="user.name" defaultMessage="Name" />,
		error: <FormattedMessage id="errorMessage.name" defaultMessage="Invalid input" />,
	},
	{
		id: 'user.surname',
		name: 'surname',
		success: <FormattedMessage id="user.surname" defaultMessage="Surname" />,
		error: <FormattedMessage id="errorMessage.name" defaultMessage="Invalid input" />,
	},
];

const stateNameToSensorPropDic = {
	name: { value: 'name', default: 'N/A' },
	surname: { value: 'surname', default: 'N/A' },
	phone_number: { value: 'phone_number', default: '+420' },
};

class MyAccount extends Component {
	successfullyUpdated = false;
	state = {
		name: {
			required: false,
			valid: false,
			value: 'N/A',
			type: 'basic',
			clicked: false,
			active: false,
			validationRules: {
				pattern: patterns.NAME_PATTERN,
				maxLength: 64,
			},
		},
		surname: {
			required: false,
			valid: false,
			value: 'N/A',
			type: 'basic',
			clicked: false,
			active: false,
			validationRules: {
				pattern: patterns.NAME_PATTERN,
				maxLength: 64,
			},
		},
		phone_number: {
			value: '+420',
			clicked: false,
			valid: false,
			required: false,
			type: 'basic',
			active: false,
			validationRules: {
				pattern: patterns.PHONE_NUMBER,
			},
		},
	};

	componentDidMount() {
		if (this.props.user.user) {
			this.syncFields();
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.user.user && this.props.user.user !== prevProps.user.user) {
			this.syncFields();
		}
	}
	syncFields = () => {
		const updatedState = { ...this.state };
		const propsToStateMap = validations.extractStateMapValues(this.props.user.user, stateNameToSensorPropDic);

		for (let mapStateName in propsToStateMap) {
			updatedState[mapStateName] = validations.recomputeState(this.state[mapStateName], propsToStateMap[mapStateName]);
		}
		if (this.successfullyUpdated) {
			toast.success(<FormattedMessage id="modal.user.userUpdated" defaultMessage="Successfully updated" />, {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2000,
			});
			this.successfullyUpdated = false;
		}

		this.setState(updatedState);
	};

	onInputChangedHandler = (e) => {
		const stateName = e.target.name;
		const value = e.target.value;
		const updatedState = validations.recomputeState(this.state[stateName], value);
		const completeState = {
			...this.state,
			[stateName]: updatedState,
		};
		this.setState(completeState);
	};

	onSubmitHandler = (e) => {
		e.preventDefault();
		const body = {
			entity: 'IOT_GSOL',
			part: 'user',
			phone_number: this.state.phone_number.value,
			name: this.state.name.value,
			surname: this.state.surname.value,
		};

		this.successfullyUpdated = true;
		this.props.putUser(body);
	};

	render() {
		const inputs = [];
		fieldNames.forEach((field) => {
			const stateName = field.name;
			inputs.push(
				<Input
					key={field.id}
					label={field.success}
					name={stateName}
					inputChange={this.onInputChangedHandler}
					touched={this.state[stateName].clicked}
					valid={this.state[stateName].valid}
					required={this.state[stateName].required}
					value={this.state[stateName].value}
					defaultValue="N/A"
					errorMessage={field.error}
					maxLength={this.state[stateName].validationRules.maxLength}
				/>
			);
		});

		inputs.push(
			<PhoneInput
				key="sensor.phonenumber"
				label={
					<div style={{ lineHeight: '18px' }}>
						{this.props.intl.formatMessage({ id: 'sensor.phonenumber' }) + '\n'}
						<small style={{ fontSize: '11px' }}>{'(' + this.props.intl.formatMessage({ id: 'sensor.forcall' }) + ')'}</small>
					</div>
				}
				name="phone_number"
				value={this.state.phone_number.value}
				inputChange={this.onInputChangedHandler}
			/>
		);
		const navigationButtons = (
			<FormNavigation
				wraperStyle={{ marginRight: '20%' }}
				confirmBtnDisabled={!validations.checkFormValidity(this.state)}
				backButtonHidden
				homeButtonHidden
				confirm={this.onSubmitHandler}
				back={this.props.onBack}
				confirmText={<FormattedMessage id={'button.save'} defaultMessage="Save" />}
			/>
		);

		let content = <PageSpinner type="rectangles" size="11px" style={{ marginTop: '100px' }} />;
		if (!this.props.isLoading) {
			content = (
				<form action="" className={classes.form}>
					<AdminMenu/>
					{inputs}
					{navigationButtons}
				</form>
			);
		}

		return content;
	}
}

const mapStateToProps = (state) => {
	return {
		isLoading: state.users.isLoading,
		user: state.users.logged_user,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		putUser: (body) => dispatch(putUser(body)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MyAccount));
