export const isInputValid = (rules, value) => {
	const regex = new RegExp(rules.pattern);
	const valid = !(!regex.test(value) || rules.minValue > value || rules.maxValue < value);
	const clicked = true; //value !== "";
	const active = value !== '' && value !== "N/A";

	return {
		valid,
		clicked,
		active
	};
};

export const isLimitsValid = (rules, value) => {
	const regex = new RegExp(rules.pattern);

	const low = value.low === '' ? undefined : +value.low;
	const high = value.high === '' ? undefined : +value.high;

	const low_validation = !(!regex.test(value.low) || rules.interval.low > low || rules.interval.high < low);

	const high_validation = !(
		!regex.test(value.high) ||
		rules.interval.high < high ||
		rules.interval.low > high ||
		low > high
	);

	const valid = [low_validation, high_validation];
	const clicked = true; //value.low !== 'N/A' || value.high !== 'N/A';
	const active = value.low !== 'N/A' || value.high !== 'N/A';

	return {
		valid,
		clicked,
		active
	};
};

export const isTxPeriodValid = (rules, value) => {
	let valueInSec = value.hours * 60 + value.minutes;
	const regex = new RegExp(rules.pattern);
	const valid = !(!regex.test(valueInSec) || rules.minValue > valueInSec || rules.maxValue < valueInSec);
	const clicked = true;
	const active = !(isNaN(value.minutes) && isNaN(value.hours));

	return {
		valid,
		clicked,
		active
	};
};

export const recomputeState = (state, value) => {
	let result = null;
	const type = state.type;

	switch (type) {
		case 'limits':
			result = isLimitsValid(state.validationRules, value);
			break;
		case 'tx_period':
			result = isTxPeriodValid(state.validationRules, value);
			break;
		default:
			result = isInputValid(state.validationRules, value);
			break;
	}

	let recomputedState = {
		...state,
		valid: result.valid,
		clicked: result.clicked,
		active: result.active,
		value: value
	};

	return recomputedState;
};

export const extractStateMapValues = (props, stateMap) => {
	let output = {};

	for (const stateName in stateMap) {
		let propName = stateMap[stateName].value;
		let arrayFields = null;
		if (typeof propName === 'object') {
			const keyName = Object.keys(propName)[0];
			arrayFields = propName[keyName];
			propName = keyName;
		}

		let value = stateMap[stateName].default;

		if (props[propName]) {
			//if (props[propName] !== 'N/A') {
			if (arrayFields) {
				value = {};
				arrayFields.forEach(fieldName => {
					value[fieldName] = props[propName][fieldName];
				});
			} else {
				value = props[propName];
			}
			//}
		}
		output[stateName] = value;
	}

	return output;
};

export const checkFormValidity = states => {

	const checkState = (state) => {
		if (state.required || (!state.required && state.active)) {
			if (Array.isArray(state.valid)) {
				return isValid && state.valid.every(x => x);
			} else {
				return isValid && state.valid;
			}
		}else{
			return true;
		}
	};

	let isValid = true;
	for (const key in states) {
		const stateToCheck = states[key];

		if(Array.isArray(stateToCheck)){
			isValid = isValid && stateToCheck.every(s => checkState(s));
		}else{
			isValid = isValid && checkState(stateToCheck);
		}
	}

	return isValid;

};


export const isEnabled = (config, type) => {
	if (config) {
		if (config.hasOwnProperty(type) && config[type]) {
			return true;
		} else {
			return false;
		}
	}

	return true;
};