import axios from "./../../axios/axios";

import * as userActions from "../actions/userActionTypes";
import { handleRemoteError } from "./sensorActions";
import { unmarshall } from "./../../utils/awsMarshaller";
import {
  isInited,
  getToken,
  getPermissionState,
  deleteToken,
  isNotifSupportedByBrowser,
} from "../../firebase";

export const fetchUser = (entity = "IOT_GSOL", type = null) => {
  let params = {
    params: {
      entity: entity,
    },
  };
  if (type) params.params.type = type;
  return (dispatch) => {
    dispatch(loading(true));
    axios
      .get("/users/loggedUser", params)
      .then((data) => {
        const user = data.data.user.Items.map((item) => unmarshall(item));
        const isSubuser = data.data.isSubuser;
        dispatch(updateUser(user, isSubuser));
      })
      .catch((err) => {
        handleRemoteError(err, "fetchUser");
      })
      .finally(() => {
        dispatch(loading(false));
      });
    //}
  };
};

export const createUser = (body) => {
  return (dispatch) => {
    dispatch(loading(true));
    axios
      .post("/users/loggedUser/", body)
      .then((data) => {
        dispatch(changeState("SUCCESS"));
        dispatch(updateUser(data.data, null));
      })
      .catch((err) => {
        dispatch(handleRemoteError(err, "createUser"));
        dispatch(changeState("FAILED"));
      })
      .finally(() => {
        dispatch(loading(false));
      });
  };
};

export const putUser = (body) => {
  return (dispatch) => {
    dispatch(loading(true));
    axios
      .put("/users/loggedUser/", body)
      .then((data) => {
        dispatch(updateUser(data.data, null));
      })
      .catch((err) => {
        if (err.response.status === 400) {
          dispatch(handleRemoteError(err, "putUser", "https.invalidField"));
        } else {
          dispatch(handleRemoteError(err, "putUser"));
        }
      })
      .finally(() => {
        dispatch(loading(false));
      });
    //}
  };
};

export const deleteUser = () => {
  return (dispatch) => {
    dispatch(loading(true));
    axios
      .delete("/users/loggedUser/")
      .then(() => {
        dispatch(deleteUserComplet());
      })
      .catch((err) => {
        dispatch(handleRemoteError(err, "deleteUser"));
      })
      .finally(() => {
        dispatch(loading(false));
      });
  };
};

export const refreshToken = (entity = "IOT_GSOL") => {
  return (dispatch) => {
    const lastUpdatedTime = +localStorage.getItem("lastRefreshTokenTime");
    let allowUpdate = true;
    if (lastUpdatedTime) {
      const actTime = Date.now() / 1000; // in sec
      allowUpdate = actTime - 3 * 24 * 60 * 60 >= lastUpdatedTime; // token refresh can be done after 3 day from last refresh
    }

    if (
      allowUpdate &&
      isInited() &&
      isNotifSupportedByBrowser() &&
      getPermissionState() === "granted"
    ) {
      dispatch(updateTokenRefreshing(true));

      function saveNewToken() {
        return deleteToken()
          .then(() => {
            // console.log("token vymazan");
            return getToken();
          })
          .then((newToken) => {
            // console.log("ukladam novy token", newToken)
            return axios.post("/users/notifications/push/device", [], {
              params: { entity, id: newToken },
            });
          });
      }

      getToken()
        .then((token) => {
          // console.log("old token", token);
          return axios.get("/users/notifications/push/device", {
            params: { entity, id: token },
          });
        })
        .then((result) => {
          const db_token = unmarshall(result.data.device.Item);
          if (Object.keys(db_token).length > 0) {
            // if this token exists in db - then save new
            // console.log("byl nalezen v db", db_token);
            const promises = [
              axios.delete("/users/notifications/push/device", {
                params: { entity, id: db_token.token },
              }),
              saveNewToken(),
            ];
            return Promise.all(promises);
          } else {
            //otherwise just delete all registered tokens
            // console.log("nebyl nalezen")
            deleteToken();
          }
        })
        .then(() => {
          localStorage.setItem(
            "lastRefreshTokenTime",
            String(Date.now() / 1000)
          );
        })
        .catch((err) => {
          //dispatch(handleRemoteError(err, 'refreshToken'));
        })
        .finally(() => {
          dispatch(updateTokenRefreshing(false));
        });
    }
  };
};

export const loading = (value) => {
  return {
    type: userActions.LOADING,
    value: value,
  };
};

export const updateUser = (user, subuser) => {
  return {
    type: userActions.UPDATE_USER,
    user_data: user,
    is_subuser: subuser,
  };
};

export const updateUserItem = (field, itemName, value) => {
  return {
    type: userActions.UPDATE_USER_ITEM,
    main_field: field,
    sub_field: itemName,
    value: value,
  };
};

export const changeState = (value) => {
  return {
    type: userActions.CHANGE_STATE,
    state: value,
  };
};

export const deleteUserComplet = () => {
  return {
    type: userActions.DELETE_USER,
  };
};

export const updateTokenRefreshing = (isRefreshing) => {
  return {
    type: userActions.REFRESH_TOKEN,
    payload: {
      isRefreshing,
    },
  };
};

export const changeAdminRegime = (value) => {
  return {
    type: userActions.CHANGE_ADMIN_REGIME,
    payload: {
      value,
    },
  };
};
